import { useEffect, useState } from 'react';
import RoundedBox from '../visual-utils/RoundedBox';
import './EmailPopup.css';
import warningIcon from '../../assets/images/icons/sign.svg';
import FormInput from './FormInput';
import { updateAccountByAddress } from '../Api';
import { toast } from 'react-toast';


const styleConfig = {
  width: '100%',
  maxWidth: '508px',
};


const EmailPopup = (props) => {
  const { visible, hideModal} = props;

  const [emailInput,setEmailInput] = useState('');
  const [emailError,setEmailError] = useState('');

  const [hasValidEmail, setHasValidEmail] = useState(false);

 

  const Icon = () => {
    return (
      <div className="text-center pt-3">
        <img src={warningIcon} />
      </div>
    );
  };


  const onHideClicked = (e)=>{
    e.preventDefault();
    hideModal();
  }


  const updateUserAccount = async ()=>{

    if(emailInput!=="" ){
      if(emailError==""){

        const storedAcc = localStorage.getItem('ACCOUNT');
        let acc=JSON.parse(storedAcc);


        let data={
          address:acc.address,
          email:emailInput, //null,
          newsletter_accepted:acc.newsletter_accepted,
          terms_accepted: acc.terms_accepted
        }
        
        const res = await updateAccountByAddress(data).catch(e=>console.log);
        console.log('UPDATE',res);
        if(res && res.success){
          acc.email=emailInput;
          localStorage.setItem('ACCOUNT',JSON.stringify(acc));
        }else{
          toast.warn('Email update failed. Please try again.');
          return;
        }
      }
    }
    hideModal();
  }

  const validateEmail = (emil)=>{
    if(emil==""){
      setEmailError('');
      setHasValidEmail(false);
      return true;
    }

    const valid = emil.toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if(!valid){
      setEmailError('Wrong email format');
      setHasValidEmail(false);
    }else{
      setEmailError('');
      setHasValidEmail(true);
    }

    return valid;
  }


  return (
    <>
      {visible && (
        <div
          className="modal warning-modal"
          style={{ display: 'block' }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <RoundedBox styleConfig={styleConfig}>
              <div className="modal-content">
                <Icon />
                <div className="modal-header justify-content-center mt-4">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Contact info
                  </h5>
                </div>
                <div className="modal-body text-center">
                    <p>You can provide your email address so that we can notify you when your website has been reviewed.</p>
                    <div className="text-left">
                      <FormInput 
                        type='text'
                        label='Email (optional)'
                        className='welcome-email'
                        value={emailInput}
                        onChange={(e)=>{validateEmail(e.target.value);setEmailInput(e.target.value)}}
                        onBlur={(e)=>validateEmail(e.target.value)}
                        error={emailError}
                      />
                    </div>

                </div>
                <div className="modal-footer">
                
                  <button
                    type="button"
                    className="btn"
                    style={{ color: '#ff692b' }}
                    data-dismiss="modal"
                    onClick={(e) => onHideClicked(e)}>
                    No, thanks
                  </button>

                  <button
                    type="button"
                    className="btn send-mail-btn"
                    style={{ color: '#ff692b' }}
                    data-dismiss="modal"
                    disabled={!hasValidEmail}
                    onClick={() => updateUserAccount()}>
                      Send
                  </button>
                </div>
              </div>
            </RoundedBox>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailPopup;
