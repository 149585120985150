import ProjectInfo from './views/ProjectInfo/ProjectInfo';
import SocialMedia from './views/SocialMedia/SocialMedia';
import WalletConnectScreen from './views/WalletConnectScreen/WalletConnectScreen';
import PreSale from './views/PreSale/PreSale';
import MainSale from './views/MainSale/MainSale';
import LayersAndCards from './views/LayersAndCards/LayersAndCards';
import Dashboard from './views/Dashboard/Dashboard';
import MissionControl from './views/MissionControl/MissionControl';
import ShareSetup from './views/ShareSetup/ShareSetup';
import PublishWebsite from './views/PublishWebsite/PublishWebsite';
import DeployMeta from './views/DeployMeta/DeployMeta';
import DeployContracts from './views/DeployContracts/DeployContracts';
import Reveal from './views/Reveal/Reveal';
import LayersOnChain from './views/LayersOnChain/LayersOnChain';
import ConfigUTC from './views/ConfigUTC/ConfigUTC';
import ConfigStickerBook from './views/ConfigStickerBook/ConfigStickerBook';
import ConfigMarketPlace from './views/ConfigMarketPlace/ConfigMarketPlace';
import ConfigLeaderboard from './views/ConfigLeaderboard/ConfigLeaderboard';
import ConfigVault from './views/ConfigVault/ConfigVault';


const routes = [
    {
        path: '/',
        component: WalletConnectScreen,
        exact: true,
    },
    {
        path: '/dashboard',
        component: Dashboard,
        exact: true,
    },
    {
        path: '/project-info',
        component: ProjectInfo,
        exact: true,
    },
    {
        path: '/social-media',
        component: SocialMedia,
        exact: true,
    },
    {
        path: '/presale',
        component: PreSale,
        exact: true,
    },   
    {
        path: '/main-sale',
        component: MainSale,
        exact: true,
    },
    {
        path: '/layers-and-cards',
        component: LayersAndCards,
        exact: true,
    },
    {
        path: '/share-setup',
        component: ShareSetup,
        exact: true,
    },
    {
        path: '/mission-control',
        component: MissionControl,
        exact: true,
    },
    {
        path: '/publish-website',
        component: PublishWebsite,
        exact: true,
    },
    {
        path: '/deploy-meta',
        component: DeployMeta,
        exact: true,
    },
    {
        path: '/deploy-contracts',
        component: DeployContracts,
        exact: true,
    },
    {
        path: '/config-utc',
        component: ConfigUTC,
        exact: true,
    },
    {
        path: '/config-stickerbook',
        component: ConfigStickerBook,
        exact: true,
    },
    {
        path: '/config-marketplace',
        component: ConfigMarketPlace,
        exact: true,
    },
    {
        path: '/config-leaderboard',
        component: ConfigLeaderboard,
        exact: true,
    },
    {
        path: '/config-vault',
        component: ConfigVault,
        exact: true,
    },
    {
        path: '/reveal',
        component: Reveal,
        exact: true,
    },
    {
        path: '/layers-on-chain',
        component: LayersOnChain,
        exact: true,
    }
    
];

export default routes;
