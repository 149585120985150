import './MediaQueries.css'
import './App.css';
import { HashRouter, Route, Switch } from 'react-router-dom';
import routes from './Routes';
import Base from './components/Base/Base';
import DataStore from './components/DataStore/DataStore';
import ConfirmModal from './components/common/ConfirmModal';
import Web3Manager from './components/Web3Manager/Web3Manager';
import { ToastContainer } from 'react-toast';

function App(props) {
    
    return (
        <Web3Manager>
            <div id="application" className="h-100">
                <HashRouter basename="/">
                    <DataStore>
                        <Base>
                            <Switch>
                                {routes.map((route, key) => (
                                    <Route
                                        key={key}
                                        path={route.path}
                                        component={route.component}
                                        exact={route.exact}
                                    />
                                ))}
                            </Switch>
                        </Base>
                        <ConfirmModal/>         
                    </DataStore>
                </HashRouter>
                <ToastContainer delay={8000} position="bottom-right" />
            </div>
        </Web3Manager>
    );
}

export default App;