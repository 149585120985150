import React, { useContext, useEffect, useState } from 'react';
import DashboardPanel from './common/DashboardPanel';
import myicon from '../../../assets/images/mc/stickerbook.svg';
import { state } from '../MissionControl';
import { communityHealthcheck, restartSBA, restartUTC } from '../../../components/Api';
import { DataContext } from '../../../components/DataStore/DataStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../../config';



const StickerBookAdmin = ({communityId,communityHealth})=>{
	const history = useHistory();
	const [components,setComponents] = useState(null);
	const [sbaDisabled,setSbaDisabled] = useState(true);
	const [sbaBaseUrl, setSbaBaseUrl] = useState(null);

	const {
		deployStatus
	  } = useContext(DataContext);

	useEffect(()=>{
		
		

		if(communityId && deployStatus?.contracts && communityHealth){
			getHealtcheck();
			console.log('SBA mounted. community: ',communityId,deployStatus);
		}else{
			//setComponents([])
			setComponents([
				{label:'Admin', status:state.INITIAL,restart:false}
			])
		}
	},[communityId,deployStatus,communityHealth]);

	const getHealtcheck = async ()=>{
		let admin = state.INITIAL;
		let restart = false;

		//let health = await communityHealthcheck(communityId).catch(e=>console.log);
		
		if(communityHealth){
			console.log('SBA communityHealth',communityHealth);
			if(communityHealth.stickerbook){
				if(communityHealth.stickerbook.status==="0" || communityHealth.stickerbook.status==="2"){
					admin = state.PROGRESS;
					restart = communityHealth.stickerbook.status==="2"?true:false;
					
					setSbaBaseUrl(null);
				}

				if(communityHealth.stickerbook.status==="1"){
					admin = state.SUCCESS;
					restart= true;
					setSbaBaseUrl(`${config.DOMAIN}/${communityId}/stickerbook/admin/#/`);
				}

				if(communityHealth.stickerbook.status==="3"){
					admin = state.ERROR;
					restart= true;
					setSbaBaseUrl(null);
				}
			}

			setComponents([
				{label:'Admin', status:admin, restart}
			]);

			if(deployStatus?.contracts?.token){
				setSbaDisabled(false);
			}
		}else{
			console.log('healtcheck not found');
		}
	}

	const handleGoToConfigSBA = () =>{
		history.push('/config-stickerbook');
	}

	const handleRestartSBA = async ()=>{
		let res = await restartSBA(communityId).catch(e=>console.log); 
		if(res){
			console.log('RESTART SBA CALLED',res);
			communityHealth.stickerbook.status="0";
		}
		
		
		getHealtcheck();
	}


	return(<>
		<DashboardPanel
			comingSoon={false}
			components={components}
			disabled={components===null||sbaDisabled}
			title="Sticker Book"
			icon={myicon}
			cta={{label:sbaBaseUrl?"sba dashboard":"configure", action:sbaBaseUrl?sbaBaseUrl:handleGoToConfigSBA}}
			restartBtn={config.RESTART_BUTTONS_ENABLED?handleRestartSBA:null}
		/>
	</>
	);
}

export default StickerBookAdmin;

