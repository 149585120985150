
import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './FormInput.css';
import ImageUploader from "./ImageUploader";
import calendarIcon from '../../assets/images/icons/calendar-icon.svg';
import { Envelope } from "react-bootstrap-icons";
import FileUploader from "./FileUploader";
import { resetWarningCache } from "prop-types";
import { Editor} from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromHTML, convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import InfoBox from "./InfoBox";

//let es = EditorState.createEmpty();

//console.log('ES',es)
const FormInput = ({
					id,
					className,
					type,
					accept,
					prefix,
					label,
					help,
					socialIcon,
					isSocialInput,
					name,
					value,
					options,
					error,
					placeholder,
					disabled,
					onBlur,
					onChange,
					onErrors,
					onClick,
					multiUploadAllowed,
					dimensions,
					extraData,
					mbNone=false,
					isOptional=false,
					upperCase=false,
					setUploading,
					disablePreview,
					isRich,
					successMessage
					}) =>{

	const [hasImage,sethasImage] = useState(false);
	const [dropdownVisible,setDropdownVisible] = useState(false);
	const [displayValue,setDisplayValue] = useState('');

	const [editorState, setEditorState] = useState();
	const [initialDataLoaded,setInitialDataLoaded] = useState(false);
	const [isImage,setIsImage] = useState(false);

	useEffect(()=>{
		if(accept){
			let image = JSON.stringify(accept).indexOf('image')>1;
			//console.log('image?',image);
			setIsImage(image);
		}
	},[])

	useEffect(()=>{
		if(editorState){
			//console.log('editor state changed',editorState.getNativelyRenderedContent());
			const html = getHtml(editorState.getCurrentContent());
			onChange({target:{id:id,value:html}});
		}
	},[editorState])

	const getHtml = (raw) => {
		let res='';
		try{
			res = convertToHTML({
				entityToHTML: (entity, originalText) => {
				  if (entity.type === 'LINK') {
				//	console.log('ENTITY type',entity.type);
					return <a href={entity.data.url}>{originalText}</a>;
				  }
				  return '';
				}
			  })(raw);
		} 
		
		catch (error) {
			console.error('TYPE ERROR',error);
		//	res=raw;
			//console.log(raw);
		}
		  
		if(res==='<p></p>'){
			res = '';
		}
		return res;
	}

	const handleRichEditorBlur = (e,id)=>{
	//	console.log('EVENT, ID',e,id);
		const html = getHtml(editorState.getCurrentContent());
//		console.log('htmlcontent ON BLUR',html);
		onChange({target:{id:id,value:html}});
		onBlur({target:{id:id,value:html}});
	}

	useEffect(()=>{
		if(options && options.length>0){
			Object.keys(options).forEach(element => {
				if(options[element].value === value){
					setDisplayValue(options[element].label);
				}
			});
		}

		if(value && isRich && !initialDataLoaded) {

			//console.log('---value',value,convertFromHTML(value));
			setInitialDataLoaded(true);

			const eState = convertFromHTML({
				htmlToEntity: (nodeName, node, createEntity) => {
					if (nodeName === 'a') {
						return createEntity(
							'LINK',
							'MUTABLE',
							{url: node.href}
						)
					}
				}
			})(value);
			setEditorState(EditorState.createWithContent(eState));
		}
	},[options,value]);

	switch(type) {
		case 'text':
			return (<div className={`form-group ${className&&className} ${disabled&&'disabled'}`} style={{marginBottom: mbNone && 0 }}>
						<div className={`floating-container ${error&&'error'}`}>
							{isSocialInput && <img src={socialIcon} className="absoluteIconForInput"/>}
							
							<div className="d-flex flex-row">
								{prefix&&<div className={`field-prefix ${value&&'has-value'}`}>{prefix}</div>}
								<input
									type={type}  
									className={`${(value || extraData?.preFilled)?'has-value':''} ${upperCase?'text-uppercase':''}`} 
									id={id}
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									disabled={disabled}
									readOnly={!onChange}
								></input>
								<label htmlFor={id}>{label}</label>
							</div>

						</div>
						{(help&&!error)&&<p className="help-text">{help}</p>}

						{error && 
							<p className="help-text error">{error}</p>
						}
					</div>);
		case 'number':
			return (<div className={`form-group ${className&&className} ${disabled&&'disabled'}`} style={{marginBottom: mbNone && 0 }}>
						<div className={`floating-container ${error&&'error'}`}>
							<input 
								type={'text'}  
								pattern={`[0-9!@#$%^*_|`}
								className={`${value!==''&&'has-value'}`} 
								id={id}
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								disabled={disabled}
							></input>
							
							<label htmlFor={id}>{label}</label>
						</div>
						{(help&&!error)&&<p className="help-text">{help}</p>}
						{error && 
							<p className="help-text error">{error}</p>
						}
						{isOptional && <span style={{color:"#ff692b",fontSize: "0.7em", opacity:"0.7"}}>Optional</span>}
					</div>);
		case 'email':
			return (<div className={`form-group ${className&&className} ${disabled&&'disabled'}`} style={{marginBottom: mbNone && 0 }}>
						<div className={`floating-container ${error&&'error'}`}>
							<input 
								type={type}  
								className={`${value&&'has-value'}`} 
								id={id}
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								disabled={disabled}
							></input>
							<label htmlFor={id}><Envelope color="rgba(255,255,255,0.6)"/> {label}</label>
						</div>
						{(help&&!error)&&<p className="help-text">{help}</p>}
						{error && 
							<p className="help-text error">{error}</p>
						}
					</div>);
		case 'textarea':
			return (<div className={`form-group ${className&&className} ${disabled&&'disabled'}`} style={{marginBottom: mbNone && 0 }}>
						<div className={`floating-container ${error?'error':''}`}>
							
							{isRich?
							
								<Editor
									id={id}
									editorState={editorState}
  									onEditorStateChange={setEditorState}
									wrapperClassName={`rich-editor-wrapper ${value&&'has-value'}`}
									editorClassName="rich-editor"
									toolbar={{
										options: ['inline', 'blockType', 'fontSize','list','link'],
										inline: {
											options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
											bold: { className: 'bordered-option-classname' },
											italic: { className: 'bordered-option-classname' },
											underline: { className: 'bordered-option-classname' },
											strikethrough: { className: 'bordered-option-classname' },
											
										},
										
										blockType: {
											inDropdown: true,
    										options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
											className: 'bordered-option-classname',
										},
										fontSize: {
											className: 'bordered-option-classname',
										}
									}}
									onBlur={(e)=>handleRichEditorBlur(e,id)}
								/>
							




							:
							<textarea
								className={`form-control ${value&&'has-value'}`} 
								id={id}
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								disabled={disabled}
								/>}
								
							<label htmlFor={id}>{label}</label>
						</div>
						{(help&&!error)&&<p className="help-text">{help}</p>}
						{error && 
							<p className="help-text error">{error}</p>
						}
					</div>);

		case 'file':
			return(
				<div className={`form-group ${className?className:''} ${disabled?'disabled':''}`} style={{marginBottom: mbNone && 0 }}>
						<div className={`floating-container ${error?'error':''}`} style={isImage && dimensions?{paddingTop: `calc(${(dimensions.height/dimensions.width)*100}% - 4px)`}:{}}>
							
							{isImage?<>
								<ImageUploader 
									id={id} 
									disabled={disabled} 
									value={value} 
									label={label} 
									help={help}
									accept={accept}
									multiUploadAllowed={multiUploadAllowed}
									dimensions={dimensions}
									extraData = {extraData}
									disablePreview = {disablePreview}
									onErrors={onErrors}
									setUploading={setUploading}
									onImageSelected={e=>{if(!multiUploadAllowed){sethasImage(true);}; onChange(e)}} 
								/>
								{((hasImage && !disablePreview) || (value!=='' && !multiUploadAllowed && !disablePreview))&& <label className="image-uploader" htmlFor={id}>{label}</label>}
							</>
							:
							<>
								<FileUploader 
									disabled={disabled} 
									value={value} 
									label={label} 
									help={help}
									accept={accept}
									successMessage={successMessage?successMessage:'Your file has been uploaded successfully.'}
									onFileSelected={e => onChange(e)}
								/>
							</>}
						
						</div>
							{error && 
								<p className="help-text error">{error}</p>
							}
					</div>
					);						
		case 'select_old':
			return (<div className={`form-group ${className&&className} ${disabled&&'disabled'}`}>
						<div className={`floating-container ${error&&'error'}`}>
							<select
								type={type}  
								className={`select ${value&&'has-value'}`} 
								id={id}
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								disabled={disabled}
							>
							{options.map((o, i) => {
            				return(
								<option value={o.value}>{o.label}</option>
							)})
								
							}
							</select>
							<label>{label}</label>
							<div className="select-triangle"/>
						</div>
						{(help&&!error)&&<p className="help-text">{help}</p>}
						{error && 
							<p className="help-text error">{error}</p>
						}
					</div>);

		case 'select':

			const [displayOptions,setDisplayOptions]=useState([]);
			const [hasFilter,setHasFilter] = useState(false);
			const [filter,setFilter] = useState('');
			const [overDropDown,setOverDropDown] = useState(false);

			useEffect(()=>{
				//console.log('optionsChanged',id,value,options);
				setDisplayOptions([...options]);
				if(options.length>30){
					setHasFilter(true);
				}	
			},[options]);

			const someFn=(e)=>{
				if(!hasFilter || !dropdownVisible){
					return;
				}
				let f = filter;

				if(e.keyCode===8){
					f=f.slice(0, -1);
				}else {
					f+=e.key.length===1?e.key:''
				}
				let opts = options.filter(i=>{return i.label.toLowerCase().indexOf(f)>-1});
				setDisplayOptions(opts);
				setFilter(f);
				setDisplayValue(f);
			}

			const resetFiltered = ()=>{
				if(filter!==''){
					setFilter('');
					console.log('reset',value);
					setDisplayOptions([...options]);
	
					if(value){
						setDisplayValue(options[value-1].label);
					}else{
						setDisplayValue('');
					}
				}

			}


			return (<div className={`form-group ${className&&className} ${disabled&&'disabled'}`} style={{marginBottom: mbNone && 0 }}>
						<div className={`floating-container ${error&&'error'}`} onBlur={(e)=>{!overDropDown&&setDropdownVisible(false)}}>
							
							<input
								type="text"  
								className={`select-input val-${value+''}- ${value!==''?'has-value':''}`} 
								id={id}
								name="select-holder"
								onKeyDown={e=>someFn(e)}
								onClick={e=>{dropdownVisible&&resetFiltered(); setDropdownVisible(!dropdownVisible)}}
								onBlur={onBlur}
								value={value}
								disabled={disabled}
								readOnly
							/>
							<label htmlFor={id}>{label}</label>
							<div className="select-triangle"/>
							<span id={"disp_"+id}>{displayValue}</span>

							<ul className={`select-dropdown ${dropdownVisible&&'opened'}`}
								onMouseEnter={(e=>{setOverDropDown(true)})}
								onMouseLeave={(e=>{setOverDropDown(false)})}
							>

								{displayOptions.map((o, i) => {
								return(
									<li key={id+i} className={`${value===o.value?'selected':''}`} 
										onClick={e=>{onChange({target:{id,value:o.value}});
													setDropdownVisible(false);setDisplayValue(o.label);setFilter('')}}>
										{o.label}
									</li>
								)})}
						

							</ul>
							

						</div>
						{(help&&!error)&&<p className="help-text">{help}</p>}
						{error && 
							<p className="help-text error">{error}</p>
						}
					</div>);			
		case 'date':
			return (<div className={`form-group ${className&&className} ${disabled&&'disabled'}`} style={{marginBottom: mbNone && 0 }}>
						<div className={`floating-container ${error&&'error'}`}>
						{isSocialInput && <img src={socialIcon} className="absoluteIconForInput"/>}

						<div className={`date-picker ${value&&'has-value'}`} >
							<DatePicker
								showTimeSelect
								dateFormat="MMM d, yyyy h:mm aa"
								minDate={new Date(Date.now())}//+7*24*60*60*1000)} //it was 60 days, now 7.
								selected={value}
								onChange={onChange}
								disabled={disabled}
							/>
						</div>

							
							<label htmlFor={id}>{label}</label>
							<div className="calendar-icon"><img src={calendarIcon}/></div>
						</div>
						{(help&&!error)&&<p className="help-text">{help}</p>}
						{error && 
							<p className="help-text error">{error}</p>
						}
					</div>);

		case 'checkbox':
			return (<div className={`form-group ${className&&className} ${disabled&&'disabled'}`} style={{marginBottom: mbNone && 0 }}>
				<div className={`checkbox-container d-flex align-items-center`}>
					<input 
						type="checkbox"  
						className={`${value&&'has-value'}`} 
						id={id}
						onChange={onChange}
						disabled={disabled}
						checked={value}/>
					<label className="mb-0 ml-2" htmlFor={id}>{label}</label>{help&&<InfoBox centered={true} position="top">{help}</InfoBox>} 
				</div>				
			</div>);
		case 'checkbox-custom':
			return (
				<>
					<div className={`${className&&className} ${disabled&&'disabled'}`} 
						 style={{marginBottom: mbNone && 0 }}
						 onClick={onClick}>
						<label htmlFor={id} className="customLabel">
						{label}
						<input type="checkbox"  
							className={`${value&&'has-value'}`} 
							id={id}
							onChange={onChange}
							disabled={disabled}
							checked={value?'checked':''}/>
						<span className="checkmark"></span>
						</label>
					</div>
					{error && 
						<p className={`help-text ${className&&className}`}>{error}</p>
					}
				</>
			);

		case 'radio-custom':
			return (
				<>
					<div className={`radio-container ${className&&className} ${disabled&&'disabled'}`}>

						{options.map((opt,i)=>{return(
							<React.Fragment key={id+i}>
								<input type="radio"
									id={id+i}
									className={`${value&&'has-value'}`} 
									name={id}
									//onChange={onChange}
									onChange={e=>{onChange({target:{id,value:opt.value}})}}
									disabled={disabled}
									value={opt.value}
									checked={opt.value===value}
								/>
								<label htmlFor={id+i}>{opt.label}</label>
							</React.Fragment>
						)})

						}
					</div>
					{(help&&!error)&&<p className="help-text">{help}</p>}
					{error &&<p className="help-text error">{error}</p>}
				</>
			);

		default:
			return (<>Unknownn type</>);
		}



}


export default FormInput;