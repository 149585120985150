import React, { useContext, useEffect, useState } from 'react';
import DashboardPanel from './common/DashboardPanel';
import myicon from '../../../assets/images/mc/TA.svg';
import { state } from '../MissionControl';
import { communityHealthcheck } from '../../../components/Api';
import { DataContext } from '../../../components/DataStore/DataStore';
import { Contract } from 'ethers';
import useConfirm from '../../../components/common/useConfirm';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const TokenArchitect = ({communityId,communityHealth})=>{

	/* const [components,setComponents] = useState([
		{label:'Website', status:state.INITIAL},
		{label:'Metadata', status:state.INITIAL},
		{label:'Contracts', status:state.INITIAL}
	]); */
	const { isConfirmed } = useConfirm();
	const history = useHistory();
	const [components,setComponents] = useState(null);

	const {
		deployStatus
	  } = useContext(DataContext);

	useEffect(()=>{

		const setHealthStatus = async ()=>{
			//let health = await communityHealthcheck(communityId).catch(e=>console.log);

			if(communityHealth && communityHealth.success){
				//console.log('communityHealth',communityHealth);
				//console.log('DEPLOY STATUS',deployStatus);

				let site = state.INITIAL;
				let meta = state.INITIAL;
				let contract = state.INITIAL;

				if(deployStatus?.siteStatus?.status.name=="Under Review"){
					site=state.PROGRESS;
				}else if(deployStatus?.siteStatus?.status.name=="Approved"){
					site=state.SUCCESS;
				}else if(deployStatus?.siteStatus?.status.name=="Rejected"){
					site=state.ERROR;
				}

				if(deployStatus?.metaStatus?.status.name=="Under Review" || 
				   (communityHealth.metadata.status=='0' || communityHealth.metadata.status=='2')||
				   (deployStatus?.metaStatus?.status.name=="Approved" && communityHealth.metadata.status==null)
				){
					meta=state.PROGRESS;
				}
				if(deployStatus?.metaStatus?.status.name=="Approved" && communityHealth.metadata.status=='1'){
					meta=state.SUCCESS;
				}
				if(deployStatus?.metaStatus?.status.name=="Rejected" || communityHealth.metadata.status=='3' ){
					meta=state.ERROR;
				}

				if(deployStatus?.contracts?.sale){
					contract=state.SUCCESS;
				}

				setComponents([
					{label:'Website', status:site},
					{label:'Metadata', status:meta},
					{label:'Contracts', status:contract}
				]);
			}
		}

		/* console.log('TA communityHealth',communityHealth);
		console.log('TA deployStatus',deployStatus); */

		if(deployStatus && deployStatus.siteStatus && deployStatus.metaStatus && communityHealth){
			setHealthStatus();
			console.log('tokenArchitect mounted. community: ',communityId);
		}
		

	},[deployStatus,communityHealth]);


	const handleGoToArchitect = async ()=>{
		if(deployStatus.siteStatus.status.name!=="Rejected" && deployStatus.metaStatus.status.name!=="Rejected"){
		  history.push('/project-info');
		}else{
	
		  const title = deployStatus.siteStatus.status.name=="Rejected"?deployStatus.metaStatus.status.name=="Rejected"?"The publish of your website and images has been delayed":"The publish of your website has been delayed":"The publish of your images has been delayed";
	
		  let content = '';
	
		  if(deployStatus.siteStatus.status.name=="Rejected"){
			content+=deployStatus.siteStatus.reason+' ';
		  }
		  if(deployStatus.metaStatus.status.name=="Rejected"){
			content+=deployStatus.metaStatus.reason+' ';
		  }
	
		  const confirmed = await isConfirmed(title,content,'Go to the issues','','ERROR');
	
		  if(deployStatus.siteStatus.status.name=="Rejected"){
			history.push('/project-info');
		  }else{
			history.push('/layers-and-cards');
		  }
		}
	  }



	return(
		<DashboardPanel
			comingSoon={false}
			disableDisable = {true}
			disabled={components===null}
			title="token architect"
			icon={myicon}
			cta={{label:"token architect", action:handleGoToArchitect}}
			components={components}
		/>
	);
}

export default TokenArchitect;

