import './MissionControl.css';
import React, { useContext, useEffect, useState } from 'react';
import mcLaunchpad from '../../assets/images/mc/TA.svg';
import mcTraits from '../../assets/images/mc/utility-trait-logo.svg';
import mcCommunity from '../../assets/images/mc/CS.svg';
import mcGovernance from '../../assets/images/mc/governance.svg';
import leftTrick from '../../assets/images/leftTrick.svg';
import rightTrick from '../../assets/images/rightTrick.svg';
import leftTopTrick from '../../assets/images/leftTopTrick.svg';
import rightTopTrick from '../../assets/images/rightTopTrick.svg';
import { DataContext } from '../../components/DataStore/DataStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { communityHealthcheck, getProjectInfoById, restartUTC } from '../../components/Api';
import { CheckCircleFill, ClockHistory, XCircleFill } from 'react-bootstrap-icons';
import useConfirm from '../../components/common/useConfirm';
import { SpinnerCircular } from 'spinners-react';
import config from '../../config';
import axios from 'axios';
import Web3Ctx from '../../components/Context/Web3Ctx';
import TokenArchitect from './DashboardPanels/TokenArchitect';
import UtiltyTraitCenter from './DashboardPanels/UtiltyTraitCenter';
import CommunitySpace from './DashboardPanels/CommunitySpace';
import Governance from './DashboardPanels/Governance';
import StickerBookAdmin from './DashboardPanels/StickerBookAdmin';
import Rewards from './DashboardPanels/Rewards';
import MarketPlace from './DashboardPanels/MarketPlace';
import useInterval from '../../components/common/useInterval';
import Leaderboard from './DashboardPanels/Leaderboard';
import Vault from './DashboardPanels/Vault';


export const state = {
	INITIAL:-1,
	PROGRESS:0,
	SUCCESS:1,
	ERROR:2
}

const MissionControl = (props) => {
  //!!!
  const collectionId = 1;
  const HC_POLL_INTERVAL = 20000;
  const [healtcheckInterval,setHealtcheckInterval] = useState(null);

  const [currentProjectName, setCurrentProjectName] = useState('');
  const [utcBaseUrl, setUtcBaseUrl] = useState(null);
  const [communityBaseUrl, setCommunityBaseUrl] = useState(null);
  const [communityHealth,setCommunityHealth] = useState(null);

 // const allowedWallets=['0x0A2926f2E2C9d60AEBf5Cfe0911FbdeFCE47Db5E','0xeA3F46C50AE211Fe59107321A32aF97F215df566','0x6FCCA8A59b545754E8f66bA29cCf2AE7f1138490'];

  const {
    currentProjectId,
    updateCurrentProjectId,
    deployStatus,
    currentCommunityName,
    communityId,  
    initDone
  } = useContext(DataContext);

  const {address} = useContext(Web3Ctx);

  //const { setChain, chainId} = useContext(Web3Ctx);
  //const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
/*   const [currentProject, setCurrentProject] = useState(null);

  const [siteState, setSiteState] = useState('');
  const [metaState, setMetaState] = useState('');
  const [utcState,setUtcState] = useState('');
  const [communityState,setCommunityState] = useState('');

  const [metadataServerUrl, setMetadataServerUrl] = useState(null);

  const { isConfirmed } = useConfirm(); */

  const sx = {
    root: {
      display: "flex",
      height: "100%",
      width: "100%",
      background:'none'
    },
    container: {
      textAlign: "center",
      margin: "auto",
    },
  };


  const SectionDividers = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <img src={leftTrick} style={{ maxHeight: '50px' }} />
      <img src={rightTrick} style={{ maxHeight: '50px' }} />
    </div>
  );


  const SectionTop = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <img src={leftTopTrick} style={{ maxHeight: '50px' }} />
      <img src={rightTopTrick} style={{ maxHeight: '50px' }} />
    </div>
  );

/*   const SectionBottom = () => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <img src={leftBottomTrick} style={{ maxHeight: '50px' }} />
      <img src={rightBottomTrick} style={{ maxHeight: '50px' }} />
    </div>
  );
 */

 /*  useEffect(()=>{
    if(deployStatus && Object.keys(deployStatus).length>0){

      console.log('MC DS',deployStatus)
      if(deployStatus.siteStatus){
        setSiteState(deployStatus.siteStatus.status.name);
      }
      if(deployStatus.metaStatus){
        setMetaState(deployStatus.metaStatus.status.name);
      }
    }

  },[deployStatus]);

 */
  useEffect(()=>{

    const getProjectInfo = async (projectId)=>{
     // console.log('ITEMID',projectId );
      
      const res= await getProjectInfoById(projectId).catch(e=>console.log);
    //  console.log('BYID',res)
      if(res.success){
       // setHeaderImage(res.data.large_banner);
       // setAddressIcon(res.data.small_banner);
        setCurrentProjectName(res.data.name);
       // setMetadataServerUrl(res.data.metadata_url);
      }else{
        setCurrentProjectName(currentCommunityName);
      }
    /*   

      let enabled = allowedWallets.find(a=>{
        return a.toLowerCase()===address.toLowerCase()
      });

      console.log('ENABLED?',allowedWallets,address,!!enabled);
 */

      hc();
      
      setHealtcheckInterval(HC_POLL_INTERVAL);

      updateCurrentProjectId(projectId);

      setIsLoading(false);
    }
    
    if(currentProjectId && communityId){
      setIsLoading(true);
      getProjectInfo(currentProjectId);
    }

  },[currentProjectId,communityId])


  
  const hc = async ()=>{
    let healthRes = await communityHealthcheck(communityId).catch(e=>console.log);

     // console.log('MC HEALTCHECK RESULT', healthRes);

      if(healthRes.success){
        setCommunityHealth(healthRes);
      }else{
        setCommunityHealth({success:'no'})
      }
  }

  useInterval(
		hc,
		healtcheckInterval
	);


/* 
  useEffect(()=>{

    const checkHealt = async ()=>{
      //https://hel2.galaxis.xyz

      let utcUrl=`${config.DOMAIN}/${communityId}/utc/`;
      let cspUrl=`${config.DOMAIN}/${communityId}/${collectionId}/community`;
      console.log('urls',utcUrl,cspUrl);

      let res = await communityHealthcheck(communityId).catch(e=>console.log);

      console.log('HEALTCHECK RESULT', res);

      if(res.success){

        if(res.utc){
          switch (res.utc.status){
            case '0':
              {
                setUtcState('Deploying');
                setUtcBaseUrl(null);
                break;
              }
            case '1':
              {
                setUtcState('Deployed');
                setUtcBaseUrl(utcUrl);
                break;
              }
            case '2':
              {
                setUtcState('Error');
                setUtcBaseUrl(null);
                break;
              }
            default:
              {
                setUtcState(null);
                setUtcBaseUrl(null);
              }
          }
        }else{
            setUtcState(null);
            setUtcBaseUrl(null);
        }

        if(res.community){
          switch (res.community.status){
            case '0':
              {
                setCommunityState('Deploying');
                setCommunityBaseUrl(null);
                break;
              }
            case '1':
              {
                setCommunityState('Deployed');
                setCommunityBaseUrl(utcUrl);
                break;
              }
            case '2':
              {
                setCommunityState('Error');
                setCommunityBaseUrl(null);
                break;
              }
            default:
              {
                setCommunityState(null);
                setCommunityBaseUrl(null);
                break;
              }
          }
        }else{
            setCommunityState(null);
            setCommunityBaseUrl(null);
        }
      }else{
        console.log('Healtcheck error.')
      }

    }

    if(communityId && initDone){
      checkHealt();
    }

  },[communityId,initDone]) */

  return (
    <>
      <div className={`mc-outer`}>
        <div className="nav-placeholder" />
        {/* <div className="mc-header"  style={{backgroundImage:`url(${headerImage})`}}/> */}
        <div className="container no-header pt-4">
          {isLoading ? (
            <div style={sx.root}>
                <div style={sx.container}>
                  <SpinnerCircular size="48px" color="#ff692b" />
                </div>
						</div>
          ) : (
            <>
              <div className="row">
                <div className="content">
                  <SectionTop />

                  <h5 className="mb-1 text-center font-weight-light f14">
                    PROJECT NAME
                  </h5>
                  <h4 className="title">{currentProjectName}</h4>
                  <h5 className="f14  text-center mt-0"><span className="font-weight-light">COMMUNITY ID:</span> {Number(communityId)}</h5>

                  <SectionDividers />
                  <div className="row d-flex small-gutters flex-wrap">

                    <TokenArchitect communityId={Number(communityId)} communityHealth={communityHealth}/>

                    <UtiltyTraitCenter communityId={Number(communityId)}  communityHealth={communityHealth}/>

                    <StickerBookAdmin communityId={Number(communityId)} communityHealth={communityHealth}/>

                    <Rewards communityId={Number(communityId)}/>

                    <MarketPlace communityId={Number(communityId)} communityHealth={communityHealth}/>
                    
                    <Leaderboard communityId={Number(communityId)} communityHealth={communityHealth}/>
                    
                    <Vault communityId={Number(communityId)} communityHealth={communityHealth}/>
                    
                    <CommunitySpace communityId={Number(communityId)}/>

                    <Governance communityId={Number(communityId)}/>

                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MissionControl;
