import { BigNumber, ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toast';
import { addTokenAddress, createOrUpdateSale, getLayerInfo, getProjectInfoById, getProjectStatus, getSaleForProject, getSocialMedia, getWalletShares, publishProject } from '../../components/Api';
import { contractKeys, DataContext } from '../../components/DataStore/DataStore';
import SSPFooter from '../../components/SSPFooter/SSPFooter';
import titleIcon from '../../assets/images/page-title-icons/contract.png';
import Web3Ctx from '../../components/Context/Web3Ctx';
import {
	CheckCircleFill,
	XCircleFill,
  } from 'react-bootstrap-icons';

import './DeployContracts.css';

import useConfirm from '../../components/common/useConfirm';
import config from '../../config';
import FormInput from '../../components/common/FormInput';
import { getContractAddress } from 'ethers/lib/utils';
import { getContract, getContractByAddress } from '../../components/Utils/GetContract';
import { SpinnerCircular } from 'spinners-react';
import saleContractAbi from '../../contracts/SaleContract.json';
import BackdropModal from '../../components/common/BackdropModal';
import TxInProgressModal from '../../components/common/TxInProgressModal';
import useInterval from '../../components/common/useInterval';

const DeployContracts = (props) => {
	const {onboard,address,handleConnect,getProvider,setChain,chainId} = useContext(Web3Ctx);
	const { currentProjectId,checkDeployStatus,deployStatus,refreshCommunityData, communityRegistry } = useContext(DataContext);
	
	const [deployEnabled, setDeployEnabled] = useState(false);
	const [polygonWarning, setPolygonWarning] = useState(false);

	const { isConfirmed } = useConfirm();

	const location = useLocation();
	const history = useHistory();

	const supportedChains=config.CHAINS;

	const [selectedChainIdx, setSelectedChainIdx] = useState(0);
	const [chains, setChains] = useState([]);
	const [targetChain, setTargetChain] = useState(null);
	const [communityId, setCommunityId] = useState(null);

	const [factoryContract, setFactoryContract] = useState(null);
	//const [communityRegistry, setCommunityRegistry] = useState(null);

	const [saleContract, setSaleContract] = useState(null);
	const [tokenContract, setTokenContract] = useState(null);
	//const [factoryProjectId, setFactoryProjectId] = useState(null);
	const [factoryTxHash, setFactoryTxHash] = useState(null);
	const [txInProgress, setTxInProgress] = useState(false);
	const [approveInProgress, setApproveInProgress] = useState(false);
	
	const [whitelistUpdateEnabled,setWhitelistUpdateEnabled] = useState(false);
	const [whitelistActivationInProgress,setWhitelistActivationInProgress] = useState(false);
	const [whitelistTxHash, setWhitelistTxHash] = useState(null);

	const [isLoading, setIsLoading]=useState(true);

	const [reservedSupply,setReservedSupply] = useState('');
	const [pollInterval, setPollInterval] = useState(null);
	const [wantToReserve, setWantToReserve] = useState(false);

	const AbiCoder = new ethers.utils.AbiCoder();

	const chainNames = {
		1: 'Mainnet',
		5: 'Goerli',
		137: 'Polygon',
		80001: 'Mumbai'
	}




/* 	const WaitForDeployedContracts = async()=>{
		

	}



	useInterval(
		WaitForDeployedContracts,
		pollInterval
	); */

	useEffect(()=>{
		
		if(factoryContract && deployStatus && currentProjectId){


			/* console.log('CR IN DEPLOY CONTRACT',deployStatus);
			console.log('FACTORY IN DEPLOY CONTRACT',factoryContract, currentProjectId);
			console.log('COMMUNITY REG',communityRegistry); */


			if(deployStatus.contracts){
				setSaleContract(deployStatus.contracts.sale);
				setTokenContract(deployStatus.contracts.token);
			}

			if(communityRegistry && !communityRegistry.deployed){
				setPolygonWarning(true);
			}



/* 
			if(Number(communityRegistry.SALE_COUNT)>0 && Number(communityRegistry.TOKEN_COUNT)>0 ){

			}
 */


		/* 
			console.log('getting pd',factoryContract,communityRegistry,communityId);
			getProjectContracts(false);
		*/
			
			//TODO: set contract addresses from the context's community registry
		}

	},[factoryContract,deployStatus,communityId, communityRegistry]);


	useEffect(()=>{
		if(currentProjectId!=null){
			console.log('---CHAIN ID');
			setIsLoading(true);

			const promises = [
				getProjectStatus(currentProjectId),
				getProjectInfoById(currentProjectId)/* ,
				getSaleForProject(currentProjectId,1),
				getSaleForProject(currentProjectId,4) */
			];

			Promise.all(promises)
			.then(res=>{
				const [status, info] = res;

				/* console.log('HEY',status, info, pSale,customPresale) */

				
				console.log(status,info);

				if(status.success && info.success){

					setCommunityId(BigNumber.from(status.data.community_id));

					console.log('community Id',BigNumber.from(status.data.community_id));

					let cid = status.data.chain_id?status.data.chain_id:config.DEPLOYED_CHAIN_ID; //for older projects
					setTargetChain(cid);



					if(chainId){
						const idx = supportedChains.findIndex(c => {
							return parseInt(c.id,16) === cid;
						});
						if(idx>-1){
							setSelectedChainIdx(idx);
						}
					}


					if( status.data.last_metadata_status.status.name === "Approved" &&
					status.data.last_website_status.status.name === "Approved" &&
					info.data.metadata_url/*  && info.data.pre_reveal_json_hash */){
						setDeployEnabled(true);
					}else{
						setDeployEnabled(false);
					}
				}
				
				//TODO:KIVENNIIIIIIII
				//setDeployEnabled(true);

			/* 	if(pSale.success || customPresale.success){
					console.log('PRESALE',pSale.data[0])
					setWhitelistUpdateEnabled(pSale.data[0].active);
				} */
			}).catch(e=>{console.log(e);setIsLoading(false);});
			
		}
	},[currentProjectId]);

	useEffect(()=>{
		const initContract = async (contractChainId)=>{
			let ethersProvider = await getProvider(contractChainId);
			
			const galaxisRegistryOnTargetChain = await getContractByAddress('GalaxisRegistry',config.GALAXIS_REGISTRY,ethersProvider).catch(e => console.log('err:', e));
			
			console.log('DC: ',contractChainId,ethersProvider,galaxisRegistryOnTargetChain)
			
			if(!galaxisRegistryOnTargetChain){
				toast.error('Target chain not supported.');
				return;
			}

			console.log('---+++Init contracts... START');

			let factoryAddress = await galaxisRegistryOnTargetChain.getRegistryAddress(contractKeys.PROJECT_FACTORY).catch(e => console.log('err:', e));
			console.log('---+++Init contracts... Factory Address:',factoryAddress);
			
		/* 
			let communityListAddress = await galaxisRegistryOnTargetChain.getRegistryAddress(contractKeys.COMMUNITY_LIST).catch(e => console.log('err:', e));
			console.log('---+++Init contracts... Communnity List Address:',communityListAddress);

		
			
			let communityListContract = await getContractByAddress('CommunityList',communityListAddress , ethersProvider);
			let communityRegistryEntry = await communityListContract.communities(communityId).catch(e=>console.log);
			let thisCommunityRegistry = await getContractByAddress('CommunityRegistry', communityRegistryEntry.registry, ethersProvider);
 		*/
			console.log('---+++Init contracts... END');




			const contract = await getContractByAddress('ContractFactory',factoryAddress ,ethersProvider).catch(e => console.log('err:', e));

			if (contract && contract!== ethers.constants.AddressZero) {
				setFactoryContract(contract);
				//console.log('Factory', contract);
			} else {
				toast.error('Project Factory not found');
				//setIsLoading(false);
				setFactoryContract(null);
				console.error('contract not found (factory)',contract);
			}

			/* if(thisCommunityRegistry && thisCommunityRegistry!==ethers.constants.AddressZero) {
				setCommunityRegistry(thisCommunityRegistry);
			} else {
				toast.error('Community Registry not found');
				setIsLoading(false);
				setCommunityRegistry(null);
				console.error('contract not found (community registry)',thisCommunityRegistry);
			} */

			setIsLoading(false);

		}

		//console.log(targetChain,chainId);
		//if(communityId && targetChain && targetChain===chainId && ){
		if(communityId && targetChain && targetChain===chainId && !deployStatus.contracts?.token){
			
			initContract(targetChain);
		}else{
			console.log('---DS',deployStatus)
			if(deployStatus.contracts){
				setSaleContract(deployStatus.contracts.sale);
				setTokenContract(deployStatus.contracts.token);
			}
		}

	},[targetChain,chainId,communityId,deployStatus]);
	//},[targetChain,chainId,communityId]);
/* 


	useEffect(()=>{
		if(saleContract){
			let res = checkWhitelistStatus();
		}

	},[saleContract])

	const checkWhitelistStatus = async ()=>{
		//console.log('checking whitelist',targetChain);
		let provider = await getProvider(targetChain);

		if(!provider)return;
		
		let sale = new ethers.Contract(saleContract, saleContractAbi.abi, provider);
			
		if(!sale)return;
		
		let saleInfo = await sale.tellEverything().catch(e=>console.log('tell everything failed',e));
		//console.log('sale info',saleInfo);
	} */


	const handleDeploy = async() => {
		if(currentProjectId!=null){
			setTxInProgress(true);
			setApproveInProgress(true);
			let res = await getSaleData();
			console.log('REEEES',res);

			if(res.pName){
				launchProject(res.saleConfig,res.tokenConfig);
			}else{
				toast.error('Deploy failed.');
				setTxInProgress(false);
				setApproveInProgress(false);
			}
		}
	}

	const getSaleData = async ()=>{

		return new Promise (async(resolve,reject)=>{
			const promises = [
				getProjectStatus(currentProjectId),
				getProjectInfoById(currentProjectId),
				getLayerInfo(currentProjectId),
				getWalletShares(currentProjectId),
				getSaleForProject(currentProjectId,1),	//Presale
				getSaleForProject(currentProjectId,4),	//Custom Presale
				getSaleForProject(currentProjectId,3)	//MainSale
			];
	
			Promise.all(promises)
			.then(res=>{
				const [status, info, nfts, shares, presale,customPresale, mainSale] = res;
	
				console.log('Project',status);
				console.log('ProjectInfo',info);
				console.log('NFTs',nfts);
				console.log('WalletShares',shares);
				console.log('PreSale',presale);
				console.log('MainSale',mainSale);

				if(!info.success || !nfts.success || !shares.success || !presale.success|| !customPresale.success || !mainSale.success){
					throw('Backend error');
				}else{
					let rs=reservedSupply?Number(reservedSupply):0;

					let presaleData = presale.data.length>0?presale.data[0]:customPresale.data[0];

					console.log('PRESALE _ DATA',presale,presaleData)

					const offset = new Date().getTimezoneOffset()*60;
					let saleConfig =[
						BigNumber.from(communityId),				//project ID
						'0x0000000000000000000000000000000000000000',								//Token address
						getWalletShareAddresses(shares),						//payable wallets
						getWalletShareShares(shares),							//share percenntages
						30,												//maxMintPerTransaction
						presaleData.max_tokens?presaleData.max_tokens:0,						//Max sold in presale across presale
						presaleData.max_tokens_per_wallet?presaleData.max_tokens_per_wallet:0,			//Limit presale per address
						mainSale.data[0].max_tokens_per_wallet?mainSale.data[0].max_tokens_per_wallet:mainSale.data[0].max_tokens,					//Limit sale per address ( includes presale )
									
						(presaleData.active && presaleData.start_date)?Math.floor(new Date(new Date(presaleData.start_date).toGMTString()).getTime()/1000)-offset:0,	//uint256 presaleStart;
						(presaleData.active && presaleData.end_date)?Math.floor(new Date(new Date(presaleData.end_date).toGMTString()).getTime()/1000)-offset:0,		//uint256 presaleEnd;
						
						(!!(mainSale.data[0].active) && mainSale.data[0].start_date)?Math.floor(new Date(new Date(mainSale.data[0].start_date).toGMTString()).getTime()/1000)-offset:0,	//uint256 mainSaleStart;
						(!!(mainSale.data[0].active) && mainSale.data[0].end_date)?Math.floor(new Date(new Date(mainSale.data[0].end_date).toGMTString()).getTime()/1000)-offset:0,		//uint256 mainSaleEnd;

						(!!(mainSale.data[0].active) && mainSale.data[0].eth_price)?ethers.utils.parseUnits(mainSale.data[0].eth_price,'ether'):0,	//uint256 fullPrice;
						//(!!(mainSale.data[0].active) && mainSale.data[0].max_tokens)?mainSale.data[0].max_tokens:0,					//uint256 maxUserMintable 
						0,					//uint256 maxUserMintable (let the sale contract decide...)
						
						presaleData.signer_address?presaleData.signer_address:'0x0000000000000000000000000000000000000000',					//address signer;
			
						//this one below
						(!!(mainSale.data[0].active) && mainSale.data[0].token_price!==null)?ethers.utils.parseUnits(mainSale.data[0].token_price):0,    // uint256 price in token
						
						(!!(mainSale.data[0].active) && !!(mainSale.data[0].eth_sale_enabled)) || !!(presaleData.eth_sale_enabled),            								// uint256 sale for eth enabled ?
						(!!(mainSale.data[0].active) && !!(mainSale.data[0].token_sale_enabled)) || presaleData.token_sale_enabled || !!(presaleData.token_address),		// uint256 sale for token enabled ?
						mainSale.data[0].token_address?mainSale.data[0].token_address:presaleData.token_address?presaleData.token_address:'0x0000000000000000000000000000000000000000'					//accepted token contract address  
					
					];
					const tokenConfig = [
						BigNumber.from(currentProjectId),               // _projectID
						nfts.data.total_nfts,                         	// _maxSupply
						info.data.name,       			       			// _name
						info.data.token_symbol,   		                // _symbol

						nfts.data.vrf_shuffle?config.IPFS_GATEWAY+info.data.pre_reveal_json_hash:'',  					    // _tokenPreRevealURI
						//info.data.metadata_url+'/',  					// _tokenRevealURI
						info.data.metadata_url+config.METADATA_URL_RIGHT_SIDE,  		// _tokenRevealURI
						false, //until the fix. mainSale.data[0].lock_tokens_during_sale,       // _transferLocked
						rs,                          					// _reservedSupply
						0,
						nfts.data.vrf_shuffle                          					// _giveawaySupply
					];
					resolve({pName:info.data.name,saleConfig,tokenConfig,presale,mainSale});
				}
	
			}).catch(e=>reject(e));
		});
	}

	const launchProject = async (saleConf, tokenConf) => {
		
		let provider = await getProvider(targetChain);

		console.log('cahin, Provider',targetChain, provider);

	
		if(provider && factoryContract){
			let factory = factoryContract.connect(provider.getSigner());

			console.log('FACTORY CONTRACT',factory);

			//TODO: UI!!!
			//const VRFShifting = true;
			const collectionId =1;
			console.log('LAUNCH PARAMS,cid,collectionId sale, token', communityId,BigNumber.from(collectionId),saleConf,tokenConf);


			setApproveInProgress(true);

			let tx = await factory.LaunchProject(communityId,BigNumber.from(collectionId),saleConf,tokenConf).catch(e=>{console.log(e);toast.error(e.message);});
			
			if(tx){
				setApproveInProgress(false);
				//console.log('tx',tx);
				setFactoryTxHash(tx.hash);
				localStorage.setItem('factoryHash'+currentProjectId,tx.hash);
				let receipt = await tx.wait();
				

				//TODO: get sale and token addresses (refresh communityRgistry inn the context)
				//getProjectContracts(true);
				//let ds = await checkDeployStatus(currentProjectId);




				await refreshCommunityData().catch(e=>console.log);

				setTxInProgress(false);

			}else{
				setTxInProgress(false);
				setApproveInProgress(false);
			}
			
		}else{
			toast.error('Contract deployment failed. Factory or provider not found.');
			setTxInProgress(false);
			setApproveInProgress(false);
		}
	}


	/* const getProjectContracts = async(sendToBackend)=>{

		//console.log('communityRegistry',communityRegistry);

		let tokenAddress = await communityRegistry.getRegistryAddress("TOKEN_1").catch(e=>console.log);
		let saleAddress = await communityRegistry.getRegistryAddress("SALE_1").catch(e=>console.log);

		if(tokenAddress!==ethers.constants.AddressZero && saleAddress!==ethers.constants.AddressZero){
			setSaleContract(saleAddress);
			setTokenContract(tokenAddress);
		}
		setIsLoading(false);
		setTxInProgress(false);
		setFactoryTxHash(null);
	} */
	
	const getWalletShareAddresses = (shares)=>{

		//console.log(shares.data)
		let res=[];

		for(let i=0; i<shares.data.length;i++){
			res.push(shares.data[i].address);
		}

		return res;
	}

	const getWalletShareShares = (shares)=>{
		let res=[];

		for(let i=0; i<shares.data.length;i++){
			res.push(shares.data[i].share);
		}

		return res;
	}

	return (
		<>
			<div className="row" style={{ marginBottom: '80px' }}>
				<div className="col-lg-10 mx-auto">
						<h4 className="page-title mt-1">
						<img src={titleIcon} style={{ marginRight: '3px' }} /> 9. Deploy contracts
						</h4>
				</div>
				
				{deployEnabled &&
				<>
					<div className="rounded-box boxShadow p-4 mt-3 col-lg-10  mx-auto">
						<div className={`publish-box-content`}>

						{deployStatus.siteStatus?<>

							{(saleContract && tokenContract)?
							<>
							<p>Your contracts are deployed:</p>
							</>
							:
							<>
								{targetChain &&<>
									{chainId!==targetChain ?
									<>
										<p>Your project's home chain is {chainNames[targetChain]}. The connected chain is {chainNames[chainId]}. <span className='fake-link' onClick={()=>setChain(targetChain)}>Click here to switch to {chainNames[targetChain]}</span>.</p>
										</>
										:
										<>
											
											{txInProgress?
											<p>Deploying the contracts.</p>
											:
											<>
												<p>Click "Deploy contracts" when you are ready. Gas fees apply.</p>

												<FormInput 
													id='want_reserve'
													label="I want to reserve some cards for myself."
													type="checkbox"
													onChange={(e)=>{
														setWantToReserve(e.target.checked);
														if(!e.target.checked){
															setReservedSupply('');
														}
													}}
													value={wantToReserve}
													mbNone={true}
												/>


												{wantToReserve&&<>
												<FormInput
													id={'reserved_supply'}
													label={'Reserved Supply'}
													type={'number'}  
													onChange={(e)=>{e.target.value=e.target.value.replace(/[^0-9]/g, '');setReservedSupply(e.target.value)}}
													value={reservedSupply}
													mbNone={true}
												/>
												<p className='text-right small text-warning'>This amount can't be changed after the contract deployment.</p>
												</>}
											
											</>
											}
											
											
										</>

									}
								</>}

								{polygonWarning&&<p className="text-danger">You selected an L2 chain for your community. Synchronisation must take place before you can deploy your sale and token contracts. Please wait!</p>}
							</>}
						
						</>
						:
						<>
							<SpinnerCircular size="32" color="#ff692b"/>
						</>}




						{txInProgress?
								<>
									<div className='text-center'>
										<SpinnerCircular size="32" color="#ff692b"/>
									</div>
									{/* {factoryTxHash!=null&&<p className="text-center mt-2 mb-0">Transaction in progress. View on <a href={supportedChains[selectedChainIdx].blockExplorerUrl+'/tx/'+factoryTxHash} target="_blank">Block Explorer</a>.</p>} */}
								</>
								:
								<>
								{(saleContract && tokenContract)&&
									<>
										<p className="mt-1 mb-0">
											Sale contract:{' '}
											<a href={supportedChains[selectedChainIdx].blockExplorerUrl+'/address/'+saleContract} target="_blank">
												{saleContract}
											</a>
										</p>
										<p className="mb-1">
											Token contract:{' '}
											<a href={supportedChains[selectedChainIdx].blockExplorerUrl+'/address/'+tokenContract} target="_blank">
												{tokenContract}
											</a>
										</p>

									</>
								}

								</>
						}
						</div>
					</div>
					{/* {(whitelistUpdateEnabled && chainId==targetChain && saleContract )&&
					<div className="rounded-box boxShadow p-4 mt-3 col-lg-10  mx-auto">
						<div className={`publish-box-content text-center`}>
							{whitelistActivationInProgress? 
								<>
									<div className='text-center'>
										<p className="my-3">Activating the whitelist</p>
										<SpinnerCircular size="32" color="#ff692b"/>
									</div>
									{whitelistTxHash!=null&&<p className="text-center mt-2 mb-0">Transaction in progress. View on <a href={supportedChains[selectedChainIdx].blockExplorerUrl+'/tx/'+whitelistTxHash} target="_blank">Block Explorer</a>.</p>}

								</>
								:
								<>
									<p className='text-left'>
										Lorem ipsum dolor sit amet.
									</p>
									
									<button className='mx-auto borderedNavLink lightNavLink' onClick={updateWhitelist}>Activate whitelist</button>
								</>
							}
						</div>
					</div>} */}

				</>
				}
				
			</div>

			<BackdropModal visible={approveInProgress}/>
			<TxInProgressModal visible={txInProgress&&factoryTxHash} txHash={`${supportedChains[selectedChainIdx].blockExplorerUrl}/tx/${factoryTxHash}`}/>

			{(!saleContract && !tokenContract && isLoading===false)&&<SSPFooter
				prev=""
				next=""
				isSubmitEnabled={!txInProgress && chainId===targetChain && deployEnabled && !polygonWarning && (wantToReserve?Number(reservedSupply)>0?true:false:true) }
				submit={{
				label: 'Deploy contracts',
				handleSubmit: handleDeploy,
				}}
			/>}
		</>
	)
}

export default DeployContracts;