import './ConfigUTC.css';
import leftTrick from '../../assets/images/leftTrick.svg';
import rightTrick from '../../assets/images/rightTrick.svg';
import leftTopTrick from '../../assets/images/leftTopTrick.svg';
import rightTopTrick from '../../assets/images/rightTopTrick.svg';
import utcIcon from '../../assets/images/utc/utc-icon.png';

import RoundedBox from '../../components/visual-utils/RoundedBox';
import FormInput from '../../components/common/FormInput';
import { useContext, useEffect, useState } from 'react';
import BorderedNavigationLink from '../../components/visual-utils/navigation-links/BorderedNavigationLink';
import { contractKeys, DataContext } from '../../components/DataStore/DataStore';
import { communityHealthcheck, getConfig, getGlobal, getProjectInfoById, launchCommunitySpace, launchUTC, setJson } from '../../components/Api';
import { toast } from 'react-toast';
import Web3Ctx from '../../components/Context/Web3Ctx';
import { BigNumber, ethers } from 'ethers';
import useConfirm from '../../components/common/useConfirm';
import config from '../../config';
import DeployInProgressModal from '../../components/common/DeployInProgressModal';
import useInterval from '../../components/common/useInterval';
import { set } from 'immutable';
import axios from 'axios';

const ConfigUTC = (props) => {

	const collectionId = 1;

	const waitDuration = 25*60*1000; //5 minnutes

	const chainNames = {
		1: 'mainnet',
		5: 'goerli',
		137: 'polygon',
		80001: 'mumbai'
	}

	const styleConfig = {
		borderRadius: '20px',
		padding:"1.5rem",
		marginTop: '20px',
		backgroundColor: '#fff',
	  };

	  const modalConfig = {
		position: 'relative',
		width: '100%',
		maxWidth: '508px',
	  };

	const {communityId,projectChain} = useContext(DataContext);
	//const {address,getProvider,setChain,chainId} = useContext(Web3Ctx);

	const { isConfirmed } = useConfirm();

	const [utcRpcUrl,setUtcRpcUrl] = useState('');
	const [utcWssUrl,setUtcWssUrl] = useState('');

	const [selectedUtcChain,setSelectedUtcChain] = useState(0);

	const [deployInProgressVisible,setDeployInProgressVisible] = useState(false);
	const [deployMessage,setDeployMessage] = useState('');
	const [UTCDashboardUrl,setUTCDashboardUrl] = useState('');
	const [pollInterval, setPollInterval] = useState(null);
	const [customRpc, setCustomRpc] = useState(false);

	const [customRpcUrl,setCustomRpcUrl] = useState('');
	const [customWssUrl,setCustomWssUrl] = useState('');

	const [errors,setErrors]=useState({
		"rpc-url":'',
		"wss-url":''
	});


	const onChainSelect = (chainIdx)=>{
		setSelectedUtcChain(chainIdx);
		console.log('CHAIN CHANGE',config.CHAINS[chainIdx]);

		if(config.CHAINS[chainIdx].id==="0x13881" || config.CHAINS[chainIdx].id==="0x89"){
			setUtcRpcUrl(config.POLYGON_UTC_RPC_URL);
			setUtcWssUrl(config.POLYGON_UTC_WSS);
		}else{
			setUtcRpcUrl(config.RPC_BASE + config.UTC_INFURA_ID);
			setUtcWssUrl(config.WSS_BASE + config.UTC_INFURA_ID);
		}
	}

	
	const handleError = (e) => {
		console.error('handle error',e);
		if (e.error && e.error.message) {
		  toast.error(e.error.message);
		} else if (e.message) {
		  toast.error(e.message);
		} else if (e.reason) {
		  toast.error(e.reason);
		}
	};

	useEffect(()=>{

		const checkDeployStatus = async()=>{
/* 
			const data = {some:'value',oher:'other value'};
			const params = {
				object: `{"1": { "TEST": {"Config": ${JSON.stringify(data)}}}}`,
			};

			let setRes = await setJson(params).catch(e=>console.log());

			console.log('NEW CONFIG SERVER SET RESULT',setRes);




			let cfgRes = await getConfig('1/TEST').catch(e=>console.log);

			console.log('NEW CONFIG SERVER GET RESULT',cfgRes);

 */

			let res = await UTCHealtcheck(true);
			console.log('deployed',res);

			switch (res){
				case '0':
					{
						setDeployMessage('The deployment of UTC started successfully. Please wait for the servers to come online...');
						setDeployInProgressVisible(true);
						console.log('poll START');
						setPollInterval(10000);
						break;
					}
			case '1':
				{
					let utcUrl=`${config.DOMAIN}/${communityId}/utc/`;
					setDeployMessage('UTC has been deployed.');
					setUTCDashboardUrl(utcUrl);
					setDeployInProgressVisible(true);

					break;
				}
			case '2':
				{

					break;
				}
			default:
				{
					//not deployed yet
					setDeployMessage('');
					setUTCDashboardUrl('');
					setDeployInProgressVisible(false);	
					break;
				}
			}
		}

		if(projectChain){

			console.log('projectChain',projectChain);


			let chIdx = config.CHAINS.findIndex(c => {
				return parseInt(c.id,16) === projectChain;
			});

			console.log('CH IDX', chIdx);

			if(chIdx>-1){
				console.log('chain found:', config.CHAINS[chIdx]);
				onChainSelect(chIdx);

			}else{
				toast.error('Chain id '+projectChain+' is not supported');
				return;
			}


			


			checkDeployStatus();
		}
	},[projectChain])



	const UTCHealtcheck = async(muted)=>{
		console.log('healtcheck called');

		let healthRes = await communityHealthcheck(communityId).catch(e=>console.log);
     	console.log('HEALTCHECK RESULT', healthRes);

		if(healthRes.success && healthRes.utc){

			if(healthRes.utc.status && Number(healthRes.utc.status)>1 && !muted){
				setPollInterval(null);
				toast.error('UTC deployment failed.');
				setDeployInProgressVisible(false);
			}

			if(healthRes.utc.status && Number(healthRes.utc.status)===1){
				
				let utcUrl=`${config.DOMAIN}/${communityId}/utc/`;
				setDeployMessage("UTC server is up and running.");
				setUTCDashboardUrl(utcUrl);
				console.log('STOP polling (success)');
				setPollInterval(null);
			}

			let utcDeployStarted = localStorage.getItem('UTC'+communityId);
			if(healthRes.utc.status===null && utcDeployStarted){
				//deploy started, but somehow the healthcheck gives back null
				return '0';
			} 

			return healthRes.utc.status;
		}
		
		return null;
	}

	useInterval(
		UTCHealtcheck,
		pollInterval
	);

	const handleDeployUTC = async ()=>{

		let rpc = customRpc?customRpcUrl:utcRpcUrl;
		let wss = customRpc?customWssUrl:utcWssUrl;
		let etherscan = config.CHAINS[selectedUtcChain].blockExplorerUrl;
		
		if(!rpc){
			toast.error('RPC url is required');
			return;
		}
		if(!wss){
			toast.error('WSS url is required');
			return;
		}

		/* const data = {
			INFURA_ID: config.UTC_INFURA_ID, // "42b2f6f62d8f493dacea9a7028f2cd85",
			ETHERSCAN_URL: config.CHAINS[selectedUtcChain].blockExplorerUrl, //"https://mumbai.polygonscan.com",
			RPC_URL: rpc, //"https://goerli.infura.io/v3/42b2f6f62d8f493dacea9a7028f2cd85",
			WSS_RPC_URL: wss, //"wss://goerli.infura.io/ws/v3/42b2f6f62d8f493dacea9a7028f2cd85",
			CHAIN_ID: parseInt(config.CHAINS[selectedUtcChain].id,16)
		}


		const params = {
			object: `{"${Number(communityId)}": { "UTC": {"Config": ${JSON.stringify(data)}}}}`,
		};


		console.log(params); */

		setDeployInProgressVisible(true);
		setDeployMessage('');

		//let res = await setJson(params).catch(e=>console.log);
		
		
		setDeployMessage('UTC Deploy is in progress.');
		//toast.success('UTC configuration saved.');
		let launchResponse = await launchUTC(Number(communityId),rpc,wss,etherscan).catch(e=>console.log)
		console.log('launch response', launchResponse);
		if(launchResponse && launchResponse.success){
			setDeployMessage('The deployment of UTC started successfully. Please wait for the servers to come online...');
			localStorage.setItem('UTC'+communityId,new Date().getTime());
			console.log('poll START');
			setPollInterval(10000);
		}else{
			setDeployInProgressVisible(false);
			toast.error('UTC deployment failed. ');
		}

		

	//	console.log('config server setJSON result',res);
	}

	const checkUrl = async(e)=> {

		let url = e.target.value;
		let id = e.target.id;
		let err = {...errors};

		if(url===''){
			err[id]="Required field";
			setErrors(err);
			return;
		}


		let valid = false;

		if(id==='rpc-url'){
			valid = isRpcURL(url);
		}else{
			valid = isWssURL(url);
		}

		if(!valid){
			err[id]="Must be a valid url";
			setErrors(err);
			return;
		}

		if((config.CHAINS[selectedUtcChain].id==="0x13881" || config.CHAINS[selectedUtcChain].id==="0x89")){
			if(url.indexOf('quiknode')>-1){
				err[id]="Quiknode is not supported on polygon.";
				setErrors(err);
				return;
			}
		}
		err[id]="";
		setErrors(err);
	}

	const isRpcURL = (str) => {
		var urlRegex = '^(?:(?:https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		var url = new RegExp(urlRegex, 'i');
		return str.length < 2083 && url.test(str);
   }
	const isWssURL = (str) => {
		var urlRegex = '^(?:(?:wss)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		var url = new RegExp(urlRegex, 'i');
		return str.length < 2083 && url.test(str);
   }

	/* ***** visual helpers ***** */

	const SectionDividers = () => (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
		  <img src={leftTrick} style={{ maxHeight: '50px' }} />
		  <img src={rightTrick} style={{ maxHeight: '50px' }} />
		</div>
	  );

	const SectionTop = () => (
	<div
		style={{
		display: 'flex',
		justifyContent: 'space-between',
		}}
	>
		<img src={leftTopTrick} style={{ maxHeight: '50px' }} />
		<img src={rightTopTrick} style={{ maxHeight: '50px' }} />
	</div>
	);

	return (
		<div className={`community-space-outer`}>
			<div className="container">
				<div className="row">
					<div className="col-md-12">

						<SectionTop/>
						<div className="col-md-9 mx-auto mb-4">
					
							<img src={utcIcon} className="title-icon mx-auto d-block"/>
							<h5 className="mb-1 text-center font-weight-light f14">
								UTILITY TRAIT CENTER
							</h5>
							<h4 className="title mb-5">Utility Trait Center Configurations</h4>
							<RoundedBox className="box-content" styleConfig={styleConfig} hasShadow={true}>
								<h4 className="title mb-3">About the perks</h4>
								<p className="text-center mb-3">You are ready to deploy the Utility Trait Center for your project. The Utility Trait Center allows you to easily add dynamic features and functions to your digital membership cards. There are five types of preconfigured traits or perks: Badges, Physical Redeemables, Appointments, Autographs and Digital Redeemables. In the future, you may also add and drop new traits to your digital collectibles. The four main perks - as well as statuses and user submissions can be administered in the Utility Trait Center Dashboard.</p>

							</RoundedBox>
						</div>

						<SectionDividers/>


						<div className="col-md-7 mx-auto mt-4">
						
						{/* 	<h4 className="title mb-3">Configurations</h4>
							<p className="text-center mb-5">At Configurations, we should explain shortly, what the user can do in the UTC.</p>
 */}

							<FormInput
								id='utcTargetChain'
								label={'Utility trait\'s home chain'}
								type='select'
								options={config.CHAINS.map((c,i)=>{return {label:c.label, value:i}})}
								value={selectedUtcChain}
								onChange={e=>onChainSelect(e.target.value)}
								disabled={true}
							/>

							<FormInput 
								id='own-stuff'
								type='checkbox'
								label='I want to use my own RPC and WSS provider'
								onChange={(e)=>setCustomRpc(e.target.checked)}
							/>
						
						{customRpc&&<>

								{(config.CHAINS[selectedUtcChain].id==="0x13881" || config.CHAINS[selectedUtcChain].id==="0x89")&&
									<p className='mb-1 text-warning small'>Qucknode rpc and wss on polygon is not yet supported.</p>
								}


								<FormInput
									id='rpc-url'
									label='RPC Url'
									type='text'
									value={customRpcUrl}
									onChange={e=>setCustomRpcUrl(e.target.value)}
									onBlur={e=>checkUrl(e)}
									error={errors['rpc-url']}
								/>

								<FormInput
									id='wss-url'
									label='WSS Url'
									type='text'
									value={customWssUrl}
									onChange={e=>setCustomWssUrl(e.target.value)}
									onBlur={e=>checkUrl(e)}
									error={errors['wss-url']}
								/>
							</>
						}

							

						</div>


						<div className='text-center mt-5'>
							<BorderedNavigationLink
								type="submit"
								className="mx-auto"
								onClick={handleDeployUTC}
								enabled={communityId && !(customRpc && (errors['rpc-url'] || errors['wss-url'] || customRpcUrl==='' || customWssUrl===''))}>
								DEPLOY UTILITY TRAIT CENTER
							</BorderedNavigationLink>
						</div>


					</div>


				</div>

				
				
				
		
			</div>
			
			<DeployInProgressModal 
				visible={deployInProgressVisible} 
				titleText={'Deploying UTC'} 
				message={deployMessage}
				appButtonLabel={'UTILITY TRAIT CENTER'}
				destinationUrl={UTCDashboardUrl}	
			/>

		</div>
	)
}

export default ConfigUTC;