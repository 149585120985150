import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toast';
import { getSession } from '../../../components/Api';
import { LazyImage } from '../../../components/common/LazyImage';
import config from '../../../config/index';

const Explorer = ({allCards,projectId,projectName,width,height,totalSides,sessionUrl})=>{
	const LAYER_SERVER = config.MISSION_CONTROL_API+'projects/';
	const ITEMS_PER_PAGE = 8;
	const [cards,setCards] = useState([])
	const [currentPage,_setCurrentPage] = useState(0);
	
	const currentPageRef = useRef(currentPage);
	const setCurrentPage = (val) => {
		currentPageRef.current=val;
		_setCurrentPage(val);
	}

	useEffect(()=>{
		//console.log('all cards, cards ',allCards.length, cards.length, currentPageRef.current);
		setCards([]);
		setCurrentPage(0)
		loadNext();
	},[allCards]);


const loadNext = ()=>{
	//console.log('loadnext currentPage',currentPageRef.current,cards.length);
	
	let c=[];
	let end = allCards.length<ITEMS_PER_PAGE?allCards.length:ITEMS_PER_PAGE;
	for(let i=0;i<end;i++){
		if((currentPageRef.current*ITEMS_PER_PAGE+i)<allCards.length){
			c.push(allCards[currentPageRef.current*ITEMS_PER_PAGE+i]);
		}
	}
	setCards(cards => cards.concat(c));
	setCurrentPage(currentPageRef.current+1);
}

/* const handlePreview = async (token_id) => {
   const res = await getSession(communityId).catch((e) => console.log);

    if (res.success) {
      if (res.data) {
        window.open(`${res.data.url}/${token_id}`, '_blank', 'noopener,noreferrer');
      }
    } else {
      toast.error("An error occured!")
    }
  }; */

const handlePreview = async (token_id) => {
    if (sessionUrl) {
        window.open(`${sessionUrl}/${token_id}`, '_blank', 'noopener,noreferrer');
      
	} else {
		toast.error("Invalid preview session.")
	}
  };


	return(
		<InfiniteScroll
			dataLength={cards.length}
			next={loadNext}
			hasMore={currentPageRef.current*ITEMS_PER_PAGE<allCards.length}
			scrollThreshold="200px"
			scrollableTarget="content-container"
			initialScrollY={0}
			loader={<h4>Loading...</h4>}
		>
			{totalSides===1?
				<div className={`row small-gutters px-0 mx-0 h-100`}>

					{cards.map((c,i)=>{return(
						<div key={i} className="col-lg-3 col-md-4 ">
							<div className="layer-image-preview" style={{paddingTop: (height/width*100)+'%', cursor: 'pointer'}}  onClick={()=>{handlePreview(c.token_id)}} >
								{/* <LazyImage idx={currentPageRef.current>0?(i/currentPageRef.current):i} src={`${LAYER_SERVER}/img/${projectId}/1/thumb/${c.token_id?c.token_id:(i+1)}/${c.dna[1]}`}/> */}
								<LazyImage idx={currentPageRef.current>0?(i/currentPageRef.current):i} src={`${LAYER_SERVER}${projectId}/1/generated/thumb/${c.dna[1]}.jpg`}/>
							</div>
							<p className="h6 mt-1 mb-3">{projectName} #{c.token_id?c.token_id:(i+1)}</p>
						</div>
					)})}

				</div>
				:
				<>
					{totalSides===2?
						<div className={`row small-gutters px-0 mx-0 h-100`}>

							{[...Array(Math.ceil(cards.length/2))].map((c,i)=>{return(
								

									<div key={'cardz'+i} className="col-lg-12 rounded-box boxShadow py-4 px-3 mt-3">
										{i===0&&<h4 className="page-title mt-0 mb-2 ml-2 ">
											<span style={{color:"#000"}}>Generated Cards ({allCards.length})</span>
										</h4>}
										<div className={`row small-gutters px-0 mx-0`}>
											<div className='col-lg-6'>
												<p className="h6 mt-1 mb-3">{projectName} #{cards[i*2].token_id?cards[i*2].token_id:(i*2+1)}</p>
										
												<div className={`row small-gutters px-0 mx-0`}>
													<div className='col-lg-6'>
														<div className="layer-image-preview" style={{paddingTop: (height/width*100)+'%', cursor: 'pointer'}} onClick={()=>{handlePreview(i*2+1)}}>
															<LazyImage idx={currentPageRef.current>0?((i*2)/currentPageRef.current):i*2} src={`${LAYER_SERVER}${projectId}/1/generated/thumb/${cards[i*2].dna[1]}.jpg`}/>
														</div>
														<p>Side 1</p>
													</div>

													<div className='col-lg-6'>
														<div className="layer-image-preview" style={{paddingTop: (height/width*100)+'%', cursor: 'pointer'}} onClick={()=>{handlePreview(i*2+1)}}>
															<LazyImage idx={currentPageRef.current>0?((i*2)/currentPageRef.current):i*2} src={`${LAYER_SERVER}${projectId}/2/generated/thumb/${cards[i*2].dna[2]}.jpg`}/>
														</div>
														<p>Side 2</p>
													</div>
												</div>
											</div>
										
											{cards.length>i*2+1&& <div className='col-lg-6'>
												<p className="h6 mt-1 mb-3">{projectName} #{cards[i*2+1].token_id?cards[i*2+1].token_id:(i*2+2)}</p>
										
												<div className={`row small-gutters px-0 mx-0`}>
													<div className='col-lg-6'>
														<div className="layer-image-preview" style={{paddingTop: (height/width*100)+'%', cursor: 'pointer'}} onClick={()=>{handlePreview(i*2+2)}}>
															<LazyImage idx={currentPageRef.current>0?((i*2+1)/currentPageRef.current):i*2+1} src={`${LAYER_SERVER}${projectId}/1/generated/thumb/${cards[i*2+1].dna[1]}.jpg`}/>
														</div>
														<p>Side 1</p>
													</div>

													<div className='col-lg-6'>
														<div className="layer-image-preview" style={{paddingTop: (height/width*100)+'%', cursor: 'pointer'}} onClick={()=>{handlePreview(i*2+2)}}>
															<LazyImage idx={currentPageRef.current>0?((i*2+1)/currentPageRef.current):i*2+1} src={`${LAYER_SERVER}${projectId}/2/generated/thumb/${cards[i*2+1].dna[2]}.jpg`}/>
														</div>
														<p>Side 2</p>
													</div>
												</div>
											</div>}
										</div>
									</div>
								
							)})}
						</div>	
					:
						<div className={`row small-gutters px-0 mx-0 h-100`}>

						{cards.map((c,i)=>{return(
							<div key={i} className="col-lg-3 col-md-4 ">
								<div className="layer-image-preview" style={{paddingTop: (height/width*100)+'%'}}>
									{/* <LazyImage idx={currentPageRef.current>0?(i/currentPageRef.current):i} src={`${LAYER_SERVER}/img/${projectId}/thumb/${c.token_id?c.token_id:(i+1)}/${c.dna}`}/> */}
									<LazyImage idx={currentPageRef.current>0?(i/currentPageRef.current):i} src={`${LAYER_SERVER}${projectId}/1/generated/thumb/${c.dna}.jpg`}/>
								</div>
								<p className="h6 mt-1 mb-3">{projectName} #{c.token_id?c.token_id:(i+1)}</p>
							</div>
						)})}
						</div>




					}
				</>
			
			
			}
		</InfiniteScroll>
	);
}

export default Explorer; 