import React, { useContext, useEffect, useRef, useState } from 'react'
import Dropzone, { useDropzone } from 'react-dropzone';
import { ChevronCompactLeft, FileEarmarkArrowDown, PlusCircle } from 'react-bootstrap-icons';
import './ImageUploader.css';
import InfoBox from './InfoBox';
import { toast } from 'react-toast';
import { apiUploadImages, uploadCommunitySpaceImage, uploadLargeBanner, uploadLayerImages, uploadOpenseaImage, uploadPreRevealImage, uploadSmallBanner } from '../Api';
import { SpinnerCircular } from 'spinners-react';
import { DataContext } from '../DataStore/DataStore';

const ImageUploader = 	({
							id,
							onImageSelected,
							label,
							help,
							value,
							accept,
							disabled, 
							dimensions, 
							multiUploadAllowed,
							disablePreview,
							extraData,
							onErrors,
							setUploading}
						)=>{
	
	
	const [files, setFiles] = useState('');

	const [uploadInProgress,setUploadInProgress] = useState(false);
	const [maxImages,setMaxImages] = useState(0);
	const [currentImage,_setCurrentImage] = useState(0);

	const currentImageRef = useRef(currentImage);
	const {currentProjectId} = useContext(DataContext);

	const [imgDim,setImgDim] = useState({width:50,height:100});

	useEffect(()=>{

		if(value){
			//console.log('VALUE',value,typeof value);
			checkImageDimensions(value);
			setFiles(value);

/* 
			if(typeof value !== 'string'){
				console.log('value is an array');
				setFiles(value);
			}else{
				setFiles([value]);
			} */
			
		}
	},[value]);

	const  setCurrentImage = x => {
		currentImageRef.current = x; // keep updated
		_setCurrentImage(x);
	  };
  
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
   // accept: "image/*",
    accept: accept,
	disabled:(disabled||uploadInProgress),
    onDrop: async (acceptedFiles) => {

	//	console.log('???',acceptedFiles,multiUploadAllowed)

		if(multiUploadAllowed){

			setCurrentImage(0);


			setMaxImages(acceptedFiles.length);
			setUploadInProgress(true);
			if(setUploading){
				setUploading(true);
			}

			//let filesToSend = [...files];

			let errors = [];
			let layerId = extraData;

			if(acceptedFiles.length > 0){
				for(let i=0;i<acceptedFiles.length;i++){

					setCurrentImage(i+1);

				//	console.log('upload',acceptedFiles[i].type,accept);

					if(Object.keys(accept).includes(acceptedFiles[i].type)){
						const res = await checkImageDimensions(acceptedFiles[i]);
						if(res.success){
					
							let bodyFormData = new FormData();
							bodyFormData.append('images[]', acceptedFiles[i]);
							const res = await uploadLayerImages(layerId, bodyFormData);
							

							if(res.success){
							//	filesToSend.push(res.data[0]);

								if(onImageSelected){
									onImageSelected(res.data[0]);
								}
							}else{
								//console.log('LAYER IMG RES',res);
								errors.push(res.message);
								if(res.message.indexOf('because total nfts is less than')>-1){
						
									break;
								}
							}
		
						}else{
							errors.push('Wrong file dimensions. '+ res.error);
						}
					}else{
						errors.push('Wrong file format. '+ acceptedFiles[i].type+' is not allowed. '+acceptedFiles[i].name);
					}

				}
			}else{
				toast.error("Wrong file format")
			}

			if(errors.length>0 && onErrors){
				onErrors(errors);
			}

			setUploadInProgress(false);
			if(setUploading){
				setUploading(false);
			}
		}else{
			let errors = [];
			if(acceptedFiles[0]){
				const res = await checkImageDimensions(acceptedFiles[0]);
				if(res.success){

					setCurrentImage(1);
					setMaxImages(1);
					setUploadInProgress(true);
					if(setUploading){
						setUploading(true);
					}

					let bodyFormData = new FormData();
					bodyFormData.append('project_id', currentProjectId);
					bodyFormData.append('image', acceptedFiles[0]);
					let res=null;

					let uploadFunction; 
					let imagePath;
				//	console.log('********** ID',id);
					switch (id){
						case 'small_banner':
							{
								res = await uploadSmallBanner(bodyFormData);
								break;
							}
						case 'large_banner':
							{
								res = await uploadLargeBanner(bodyFormData);
								break;
							}
						case 'pre_reveal_image':
							{
								
								res = await uploadPreRevealImage(currentProjectId,bodyFormData);
								break;
							}
						//opensea
						case 'logo_image':
							{
								res = await uploadOpenseaImage(currentProjectId,"logo-image",bodyFormData);
								break;
							}
						case 'featured_image':
							{
								res = await uploadOpenseaImage(currentProjectId,"featured-image",bodyFormData);
								break;
							}
						case 'banner_image':
							{
								res = await uploadOpenseaImage(currentProjectId,"banner-image",bodyFormData);
								break;
							}
						case 'community_images':
							{
								bodyFormData.append('name', extraData);
								res = await uploadCommunitySpaceImage(currentProjectId,bodyFormData);
								break;
							}
						
					}
					
					//console.log('Upload end',res);
					
					setUploadInProgress(false);
					if(setUploading){
						setUploading(true);
					}

					if(res && res.success){
						setFiles(res.data.url);

						if(onImageSelected){
							onImageSelected(res.data.url);
						}
					}else{
						//HACK
						if(res===null){
							//console.log(acceptedFiles[0]);
							let img = new Image();
							let objectUrl = URL.createObjectURL(acceptedFiles[0]);
							img.src=objectUrl
							onImageSelected(objectUrl);
						}else{
							console.log('img UPLOAD res',res)
							errors.push(res.message);
						}
						//onImageSelected(res.data.url);
					}
				}else{
					errors.push('Wrong file dimensions.'+ res.error);
				}
			}else{
				errors.push('Wrong file format.');
			}

			if(errors.length>0 && onErrors){
				onErrors(errors[0]);
			}
		}
    }
  });

  	const checkImageDimensions = (file) =>{
		return new Promise(function (resolve, reject) {
			//console.log('dims',dimensions);
			
			/* 			
			if(dimensions===undefined){
				console.log('WHATEVER')
				resolve({success:true});
				return;
			} */
			
			const img = new Image();
			//console.log('typeof',typeof file)
			var objectUrl = typeof file==='string'?file:URL.createObjectURL(file);
			img.onload = function () {
			//	console.log(this.width + " " + this.height);
				URL.revokeObjectURL(objectUrl);

				if(!dimensions){
					//console.log('WAAAAAAA',this.width,this.height)
					setImgDim({width:this.width,height:this.height});
					resolve({success:true,image:img});
					return;
				}

				if(Number(dimensions.width) ===this.width && Number(dimensions.height) === this.height){
					resolve({success:true,image:img});
				}else if (Number(dimensions.width) === undefined && Number(dimensions.height) === undefined){
					resolve({success:true,image:img});
				}		
				else{
					resolve({success:false,error:file.name+` (${this.width}x${this.height})`});
				}
			};
			img.src = objectUrl;
		});
	}



	return (
		<section className={`dropcontainer ${isDragActive?'dragging':''} mx-auto`}>
			<div {...getRootProps({ className: `dropzone d-flex align-items-center ${disablePreview?'justify-content-left':'justify-content-center'}` })}>
				<input {...getInputProps()}  accept={accept} multiple={multiUploadAllowed}/>

				{!uploadInProgress&&<>
					{(files ==="" || multiUploadAllowed || disablePreview) ?
						<div className='px-1 py-3'>
							<PlusCircle className='primary' size={36}/>
							<div className={disablePreview?'text-left':''}>
								<p className={`${disablePreview?'ml-2 mt-0 mb-0':'mt-3 mb-0'} font-weight-normal px-1`}>{files===""?label:<span className='file-name'>{files.substring(files.lastIndexOf('/') + 1)}</span>}</p>
								{(help && files ==="")&&<p className='help-text px-2'>{help}</p>}
							</div>
						</div>
						:
						<>
							{(!multiUploadAllowed && (dimensions||imgDim) && !disablePreview)&&<div className='preview-image' style={{paddingTop: dimensions ? `calc(${(dimensions.height/dimensions.width)*100}% - 4px)` : `calc(${(imgDim.height/imgDim.width)*100}% - 4px)`}}>
								<img src={files} />
							</div>}
						</>
					}
				</>}

				{uploadInProgress&&
					<div className="upload-progress py-5">

						<SpinnerCircular size={32} color="#ff692b"/>
						<p className={disablePreview?'ml-2':''}>Uploading images: {currentImage}/{maxImages}</p>

					</div>
				}

			</div>
		</section>
		);

}

export default ImageUploader;