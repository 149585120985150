
import { Link } from 'react-router-dom';
import { DataContext } from '../DataStore/DataStore';
import {
  CheckCircle,
  ExclamationCircleFill,
  XCircleFill,
} from 'react-bootstrap-icons';
import './SSPSidebar.css';
import React, { useContext, useEffect, useState } from 'react';
import RoundedBox from '../visual-utils/RoundedBox';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { formState } from '../DataStore/DataStore';
import icon from '../../assets/images/icons/help-icon.svg';
import config from '../../config';

const SSPSidebar = (props) => {
  const { onClose } = props;
  const location = useLocation();
  const { pageData, deployStatus,  setPageErrors, communityId, currentCommunityName,setHelpModalVisible, getProjectInfoData } = useContext(DataContext);

  const [siteUrl, setSiteUrl] = useState('');

  useEffect(()=>{
    //console.log('SB-INFO',getProjectInfoData());
    let pInfo = getProjectInfoData();
    if(pInfo && pInfo.slug){
      setSiteUrl(`${config.GALAXIS_URL}/projects/${pInfo.slug}`);
      //console.log('SB SITE URL:',`${config.GALAXIS_URL}/projects/${pInfo.slug}`);
    }

  },[]);


  useEffect(()=>{
    setPageErrors();
  },[deployStatus]);

  return (
    <div className="">
      <RoundedBox hasShadow={true} styleConfig={{ borderRadius: '30px' }}>
        <div
          className="sidebar-content py-3"
          style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
        >
          <div className='d-flex justify-content-between flex-row align-items-start'>
            <h5 className='text-left sidebar-title'>

              {currentCommunityName&&<>
                {siteUrl===''?
                <>{currentCommunityName}</>
                :
                <a href={siteUrl}  className="sidebar-site-link" target="_blank">
                  {currentCommunityName}
                </a>
                }
              </>}



              <br/>
              
              <span>#{Number(communityId)}</span>
            
            </h5>
            <img className='help-icon' onClick={()=>setHelpModalVisible(true)} src={icon}/>
          </div>


          <div className='my-3' style={{background:'#ddd', height:'1px'}}/>

          <>
            {Object.keys(pageData).map((k, i) => (

              <React.Fragment key={i}>
                <Link
                    className={`side-link d-block my-3 d-flex justify-content-between flex-row ${pageData[k].enabled ? '' : 'btn-inactive'}  ${
                      location.pathname === pageData[k].url && 'active'
                    }`}
                    to={pageData[k].url}

                    onClick={()=>onClose?onClose():()=>{}}
                  >
                    <div className="pr-1">
                      <span>{i + 1 + '. ' + pageData[k].name}</span>
                    </div>


                    {pageData[k].error && pageData[k].error!==''?
                    <>
                      <div>
                        <XCircleFill size="20px" color="#f00" />
                      </div>
                    </>
                    :
                    <>
                      {pageData[k].state === formState.FORM_SAVED ? (
                        <div>
                          <CheckCircle size="20px" color="#000" />
                        </div>
                      ) : (
                        <span style={{ width: '20px', height: '20px' }}></span>
                      )}
                      {pageData[k].state === formState.FORM_UNSAVED && (
                        <div>
                          <ExclamationCircleFill size="20px" color="#ff692b" />
                        </div>
                      )}
                    </>
                    }


                  </Link>

                  {pageData[k].divider&&
                    <div className='my-3' style={{background:'#ddd', height:'1px'}}/>
                  }
                </React.Fragment>
            ))}
          </>
        </div>
      </RoundedBox>
    </div>
  );
};
export default SSPSidebar;
