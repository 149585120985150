import { useEffect, useState } from 'react';
import RoundedBox from '../visual-utils/RoundedBox';
import './AlertModal.css';
import FormInput from './FormInput';
import byIcon from '../../assets/images/icons/x.svg';
import closeIcon from '../../assets/images/close.svg';
import { X } from 'react-bootstrap-icons';


const ERROR_COLOR = '#F50808';
const NORMAL_COLOR = '#ff692b';

const styleConfig = {
  position:'relative',
  width: '100%',
  maxWidth: '508px',
};

const CustomImageSizeModal = (props) => {
  const { visible, hideModal,  saveFunc } = props;

  const [formValid,setFormValid] = useState(false);

  const [values,setValues]=useState({
      width:{
              value:'',
              error:''
            },
      height:{
              value:'',
              error:''
      }
  })

  const closeModal = () =>{

  }


  const onValueChange = (field,val)=>{
      let tempVal = {...values};
      tempVal[field].value=val;

      if(val){
        if(val<1 || val>2000){
          tempVal[field].error="Wrong value";
        }else{
          tempVal[field].error="";
        }
      }else{
        tempVal[field].error="Required";
      }
      setValues(tempVal);
      if(tempVal.width.error==="" && tempVal.height.error==="" && tempVal.height.value && tempVal.width.value){
        setFormValid(true);
      }else{
        setFormValid(false);
      }
  }

  return (
    <>
      {visible && (
        <div
          className="modal warning-modal"
          style={{ display: 'block' }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <RoundedBox styleConfig={styleConfig}>
              <div className="modal-content">
                <button className='btn-with-icon' style={{position:"absolute",right:"8px", top:"10px"}} onClick={props.closeModal}></button>
                <div className="modal-header justify-content-center">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Custom Image size
                  </h5>
                </div>
                <div className="modal-body row">

                  <div className="col-lg-10 mx-auto mt-4">
                    <div className="row small-gutters">
                      <div className="col-md-5">
                        <FormInput
                          id="width"
                          type="number"
                          label="Width"
                          help="Max 2000px"
                          value={values.width.value}
                          onChange={(e)=>onValueChange('width',e.target.value)}
                          error={values.width.error}
                        />
                      </div>
                      <div className="col-md-2 d-flex by">
                        <img  className="mx-auto mt-3" src={byIcon}/>
                      </div>
                      <div className="col-md-5">
                        <FormInput
                            id="height"
                            type="number"
                            label="Height"
                            help="Max 2000px"
                            value={values.height.value}
                            onChange={(e)=>onValueChange('height',e.target.value)}
                            error={values.height.error}
                          />
                      </div>

                    </div>
                  </div>
                  
                </div>
                <div className="modal-footer">
                    <button
                      type="button"
                      className={`btn`}
                      style={{ color: NORMAL_COLOR }}
                      data-dismiss="modal"
                      disabled={!formValid}
                      onClick={() => saveFunc(values.width.value,values.height.value)}
                    >
                      Save
                    </button>
                </div>
              </div>
              <span className="close-button" onClick={()=>{console.log('hide clicked');hideModal()}}><img src={closeIcon}/></span>
            </RoundedBox>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomImageSizeModal;
