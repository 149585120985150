import React, { useContext, useEffect, useState } from 'react';
import DashboardPanel from './common/DashboardPanel';
import myicon from '../../../assets/images/mc/governance.svg';
import { state } from '../MissionControl';
import { communityHealthcheck, restartMPL, restartUTC } from '../../../components/Api';
import { DataContext } from '../../../components/DataStore/DataStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../../config';



const Leaderboard = ({communityId,communityHealth})=>{
	const history = useHistory();
	const [components,setComponents] = useState(null);
	const [leaderboardDisabled,setLeaderboardDisabled] = useState(true);
	const [lbrdBaseUrl,setLbrdBaseUrl] = useState(null);

	const {
		deployStatus
	  } = useContext(DataContext);

	useEffect(()=>{
		if(communityId && deployStatus?.contracts && communityHealth){
			getHealtcheck();
			console.log('LeaderboardPanel mounted. community: ',communityId,deployStatus);
		}else{
			//setComponents([])
			setComponents([
				{label:'Admin', status:state.INITIAL,restart:false}])
		}
	},[communityId,deployStatus,communityHealth]);

	const getHealtcheck = async ()=>{
		let admin = state.INITIAL;


		//let health = await communityHealthcheck(communityId).catch(e=>console.log);
		
		if(communityHealth){
			console.log('Leaderboard health',communityHealth);
			let restart = false;
			if(communityHealth.leaderboard){
				if(communityHealth.leaderboard.status==="0" || communityHealth.leaderboard.status==="2"){
					admin = state.PROGRESS;
					restart = communityHealth.leaderboard.status==="2"?true:false;
					setLbrdBaseUrl(null);
				}

				if(communityHealth.leaderboard.status==="1"){
					admin = state.SUCCESS;
					restart= true;
					setLbrdBaseUrl(`${config.DOMAIN}/${communityId}/leaderboard/`);
				}

				if(communityHealth.leaderboard.status==="3"){
					admin = state.ERROR;
					restart= true;
					setLbrdBaseUrl(null);
				}
			}

			setComponents([
				{label:'Admin', status:admin, restart}
			]);

			if(deployStatus?.contracts?.token){
				setLeaderboardDisabled(false);
			}
		}else{
			console.log('healtcheck not found');
		}
	}

	const handleGoToConfigLeaderboard = () =>{
		history.push('/config-leaderboard');
	}
	const handleRestartLeaderboard = async ()=>{
		let res = await restartMPL(communityId).catch(e=>console.log); 
		if(res){
			console.log('RESTART LEADERBOARD CALLED',res);
			communityHealth.leaderboard.status="0"
		} 
		getHealtcheck();
	}

	return(<>
		<DashboardPanel
			comingSoon={false}
			components={components}
			disabled={components===null || leaderboardDisabled}
			title="Leaderboard"
			icon={myicon}
			cta={{label:lbrdBaseUrl?"leaderboard":"configure", action:lbrdBaseUrl?lbrdBaseUrl:handleGoToConfigLeaderboard}}
			restartBtn={config.RESTART_BUTTONS_ENABLED?handleRestartLeaderboard:null}
		/>
	</>
	);
}

export default Leaderboard;

