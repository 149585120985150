import React, { useContext, useEffect, useState } from "react";
import { DataContext, formSkeleton, formState} from "../../components/DataStore/DataStore";
import FormInput from '../../components/common/FormInput';
import collapseIcon from '../../assets/images/icons/expand_circle_down_black_24dp.svg';
import { useFormik } from "formik";
import SSPFooter from "../../components/SSPFooter/SSPFooter";
import saveIcon from '../../assets/images/icons/saveBlackIcon.svg'
import './LayersAndCards.css';
import titleIcon from '../../assets/images/page-title-icons/layers.svg'
import listIcon from '../../assets/images/icons/view_list.svg'
import gridIcon from '../../assets/images/icons/view_grid.svg';
import gridIconInactive from '../../assets/images/icons/view_grid_inactive.svg';
import listIconInactive from '../../assets/images/icons/view_list_inactive.svg'
import sortIcon from '../../assets/images/sort.svg';
import AlertModal from "../../components/common/AlertModal";
import { createLayerInfo, deleteLayerImage, deleteLayerImages, generateNFTs, getImagesByLayerId, getLayers, getSides, getNFTs, updateLayerInfo, updateLayers, updateSide, updateProjectInfoRevealData } from "../../components/Api";
import { toast } from "react-toast";
import { SpinnerCircular } from "spinners-react";
import Explorer from "./Explorer/Explorer";
import CustomImageSizeModal from "../../components/common/CustomImageSizeModal";
import useConfirm from "../../components/common/useConfirm";
import InfoBox from "../../components/common/InfoBox";
import LayerImagePreview from "./LayerImagePreview";
import ReGenerateModal from "../../components/common/ReGenerateModal";


const LayersAndCards = (props) => {


    const PAGE = 'LAYERS_AND_CARDS';
	const INFO_PAGE = 'PROJECT_INFO';
    
    const {  
			updateFormV2, 
			currentProjectId, 
			updatePageState, 
			layers, 
			setLayers,
			setNFTsLengthFunc, 
			deployStatus, 
			previewSessionUrl,
			getLayerInfoData,
			getProjectInfoData,
			initDone,
			communityId,
			clearMetadataError
		} = useContext(DataContext);

	const [form,setForm] = useState({...formSkeleton[PAGE].form});
	const [projectInfo,setProjectInfo] = useState(null);
	const [layersId,setLayersId] = useState(null);

	const [preRevealImage,setPreRevealImage] = useState(null);

	const [imagesCollapsed,setImagesCollapsed] = useState(false);
	const [prerevealCollapsed,setPrerevealCollapsed] = useState(false);
	const [layersCollapsed,setLayersCollapsed] = useState(false);

	const [areCardsGenerating, setCardsGenerating] = useState(false)

	const [sizeOptions]=useState({
											portrait:	[
															{	
																width:1080,
																height:1920
															},
															{	
																width:1080,
																height:1440
															},
															{
																width:720,
																height:1280
															},
															{
																width:0,
																height:0
															}
														],
												square:	[
															{	
																width:2000,
																height:2000
															},
															{	
																width:1500,
																height:1500
															},
															{
																width:1000,
																height:1000
															},
															{
																width:0,
																height:0
															}
														],
										});

	const [selectedSide,setSelectedSide] = useState(0); 
	const [sides,setSides] = useState([]);
	const [totalSideLayers, setTotalSideLayers]=useState('');

	const [layerInfoId,setLayerInfoId] = useState(null); 
	const [layerInfo,setLayerInfo] = useState(null); 

	const [layerSelectorOptions, setLayerSelectorOptions] = useState([]);
	const [layerImages, setLayerImages] = useState([]);
	const [isListView, setIsListview] = useState(false);

	const [tempUploadImage,setTempUploadImage] = useState(null);

	const [currentLayer,setCurrentLayer]= useState(0);
	const [visibleDotsPanel, setDotsPanelVisible] = useState(false);

	const [deleteInProgress, setDeleteInProgress] = useState(false);
	const [layerInfoUpdating, setLayerInfoUpdating] = useState(false);
	const [layerUpdating, setLayerUpdating] = useState(false);
	
	const [uploadInProgress, setUploadInProgress] = useState(false);


	const [generatedCards,setGeneratedCards] = useState([]);

	const [sortBy,setSortBy] = useState('id');
	const [sortOrder,setSortOrder] = useState('asc');

	const { isConfirmed } = useConfirm();

	const [updateEnabled, setUpdateEnabled] = useState(false);




	//Alert stuff
	const [alertType, setAlertType] = useState('ERROR');
	const [alertMessage, setAlertMessage] = useState('');
	const [alertButtonLabel, setAlertButtonLabel]=useState('Close');
	const [alertTitle, setAlertTitle]=useState('Upload error');
	const [alertVisible, setAlertVisible] = useState(false);

	const [customModalVisible, setCustomModalVisible] = useState(false);
	const [customWidth,setCustomWidth]=useState(0)
	const [customHeight,setCustomHeight]=useState(0)
	
	const [generateModalVisible, setGenerateModalVisible] = useState(false);

	const [layerImgUrlNonce,setLayerImgUrlNonce] = useState(0);

	useEffect(()=>{
		console.log('Layers&images mounted');
		//console.log(formData[INFO_PAGE].form)
		let pv = localStorage.getItem('preferredView');
		if(pv){
			setIsListview(pv==='list');
		}
	},[])

	useEffect(()=>{
		console.log('STATUS',deployStatus);
		if(deployStatus && deployStatus.metaStatus){
			if(deployStatus.metaStatus.status.name==="Approved" ||
			   deployStatus.metaStatus.status.name==="Under Review"){
				setUpdateEnabled(false);
			}else{
				setUpdateEnabled(true);
			}
		}
	},[deployStatus])


	useEffect(()=>{
		if(currentProjectId && getLayerInfoData && getProjectInfoData){
			getLayerInformation();
			getProjectInfo();
        }
    },[currentProjectId, initDone]);

	useEffect(()=>{
		if(layerInfo){
			console.log('************** LAYERINFO IN useEffect CALLED',layerInfo);
			getCardSides();
			if(layerInfo.valid_nfts_generated){
				getCards();
			}
		}
	},[layerInfo]);

	useEffect(()=>{
		if(sides && sides.length>0 && selectedSide!==null){
			//console.log('GET LAYERS FOR SIDE (EFFECT)',sides,selectedSide);
			setTotalSideLayers(sides[selectedSide].total_layers);
			getProjectLayers();
		}
	},[sides, selectedSide]);


	const getCardSides = async()=>{
		const res = await getSides(currentProjectId).catch(e=>console.log);
		//console.log('GET CARD SIDES (getSides res)',res);
		if(res && res.success){
			if(res.data.length<selectedSide+1){
				setSelectedSide(0);
			}
			res.data.sort((a,b)=>{return a.order<b.order?-1:a.order===b.order?0:1})
			setSides(res.data);
		}else{
			toast.error('Card sides not found');
		}

	//	getProjectLayers();

	}



	const getAvailable=()=>{
		let total = layerInfo.total_nfts;
		let distributed=0;
		layerImages.forEach(image=>{
			if(image.rarity){
				distributed+=image.rarity;
			}
		});
		//console.log('get available',total,distributed);
		return total-distributed;
	}

	
	const getLayerInformation = async()=>{
		//console.log('Layers&images, projectID:',currentProjectId);
		const layerInfoData = getLayerInfoData();
		console.log('LayerInfo(api)',layerInfoData);

		if(layerInfoData){

			if(layerInfoData.orientation && layerInfoData.width && layerInfoData.height){
				let sizeIndex = sizeOptions[layerInfoData.orientation].findIndex( item => (item.width === layerInfoData.width &&item.height === layerInfoData.height));
				//console.log('SSSSizeindex',sizeIndex);
	
				if(sizeIndex===-1 && layerInfoData.width && layerInfoData.height){
				/* 	let f = document.getElementById('disp_size');
					f.innerHTML=layerInfoData.width+'x'+layerInfoData.height; */
					setCustomWidth(layerInfoData.width);
					setCustomHeight(layerInfoData.height);
					formik.values.size=4;//custom size
				}else{
					formik.values.size=sizeIndex+1;
				}
				formik.values.orientation=layerInfoData.orientation;
			}


			formik.values.total_sides=layerInfoData.total_sides?layerInfoData.total_sides:'';
			formik.values.total_nfts=layerInfoData.total_nfts;
			formik.values.vrf_shuffle=layerInfoData.vrf_shuffle;
	
			formik.validateForm();
			setLayerInfoId(layerInfoData.id?layerInfoData.id:null);
			setLayerInfo(layerInfoData);
		}else{
			toast.error(layerInfoData.message)
		}
	}

	const getProjectInfo = async()=>{

		let projectInfo = getProjectInfoData();

		//.log('project info',projectInfo);
		if(projectInfo){
			setPreRevealImage(projectInfo.pre_reveal_image!==null?projectInfo.pre_reveal_image:'');
			setProjectInfo(projectInfo);
		}
	}

	const getProjectLayers = async()=>{

	/* 	console.log('*****************************')
		console.log('sides',sides);
		console.log('selectedSide',selectedSide);
		console.log('*****************************')
		 */

		const res = await getLayers(sides[selectedSide].id).catch(e=>console.log);
		if(res.success){
			let opt = [];
			let tmpLayers = [];

			res.data.sort((a,b)=> {return a.order>b.order?1:b.order>a.order?-1:0});

			for(let i=0; i<res.data.length;i++){
				opt.push({label:"Layer "+(i+1)/* res.data[i].order */, value:i});

				tmpLayers.push({...res.data[i]})
				if(res.data[i].name=="Layer "+(i+1)){
					tmpLayers[i].name="";
				}
			}
			setLayerSelectorOptions(opt);
			//console.log('LAYER DATA',tmpLayers);
			//setLayers(res.data);
			setLayers(tmpLayers);
			
			if(opt.length>0){
				await getLayerImages(res.data[0].id);
			}
		}else{
			toast.error(res.message);
		}
	}

	const getCards = async()=>{

		const res = await getNFTs(currentProjectId).catch(e=>console.log);
		
		//console.log('cards',res);
		
		if(res.success){
			//console.log('nr of cards',res.data.length);
			setNFTsLengthFunc(res.data.length);
			setGeneratedCards(res.data);

			if(res.data.length>0){
				updatePageState(PAGE,formState.FORM_SAVED);
			}else{
				updatePageState(PAGE,formState.FORM_EMPTY);
			}
			
		}else{
		//	toast.error(res.message);
		}
	}
	
	const getLayerImages = async(layerId) =>{

		console.log('about tot get images for layer',layerId);
		const res = await getImagesByLayerId(layerId).catch(e=>console.log);

		if(res.success){
			let sorted = sortImages(res.data,'id','asc');
			let withDNS = [...sorted].map((item,i)=>{return {...item,dns:i};});
			//console.log('images',withDNS);
			setLayerImages(withDNS);
		}else{
			toast.error(res.message);
		}
	}


	const updateCurrentLayer = async()=>{

		setLayerUpdating(true);
		const data = {
						project_id: layers[currentLayer].project_id,
						name: layers[currentLayer].name,
						order: layers[currentLayer].order,
						distribution: layers[currentLayer].distribution,
						usage: layers[currentLayer].usage
					};
		
		const res= await updateLayers(layers[currentLayer].id,data).catch(e=>console.log);
		if(res.success){
			toast.success("Layer updated");
		}else{
			//console.log('UPDATE RES',res)
			toast.error(res.message.message);
		}
		setLayerUpdating(false);
	}

	const updateLayerData = (field,value)=>{
		let newData = [...layers];
		newData[currentLayer][field]=value;
		console.log('',newData);
		setLayers(newData);
	}

	const deleteCurrentLayerImages= async ()=>{
		if(layerImages.length===0){
			return;
		}


		let confirmed = await isConfirmed('Delete images',`This will delete all images from ${layers[currentLayer].name}${(generatedCards.length>0)?'. You will also loose your generated collection.':'.'} Are you sure?`,'Delete','NO');

		if(!confirmed){
			return;
		}

		//setLayerImgUrlNonce(layerImgUrlNonce+1);

		setDeleteInProgress(true);
			const res = await deleteLayerImages(layers[currentLayer].id).catch(e=>console.log);
		setDeleteInProgress(false);
		if(res.success){
			setLayerImages([]);
			invalidateGeneratedCards();
		}else{
			toast.error(res.message);
		}
	}

	const deleteLayerImageById= async (imageId)=>{

		let confirmed = await isConfirmed(`Delete image #${imageId}`,`${(generatedCards.length>0)?'You will also loose your generated collection. Are you sure?':'Are you sure?'}`,'Delete','NO');

		//console.log(confirmed)

		if(!confirmed){
			return;
		}
		const res = await deleteLayerImage(imageId).catch(e=>console.log);
		if(res.success){
			getLayerImages(layers[currentLayer].id);
			invalidateGeneratedCards();
		}else{
			//console.log(res)
			toast.error(res.message);
		}
	}

	 
    const handleFormikChange=(e,key)=>{
	    formik.handleChange(e);

	//console.log(key,formik.values)

		if(key==='orientation'){

			if(!formik.values.size){
				updateFormV2(PAGE,key,e.target.value);
			}else{

				updateFormV2(PAGE,null,{orientation:e.target.value,width:sizeOptions[e.target.value][formik.values.size-1].width,height:sizeOptions[e.target.value][formik.values.size-1].height});
			}
		}else if(key==='size'){
			//console.log(e.target.value,sizeOptions)
			let value=Number(e.target.value)-1;
			//updateFormV2(PAGE,null,{width:sizeOptions[layerInfo.orientation][value].width,height:sizeOptions[layerInfo.orientation][value].height});
			updateFormV2(PAGE,null,{width:sizeOptions[formik.values.orientation][value].width,height:sizeOptions[formik.values.orientation][value].height});
		}else if(key==='vrf_shuffle'){
			updateFormV2(PAGE,key,e.target.checked);
		}else{
			updateFormV2(PAGE,key,e.target.value);
		}

		if(layerInfoId && key!=='total_nfts'){
			console.log('must submit');
			formik.handleSubmit();
		}

    }
	 
 /*    const handleFormikChangeOrientation=(e,i)=>{
		console.log(formik.values.orientation,e.target.value)

        formik.handleChange(e);
        updateForm(PAGE,i,e.target.value );
    }
 */

	const showUploadErrors =(errors) => {
		//console.log("SHOW ERRORS",errors)
		const msg = errors.join();
		setAlertMessage(msg);
		setAlertVisible(true);
	}

	const DotsPanel = () => {
		return (
		  <div className="dots-panel">
			<button className="btn-with-icon my-1" style={{minWidth:"140px"}} onClick={()=>{deleteCurrentLayerImages();setDotsPanelVisible(false)}}>
			  Delete Images
			</button>
		  </div>
		);
	  };

	const selectLayer = async(value)=>{
		setCurrentLayer(value);
		//console.log(layers[value].id);
		setLayerImages([]); //delete old layer data;
		await getLayerImages(layers[value].id);
	}

	const checkIfCustom = (val)=>{
		if(val===4){
			setCustomModalVisible(true);
		}
	}

	const setCustomSizevalues=(w,h)=>{
		setCustomModalVisible(false);

		if(Number(w)>=Number(h) && formik.values.orientation==='portrait'){
			toast.error('Wrong dimensions for portrait shape.');
			return;
		}

		if(Number(w)!==Number(h) && formik.values.orientation==='square'){
			toast.error('Wrong dimensions for square shape.');
			return;
		}



		/* let f = document.getElementById('disp_size');
		f.innerHTML=w+'x'+h; */
		setCustomWidth(Number(w));
		setCustomHeight(Number(h));
		updateFormV2(PAGE,null,{width:Number(w),height:Number(h)});
		//console.log(w,h);
	}

	const sortImages = (data, field, sOrder)=>{
		let order = sortOrder;
		if(sOrder){
			order=sOrder;
		}else{
			if(sortBy===field){
				order=sortOrder==='asc'?'desc':'asc';
			}else{
				order='asc';
			}
		}
		setSortOrder(order);
		setSortBy(field);

		let asc=order==='asc';
		let sData = [...data];

		return sData.sort((a,b)=> {
			let aVal, bVal;
			if(typeof a[field] == 'string'){
				aVal=a[field].toLowerCase();
				bVal=b[field].toLowerCase();
			}else{
				aVal=a[field];
				bVal=b[field];
			}
			return aVal>bVal?(asc?1:-1):bVal>aVal?(asc?-1:1):0});
	}


	const generateCards = async()=>{

		if(!preRevealImage && formik.values.vrf_shuffle){

			toast.error('Pre-reval image is missing.');
			return;
		}

		if(generatedCards.length>0 && sides.length>1){
			setGenerateModalVisible(true);
		}else{
			_generateCards(0); 
		}

	}



	const _generateCards = async(whichSide)=>{
		// 0: both sides
		// 1: side1
		// 2: side2

		window.scrollTo(0,0);
		//setGeneratedCards([]);
		setCardsGenerating(true);

		let sidesToGenerate = []
		if(whichSide===0){
			sides.forEach(s=>sidesToGenerate.push(s.id));
		}else{
			sidesToGenerate.push(sides[whichSide-1].id);
		}

		//console.log('s to gen',sidesToGenerate)
		const res = await generateNFTs(currentProjectId, {sides:sidesToGenerate}).catch(e=>console.log);
		setCardsGenerating(false);
		//console.log('GEN RES',res);
		if(res.success){
			if(res.data.length > 0){
				clearMetadataError();
				updatePageState(PAGE,formState.FORM_SAVED);
				updateFormV2(PAGE,'valid_nfts_generated',true);
			}

			let cardArray = [];

			Object.keys(res.data).forEach(k=>cardArray.push(res.data[k]));

			setGeneratedCards(cardArray);
			setNFTsLengthFunc(cardArray.length);
			
			//getCards();
		}else{
			toast.error(res.message.message);
		}
	}

	const onImagesSubmit = async (values) => {

		//console.log('values',values,sizeOptions[values.orientation].length);

		setLayerInfoUpdating(true);

		let width=0;
		let height=0;
		if(values.size===sizeOptions[values.orientation].length || values.size === 0){
			width=Number(customWidth);
			height=Number(customHeight);
		}else{
			width = sizeOptions[values.orientation][Number(values.size)-1].width;
			height = sizeOptions[values.orientation][Number(values.size)-1].height;
		}

		if(values.orientationconsole === 'square' && width !== height){
			toast.error("Wrong dimensions for square shape")
			setLayerInfoUpdating(false);
			return;
		}else if(values.orientation === 'portrait' && !(height > width)){
			toast.error("Wrong dimensions for portrait shape")
			setLayerInfoUpdating(false);
			return;
		}

		if(width<400 || height<400){
			toast.error("Minimum width and height is 400 pixel.")
			setLayerInfoUpdating(false);
			return;
		}
		
		const data = {
						orientation: values.orientation,
						width: width,
						height: height,
						total_layers: 0,
						total_sides: Number(values.total_sides),
						total_nfts: Number(values.total_nfts),
						project_id: Number(currentProjectId),
						vrf_shuffle: values.vrf_shuffle
					}

		let res = null;

		if(layerInfoId!==null){

			if(generatedCards.length>0){
				const confirmed = await isConfirmed('Warning!','You have made some global changes. The previously generated collection will be deleted, also the Utility Trait assignment.','Proceed','Back');
				if(!confirmed){
					setLayerInfoUpdating(false);
					return;
				}
			}
			
			res = await updateLayerInfo(layerInfoId,data);
		}else{
			res = await createLayerInfo(currentProjectId,data).catch(e=>console.log);
		}

		if(res.success){
			//console.log(res);
			toast.success("Layer Info updated");
			/* if(sides && sides.length>0){
				getProjectLayers();
			} */
			setCurrentLayer(0);
			invalidateGeneratedCards();
			setLayerInfoId(res.data.id);
			setLayerInfo(res.data);

			console.log('LAYERINFO ON SAVE',res.data);
			updateFormV2(PAGE,null,{...res.data});
		}else{
			//console.log('RES',res)
			toast.error(res.message);
		}
		setLayerInfoUpdating(false);
	}

	

	const formik = useFormik({
        enableReinitialize: true,
        initialValues: form,
        validationSchema:formSkeleton[PAGE].formScheme,
        validateOnBlur: true,
        validateOnChange: true,
        onChange: v=>{console.log('CHANGEVALS',v)},
        onSubmit: onImagesSubmit
      });

	useEffect(()=>{
		//console.log('size',formik.values.size,customHeight,customWidth)

		if(formik.values.size===4 && customHeight && customWidth){
			//console.log('size',formik.values.size,customHeight,customWidth)
			let f = document.getElementById('disp_size');
			f.innerHTML=customWidth+'x'+customHeight;
		}


	},[formik.values.size,customHeight,customWidth])



	const invalidateGeneratedCards = () =>{
	//	console.log('Erase genereated cards, update sidebar info');
		setGeneratedCards([]);
		setNFTsLengthFunc(0);
		updatePageState(PAGE,formState.FORM_EMPTY);
	}



	const handleSideChange = (idx) => {
	//	console.log('disabled?', (layerUpdating || uploadInProgress));
		if(layerUpdating || uploadInProgress || selectedSide===idx){
			return;
		}
		setCurrentLayer(0);
		setLayerImages([]); 
		setSelectedSide(idx);
	}


	const updateTotalLayers = async (newLayerNumber) =>{
		//console.log(typeof totalSideLayers, typeof newLayerNumber,newLayerNumber);
		if(totalSideLayers>newLayerNumber){
			const confirmed = await isConfirmed('Warning!','You are going to lose some of your layers. Are you sure?','Yes','No');
			
			if(!confirmed){
				return;
			}
		}

		const res = await updateSide(sides[selectedSide].id,{total_layers:newLayerNumber,unique:sides[selectedSide].unique}).catch(e=>console.log);;

		//console.log('total layers updated', res);
		setTotalSideLayers(newLayerNumber); //todo: from result maybe
		
		if(currentLayer>newLayerNumber-1){
			setCurrentLayer(newLayerNumber-1);
		}

		await getProjectLayers();
		getCardSides();
	}

	const updateUnique = async (unique)=>{
		const res = await updateSide(sides[selectedSide].id,{total_layers:sides[selectedSide].total_layers,unique}).catch(e=>console.log);;
		if(res && res.success){
			toast.success('Side '+(selectedSide+1)+' updated.');
			getCardSides();
		}else{
			toast.error('Side '+(selectedSide+1)+' update failed.');
		}
	}

	const toggleView = (viewType)=>{
		if(localStorage){
			localStorage.setItem('preferredView',viewType?'grid':'list');
		}
		setIsListview(!viewType)
	}

	const updateRevealImage = async(imgUrl)=>{
		setPreRevealImage(imgUrl);


		let res = await updateProjectInfoRevealData(currentProjectId,{pre_reveal_image:imgUrl}).catch(e=>console.log);
		//console.log('pre reveal update res:',res);
		if(res.success){
			
			toast.success('Pre-reveal image updated');
			updateFormV2('PROJECT_INFO','pre_reveal_image',imgUrl);

			//setPrerevealCollapsed(true);
		}else{
			toast.warn('Pre-reveal image update failed.');
		}
	}

	return (
		<>
		<div className='row'>
			<div className='col-lg-12'>
			<div style={{ display: 'flex'}}>
				<h4 className="page-title mt-1"><img src={titleIcon} style={{marginRight:"5px"}}/>3. Preferences, layers, images</h4>
				<InfoBox position="bottom" title="Preferences, layers, images"> 
					<p>Provide the shape, size, sides, and the number of your images, then click on the little orange floppy disk icon to save your preferences. Maximum accepted image size is 2000x2000 pixels.</p>
					<p>After that, you can upload the pre-reveal image and the other images for the sides and layers. Please remember to save your preferences after setting them. </p>
					<p>After the layer image upload is finished, please click on “Generate cards”. If the generated cards do not meet your expectations, you can upload different layer images and re-generate your cards at any time. To continue, click on “Next step” or jump directly to step 8. Deploy metadata - to start the reviewing process of your collection and the deployment of final images to IPFS. From there, you may come back to step 4 to configure your sale.</p>
				</InfoBox>
			</div>
				<div className='rounded-box boxShadow p-4 mt-3 images-form '>
					
					<h4 className="page-title mt-0 mb-1 d-flex justify-content-between">
						<span>Preferences</span>
						<img className={`collapse-icon ${imagesCollapsed?'collapsed':''}`} onClick={(e)=>setImagesCollapsed(!imagesCollapsed)} src={collapseIcon} />
					</h4>
					<form onSubmit={formik.handleSubmit}> 
						<div className={`row images-form px-2 align-items-start ${imagesCollapsed&&'collapsed'}`}>
								<div className="col-lg-11">	
										<div className="row small-gutters">
											<div className="col-lg-3">
												<FormInput 
													id='orientation'
													label={formSkeleton[PAGE].formProperties['orientation'].label}
													type={formSkeleton[PAGE].formProperties['orientation'].fieldType} 
													options={formSkeleton[PAGE].formProperties['orientation'].options}
													onChange={(e)=>{handleFormikChange(e,'orientation')}}
													onBlur={formik.handleBlur}
													disabled={!updateEnabled || uploadInProgress}
													value={formik.values['orientation']}
													error={(formik.touched['orientation'] && formik.errors['orientation'])?formik.errors['orientation']:null}
												/>
											</div>
											<div className="col-lg-3">
												<FormInput 
													id='size'
													label={formSkeleton[PAGE].formProperties['size'].label}
													type={formSkeleton[PAGE].formProperties['size'].fieldType}
													disabled={!updateEnabled || uploadInProgress || formik.values['orientation']===''}
													options={formik.values['orientation']!==''?formSkeleton[PAGE].formProperties['size'].options[formik.values['orientation']]:[{label:'',value:''}]}
													onChange={(e)=>{handleFormikChange(e,'size');checkIfCustom(e.target.value)}}
													onBlur={formik.handleBlur}
													value={formik.values['size']}
													error={(formik.touched['size'] && formik.errors['size'])?formik.errors['size']:null}
												/>
											</div>
											<div className="col-lg-3">
												<FormInput 
													id='total_sides'
													label={formSkeleton[PAGE].formProperties['total_sides'].label}
													type={formSkeleton[PAGE].formProperties['total_sides'].fieldType}
													disabled={!updateEnabled || uploadInProgress || formik.values['size']===''}
													options={formSkeleton[PAGE].formProperties['total_sides'].options}
													onChange={(e)=>{handleFormikChange(e,'total_sides')}}
													onBlur={formik.handleBlur}
													value={formik.values['total_sides']}
													error={(formik.touched['total_sides'] && formik.errors['total_sides'])?formik.errors['total_sides']:null}
												/>
											</div>
											<div className="col-lg-3">
												<FormInput 
													id='total_nfts'
													label={formSkeleton[PAGE].formProperties['total_nfts'].label}
													type={formSkeleton[PAGE].formProperties['total_nfts'].fieldType}
													disabled={!updateEnabled || uploadInProgress || formik.values['total_sides']===''}
													onChange={(e)=>{handleFormikChange(e,'total_nfts')}}
													onBlur={formik.handleBlur}
													value={formik.values['total_nfts']}
													error={(formik.touched['total_nfts'] && formik.errors['total_nfts'])?formik.errors['total_nfts']:null}
												/>
											</div>
										</div>
										<div className="row small-gutters">
											<div className="col-lg-12">
												<FormInput 
													id='vrf_shuffle'
													label="I want to shuffle the cards during the reveal process."
													type="checkbox"
													disabled={!updateEnabled || uploadInProgress || formik.values['total_sides']===''}
													onChange={(e)=>{handleFormikChange(e,'vrf_shuffle')}}
													onBlur={formik.handleBlur}
													value={formik.values['vrf_shuffle']}
													error={(formik.touched['vrf_shuffle'] && formik.errors['vrf_shuffle'])?formik.errors['vrf_shuffle']:null}
												/>
											</div>
										</div>
								</div>
								<div className="col-lg-1 pr-1">
									<div className="form-group d-flex">
										<button title="Save/Update preferences"  className='btn-with-icon ml-auto' disabled={layerInfoUpdating || !updateEnabled || uploadInProgress} type="submit">
											{layerInfoUpdating?
												<SpinnerCircular size={18} thickness={180} speed={180} color="rgba(250, 128, 6, 1)" secondaryColor="rgba(0, 0, 0, 0.13)" />
											:
												<img src={saveIcon}/>}
										</button>
									</div>
								</div>
						</div>
					</form>
				</div>

				{(sides.length>0 && formik.values.vrf_shuffle)  &&<div className='rounded-box boxShadow p-4 mt-3 images-form '>
					
					<h4 className="page-title mt-0 mb-1 d-flex justify-content-between">
						<span>Pre-reveal Image</span>
						<img className={`collapse-icon ${prerevealCollapsed?'collapsed':''}`} onClick={(e)=>setPrerevealCollapsed(!prerevealCollapsed)} src={collapseIcon} />
					</h4>
					
					<div className={`row images-form px-2 align-items-center ${prerevealCollapsed&&'collapsed'}`}>
						<div className={preRevealImage?'col-md-5 auto-height':'position-relative col-md-5 '}>
							<div className="main-sale-image">
								<FormInput 
									id='pre_reveal_image'
									label='Pre-reveal image'
									help=''
									mbNone={true}
									type='file'
									disabled={!updateEnabled   || uploadInProgress}
									multiUploadAllowed={false}
									accept={{
												'image/png': ['.png'],
												'image/jpeg': ['.jpg', '.jpeg'],
											}}
									value={preRevealImage}
									onChange={updateRevealImage}
									onBlur={e=>{console.log('on blur:',e)}}
									onErrors={e=>{console.log('on error:',e)}}
									error={null}
								/>
							</div>
						</div>
					</div>



				</div>}



				
				{sides.length>0&&<>

				{!layersCollapsed&&<h4 className="page-title mt-0 mb-0 mt-3 d-flex justify-content-between">
					
					<span>
						{sides.length > 0 &&
							<>
								{sides.map((side,idx)=>{ return(
									<span  key={'tab'+idx} className={`side-tab px-4 ${selectedSide==idx?'active':''} ${(uploadInProgress||layerUpdating)?'disabled':''}`} onClick={()=>handleSideChange(idx)}>
										Side {idx+1} Layers
									</span>
								)})}
							</>
						}
					</span>
					
				</h4>}


				<div className={`rounded-box ${!layersCollapsed?(layerInfo&&layerInfo.sides===1)?'top-sharp':selectedSide===0?'tl-sharp':'top-round':'mt-3'}  boxShadow p-4 mt-0 layers-form`}>
					
					<div className={`row  align-items-center px-2`}>

						<div className="col-lg-10">	
					
							{!layersCollapsed?
								<div className="row small-gutters">
									<div className="col-lg-3">
										<FormInput 
											id='layers_number_selector'
											label='Layers #'
											type='select'

											options={[
											{
												label:'1',
												value:1
											},
											{
												label:'2',
												value:2
											},
											{
												label:'3',
												value:3
											},
											{
												label:'4',
												value:4
											},
											{
												label:'5',
												value:5
											},
											{
												label:'6',
												value:6
											},
											{
												label:'7',
												value:7
											},
											{
												label:'8',
												value:8
											},
											{
												label:'9',
												value:9
											},
											{
												label:'10',
												value:10
											},{
												label:'11',
												value:11
											},
											{
												label:'12',
												value:12
											},
											{
												label:'13',
												value:13
											},
											{
												label:'14',
												value:14
											},
											{
												label:'15',
												value:15
											},
											{
												label:'16',
												value:16
											},
											{
												label:'17',
												value:17
											},
											{
												label:'18',
												value:18
											},
											{
												label:'19',
												value:19
											},
											{
												label:'20',
												value:20
											}
										]}
											onChange={(e)=>{updateTotalLayers(e.target.value);}}
											value={totalSideLayers}
											disabled={uploadInProgress || !updateEnabled}
										/>
									</div>
									<div>
										<FormInput 
											id='unique_dns'
											label="Generate unique DNA."
											type="checkbox"
											disabled={!updateEnabled || uploadInProgress || formik.values['total_sides']===''}
											onChange={(e)=>{updateUnique(e.target.checked)}}
											onBlur={formik.handleBlur}
											value={sides[selectedSide].unique}
										/>
									</div>
								</div>

								:

								<h4 className="page-title mt-0 mb-1 d-flex justify-content-between">
									<span>Sides</span>
								</h4>
										
							
							
							}


						</div>
						<div className="col-lg-2 pr-1">
							<div className="form-group d-flex flex-row align-items-center justify-content-end mb-0">
								{!layersCollapsed&&<div>
									<img title="List view" src={isListView?listIcon:listIconInactive} className="mr-2" onClick={()=>toggleView(false)}/>
									<img title="Grid view" src={isListView?gridIconInactive:gridIcon} className="mr-2" onClick={()=>toggleView(true)}/>
								</div>}

								<img  title="Collapse"  className={`collapse-icon ${layersCollapsed?'collapsed':''}`} onClick={(e)=>setLayersCollapsed(!layersCollapsed)} src={collapseIcon} />
							</div> 
						</div>
					</div>

					<div className={`layers-form px-2  ${layersCollapsed?'collapsed':''}`}>

					{(layers && layers.length>0)&&
						<>
							<div className={`row  align-items-center px-2 small-gutters`}>
								<div className="col-lg-10">	
									<div className="row small-gutters">
										<div className="col-lg-3">
											<FormInput 
												id='layers_number'
												label='Layers'
												type='select' 
												options={layerSelectorOptions}
												onChange={(e)=>selectLayer(e.target.value)}
												value={currentLayer}
												disabled={uploadInProgress}
											/>
										</div>
										<div className="col-lg-3">
											<FormInput 
												id='layer_name'
												label='Layer name'
												type='text'
												extraData={{preFilled:true}}
												disabled={!updateEnabled || uploadInProgress}
												onChange={(e)=>{updateLayerData('name',e.target.value)}}
												onBlur={()=>updateCurrentLayer()}
												value={layers[currentLayer].name}
											/>
										</div>
										<div className="col-lg-3">
											<FormInput 
												id='distribution'
												label='Distribution'
												type='select'
												disabled={!updateEnabled || uploadInProgress}
												options={formSkeleton[PAGE].formProperties['distribution'].options}
												onChange={(e)=>{updateLayerData('distribution',e.target.value);updateCurrentLayer()}}
												onBlur={()=>console.log('distribution onBlur')}
												value={layers[currentLayer].distribution.toLowerCase()}
											/>
										</div>
										<div className="col-lg-3">
											<FormInput 
												id='layer_usage'
												label='Layer usage'
												type='text' 
												onChange={()=>console.log('layer_usage onChange')}
												onBlur={()=>console.log('layer_usage onBlur')}
												value='Mandatory'
												readOnly={true}
												disabled={true}
											/>
										</div>

									</div>
									
								</div>
								<div className="col-lg-2 pr-1">
									<div className="form-group d-flex flex-row justify-content-end">
										{(updateEnabled && !uploadInProgress)&&<>
										{deleteInProgress?<p className=" mb-0 mr-2">
										<SpinnerCircular size={18} thickness={180} speed={180} color="rgba(250, 128, 6, 1)" secondaryColor="rgba(0, 0, 0, 0.13)" />
										</p>
										:
										<p className="btn-with-icon kebab open-dots-panel primary mb-0 mr-2"
											onClick={() => setDotsPanelVisible(!visibleDotsPanel)}>
											...
										</p>}
										</>}

										{visibleDotsPanel && <DotsPanel />}

										<button title="Update layer" className='btn-with-icon pr-0' disabled={layerUpdating || !updateEnabled  || uploadInProgress} onClick={()=>{updateCurrentLayer()}}>
										{layerUpdating?
												<SpinnerCircular size={18} thickness={180} speed={180} color="rgba(250, 128, 6, 1)" secondaryColor="rgba(0, 0, 0, 0.13)" />
											:
												<img src={saveIcon}/>}
										</button>
									</div>
								</div>
							</div>

							<div className={`row small-gutters ${deleteInProgress?'disabled':''}`}>
								{
									
									<div className={`layer-image-upload ${(layerImages.length===0)?(isListView?'list-view col-md-4 pr-5':'col-lg-12'):(isListView?'list-view col-md-4 pr-5':'col-lg-3 col-md-4')}`}>
									{/* <div className={`layer-image-upload ${(layerImages.length===0)?(isListView?'list-view col-md-4 pr-5':'col-lg-12'):'col-lg-3 col-md-4'}`}>
									*/}
									
									
									<FormInput 
										id='layer_images'
										className="portrait"
										label={isListView?'Add Layer Image':'Upload layer images here'}
										type='file'
										disabled={!updateEnabled || uploadInProgress}
										help={isListView?'':`(Minimum 1, maximum ${256-layerImages.length} images)`}
										dimensions={layerInfo?{width:layerInfo.width,height:layerInfo.height}:{width:1,height:1}}
										accept={{
													'image/png': ['.png']
												}}
										onChange={(image)=>{console.log(layerImages);setLayerImages(oldArray => [...oldArray, {...image,dns:oldArray.length}]);console.log('IMAGE TO ADD TO LAYER',image)}}
										onBlur={()=>console.log('layer_images onBlur')}
										onErrors={showUploadErrors}
										multiUploadAllowed={true}
										extraData = {layers[currentLayer].id}
										value={tempUploadImage}
										setUploading={(v)=>setUploadInProgress(v)}
									/>
								</div>}
								
								{(isListView &&layerImages.length>0) &&
									<div className="col-lg-12 mx-n5 order-bar">
										
										<div className='row small-gutters full-width px-3 py-3 align-items-center'>
											<div className={`col-2 order-button no-click`}>
												Image
											</div>

											<div className={`col-2 order-button ${sortBy==='id'?'active':''} ${sortOrder}`} onClick={(e)=>(setLayerImages(sortImages(layerImages,'id')))}>
												DNA <img src={sortIcon}/>
											</div>

											<div className={`col-5 order-button ${sortBy==='name'?'active':''} ${sortOrder}`} onClick={(e)=>(setLayerImages(sortImages(layerImages,'name')))}>
												Name <img src={sortIcon}/>
											</div>
											{layers[currentLayer].distribution==='manual'&&<div className={`col-3 order-button ${sortBy==='rarity'?'active':''} ${sortOrder}`} onClick={(e)=>(setLayerImages(sortImages(layerImages,'rarity')))}>
												Distribution <img src={sortIcon}/>
											</div>}
										
										</div>
									</div>
				

								}

								{layerImages.map((img,idx)=>{
									return(
										<React.Fragment key={idx}>
											<LayerImagePreview 
												index={idx}
												listView={isListView}
												isManual={layers[currentLayer].distribution==='manual'}
												imageData={layerImages[idx]} 
												width={layerInfo.width} 
												height={layerInfo.height} 
												className={isListView?'row':'col-lg-3 col-md-4'}
												getAvailable={getAvailable}
												disabled={!updateEnabled}
												updateLocalSet={(newName,newRarity)=>{
													let tempImages = [...layerImages];
													layerImages[idx].name=newName;
													layerImages[idx].rarity=newRarity;
													setLayerImages(tempImages);
												}}
												onDelete={deleteLayerImageById}
												onUpdateSuccess={invalidateGeneratedCards}
												/>
										</React.Fragment>
									)
								})
								}
							</div>
						</>
					}
					</div>
				</div>

				</>}

				{generatedCards.length>0&&
					
					<>
						{layerInfo.total_sides===1?
							<div className='rounded-box boxShadow py-4 px-3 mt-3'>
								<h4 className="page-title mt-0 mb-1 ml-2 ">
									<span style={{color:"#000"}}>Generated Cards ({generatedCards.length})</span>
								</h4>

								<Explorer allCards={generatedCards} projectId={currentProjectId} projectName={projectInfo?projectInfo.name:''} width={layerInfo?layerInfo.width:1} height={layerInfo?layerInfo.height:1} totalSides={layerInfo.total_sides?layerInfo.total_sides:1} sessionUrl={previewSessionUrl}/>
								
							</div>
							:
							<div>
								<Explorer allCards={generatedCards} projectId={currentProjectId} projectName={projectInfo?projectInfo.name:''} width={layerInfo?layerInfo.width:1} height={layerInfo?layerInfo.height:1} totalSides={layerInfo.total_sides?layerInfo.total_sides:1} sessionUrl={previewSessionUrl}/>
							</div>

						}
					</>
				}

			</div>
			<SSPFooter 
				dataIsLoading={areCardsGenerating } 
				isSubmitEnabled={layerSelectorOptions.length>0 && updateEnabled  && !uploadInProgress} 
				prev="/social-media" 
				next="/presale" 
				submit={{label:generatedCards.length>0?"Regenerate cards":"Generate cards",handleSubmit:()=>generateCards('generate cards...')}}/>
		</div>
		<AlertModal 
			visible={alertVisible}
			hideModal={()=>setAlertVisible(false)}
			type={alertType} 
			modalData = {{title:alertTitle,description:alertMessage, buttonText:alertButtonLabel}}
		/>
		<CustomImageSizeModal 
			closeModal={()=>setCustomModalVisible(false)}
			visible={customModalVisible}
			hideModal={()=>setCustomModalVisible(false)}
			saveFunc = {setCustomSizevalues}
		/>

		<ReGenerateModal 
			closeModal={()=>setGenerateModalVisible(false)}
			visible={generateModalVisible}
			hideModal={()=>setGenerateModalVisible(false)}
			saveFunc = {_generateCards}
		/>
		</>
	)
}

export default LayersAndCards;
