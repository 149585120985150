import React, { useContext, useEffect, useState } from 'react';
import DashboardPanel from './common/DashboardPanel';
import myicon from '../../../assets/images/mc/governance.svg';
import { state } from '../MissionControl';
import { communityHealthcheck, restartLeaderboard, restartMPL, restartUTC, restartVault } from '../../../components/Api';
import { DataContext } from '../../../components/DataStore/DataStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../../config';



const Vault = ({communityId,communityHealth})=>{
	const history = useHistory();
	const [components,setComponents] = useState(null);
	const [vaultDisabled,setVaultDisabled] = useState(true);
	const [vaultUrl,setVaultUrl] = useState(null);

	const {
		deployStatus
	  } = useContext(DataContext);

	useEffect(()=>{
		if(communityId && deployStatus?.contracts && communityHealth){
			getHealtcheck();
			console.log('VaultPanel mounted. community: ',communityId,deployStatus);
		}else{
			//setComponents([])
			setComponents([
				{label:'Admin', status:state.INITIAL,restart:false}])
		}
	},[communityId,deployStatus,communityHealth]);

	const getHealtcheck = async ()=>{
		let admin = state.INITIAL;


		//let health = await communityHealthcheck(communityId).catch(e=>console.log);
		
		if(communityHealth){
			console.log('Vault health',communityHealth);
			let restart = false;
			if(communityHealth.vault){
				if(communityHealth.vault.status==="0" || communityHealth.vault.status==="2"){
					admin = state.PROGRESS;
					restart = communityHealth.vault.status==="2"?true:false;
					setVaultUrl(null);
				}

				if(communityHealth.vault.status==="1"){
					admin = state.SUCCESS;
					restart= true;
					setVaultUrl(`${config.DOMAIN}/${communityId}/vault/admin`);
				}

				if(communityHealth.vault.status==="3"){
					admin = state.ERROR;
					restart= true;
					setVaultUrl(null);
				}
			}

			setComponents([
				{label:'Admin', status:admin, restart}
			]);

			if(deployStatus?.contracts?.token){
				setVaultDisabled(false);
			}
		}else{
			console.log('healtcheck not found');
		}
	}

	const handleGoToConfigVault = () =>{
		history.push('/config-vault');
	}
	const handleRestartVault = async ()=>{
		let res = await restartVault(communityId).catch(e=>console.log); 
		if(res){
			console.log('RESTART VAULT CALLED',res);
			communityHealth.vault.status="0"
		} 
		getHealtcheck();
	}

	return(<>
		<DashboardPanel
			comingSoon={false}
			components={components}
			disabled={components===null || vaultDisabled}
			title="Vault"
			icon={myicon}
			cta={{label:vaultUrl?"vault admin":"configure", action:vaultUrl?vaultUrl:handleGoToConfigVault}}
			restartBtn={config.RESTART_BUTTONS_ENABLED?handleRestartVault:null}
		/>
	</>
	);
}

export default Vault;

