import { useContext, useEffect, useState } from 'react';
import Web3Ctx from '../../components/Context/Web3Ctx';
import { contractKeys, DataContext } from '../../components/DataStore/DataStore';
import { getContractByAddress } from '../../components/Utils/GetContract';
import ProjectCard from './ProjectCard';
import { Zoom } from "zoom-next";
import { SpinnerCircular } from 'spinners-react';
import { createProject, getProjectInfoById, getProjectNameById, getProjects, getProjectsByAccount, getProjectStatus } from '../../components/Api';
import { toast } from 'react-toast';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AlertModal from '../../components/common/AlertModal';
import config from '../../config';
import CreateProjectModal from './CreateProjectModal';
import useConfirm from '../../components/common/useConfirm';
import { ethers } from 'ethers';
import BackdropModal from '../../components/common/BackdropModal';
import TxInProgressModal from '../../components/common/TxInProgressModal';
import { ExclamationCircle } from 'react-bootstrap-icons';
import ProjectCardV2 from './ProjectCardV2';



const Dashboard = () => {

  const {accountInfo,currentProjectId,updateCurrentProjectId,getAllData,setLayers,checkDeployStatus,communityAddresses,setCurrentCommunityName,setGlobalCommunityId,resetCommunityData} = useContext(DataContext);
  const {address,chainId,getProvider,defaultProvider,setChain} = useContext(Web3Ctx);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [launchKey, setLaunchKey] = useState(null);
  const [zoom2, setZoom2] = useState(null);

  const [cardIDs,setCardIDs] = useState([]);

	const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
	const [selectedCardId, setSelectedCardId] = useState(null);
	const [initDone, setInitDone] = useState(false);
	const [communityData, setCommunityData] = useState(null);

  const [assignedCardIds, setAssignedCardIds] = useState([]);

  const [approveInProgress, setApproveInProgress] = useState(false);
  const [txInProgress, setTxInProgress] = useState(false);
  const [txHash, setTxHash] = useState(null);

  const { isConfirmed } = useConfirm();


  useEffect(()=>{
    if(resetCommunityData){
      resetCommunityData();
    }
  },[])


  useEffect(()=>{
    
    const createCommunity = async ()=>{

      if(config.DEPLOYED_CHAIN_ID!==chainId){
        await setChain(config.DEPLOYED_CHAIN_ID);
        return;
      }


      console.log('creating');

      setApproveInProgress(true);
      
     //const provider = await getProvider(parseInt(communityData.targetChain.id,16));
      const provider = await getProvider(config.DEPLOYED_CHAIN_ID);
      let masterReg = await getContractByAddress('MasterRegistry',communityAddresses[contractKeys.MASTER_REGISTRY],provider);
      if(masterReg){
        let mr = masterReg.connect(provider.getSigner());
        //console.log(mr);
        let d1 =null;
      
        if(parseInt(communityData.targetChain.id,16)===11155111 || parseInt(communityData.targetChain.id,16)===1){
          d1=1; //ETHEREUM
        }else{
          if(parseInt(communityData.targetChain.id,16)===	421613){
            d1=42161;//ARBITRUM ONE
          }else{
            d1=137;//POLIGON
          }
        }

        let d2 = Number(communityData.communityId);
        let d3 = communityData.communityAdmin;
        let d4 = communityData.communityName;

        console.log('data to send',d1,d2,d3,d4);

        let tx = await mr.createCommunity(d1,d2,d3,d4).catch(handleError);
       
        setApproveInProgress(false);

        console.log('tx',tx);

        if(tx){
          setTxHash(`${config.DEPLOYED_EXPLORER}/tx/${tx.hash}`);
         // setTxHash(`${communityData.targetChain.blockExplorerUrl}/tx/${tx.hash}`);
          setTxInProgress(true);
          let receipt = await tx.wait().catch((e) => {
            handleError(e);
            setTxInProgress(false);
          });
          //console.log('receipt',receipt);
          setTxInProgress(false);
          setCurrentCommunityName(communityData.communityName);
          setGlobalCommunityId(communityData.communityId);
          createMissionControlProjec(communityData.communityId,parseInt(communityData.targetChain.id,16),communityData.communityName);
        }
      }
    }
    
    if(communityData && chainId && communityAddresses){
      console.log('commuity data',communityData, chainId);
      createCommunity();
    }

  },[communityData,chainId,communityAddresses]);



  useEffect(() => {
    const initContract = async () => {
        console.log('INIT CONTRACTS');
        console.time('init contracts');
        let ethersProvider = defaultProvider;//await getProvider(137);

        const contract = await getContractByAddress('LaunchKey',communityAddresses[contractKeys.LAUNCHKEY] ,ethersProvider).catch(e => console.log('err:', e));
        if (contract) {
            setLaunchKey(contract);
        } else {
            console.error('contract not found (launch pass)');
        }


        let zoom = await getContractByAddress("Zoom2", communityAddresses[contractKeys.ZOOM] ,ethersProvider);
        if (zoom) {
            setZoom2(zoom);
        } else {
            console.error('contract not found (zoom)');
        }
        console.timeEnd('init contracts');
        setInitDone(true);
    }

    if(chainId && communityAddresses){
      setInitDone(false);
      initContract();
    }
  }, [communityAddresses]);


  useEffect(()=>{
    const getCards = async ()=>{
       //checking if approved
      let registryAddress = communityAddresses[contractKeys.MASTER_REGISTRY];
      if(registryAddress){

        console.time('projects',defaultProvider);

        let mr = await getContractByAddress('MasterRegistry',registryAddress,defaultProvider).catch(e=>console.log);
        let userCommunities = await mr.userCommunities(address).catch(e=>console.log);

        //console.log('THE TUPLE',userCommunities);

        let mappedCommunities = [];

        if(userCommunities.length>0){
          //user has projects

          const res = await getProjects().catch(e=>console.log);
         // console.timeEnd('projects');
         // console.log('PROJECT RES',res);
    
          if(res.success){
            for (let community of userCommunities) {
              let card = {...community};
              let project = res.data.find(project => project.community_id === card.community_id.toString());
              
            //  console.log('=========project.chain_id',project,community);

              if(project){
                let err = false;
                card.projectId=project.id;
                card.chain_id = project.chain_id;
                card.hasProject=true;
                card.siteStatus = project.last_website_status;
                card.metaStatus = project.last_metadata_status;
                card.hasError=err;
              }else{
                card.projectId=null;
                card.hasProject=false;
                card.assignedButNoProjectYet = true;
              }
              mappedCommunities.push(card);
            }

           // console.log('USER COMMUNITIES',mappedCommunities);

          }else{
            toast.error(res.message.message?res.message.message:res.message);
          }

        }
        //ADD the unassigned cards to the list (if is there any)
        getUserCards(mappedCommunities);
      }
    }

    if(initDone === true && zoom2 && launchKey && accountInfo){
      setIsLoading(true);
      getCards();
    }

    

  },[initDone]);
  
  const getUserCards = async(assignedIds)=>{
   // setIsLoading(true);
    const unusedTokens = [];
    const res = await launchKey.balanceOf(accountInfo.address).catch(e=>console.log);

    const nrOfCards = res.toNumber();
    if(nrOfCards > 0){
      
      const ZoomLibraryInstance = new Zoom({ use_reference_calls: true });
      let calls = [];

      for (let i = 0; i < nrOfCards; i++) {
        // request the token ID
        const tId = ZoomLibraryInstance.addMappingCountCall(
            // the contract we're calling
            launchKey,
            ["tokenOfOwnerByIndex", [accountInfo.address, i]],
            "tokenOfOwnerByIndex(address,uint256) returns (uint256)",
            [{ contract: launchKey, mapAndParams: ["tokenURI(uint256)", [i]] }]
        );
        calls.push(tId);
/* 
        const tUri = ZoomLibraryInstance.addType5Call(
            launchKey,
            ["tokenURI(uint256)", [i]],
            "tokenURI(uint256) returns (string)"
        );
        calls.push(tUri); */
      }

      const ZoomQueryBinary = ZoomLibraryInstance.getZoomCall();

       console.log("======== ZOOM CALL START ============");
      console.time("launchKey"); 
      const combinedResult = await zoom2.combine(ZoomQueryBinary);
       console.timeEnd("launchKey");
      console.log("======== ZOOM CALL END ==============");

      ZoomLibraryInstance.resultsToCache(combinedResult, ZoomQueryBinary);

      for (let i = 0; i < nrOfCards/*  * 2 */; i = i + 1/* 2 */) {
          const id = ZoomLibraryInstance.decodeCall(calls[i]).toString();
          //console.log('TOKEN ID PUNK', id);
         // const tokenURI = ZoomLibraryInstance.decodeCall(calls[i + 1]).toString();
          unusedTokens.push({community_id:Number(id),projectId:null,name:null,hasProject:false});
      }

      //console.log('UNUSED',unusedTokens);
      assignedIds.sort((a,b)=> {return Number(a.community_id)>Number(b.community_id)?1:Number(b.community_id)>Number(a.community_id)?-1:0});
      unusedTokens.sort((a,b)=> {return Number(a.community_id)>Number(b.community_id)?1:Number(b.community_id)>Number(a.community_id)?-1:0});





      setCardIDs(assignedIds.concat(unusedTokens));
    }else{
      //console.log('assigned IDS', assignedIds)
      assignedIds.sort((a,b)=> {return Number(a.community_id)>Number(b.community_id)?1:Number(b.community_id)>Number(a.community_id)?-1:0});
      setCardIDs(assignedIds);
    }
    
    setIsLoading(false);
  }

  const handleOnCreate = async(cardId,needToTransfer, communityName)=>{
    console.log('ON CREATE', communityData, needToTransfer);

    let registryAddress = communityAddresses[contractKeys.MASTER_REGISTRY];
    if(!registryAddress){
      console.log('no registry address :/');
      return;
    }

    if(needToTransfer){
      setCurrentCommunityName(communityName);
      
      /* setApproveInProgress(true);
      let approved = await launchKey.isApprovedForAll(address,registryAddress).catch(handleError);
      setApproveInProgress(false);

      console.log('APPROVED',approved,registryAddress);
      
      if(!approved){
        const confirmed = await isConfirmed('Approve operator','Placeholder for an explanation why we need to do this. And lorem ipsum. Same in english.','Approve','No, thanks.');
        if(!confirmed){
          return;
        }
  
        let provider = await getProvider(config.DEPLOYED_CHAIN_ID);
        let lk = launchKey.connect(provider.getSigner());
        setApproveInProgress(true);
        let tx = await lk.setApprovalForAll(registryAddress,true).catch(handleError);
        setApproveInProgress(false);
        console.log('TX', tx);
        if(tx){
          setTxHash(`${config.DEPLOYED_EXPLORER}/tx/${tx.hash}`);
          setTxInProgress(true);
          let receipt = await tx.wait().catch(e=>{
            setTxInProgress(false);
            handleError(e);
            return;
          });
          console.log('receipt',receipt);
          setTxInProgress(false);
        }
      } */

      setSelectedCardId(cardId);
      setIsCreateModalVisible(true);
    }else{
      //TODO:replace with real data

      let provider = await getProvider(config.DEPLOYED_CHAIN_ID);
      let mr = await getContractByAddress('MasterRegistry',registryAddress,provider).catch(e=>console.log);

      if(!mr){
        setApproveInProgress(false);
        toast.error("Master Registry not found.");
        return;
      }

      let project = await mr.communityListByID(cardId).catch(e=>console.log);
      
   // console.log('project from master reg',project, project.chainID);

      if(project){
        //let theId = project.chainID===1?config.DEPLOYED_CHAIN_ID===5?5:project.chainID:project.chainID;
        let theId = null;
        if(project.chain_id===1){
          theId = config.DEPLOYED_CHAIN_ID === 11155111?11155111:1; //eth
        }else{
          if(project.chain_id===137){
            theId = config.DEPLOYED_CHAIN_ID === 11155111?80001:137; //polygon
          }else{
            theId = config.DEPLOYED_CHAIN_ID === 11155111?421613:42161; //arbitrum
          }
        }

        console.log('cardId, THE ID:',cardId,theId);
        setCurrentCommunityName(project.community_name);
        setGlobalCommunityId(cardId);
        createMissionControlProjec(cardId,theId,project.community_name);
      } else {
        toast.error('Can\'t acquire project details from Master Registry');
      }

    }
  }

  const createMissionControlProjec = async(cardId,targetChainId,communityName)=>{
    const data = {
      community_id: cardId.toString(),
      account_id: accountInfo.id,
      chain_id: targetChainId
    }

    let res = await createProject(data).catch(e=>{console.log(JSON.stringify(e))});
    console.log('CREATE',res);

    if(res.success){
    //current project id
    setLayers(null);
    updateCurrentProjectId(res.data.id);
    checkDeployStatus(res.data.id);

    getAllData(res.data.id,communityName);

    history.push("/mission-control");
    }else{
    console.log(JSON.stringify(res.message));
    console.log(res.message);

    if(res.message==="Unauthenticated."){
    console.log('LOGIN!')
    }
    toast.error(res.message.message?res.message.message:res.message);
    //toast.error(res.message.toJSON().message);
    }
  }

  const onCreateCommunity =  (data)=>{
    console.log('oncreate',data)
    setCommunityData(data);
  }

  const handleOnOpen = (cardId,projectId,communityName)=>{
    setCurrentCommunityName(communityName);
    setLayers(null);
    updateCurrentProjectId(projectId);
    getAllData(projectId,communityName);
    checkDeployStatus(projectId);
    history.push("/mission-control");
  }

  const handleError = (e) => {
    console.error('handle error',e);
    if (e.error && e.error.message) {
      toast.error(e.error.message);
    } else if (e.message) {
      toast.error(e.message);
    } else if (e.reason) {
      toast.error(e.reason);
    }
  };

/*   const handleOnDelete = (projectId) => {
    setProjectIdToDelete(projectId);
    setAlertVisible(true);
  } */

 /*  const deleteProjectById = async()=>{
    if(projectIdToDelete===null){
      toast.warn('No project ID found');
      return;
    } */

 /*    const res = await deleteProject(projectIdToDelete).catch(e=>console.log);
    if(res.success){
      setProjectIdToDelete(null);
      getUserCards();
      toast.success('Project deleted.');
    }else{
      if(res.message.indexOf('401')>0){
        console.log('LOGIN!');
      }
      toast.error(res.message);
    }
  } */

  return (
    <>
      <div className="container mt-5" >
        <h4 className="page-title mt-1 mb-2">Manage your projects or start a new one</h4>
        <div className="row">
          {isLoading?<>
            <div className="col-lg-12 text-center mt-5">
              <SpinnerCircular size="64" color="#ff692b"/>
            </div>
          </>
          :
          <>
            {cardIDs.map((card,idx) => {
              return (
                <div key={idx} className="col-md-3 col-xl-3">
                  <ProjectCardV2
                    card={card}
                    getProvider={getProvider}
                    onCreateProject={handleOnCreate}
                    onOpenProject={handleOnOpen}
                  />
                </div>
              );
            })}
          </>}


        </div>
      </div>
      <CreateProjectModal
        visible={isCreateModalVisible}
        address={address}
        communityId = {selectedCardId}
        onCreate={(e)=>onCreateCommunity(e)}
        hideModal={()=>setIsCreateModalVisible(false)}
      />
      <BackdropModal visible={approveInProgress}/>
      <TxInProgressModal visible={txInProgress} txHash={txHash}/>
    </>
  );
};

export default Dashboard;
