import { BigNumber, ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toast';
import { createOrUpdateSale, getLayerInfo, getProjectInfoById, getProjectStatus, getSaleForProject, getSocialMedia, getWalletShares, publishProject } from '../../components/Api';
import { contractKeys, DataContext } from '../../components/DataStore/DataStore';
import SSPFooter from '../../components/SSPFooter/SSPFooter';
import titleIcon from '../../assets/images/page-title-icons/contract.png';
import Web3Ctx from '../../components/Context/Web3Ctx';
import {
	CheckCircleFill,
	XCircleFill,
  } from 'react-bootstrap-icons';
import axios from 'axios';

import './LayersOnChain.css';

import useConfirm from '../../components/common/useConfirm';
import config from '../../config';
import FormInput from '../../components/common/FormInput';
import { getContractAddress } from 'ethers/lib/utils';
import { getContract, getContractByAddress } from '../../components/Utils/GetContract';
import { SpinnerCircular } from 'spinners-react';
//import tokenContractAbi from '../../contracts/TokenContract.json';
import BorderedNavigationLink from '../../components/visual-utils/navigation-links/BorderedNavigationLink';

import { VisualTraits, Tools as ECTools } from '@ethercards/ec-util'
import TxInProgressModal from '../../components/common/TxInProgressModal';
import BackdropModal from '../../components/common/BackdropModal';
// import Tools from '../src/utils/Tools'
// import TokenSpecs from '../src/ec/TokenSpecs'
// import VisualTraits from '../src/ec/VisualTraits'

const LayersOnChain = (props) => {
	const {onboard,address,handleConnect,getProvider,setChain,chainId} = useContext(Web3Ctx);
	const {currentProjectId, communityId, communityAddresses, communityRegistry, getChainSpecificCommunityAddresses, deployStatus, projectChain,refreshCommunityData } = useContext(DataContext);

	const location = useLocation();
	const history = useHistory();

	const supportedChains=config.CHAINS;

	
	const [txInProgress, setTxInProgress] = useState(false);
	const [approveInProgress, setApproveInProgress] = useState(false);
	const [txHash, setTxHash] = useState(null);
	const [startReveal, setStartReveal] = useState(null);
	const [tokenContract, setTokenContract] = useState(null);
	const [selectedChainIdx, setSelectedChainIdx] = useState(0);

	const [mintedSupply,setMintedSupply]=useState(0);
	const [revealsSoFar,setRevealsSoFar]=useState(0);
	const [revealed,setRevealed]=useState(0);
	const [lastReveal,setLastReveal]=useState(false);
	const [revealData,setRevealData]=useState(false);
	const [tokenSpecsOnIPFSDeployed,setTokenSpecsOnIPFSDeployed]=useState("Unknown");
	const [traitsOnChainContractsDeployed,setTraitsOnChainContractsDeployed]=useState("Unknown");
	const [layersOnChainContractsDeployed,setLayersOnChainContractsDeployed]=useState("Unknown");
	const [layersOnChainContractsCanBeDeployed,setLayersOnChainContractsCanBeDeployed]=useState(false);
	const [layersCanBeAdded,setLayersCanBeAdded]=useState(false);
	
	const [updatesAvailable,setUpdatesAvailable]=useState("Unknown");
	const [collectionSPECS, setCollectionSPECS] = useState(null);	
	const [allJSON, setAllJSON] = useState(null);	
	const [ipfsHash, setIpfsHash] = useState(null);	
	
	const [traitRegistryAddress,setTraitRegistryAddress]=useState(null);
	const [ipfsSPECUrl,setIpfsSPECUrl]=useState(null);
	const [layersOnChainAdress,setLayersOnChainAdress]=useState(null);

	const [layersTxns,setLayersTxns]=useState([]);
	
	const [vrfEnabled, setVrfEnabled] = useState(true);
	
	const { isConfirmed } = useConfirm();

	useEffect(()=>{

		const refreshCa = async()=>{
			if(!communityAddresses.VISUAL_TRAIT_REGISTRY_FACTORY){
				
				const ca = await getChainSpecificCommunityAddresses(projectChain);
				//console.log('%%%%%%community registry refreshed',ca);
			}
			getLayerOnChainDataUpdates();
		}



		if(projectChain && deployStatus.contracts?.token){
			console.log('PROJECT CHAIN',projectChain)
			const idx = supportedChains.findIndex(c => {
				return parseInt(c.id,16) === projectChain;
			});
			if(idx>-1){
				setSelectedChainIdx(idx);
			}
			refreshCa();

			
		}
	},[projectChain, deployStatus])


	useEffect(()=>{
		if(communityAddresses && communityRegistry){
			console.log('______effect: communityAddresses,communityRegistry',communityAddresses,communityRegistry);
			console.log(Date.now());
			const tokenNumber = 1;
			const thisTokensTraitRegistryAddress = communityRegistry["TRAIT_REGISTRY_"+tokenNumber];
			let tstamp = Number(localStorage.getItem('TTRA'));
			console.log('tstamp',tstamp);
			if(thisTokensTraitRegistryAddress==ethers.constants.AddressZero && Date.now()-tstamp > 20000 ){
				console.log('REFRESH')
				refreshCommunityData();
				localStorage.setItem('TTRA',Date.now());
			}
		}
	},[communityAddresses,communityRegistry])

	/* UPDATE
	useEffect(()=>{

		const initContract = async ()=>{

			let ethersProvider = await getProvider(projectChain);

			if(deployStatus.contracts.token){
				let token = new ethers.Contract(deployStatus.contracts.token, tokenContractAbi.abi, ethersProvider);
				if(token){
					setTokenContract(token);
				}
			}
		}

		//console.log(targetChain,chainId);
		if(projectChain && deployStatus?.contracts?.token){
			initContract();
		}

	},[projectChain, deployStatus]); */


	/* useEffect(()=>{

		if(tokenContract){
			console.log("useEffect getLayerOnChainDataUpdates lastReveal", lastReveal);
			getLayerOnChainDataUpdates();
		}
	},[lastReveal]) */
	

	const loadLayerOnChainRequirements = async()=>{

		console.log('community registry in req',communityRegistry);

		let _ipfsHashURL = config.IPFS_GATEWAY+ communityRegistry.specs;
		let _allMetadataURL = communityRegistry.metadata_url;

		console.log('TAS  URLs',_ipfsHashURL,_allMetadataURL);
		// 
		
		// TODO: remove after implementation
		// _ipfsHashURL = "https://nowlive.ro/ec/450";

		// TODO: link this to current selected token ID
		const tokenNumber = 1;
		const thisTokensTraitRegistryAddress = communityRegistry["TRAIT_REGISTRY_"+tokenNumber];
		
		setTraitRegistryAddress(thisTokensTraitRegistryAddress);

		const provider = getProvider(chainId);

		let _specsDeployed = false;

		if(ipfsHash === null) {
			//const _ipfsSpecUrl = `${_ipfsHashURL}/metadata/specs.json`
			const _ipfsSpecUrl = `${_ipfsHashURL}/specs.json`

			

			setIpfsSPECUrl(_ipfsSpecUrl);

			await axios.get(_ipfsSpecUrl).then(res=>{
				console.log('specs json',res);
				if(res && res.status===200){
					setCollectionSPECS(res.data);
					_specsDeployed = true;
					setTokenSpecsOnIPFSDeployed(_specsDeployed);
				}
				//setIpfsHash(communityRegistry.collection_hash);

				console.log('communityRegistry.specs',communityRegistry)
				setIpfsHash(communityRegistry.specs);

			}).catch(e=>{
				console.log('specs json error',e);
				setCollectionSPECS(null);
			})

			const _ipfsAllUrl = `${config.IPFS_GATEWAY+ communityRegistry.collection_hash}/metadata/all.json`
			// const _ipfsAllUrl = `${_allMetadataURL}/all`
			await axios.get(_ipfsAllUrl).then(res=>{
				console.log('all.json',`${_allMetadataURL}/all`);
				if(res && res.status===200){
					setAllJSON(res.data);
				}
			}).catch(e=>{
				console.log('all json error',e);
				setAllJSON(null);
			})
		} else {
			_specsDeployed = tokenSpecsOnIPFSDeployed;
		}

		let _tocDeployed = false;
		let _locDeployed = false;
		let _layersOnChainAdress = null;

		if(communityRegistry && communityRegistry.deployed) {
			if(communityRegistry.data.tokens[0] && communityRegistry.data.tokens[0].traitRegistryDeployed) {
				_tocDeployed = true;
			}

			// check if our visual traits registry has been deployed

			if(thisTokensTraitRegistryAddress && thisTokensTraitRegistryAddress!==ethers.constants.AddressZero){
				let thisTraitRegistry = await getContractByAddress('TraitRegistry', thisTokensTraitRegistryAddress, provider);
				const existingTraits = await thisTraitRegistry.getTraits();
				if(existingTraits.length > 0) {
					for(let j = 0; j < existingTraits.length; j++) {
						if(existingTraits[j].traitType === 104) {
							// make sure not empty
							if(existingTraits[j].storageImplementer !== ethers.constants.AddressZero) {
								_locDeployed = true;
								_layersOnChainAdress = existingTraits[j].storageImplementer;
								setLayersOnChainAdress(_layersOnChainAdress);
							}
						}
					}
				}
			}
		}

		setTraitsOnChainContractsDeployed(_tocDeployed);
		setLayersOnChainContractsDeployed(_locDeployed);

		// if Trait Registry has been deployed
		// and Visual Layers have not
		if(_tocDeployed && !_locDeployed ) {
			setLayersOnChainContractsCanBeDeployed(_specsDeployed);
		}else if(_tocDeployed && _locDeployed && _specsDeployed){
			//visual layers has been deployed
			setLayersOnChainContractsCanBeDeployed(false);
		}

	}

	
	const generateLayerOnChainUpdates = async()=>{
		console.log('generateLayerOnChainUpdates START');

		if(!layersOnChainContractsDeployed || collectionSPECS === null  || revealData === null) {
			setLayersCanBeAdded(false);
			return;
		}

		console.log("generateLayerOnChainUpdates reveal", revealed);

		const txns = [];


		if(!layersOnChainAdress){
			console.log('NO "VisualTraitRegistry" ADDRESS');
			return;
		}

		let provider = await getProvider(chainId);
		let layersOnChain = await getContractByAddress('VisualTraitRegistry', layersOnChainAdress, provider);
		

		
		layersOnChain = layersOnChain.connect(provider.getSigner());

		if(typeof collectionSPECS === "undefined" || typeof collectionSPECS.sides === "undefined" ) {
			setLayersCanBeAdded(false);
			return;
		}

		//
		// Part 1, createTraitSet
		//
		// iterate through our collection specs to see if we've got sides that have not been deployed yet
		//
		let layersNeedToBeAdded = [];
		for(let i = 0; i < collectionSPECS.sides.length; i++) {
			const onChainBitlength = (await layersOnChain.traitInfoLength(i)).toNumber();


			const specBitLength = collectionSPECS.sides[i].bitlength;

			layersNeedToBeAdded[i] = false;

			if(onChainBitlength === 0) {
				console.log("SIDE:", i, collectionSPECS.sides[i]);
				console.log("SIDE:", i, "specBitLength", specBitLength, "onChainBitlength", onChainBitlength);

				const traitData = [];
				for(let y = 0; y < collectionSPECS.sides[i].layers.length; y++) {
					const layer = collectionSPECS.sides[i].layers[y];
					traitData.push({
						name: layer.name,
						len: layer.bitlength
					});
				}

				txns.push({
					contract: layersOnChain,
					method: "createTraitSet",
					data: [
						collectionSPECS.sides[i].name,
						traitData
					],
					label: {title: "Create TraitSet for Side "+i}
				});
				layersNeedToBeAdded[i] = true;
			}
		}

		//
		// Part 2, setData
		//
		const vt = new VisualTraits();

		console.log('*******allJSON, revealData',allJSON, revealData)
		if((!revealData || revealData.length===0) && vrfEnabled){
			console.log('TAS: no reveal data yet');
			return;
		}


		
		// Since our tokens start at id 1, we need to pad our data by 1 token.
		//StorableTokenJson.push({"sides": [{"dna": "0000",},{"dna": "0000"}]});
		
		let StorableTokenJson = [];
		const dnaToAdd = getDnaFromSpecs(collectionSPECS);
		console.log('DNA TO ADD',dnaToAdd)
		StorableTokenJson.push({"sides": dnaToAdd});
		
		// if no vrf, shifted equals all.json, no need  to shift.
		let lastRevealedTokenId = null;
		if(vrfEnabled){
			//ECTools		
			const [ShiftedTokenJson, lastId] = ECTools.shiftAndFilterRevealedTokens(allJSON, revealData);
			// const encodedVisualLayerData = vt.encodeVisualLayerData(allJSON, collectionSPECS, lastRevealedTokenId);
	
			lastRevealedTokenId = lastId
			for(let i = 0; i < ShiftedTokenJson.length; i++) {
				StorableTokenJson.push(ShiftedTokenJson[i]);
			}
		}else{
			lastRevealedTokenId = mintedSupply
			StorableTokenJson=[...StorableTokenJson,...allJSON];
		}

		console.log("revealData          ", revealData);
		console.log("allJSON             ", allJSON);
		console.log("lastRevealedTokenId ", lastRevealedTokenId);
		console.log("StorableTokenJson   ", StorableTokenJson);
		console.log("collectionSPECS     ", collectionSPECS);
		console.log("encodedVisualLayerData before");

		console.log('PPPPPPPPPP',StorableTokenJson, collectionSPECS, lastRevealedTokenId)
		const encodedVisualLayerData = vt.encodeVisualLayerData(StorableTokenJson, collectionSPECS, lastRevealedTokenId);
		
		console.log("encodedVisualLayerData", encodedVisualLayerData);

		// unshifted
		// "0x26456a329229669172856d2d8e665200"
		// shifted
		// "0x6d2d8e665226456a3292296691728500"

		//
		// TODO: do shifts based on reveals
		// OR: call metadata server to get shifted data
		// need metadata server endpoint.. see line 370
		//

		// load onChain Metadata
		const chainMetaData = await layersOnChain.MetaData();
		console.log("chainMetaData", chainMetaData);
		console.log("layersNeedToBeAdded", layersNeedToBeAdded);

		// for each side
		for(let i = 0; i < encodedVisualLayerData.length; i++) {

			if(!layersNeedToBeAdded[i]) {
				const onChainWordCount = parseInt(chainMetaData.wordCounts[i].toString());
				const encodedWordCount = encodedVisualLayerData[i].Data.length;
				const positions = [];

				// get positions including last word
				for(let z = 0; z < encodedWordCount; z++) {
					positions.push(z);
				}

				const indexes = [];
				const values = [];

				console.log("getRandomDataStream", i, positions);

				// load existing data from chain
				const chainDataResult = await layersOnChain.getRandomDataStream(i, positions) ;
				const chainData = [];
		
				for(let i = 0; i < chainDataResult.length; i++) {

					// if(i < onChainWordCount) {
					// 	if(chainDataResult[i] !== 0) {
					// 		continue;
					// 	}
					// }

					chainData[positions[i]] = chainDataResult[i];
				}
				

				console.log("chainData", chainData);
				console.log("encodedWordCount", encodedWordCount);

				// check against new data we have
				for(let j = 0; j < encodedWordCount; j++) {

					const onChainWord = chainData[j];
					const encodedWord = encodedVisualLayerData[i].Data[j]

					// if(j < onChainWordCount) {
					// 	if(onChainWord !== 0) {
					// 		continue;
					// 	}
					// }

					console.log("VTR Check:", i , "j", j, "old", BigNumber.from(onChainWord.toHexString()).toString());
					console.log("VTR Check:", i , "j", j, "new", BigNumber.from(encodedWord.toString()).toString());
					console.log("j", j, "onChainWordCount", onChainWordCount, "onChainWord", onChainWord.toHexString(), "encodedWord", encodedWord.toString());
					console.log("VTR Check:");
					if(j <= onChainWordCount) {

						if(!onChainWord.eq(encodedWord)){
							indexes.push(j);
							values.push(encodedVisualLayerData[i].Data[j]);
						}

						/* 
					 	if(onChainWord.eq(BigNumber.from(0)) ) {
							console.log("VTR Check: DATA RESET found.. need update index", j);
							indexes.push(j);
							values.push(encodedVisualLayerData[i].Data[j]);
						} else {
							console.log("VTR Check: DATA EXISTS .. skip index", j);
						} 
						 */

					} else if(onChainWord.toHexString() !== encodedWord.toString() ) {
						console.log("VTR Check: difference found.. need update index", j);
						indexes.push(j);
						values.push(encodedVisualLayerData[i].Data[j]);
					}
				}

				if(indexes.length > 0) {

					const maxGasPerTxn = 9000000;
					// set this to more than half of maxGasPerTxn to see 2 txns
					const gasPerIndex =  50001;	// gasPerIndex

					// TODO: in testing use this
					// normally we can expect around 20k per index + initial logic overhead of ~50k
					// const gasPerIndex =  20000;	// gasPerIndex

					const maxIndexesPerTxn = Math.floor( maxGasPerTxn / gasPerIndex);
					const gasForCurrentIndexes = indexes.length * gasPerIndex;
					const pages = Math.ceil(gasForCurrentIndexes / maxGasPerTxn);

					console.log("maxGasPerTxn:    ", maxGasPerTxn)
					console.log("gasPerIndex:     ", gasPerIndex)
					console.log("maxIndexes:      ", maxIndexesPerTxn)
					console.log("current indexes: ", indexes.length)
					console.log("pages(txns):     ", pages)

					let lastIndex = 0;
					// split up data into multiple transactions
					for(let p = 0; p < pages; p++) {
						const newIndexes = [];
						const newValues = [];

						console.log("lastIndex: ", lastIndex)
						console.log("indexes:   ", indexes)
						console.log("indexesL:  ", indexes[lastIndex])
						console.log("values:    ", values)
						console.log("valuesL:   ", values[lastIndex])


						for(let r = 0; r < maxIndexesPerTxn; r++) {
							if(typeof indexes[lastIndex] !== "undefined") {
								newIndexes.push(indexes[lastIndex]);
								newValues.push(values[lastIndex]);
							}
							lastIndex++;
						}

						// estimate gas and split if too big
						const gasCostEstimate = await layersOnChain.estimateGas.setTraitsByRandomWords(
							i,			// sideId
							newIndexes,	// indexes/positions
							newValues,	// values
							lastRevealedTokenId
						);

						txns.push({
							contract: layersOnChain,
							method: "setTraitsByRandomWords",
							data: [
								i,			// sideId
								newIndexes,	// indexes/positions
								newValues,  // values
								lastRevealedTokenId
							],
							label: {
								title:"Add Layer Data for side "+ i,
								lastId:lastRevealedTokenId,
								wordCount:indexes.length,
								// add to UI
								transactionId: (p+1),
								transactionCount: pages,
								// you can call the network's gas station and multiply this to get a dollar cost if you want
								gasEstimate: Number(gasCostEstimate.toString()),
							},
							_label: "["+(p+1)+"/"+pages+"]Add Layer Data for side: "+ i + " Last token id:"+ lastRevealedTokenId + " WordCount:" + newIndexes.length
						});
					}

					// } else {
					// 	// create replacement data txn
					// 	txns.push({
					// 		contract: layersOnChain,
					// 		method: "setTraitsByRandomWords",
					// 		data: [
					// 			i,			// sideId
					// 			indexes,	// indexes/positions
					// 			values,		// values
					// 			lastRevealedTokenId
					// 		],
					// 		label: "Add Layer Data for side: "+ i + " Last token id:"+ lastRevealedTokenId + " WordCount:" + indexes.length
					// 	});
					// }

					// create replacement data txn
					// txns.push({
					// 	contract: layersOnChain,
					// 	method: "setTraitsByRandomWords",
					// 	data: [
					// 		i,			// sideId
					// 		indexes,	// indexes/positions
					// 		values,		// values
					// 		lastRevealedTokenId
					// 	],
					// 	label: {title:"Add Layer Data for side "+ i,  lastId:lastRevealedTokenId, wordCount:indexes.length},
					// 	_label: "Add Layer Data for side "+ i + "Last token id:"+ lastRevealedTokenId + " WordCount:" + indexes.length
					// });

				}

			}

		}


		if(txns.length > 0) {
			console.log("new txns:", txns);
			setLayersCanBeAdded(true);
		} else {
			setLayersCanBeAdded(false);
		}
		setLayersTxns(txns);
		// 

		console.log('generateLayerOnChainUpdates END');
	}


	const getDnaFromSpecs = (specs) =>{

		let dnasToReturn=[];
		for(let i=0;i<specs.sides.length;i++){
			let dnaForSide="";
			for(let j=0;j<specs.sides[i].layers.length;j++){
				dnaForSide+="00";
			}
			dnasToReturn.push({"dna":dnaForSide});
		}

		return dnasToReturn;
	}

	useEffect(()=>{

		if(layersOnChainAdress === null) {
			setLayersCanBeAdded(true);
		}

		//if(collectionSPECS !== null, layersOnChainAdress !== null, revealData !== false) {
		if(collectionSPECS !== null, layersOnChainAdress !== null, revealed !== null, revealData !== null, allJSON !== null) {
			generateLayerOnChainUpdates();
		}else{

			if(projectChain === null) {
				console.log("else useEffect projectChain:		", projectChain);
			}
			if(communityRegistry === null) {
				console.log("else useEffect communityRegistry:	", communityRegistry);
			}
			if(allJSON === null) {
				console.log("else useEffect allJSON:			", allJSON);
			}
			if(layersOnChainAdress === null) {
				console.log("else useEffect layersOnChainAdress ", layersOnChainAdress);
			}
			if(revealed === null) {
				console.log("else useEffect revealed:			", revealed);
			}
			if(revealData === null) {
				console.log("else useEffect revealData:			", revealData);
			}
		}

	},[chainId,projectChain,communityRegistry,collectionSPECS,allJSON,layersOnChainAdress,revealed,revealData])

	useEffect(()=>{

		if(communityRegistry/*  !== null */) {

			loadLayerOnChainRequirements();
		}
		console.log('*******communityRegistry',communityRegistry);

	},[chainId,projectChain,communityRegistry])

	let timer;
	
	const checkForRevealProcess = (_reveal) => {
		console.log("checkForRevealProcess: lastReveal:", lastReveal,_reveal);
		clearTimeout(timer);
		if(_reveal.processed === false) {
			timer = setTimeout(checkForRevealProcess(lastReveal), 15000);
		} else {

			// TODO: call metadata server lastReveal and make sure the last one is processed before continuing..
			// also refresh all.json so we have the latest shifted data
			// or implement shifting in this library

			setLastReveal(true);
		}
	}

	const getLayerOnChainDataUpdates = async ()=>{

		console.log('getLayerOnChainDataUpdates CALLED');
		setUpdatesAvailable(false);
		// make sure we have at least 1 reveal,
		// that's the most we can add layers for

		let ethersProvider = await getProvider(projectChain);

		let liveToken=null;
		if(deployStatus.contracts.token){
			console.log('ADDDREssssss',deployStatus.contracts.token,ethersProvider)
			//liveToken = new ethers.Contract(deployStatus.contracts.token, tokenContractAbi.abi, ethersProvider);
			liveToken = await getContractByAddress('TokenContract',deployStatus.contracts.token, ethersProvider).catch(e=>console.log);
			console.log('LIVETOKEN',liveToken);
			if(liveToken){
				console.log('LIVETOKEN IS OK')
				setTokenContract(liveToken);
			}
		}

		//const mainnetProvider =  new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/714442da76574ddeb9549bc967e3c4e0");
		//const liveToken = new ethers.Contract("0x0847d1fb2adc3d1c19b233afb7d01a631728a415", tokenContractAbi, mainnetProvider);
		
		if(!liveToken){
			console.error('Contract not found',deployStatus.contracts.token,liveToken);
			return;
		}

		let ms = await liveToken.mintedSupply().catch(e=>console.log);
		let revealCount = await liveToken.currentRevealCount().catch(e=>console.log);
		let reveal = await liveToken.reveals(revealCount).catch(e=>console.log);
		let vrfShifting = await liveToken.VRFShifting().catch(e=>console.log);
		let tellEverythingData = await liveToken.tellEverything().catch(e=>console.log);


		console.log('Minted Supply',ms);
		console.log('Reveal count',revealCount);
		console.log('Last reveal data',reveal);
		console.log('VRF status',vrfShifting);
		console.log('communityAddresses', communityAddresses);
		console.log('tellEverythingData', tellEverythingData);

		setVrfEnabled(vrfShifting);

		if(ms && reveal){
			setMintedSupply(Number(ms));
			setRevealsSoFar(revealCount);
			setRevealed(Number(reveal.RANGE_END));
			setRevealData(tellEverythingData.reveals);

			if(revealCount>0 && !reveal.processed && vrfShifting ) {
				checkForRevealProcess(reveal);
			}
		}
		
	//	console.log('getLayerOnChainDataUpdates FINISHED');
	}

	const handleError = (e) => {
		console.error('handle error',e);
		if (e.error && e.error.message) {
		  toast.error(e.error.message);
		} else if (e.message) {
		  toast.error(e.message);
		} else if (e.reason) {
		  toast.error(e.reason);
		}
	};

	const getIcon = (type) => {
		if(type === true) {
			return (
				<>
					<span className='col-1'>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
							<g id="check_circle_black_24dp" transform="translate(-1 -1)">
								<path id="Path_63" data-name="Path 63" d="M0,0H24V24H0Z" transform="translate(1 1)" fill="none"/>
								<path id="Path_64" data-name="Path 64" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM16.59,7.58,10,14.17,7.41,11.59,6,13l4,4,8-8Z" transform="translate(1 1)" fill="#0b8d00"/>
							</g>
						</svg>
					</span>
					<span className='col-1'>{type.toString()}</span>
				</>
			)
		} else {
			return (
				<>
					<span className='col-1'>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
							<path id="error_outline_black_24dp" d="M11,15h2v2H11Zm0-8h2v6H11Zm.99-5A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" transform="translate(-2 -2)" fill="#ff6200"/>
						</svg>
					</span>
					<span className='col-1'>{type.toString()}</span>
				</>
			)
		}
	}
	
	const deployVTR = async()=>{
		// TODO: link this to current selected token ID
		const tokenNumber = 1;

		console.log('deployVTR', communityAddresses.VISUAL_TRAIT_REGISTRY_FACTORY);
		let provider = getProvider(chainId);
		let VisualTraitRegistryFactory = await getContractByAddress('VisualTraitRegistryFactory', communityAddresses.VISUAL_TRAIT_REGISTRY_FACTORY, provider);
		VisualTraitRegistryFactory = VisualTraitRegistryFactory.connect(provider.getSigner());

		console.log("VisualTraitRegistryFactory.ecVersion", VisualTraitRegistryFactory.ecVersion);

		// If a new ABI is added to a contract, it is loaded automatically by VisualTraitRegistryFactory
		// if present in the ABI array.
		// based on contract.ecVersion we can decide to use different parameters for our calls.


		const VisualTraitInitData = {
			communityId: communityId,
			start: collectionSPECS.tokenStart,
			end: collectionSPECS.tokenEnd,
			enabled: true,
			ipfsHash: ipfsHash,
			name: "Visual Traits",
			tokenNum: tokenNumber,
		}

		console.log("collectionSPECS", collectionSPECS);
		console.log("VisualTraitInitData", VisualTraitInitData);
		setApproveInProgress(true);
		let tx = await VisualTraitRegistryFactory.addTrait(VisualTraitInitData).catch(handleError);
		setApproveInProgress(false);
		if(tx){
			setTxHash(tx.hash);
			setTxInProgress(true);
			let receipt = await tx.wait().catch(e=>{
			  setTxInProgress(false);
			  handleError(e);
			  return;
			});
			setLayersOnChainContractsCanBeDeployed(false);
			await loadLayerOnChainRequirements();
			await getLayerOnChainDataUpdates();
			await generateLayerOnChainUpdates();

		}else{
			console.log('ERROR')
		}

		
		setTxInProgress(false);

	}

	const addLayers = async()=>{
		console.log("addLayers");
	}

	const sendTransaction = async(id)=>{
		const txn = layersTxns[id];
		console.log("sendTransaction", id, txn);

		const _provider = getProvider(chainId);
		const contract = txn.contract.connect(_provider.getSigner());
		
		setApproveInProgress(true);
		let tx = await contract[txn.method]( ... txn.data).catch(handleError);
		setApproveInProgress(false);

		if(tx){
			setTxHash(tx.hash);
			setTxInProgress(true);
			let receipt = await tx.wait().catch(e=>{
			  setTxInProgress(false);
			  handleError(e);
			  return;
			});
		}
		setTxInProgress(false);

		await getLayerOnChainDataUpdates();
		await generateLayerOnChainUpdates();

	}
	

	
	return (
		<>
			<div className="row" style={{ marginBottom: '80px' }}>
				<div className="col-lg-8 mx-auto">
						<h4 className="page-title mt-1">
							<img src={titleIcon} style={{ marginRight: '3px' }} /> 11. Layers on Chain
						</h4>
				</div>
					<div className="rounded-box boxShadow p-4 mt-3 col-lg-8 mx-auto">
						<div className={`publish-box-content`}>
							This functionality enables forge and stickerbook functionality by saving layer data pertaining to each token on chain.
							{vrfEnabled&&<>
							<br />
							You can only push this data to chain <span className="bolder">AFTER</span> a reveal happens.
							</>}
						</div>
					</div>

					<div className="rounded-box boxShadow p-4 mt-3 col-lg-8 mx-auto">
						<div className={`publish-box-content`}>
							<div className='bold'>Requirements: </div>
							<div className="status-box">
								<div className="container">
									<div className='row'>
										<span className='col-10'>
											Token SPEC Metadata published to IPFS 
											{ipfsSPECUrl&&
											 <a className="ml-2" href={ipfsSPECUrl} target="_blank">VIEW</a>
											}

										</span>


										{getIcon(tokenSpecsOnIPFSDeployed)}
									</div>
									{/* <div className='row'>
										<a href={ipfsSPECUrl}>{ipfsSPECUrl}</a>
									</div> */}
								</div>
							</div>
							<div className="status-box">
								<div className="container">
									<div className='row'>
										<span className='col-10'>
											UTC - Trait Registry Contract deployed
											{(traitRegistryAddress && traitRegistryAddress!==ethers.constants.AddressZero)  &&
												<a  className="ml-2" target="_blank" href={supportedChains[selectedChainIdx].blockExplorerUrl+'/address/'+traitRegistryAddress}>Block Explorer</a>
											}

										</span>
										{getIcon(traitsOnChainContractsDeployed)}
									</div>
									{/* <div className='row'>
										<a href={traitRegistryAddress}>{traitRegistryAddress}</a>
									</div> */}
								</div>
							</div>
							<div className="status-box">
								<div className="container">
									<div className='row'>
										<span className='col-10'>Visual Layer Contract deployed
										{layersOnChainAdress && 
												<a className="ml-2" target="_blank" href={supportedChains[selectedChainIdx].blockExplorerUrl+'/address/'+layersOnChainAdress}>Block Explorer</a>
										}
										</span>
										{getIcon(layersOnChainContractsDeployed)}
									</div>
									{layersOnChainContractsCanBeDeployed &&
										<div className='row my-2 justify-content-center'>
											<button className="borderedNavLink lightNavLink" onClick={deployVTR}>Click here to Deploy contract</button>
										</div>
									}
								</div>
							</div>

							<div className="status-box">
								<div className="container">
									<div className='row'>
										<span className='col-10'>Visual Layers up to date?</span>
										{getIcon(!layersCanBeAdded && traitRegistryAddress!==ethers.constants.AddressZero)}
									</div>
									{/* {layersCanBeAdded &&
										<div className='row'>
											<button className="borderedNavLink lightNavLink" onClick={addLayers}>Click here to Add Layers to contract</button>
										</div>
									} */}
								</div>
							</div>
						</div>
					</div>

					{/* {txInProgress&&
						<div className="rounded-box boxShadow p-4 mt-3 col-lg-8 mx-auto">
							<div className={`publish-box-content`}>
								<div className="status-box">
									<div className='text-center'>
										<SpinnerCircular size="32" color="#ff692b"/>
									</div>
									{txHash!=null&&<p className="text-center mt-2 mb-0">Transaction in progress. View on <a href={supportedChains[selectedChainIdx].blockExplorerUrl+'/tx/'+txHash} target="_blank">Block Explorer</a>.</p>}
								</div>
							</div>
						</div>
					} */}

					{layersTxns.length ?
						
						<div className="rounded-box boxShadow p-4 mt-3 col-lg-8 mx-auto">
							<div className={`publish-box-content`}>
								<div className='bold'>Pending transactions:</div>
								<div className="status-box">

									<div className="container">
										{layersTxns.map((key, i) => {
											return (
												<div key={i} className='row my-2'>

													<div className='col-8 tx-container'>
														<div>
															{layersTxns[i].label.title}
														</div>
														{layersTxns[i].method==="setTraitsByRandomWords"&&
															<>
																<div className="my-1 details">
																	Last token id: <span>{layersTxns[i].label.lastId}</span>
																</div>
																<div className="details">
																	Wordcount: <span>{layersTxns[i].label.wordCount}</span>
																</div>
															</>
														}
														
													
													</div>


													<div className='col-4'>
														<button className="borderedNavLink lightNavLink" onClick={() => sendTransaction(i)}>Send TXN</button>
													</div>
												</div>
											);
										})}
									
									</div>
								</div>
							</div>
						</div>
					:
						<div className="rounded-box boxShadow p-4 mt-3 col-lg-8 mx-auto">
							<div className={`publish-box-content`}>
								<div className='bold'>No pending transactions.</div>
							</div>
						</div>
					}

					{/* <div className="rounded-box boxShadow p-4 mt-3 col-lg-8 mx-auto">
						<div className={`publish-box-content`}>
							<div className='bold'>Requirements: </div>
							<div className="status-box">
								<span className='col-10'>Data updates available?</span>
								{getIcon(updatesAvailable)}
							</div>
						</div>
					</div> */}

					{/* <div className="rounded-box boxShadow p-4 mt-3 col-lg-8 mx-auto">
						<div className={`publish-box-content`}>
							<FormInput
								id={'token_specs'}
								label='Token Specs'
								type='textarea'
								value={JSON.stringify(collectionSPECS)}
							/>
						</div>
					</div> */}

					{vrfEnabled&&<div className="rounded-box boxShadow p-4 mt-3 col-lg-8 mx-auto">
						<div className={`publish-box-content`}>
							<p className="mb-2">Number of reveals: <span className="bolder">{revealsSoFar}</span> </p>
							<p className="mb-2">Minted supply: <span className="bolder">{mintedSupply}</span></p>
							<p className="mb-2">Revealed: <span className="bolder">{revealed}</span></p>
							<p className="mb-2">Unrevealed: <span className="bolder">{mintedSupply-revealed}</span></p>
							<p className="mb-2">Last Reveal Processed: <span className="bolder">{lastReveal?"Yes":"No"}</span></p>
						</div>
					</div>}

					<BackdropModal visible={approveInProgress}/>
					<TxInProgressModal visible={txInProgress} txHash={supportedChains[selectedChainIdx].blockExplorerUrl+'/tx/'+txHash}/>

					{/* <SSPFooter
						prev=""
						next=""
						isSubmitEnabled={mintedSupply-revealed>0 && !txInProgress}
						submit={{
						label: 'Reveal at current supply',
						handleSubmit: revealAtCurrentSupply,
						}}
					/> */}
				
			</div>

		</>
	)
}

export default LayersOnChain;