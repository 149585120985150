import { useEffect, useState } from 'react';
import RoundedBox from '../visual-utils/RoundedBox';
import './AlertModal.css';
import FormInput from './FormInput';
import byIcon from '../../assets/images/icons/x.svg';
import closeIcon from '../../assets/images/close.svg';
import { X } from 'react-bootstrap-icons';


const ERROR_COLOR = '#F50808';
const NORMAL_COLOR = '#ff692b';

const styleConfig = {
  position:'relative',
  width: '100%',
  maxWidth: '508px',
};

const ReGenerateModal = (props) => {
  const { visible, hideModal,  saveFunc } = props;

  const [sideValue,setSideValue] = useState(0);


  const closeModal = () =>{

  }



  return (
    <>
      {visible && (
        <div
          className="modal warning-modal"
          style={{ display: 'block' }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <RoundedBox styleConfig={styleConfig}>
              <div className="modal-content">
                <button className='btn-with-icon' style={{position:"absolute",right:"8px", top:"10px"}} onClick={props.closeModal}></button>
                <div className="modal-header justify-content-center">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Please select what to regenerate
                  </h5>
                </div>
                <div className="modal-body row">

                  <div className="col-lg-10 mx-auto my-2">
                    <div className="d-flex flex-column custom-radio">
                    
                        <div className="form-check w-50 mx-auto mb-2">
                          <input className="form-check-input mr-2" type="radio" name="generateRadios" id="front_side" checked={sideValue===1} onChange={()=>setSideValue(1)}/>
                          <label className="form-check-label ml-2" htmlFor="front_side">
                            Side 1
                          </label>
                        </div>

                        <div className="form-check w-50 mx-auto mb-2">
                          <input className="form-check-input mr-2" type="radio" name="generateRadios" id="back_side" checked={sideValue===2} onChange={()=>setSideValue(2)}/>
                          <label className="form-check-label ml-2" htmlFor="back_side">
                            Side 2
                          </label>
                        </div>

                        <div className="form-check w-50 mx-auto">
                          <input className="form-check-input mr-2" type="radio" name="generateRadios" id="both_sides" checked={sideValue===0} onChange={()=>setSideValue(0)}/>
                          <label className="form-check-label ml-2" htmlFor="both_sides">
                            Both sides
                          </label>
                        </div>
                      

                    </div>
                  </div>
                  
                </div>
                <div className="modal-footer">
                    <button
                      type="button"
                      className={`btn`}
                      style={{ color: NORMAL_COLOR }}
                      data-dismiss="modal"
                      onClick={() => {hideModal();saveFunc(sideValue)}}
                    >
                      Generate
                    </button>
                </div>
              </div>
              <span className="close-button" onClick={()=>{console.log('hide clicked');hideModal()}}><img src={closeIcon}/></span>
            </RoundedBox>
          </div>
        </div>
      )}
    </>
  );
};

export default ReGenerateModal;
