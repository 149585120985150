
import { useCallback, useContext, useEffect, useState } from 'react';
import { PlusCircle, CheckCircleFill } from 'react-bootstrap-icons';
import { useDropzone } from 'react-dropzone';
import { DataContext } from '../DataStore/DataStore';
import  './FileUploader.css';

const FileUploader = ({onFileSelected,label,help,value,accept,disabled,successMessage})=>{

	const [file, setFile] = useState('');
	useEffect(()=>{
		if(value){
			//console.log('value',value);
			setFile(value);
		}
	},[value]);




	const { getRootProps, getInputProps, isDragActive } = useDropzone({

		 accept: accept,
		 disabled,
		 onDrop: async (acceptedFiles) => {
			if(acceptedFiles.length>0){
				//console.log('accepdedFiles.type',acceptedFiles[0].type);
				if(acceptedFiles[0].type.startsWith('text') || acceptedFiles[0].type.indexOf('excel')>-1){
					
					if(onFileSelected){
						onFileSelected(acceptedFiles[0]);
					}
				}
			}

		 }})
	 
	  return (
		<div className={`dropcontainer ${isDragActive&&'dragging'}`} {...getRootProps()}>
		  <input {...getInputProps()} accept={accept}  />
		  		{!value ?
					<div className='px-1 py-3'>
						<PlusCircle className='primary' size={36}/>
						<p className="mt-3 mb-0 font-weight-normal">{label}</p>
						{help&&<p className='help-text'> {help}</p>}
					</div>
					:
					<div className='preview-image px-1 py-3'>
						<CheckCircleFill className="mt-4 primary" size={36}/>
						<p className="mt-3 mb-0">{successMessage}</p>
					</div>
				}
		</div>
	  );


}

export default FileUploader;