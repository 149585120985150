import { useContext, useEffect, useState } from "react";
import { DataContext,formState } from "../../components/DataStore/DataStore";
import FormInput from '../../components/common/FormInput';
import { useFormik } from "formik";
import SSPFooter from "../../components/SSPFooter/SSPFooter";
import titleIcon from '../../assets/images/page-title-icons/alarm-clock.svg'
import AlertModal from "../../components/common/AlertModal";
import { createOrUpdateSale } from "../../components/Api";
import { toast } from "react-toast";
import { dateWithOffsetToIsoString } from "../../components/Utils";
import InfoBox from "../../components/common/InfoBox";
import { ethers } from "ethers";
import useConfirm from "../../components/common/useConfirm";
import { getSaleConfig } from "../../components/Utils/GetSaleConfig";
import Web3Ctx from "../../components/Context/Web3Ctx";
import saleContractAbi from '../../contracts/SaleContract.json';
import TxInProgressModal from "../../components/common/TxInProgressModal";
import BackdropModal from "../../components/common/BackdropModal";
import config from "../../config";
import { formSkeleton } from '../../components/DataStore/DataStore';
import './PreSale.css';

const AbiCoder = new ethers.utils.AbiCoder();

const PreSale = (props)=>{

    const PAGE = 'PRESALE';
    const { updateFormV2, currentProjectId, updatePageState ,allTimezones, communityId,projectChain,deployStatus, initDone, getPreSaleData,getLayerInfoData} = useContext(DataContext);
    const {getProvider,setChain,chainId} = useContext(Web3Ctx);

	const [form] = useState({...formSkeleton[PAGE].form});
	const [formProperties] = useState({...formSkeleton[PAGE].formProperties});
    const [formEnabled,setFormEnabled] = useState(true);

	//Alert stuff
	const [alertType, setAlertType] = useState('ERROR');
	const [alertMessage, setAlertMessage] = useState('');
	const [alertButtonLabel, setAlertButtonLabel]=useState('Close');
	const [alertTitle, setAlertTitle]=useState('Upload error');
	const [alertVisible, setAlertVisible] = useState(false);

	const [invalidDatesError,setInvalidDatesError] = useState('');
	const [invalidStartDate,setInvalidStartDate] = useState('');


	const CURRENT_SALE_TYPE = 1;
	const CUSTOM_SALE_TYPE = 4;

	const [walletTokensError, setWalletTokensError] = useState(null)


	const { isConfirmed } = useConfirm();
	const [saleConfig,setSaleConfig] = useState(null);
	const [approveInProgress,setApproveInProgress]=useState(false);
	const [txInProgress,setTxInProgress]=useState(false);
	const [saveInProgress,setSaveInProgress]=useState(false);

	const [txHash,setTxHash]=useState(null);
	const [whiteListMessage,setWhiteListMessage]=useState('');
	const [nativeTokenName,setNativeTokenName] = useState('ETH');

	const [customCSV, setCustomCSV] = useState([]);

	useEffect(()=>{

		const updateSaleContract = async ()=>{

			if(chainId === projectChain){


				let selectedChainIdx = 0;
				const idx = config.CHAINS.findIndex(c => {
				return parseInt(c.id,16) === projectChain;
				});
				if(idx>-1){
				selectedChainIdx=idx;
				}


				setApproveInProgress(true);
				let provider = await getProvider(projectChain);
				if(!provider)return;
				let sale = new ethers.Contract(deployStatus.contracts.sale, saleContractAbi.abi, provider);
				if(!sale)return;
				let sc = sale.connect(provider.getSigner());

			//	console.log(sc)
				
				let tx=await sc.UpdateSaleConfiguration(saleConfig).catch(handleError);
				setApproveInProgress(false);
			//	console.log('TX', tx);
				if(tx){
					setTxHash(`${config.CHAINS[selectedChainIdx].blockExplorerUrl}/tx/${tx.hash}`);
					setTxInProgress(true);
					let receipt = await tx.wait().catch(e=>{
						setTxInProgress(false);
						handleError(e);
						return;
					});
			//		console.log('receipt',receipt);
					setTxInProgress(false);
					setSaleConfig(null);
				}
				setSaveInProgress(false);
			}else{
				setSaveInProgress(false);
			}
		}

		if(saleConfig && chainId){
			updateSaleContract();
		}


	},[saleConfig,chainId]);

	const handleError = (e) => {
		//console.error('handle error',e);
		if (e.error && e.error.message) {
		  toast.error(e.error.message);
		} else if (e.message) {
		  toast.error(e.message);
		} else if (e.reason) {
		  toast.error(e.reason);
		}
	  };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: form,
        validationSchema:formSkeleton[PAGE].formScheme,
        validateOnBlur: true,
        validateOnChange: true,
        onSubmit: async formikvalues => {

			console.log('DS',deployStatus);
			setSaveInProgress(true);

			let txNeeded = (deployStatus.contracts && deployStatus.contracts.sale!==null);


			if(txNeeded){
				const confirmed = await isConfirmed('Sale contract update','To change your sale parameters on the blockchain please sign the transaction.','OK','Back');
				if(!confirmed){
					setSaveInProgress(false);
					return;
				}
			}

			let values={...formikvalues};
			if(!invalidDatesError){
			//	console.log('VALS',values);
			//	console.log('formik.values',formik.values);
				
				values.type_id = formik.values.active==='yes'?CURRENT_SALE_TYPE:formik.values.active==='custom'?CUSTOM_SALE_TYPE:null;

				(values.active === 'yes' ||  values.active === 'custom')? values.active = true : values.active = false;
				values.eth_sale_enabled === 'yes'?values.eth_sale_enabled = true : values.eth_sale_enabled = false;
				values.token_sale_enabled === 'yes'?values.token_sale_enabled = true : values.token_sale_enabled = false;

				let data = values.active?{...values}:{active:false,type_id:CURRENT_SALE_TYPE};

				let customStartDate = 0;
				let customEndDate = 0;

				if(values.active){

					const wallet = ethers.Wallet.createRandom();
			//		console.log('address:', wallet.address);
			//		console.log('mnemonic:', wallet.mnemonic.phrase);
			//		console.log('privateKey:', wallet.privateKey);
					data.signer_address = wallet.address;

					if(data.type_id===1){

						data.timezone=allTimezones[values.timezone-1].label;
	
						data.start_date = dateWithOffsetToIsoString(data.start_date,allTimezones[values.timezone-1].offset);
						data.end_date = dateWithOffsetToIsoString(data.end_date,allTimezones[values.timezone-1].offset);
	
						if(!data.eth_sale_enabled){
							delete data.eth_price;
						}
	
						if(!data.token_sale_enabled){
							delete data.token_price;
							delete data.token_address;
						}
					}else{

						//let layerInfo =   getLayerInfoData();
						//console.log('LAYER DATA',layerInfo);
						data = {active:true, type_id: data.type_id, signer_address:wallet.address, max_tokens:data.max_tokens,token_address:data.token_address, whitelist:data.whitelist}
						
						if(data.token_address==="" || data.token_address===null){
							delete data.token_address
						}
					
					}

					let whitelistJson = {};

				//	console.log('DaTa',data.whitelist,data.whitelist.length);

					

					if(data.whitelist.length>0 && formik.values.active==='custom'){
						customStartDate = data.whitelist[0][4];
						customEndDate = data.whitelist[0][5];
					}

					

					for(let i=0;i<data.whitelist.length;i++){

						//console.log('which?',formik.values.active,i);

						let paramObj = {};

						if(formik.values.active!=='custom'){
							paramObj = {
								projectID:  communityId,
								chainID:  	projectChain,
								free:       data.free_mint, 
								max_mint:   parseInt(data.max_tokens_per_wallet),
								receiver:   data.whitelist[i],
								valid_from: Math.floor(new Date(data.start_date).getTime()/1000),
								valid_to:   Math.floor(new Date(data.end_date).getTime()/1000),
								eth_price:  data.eth_price?ethers.utils.parseEther(`${data.eth_price}`):0,
								dust_price: data.token_price?ethers.utils.parseEther(`${data.token_price}`):0,
							};	
						}else{
							paramObj = {
								projectID:  communityId,
								chainID:  	projectChain,
								free:       (Number(data.whitelist[i][2])===0 && Number(data.whitelist[i][3])===0), 
								max_mint:   parseInt(data.whitelist[i][1]),
								receiver:   data.whitelist[i][0],
								valid_from: data.whitelist[i][4],
								valid_to:   data.whitelist[i][5],
								eth_price:  data.whitelist[i][2],
								dust_price: data.whitelist[i][3]
							};

							//console.log('PARAM OBJ TO SAVE', paramObj,data.whitelist[i][2],Number(data.whitelist[i][2]),Number(data.whitelist[i][3]));

							if(customStartDate>data.whitelist[i][4]){
								customStartDate=data.whitelist[i][4]
							}

							if(customEndDate<data.whitelist[i][5]){
								customEndDate=data.whitelist[i][5]
							}
						}

						const params = [];
						const keys = Object.keys(paramObj);
						for(let i = 0; i < keys.length; i++) {
							params[i] = paramObj[keys[i]];
						}

						const encodedPayload = AbiCoder.encode(
							[
								"uint256",  // projectID
								"uint256",  // chainID
								"bool",     // free
								"uint16",   // max_mint
								"address",  // receiver
								"uint256",  // valid_from
								"uint256",  // valid_to
								"uint256",  // eth_price
								"uint256",  // dust_price
							],
							params
						);

				
						
						const hash = ethers.utils.keccak256(encodedPayload);
						const signature = await wallet.signMessage(ethers.utils.arrayify(hash));
						
						if(formik.values.active!=='custom'){
							whitelistJson[data.whitelist[i]]={params,signature}
						}else{
							whitelistJson[data.whitelist[i][0]]={params,signature}
						}
						
					}
					data.whitelist_json = JSON.stringify(whitelistJson);



				}


				if(customStartDate>0 && customEndDate>0){
					data.start_date=new Date(customStartDate*1000);
					data.end_date=new  Date(customEndDate*1000);
				}

				let res = null;

				res = await createOrUpdateSale(parseInt(currentProjectId), data).catch((e) => console.log);
				if (res.success) {
				  updatePageState(PAGE,formState.FORM_SAVED);
				  toast.success("Successfully updated")

				  if(txNeeded){
					  //TODO: update sale config on chain
					  setApproveInProgress(true);

					  let sconf = await getSaleConfig(currentProjectId,communityId,deployStatus.contracts.token).catch(e=>console.log);
					  

					  if(sconf){
						if(chainId!==projectChain){
							await setChain(projectChain);
						}
						setSaleConfig(sconf);
					  }
					  
					
				  }else{
					setSaveInProgress(false);
				  }

				} else {
					toast.error(res.message.message?res.message.message:res.message);
				//  console.log(res.message);
					setSaveInProgress(false);
				}
			}
		}
    });

	useEffect(()=>{
		setFormEnabled(formik.values['active']==='yes');
	},[formik.values['active']]);

	useEffect(()=>{
		if(formik.values.start_date){
			const sd = new Date(formik.values.start_date).getTime();
			if(sd<new Date().getTime() && deployStatus?.contracts?.sale===null){
				setInvalidStartDate('That date is in the past.');
			}else{
				setInvalidStartDate('');
			}
		}

		if(formik.values.start_date && formik.values.end_date){
			const sd = new Date(formik.values.start_date).getTime();
			const ed = new Date(formik.values.end_date).getTime();
			if(ed-sd<0){
				setInvalidDatesError('The event will end before the start date.')
			}else{
				setInvalidDatesError('');
			}
		}

		
	},[formik.values.start_date,formik.values.end_date,deployStatus]);


	useEffect(()=>{
		if(formik.values.max_tokens && formik.values.max_tokens_per_wallet){

			if(Number(formik.values.max_tokens)<Number(formik.values.max_tokens_per_wallet)){
				setWalletTokensError('Can\'t be higher than Nr. of tokens');
			}else{
				setWalletTokensError(null);
			}

		}else{setWalletTokensError(null)}


		
	},[formik.values.max_tokens_per_wallet,formik.values.max_tokens]);

    const handleFormikChange=(e,key)=>{
//console.log('formik vals', formik.values, key,e);

		if(key==='active'){
			if((formik.values.active==='yes' && e.target.value==='custom') || (formik.values.active==='custom' && e.target.value==='yes')){
		//console.log('erase CSV');
				formik.values.whitelist=null;
				formik.values.token_sale_enabled='no';
			}
		}

        formik.handleChange(e);
		/* console.log(e.target.type,e.target.checked); */
		updateFormV2(PAGE, key, e.target.type==='checkbox'?e.target.checked:e.target.value);
    }

	const uploadCSVfile = async (e) =>{

		let fileReader = new FileReader();

		console.log('FR',fileReader);

		fileReader.onerror = (e)=>console.log('read error',e);
		fileReader.onloadend = (e)=>{
			let addresses = fileReader.result.split(/\r\n|\n/);
			//console.log('addresses',addresses);
			if(addresses.length>0){

				if(formik.values.active === 'yes'){
					//simple
					//console.log('addresses',addresses);
					//console.log('checking the addresses');
	
					let invalids = [];
					let valids = []
					for(let i=0;i<addresses.length;i++){
						if(addresses[i].length!==42 && addresses[i].length>0 ){
							invalids.push(addresses[i]);
						}else{
							if(addresses[i].length===42){
								valids.push(addresses[i]);
							}
						}
					}
	
					//console.log(addresses,valids);
	
					if(invalids.length===0 && valids.length>0){
						toast.success(`${valids.length} address${valids.length>1?'es':''} found.`);
						formik.setFieldValue('whitelist', valids);
						updateFormV2(PAGE,'whitelist',valids);
						setWhiteListMessage(`${valids.length} address${valids.length>1?'es':''} whitelisted.`);
					}else{
						formik.setFieldValue('whitelist', null);
						updateFormV2(PAGE,'whitelist',null);
						if(invalids.length===0){
							toast.error('No addresses found');
						}else{
							toast.error(`${invalids.length} Invalid address${invalids.length>1?'es':''} found.`);
						}
					}
				}else{
					//custom
					//console.log(addresses,addresses.length);

					let valids = [];
					let errors = [];

					let addressNeeded = false;

					for(let i=0;i<addresses.length;i++){

						console.log('LINE',addresses[i]);
						if(addresses[i].toLowerCase().indexOf('0x')>-1){
							let item = addresses[i].split(',');	
							console.log('ITEM',i,item);
	
							if(item.length===6){

								try {

									item[0]=item[0].trim();
									let from = new Date(item[4]);
									let to = new Date(item[5]);
									let fromUTC = Date.UTC(from.getFullYear(),from.getMonth(),from.getDate(),from.getHours(),from.getMinutes());
									let toUTC = Date.UTC(to.getFullYear(),to.getMonth(),to.getDate(),to.getHours(),to.getMinutes());
									
									if(item[2]){
										//console.log('price:',item[2]);
										item[2]= ethers.utils.parseEther(item[2].replace(',','.'));
									}else{
										throw('Missing price amount in csv, line '+(i+1));
									}
									if(item[3]){
										item[3]= ethers.utils.parseEther(item[3].replace(',','.'));
									}else{
										throw('Missing token price amount in csv, line '+(i+1));
									}
									
									item[4]= Math.floor(fromUTC/1000);
									item[5]= Math.floor(toUTC/1000);
									

									console.log(i,from.getTime(),to.getTime());
									
									if(!from.getTime() || !to.getTime()){
										throw('Invalid date in csv, line '+(i+1));
									}

									if(to.getTime()<=from.getTime()){
										throw('Invalid date range in csv, line '+(i+1));
									}


									//console.log('ADRESS????',ethers.utils.isAddress(item[0]));
									if(!ethers.utils.isAddress(item[0])){
										//console.log('ADDRSSSS')
										throw('invalid address in csv, line '+(i+1));
									}

									if(!item[1] || item[1]===0){
										throw('Invalid amount in csv, line '+(i+1));
									}

									if(item[3].gt(0)){
										addressNeeded = true;
									}

									valids.push(item);
								}catch(e){
									errors.push(e);
									console.log('catch',e);
								}
							}else{
								errors.push("Invalid entry in csv, line "+(i+1));
							}
						}else{
							console.log('header');
						}


						console.log('VALIDS',valids);
					}

					setCustomCSV(valids);
					formik.setFieldValue('whitelist', valids);
					updateFormV2(PAGE,'whitelist',valids);
					
					if(valids.length>0){
						if(addressNeeded){
							console.log('+++++++Token Aaddress form needed');

							console.log(formik.values.token_sale_enabled);
							formik.setFieldValue('token_sale_enabled', 'yes');
						}else{
							console.log('-------Token Aaddress form NOT needed');
							formik.setFieldValue('token_sale_enabled', 'no');
							formik.setFieldValue('token_address','');
							updateFormV2(PAGE,'token_address','');
						}

						toast.success(`${valids.length} address${valids.length>1?'es':''} found.`);
						setWhiteListMessage(`${valids.length} address${valids.length>1?'es':''} whitelisted.`);
					}else{
						formik.setFieldValue('whitelist', null);
						updateFormV2(PAGE,'whitelist',null);
						setWhiteListMessage('');
						//toast.error('No valid entries found.');
					}

					if(errors.length>0){
						console.log(errors);
						let msg = errors.join(', ');
						toast.error(msg);
					}



				}



			//	console.log('res',fileReader.result)
			}else{
				toast.error("No addresses found");
			}
		};
		fileReader.readAsText(e);
/* 
	
*/

	}	
	/* get sales */

	useEffect(() => {

		if(projectChain){
			if(projectChain===1 || projectChain===11155111){
				setNativeTokenName(projectChain===1?'ETH':'sETH');
			}else{
				setNativeTokenName(projectChain===137?'MATIC':'mMATIC');
			}
		}


		if (currentProjectId && initDone && getPreSaleData) {
			let saleObj = getPreSaleData();
			//console.log('===========SALE OBJ',saleObj);
			Object.keys(form).forEach(key=>{
				//console.log('key',key,saleObj[key]);
				if(key==='active'){
					if(saleObj.type_id==4){
						formik.values[key]='custom';
						setCustomCSV(saleObj.whitelist);
					}else{
						formik.values[key]=saleObj[key];
					}
				}else{
					formik.values[key]=saleObj[key]===null?'':saleObj[key];
				}
			});

			if(formik.values.token_address){
				formik.values.token_sale_enabled='yes';
			}


			formik.validateForm();
			if(saleObj.whitelist){
				setWhiteListMessage(`The whitelist contains ${saleObj.whitelist.length} valid address${saleObj.whitelist.length>1?'es':''}.`);
			}
		}
		//console.log('presale DATA',currentProjectId, initDone, getPreSaleData,projectChain)
	}, [currentProjectId, initDone]);


	useEffect(()=>{
		//console.log('free mint',formik.values.free_mint)
		//
		if(formik.values.free_mint){
			clearPrices();
		}
	
	},[formik.values.free_mint])


	const getTokenName = (label) => {
		return label.replace('*nativeToken*',nativeTokenName);
	}


	const clearPrices = ()=>{
			//console.log('ERASING PRICE FIELDS');

			updateFormV2(PAGE,null,{
				eth_price:0,
				eth_sale_enabled:'no',
				token_price:0,
				token_sale_enabled:'no',
				token_address:''
			});

			formik.values['eth_price']='';
			formik.values['eth_sale_enabled']='no';
			formik.values['token_price']='';
			formik.values['token_sale_enabled']='no'
			formik.values['token_address']='';

			formik.validateForm();
	}

	const getDateString = (timestamp)=>{
		let d = new Date(timestamp*1000);
		var month = ("0" + (d.getUTCMonth() + 1)).slice(-2);
		var day  = ("0" + (d.getUTCDate())).slice(-2);
		var year = d.getFullYear();
		var hour =  ("0" + (d.getUTCHours())).slice(-2);
		var min =  ("0" + (d.getUTCMinutes())).slice(-2);
		return `${year}.${month}.${day} ${hour}:${min}`;
	}

	return (
		<>
			<div className='row'>
				<div className='col-lg-10 mx-auto'>
					<div style={{ display: 'flex'}}>
						<h4 className="page-title mt-1"><img src={titleIcon} style={{marginRight:"3px"}}/> 4. Private sale</h4>
						<InfoBox position="bottom" title="Private sale"> 
						<p>Enter the details of your private sale, if any, then click “Submit Private sale”. To continue, click “Next Step”.</p>
						</InfoBox>
					</div>
					<div className='rounded-box boxShadow p-4 mt-3'>
						<form onSubmit={formik.handleSubmit}>
							<FormInput 
								id={'active'}
								label={formProperties['active'].label}
								type={formProperties['active'].fieldType} 
								options={formProperties['active'].options}
								onChange={(e)=>{handleFormikChange(e,'active');setFormEnabled(e.target.value==='yes')}}
								onBlur={formik.handleBlur}
								value={formik.values['active']}
								error={(formik.touched['active'] && formik.errors['active'])?formik.errors['active']:null}
							/>

							{formik.values['active']!=='custom'&&<>
								<FormInput 
									id={'timezone'}
									label={formProperties['timezone'].label}
									type={formProperties['timezone'].fieldType} 
									options={allTimezones}
									onChange={(e)=>{handleFormikChange(e,'timezone')}}
									onBlur={formik.handleBlur}
									disabled={!formEnabled}
									value={formik.values['timezone']}
									error={(formik.touched['timezone'] && formik.errors['timezone'])?formik.errors['timezone']:null}
								/>


								<div className="row">
									<div className="col-md-6 pr-md-2">
										<FormInput 
											id={'start_date'}
											label={formProperties['start_date'].label}
											type={formProperties['start_date'].fieldType}  
											onChange={e=>{updateFormV2(PAGE,'start_date',e);formik.setFieldValue('start_date', e)}}
											onBlur={formik.handleBlur}
											disabled={!formEnabled}
											value={formik.values['start_date']}
											error={(formik.touched['start_date'] && formik.errors['start_date'])?formik.errors['start_date']:invalidStartDate}
										/>			
									</div>
									<div className="col-md-6 pl-md-2">
										<FormInput 
											id={'end_date'}
											label={formProperties['end_date'].label}
											type={formProperties['end_date'].fieldType}  
											onChange={e=>{updateFormV2(PAGE,'end_date',e);formik.setFieldValue('end_date', e)}}
											onBlur={formik.handleBlur}
											disabled={!formEnabled}
											value={formik.values['end_date']}
											error={(formik.touched['end_date'] && formik.errors['end_date'])?formik.errors['end_date']:invalidDatesError}
										/>
									</div>
								</div>
		
								<FormInput 
									id={'max_tokens'}
									label={formProperties['max_tokens'].label}
									type={formProperties['max_tokens'].fieldType}  
									onChange={(e)=>{e.target.value=e.target.value.replace(/[^0-9]/g, '');handleFormikChange(e,'max_tokens')}}
									onBlur={formik.handleBlur}
									disabled={!formEnabled}
									value={formik.values['max_tokens']}
									error={(formik.touched['max_tokens'] && formik.errors['max_tokens'])?formik.errors['max_tokens']:null}
								/>			
								<FormInput
									id={'max_tokens_per_wallet'}
									label={formProperties['max_tokens_per_wallet'].label}
									type={formProperties['max_tokens_per_wallet'].fieldType}  
									onChange={(e)=>{e.target.value=e.target.value.replace(/[^0-9]/g, '');handleFormikChange(e,'max_tokens_per_wallet')}}
									onBlur={formik.handleBlur}
									disabled={!formEnabled}
									value={formik.values['max_tokens_per_wallet']}
									error={(formik.touched['max_tokens_per_wallet'] && formik.errors['max_tokens_per_wallet'])?formik.errors['max_tokens_per_wallet']:walletTokensError}
								/>
								{/* <div className="row">
									<div className="col-md-6 pr-md-2">
									</div>
									<div className="col-md-6 pl-md-2">
									</div>
								</div> */}

								<div style={{marginTop:'-1rem'}}>
									<FormInput
									id={'free_mint'}
									label={'Free mint'}
									help={''}
									type={'checkbox'}
									onChange={(e) => {handleFormikChange(e, 'free_mint');}}
									onBlur={formik.handleBlur}
									disabled={!formEnabled}
									value={formik.values['free_mint']}
									error={
										formik.touched['free_mint'] &&
										formik.errors['free_mint']
										? formik.errors['free_mint']
										: null
									}
									/>
								</div>

								<FormInput 
									id={'eth_sale_enabled'}
									label={getTokenName(formProperties['eth_sale_enabled'].label)}
									type={formProperties['eth_sale_enabled'].fieldType} 
									options={formProperties['eth_sale_enabled'].options}
									disabled={!formEnabled || formik.values['free_mint']}
									onChange={(e)=>{handleFormikChange(e,'eth_sale_enabled')}}
									onBlur={formik.handleBlur}
									value={formik.values['eth_sale_enabled']}
									error={(formik.touched['eth_sale_enabled'] && formik.errors['eth_sale_enabled'])?formik.errors['eth_sale_enabled']:null}
								/>


								<FormInput 
									id={'eth_price'}
									label={getTokenName(formProperties['eth_price'].label)}
									type={formProperties['eth_price'].fieldType}  
									onChange={(e)=>{e.target.value=e.target.value.replace(/[^0-9.]/g, '');handleFormikChange(e,'eth_price')}}
									onBlur={formik.handleBlur}
									disabled={!formEnabled || formik.values['eth_sale_enabled']==='no' || formik.values['free_mint']}
									value={formik.values['eth_price']}
									error={(formik.touched['eth_price'] && formik.errors['eth_price'])?formik.errors['eth_price']:null}
								/>			

								<FormInput 
									id={'token_sale_enabled'}
									label={formProperties['token_sale_enabled'].label}
									type={formProperties['token_sale_enabled'].fieldType} 
									options={formProperties['token_sale_enabled'].options}
									disabled={!formEnabled || formik.values['free_mint']}
									onChange={(e)=>{handleFormikChange(e,'token_sale_enabled')}}
									onBlur={formik.handleBlur}
									value={formik.values['token_sale_enabled']}
									error={(formik.touched['token_sale_enabled'] && formik.errors['token_sale_enabled'])?formik.errors['token_sale_enabled']:null}
								/>


								<FormInput 
									id={'token_price'}
									label={formProperties['token_price'].label}
									isOptional={false}
									type={formProperties['token_price'].fieldType}  
									onChange={(e)=>{e.target.value=e.target.value.replace(/[^0-9.]/g, '');handleFormikChange(e,'token_price')}}
									onBlur={formik.handleBlur}
									disabled={!formEnabled || formik.values['token_sale_enabled']==='no' || formik.values['free_mint']}
									value={formik.values['token_price']}
									error={(formik.touched['token_price'] && formik.errors['token_price'])?formik.errors['token_price']:null}
								/> 

								<FormInput 
									id={'token_address'}
									label={formProperties['token_address'].label}
									isOptional={false}
									type={formProperties['token_address'].fieldType}  
									onChange={(e)=>handleFormikChange(e,'token_address')}
									onBlur={formik.handleBlur}
									disabled={!formEnabled || formik.values['token_sale_enabled']==='no' || formik.values['free_mint']}
									value={formik.values['token_address']}
									error={(formik.touched['token_address'] && formik.errors['token_address'])?formik.errors['token_address']:null}
								/> 
							</>}


							{formik.values['active']==='custom'&&
							<>
								<FormInput 
									id={'max_tokens'}
									label={formProperties['max_tokens'].label}
									type={formProperties['max_tokens'].fieldType}  
									onChange={(e)=>{e.target.value=e.target.value.replace(/[^0-9]/g, '');handleFormikChange(e,'max_tokens')}}
									onBlur={formik.handleBlur}
									disabled={(!formEnabled && formik.values['active']!=='custom')}
									value={formik.values['max_tokens']}
									error={(formik.touched['max_tokens'] && formik.errors['max_tokens'])?formik.errors['max_tokens']:null}
								/>

								{formik.values['token_sale_enabled']==='yes'&&
									<FormInput 
										id={'token_address'}
										label={formProperties['token_address'].label}
										isOptional={false}
										type={formProperties['token_address'].fieldType}  
										onChange={(e)=>handleFormikChange(e,'token_address')}
										onBlur={formik.handleBlur}
										value={formik.values['token_address']}
										error={(formik.touched['token_address'] && formik.errors['token_address'])?formik.errors['token_address']:null}
									/> 
								}
								
								<p className="mb-0 text-right small">Download the example CSV <a className="fake-link" href={process.env.PUBLIC_URL + '/example_list.csv'} download="example.csv">here</a>.</p>
								
							</>

							
							}	

							<FormInput 
								id={'whitelist'}
								label={formProperties['whitelist'].label}
								help={formProperties['whitelist'].help}
								type={formProperties['whitelist'].fieldType}
								accept={formProperties['whitelist'].accept}
								onChange={(e)=>{uploadCSVfile(e)}}
								onBlur={formik.handleBlur}
								disabled={(!formEnabled && formik.values['active']!=='custom')}
								mbNone={true}
								successMessage={whiteListMessage}
								value={formik.values['whitelist']}
								error={(formik.touched['whitelist'] && formik.errors['whitelist'])?formik.errors['whitelist']:null}
							/>
	
						</form>

					</div>
				</div>
			</div>

			{(formik.values.active==='custom' && customCSV?.length>0)&&
				<div className='row'>
					<div className='col-lg-10 mx-auto'>
						<div className='rounded-box boxShadow p-4 mt-3'>
							<div className=' csv-display'>
								<div className="csv-table-row head">
									<div className="csv-table-cell">
										Address
									</div>
									<div className="csv-table-cell">
										Tokens
									</div>
									<div className="csv-table-cell">
										Price
									</div>
									<div className="csv-table-cell">
										Token price
									</div>
									<div className="csv-table-cell">
										Valid from (UTC)
									</div>
									<div className="csv-table-cell">
										Valid to (UTC)
									</div>
								</div>

									{customCSV.map((item,idx)=>{return(
										<div className="csv-table-row" key={idx}>
											<div className="csv-table-cell">
												{item[0].slice(0,8)}...{item[0].slice(item[0].length-6)}
											</div>
											<div className="csv-table-cell text-center">
												{item[1]}
											</div>
											<div className="csv-table-cell text-center">
												{ethers.utils.formatEther(item[2])}
											</div>
											<div className="csv-table-cell text-center">
												{ethers.utils.formatEther(item[3])}
											</div>
											<div className="csv-table-cell">
												{getDateString(item[4])}
											</div>
											<div className="csv-table-cell">
												{getDateString(item[5])}
											</div>
										</div>
									)})}

								</div>
							</div>

					</div>
				</div>
			}


			<AlertModal
				visible={alertVisible}
				type={alertType}
				hideModal={()=>setAlertVisible(false)}
				modalData = {{title:alertTitle,description:alertMessage, buttonText:alertButtonLabel}}
			>
			</AlertModal>

			

			<BackdropModal visible={approveInProgress}/>
			<TxInProgressModal visible={txInProgress} txHash={txHash}/>

			<SSPFooter prev="/layers-and-cards" next="/main-sale" isSubmitEnabled={!txInProgress && !saveInProgress} submit={{label:"Submit Private sale",handleSubmit:formik.handleSubmit}}/>
		</>
	)
}


export default PreSale;