
import { BigNumber, ethers } from "ethers";
import config from "../../config";
import { getLayerInfo, getProjectInfoById, getProjectStatus, getSaleForProject, getWalletShares } from "../Api";

export const getSaleConfig = async (currentProjectId,communityId,tokenAddress)=>{
	
	/* let reservedSupply = 0;

	if(token){
		let tokenSaleInfo = await token.getTokenInfoForSale().catch(e=>console.log);
		//console.log('saleinfo from token',tokenSaleInfo);
		if(tokenSaleInfo){
			reservedSupply=Number(tokenSaleInfo.reservedSupply);
		}
	} 
	

	console.log('RESERVED SUPPLY',reservedSupply);
	*/

	return new Promise (async(resolve,reject)=>{
		const promises = [
			//getProjectStatus(currentProjectId),
			getProjectInfoById(currentProjectId),
			//getLayerInfo(currentProjectId),
			getWalletShares(currentProjectId),
			getSaleForProject(currentProjectId,1),	//Presale
			getSaleForProject(currentProjectId,4),	//Presale
			getSaleForProject(currentProjectId,3)	//MainSale
		];
		
		Promise.all(promises)
		.then(res=>{
			const [info, shares, presale,customPresale,mainSale] = res;
				//console.log('Project',status);
				console.log('ProjectInfo',info);
				//console.log('NFTs',nfts);
				console.log('WalletShares',shares);
				console.log('PreSale',presale);
				console.log('customPreSale',customPresale);
				console.log('MainSale',mainSale);

			const offset = new Date().getTimezoneOffset()*60;

			let presaleData = presale.data.length>0?presale.data[0]:customPresale.data[0];


			console.log('THE presale data',presaleData);

			let saleConfig =[
				BigNumber.from(communityId),				//project ID
				tokenAddress,								//Token address
				getWalletShareAddresses(shares),						//payable wallets
				getWalletShareShares(shares),							//share percenntages
				30,												//maxMintPerTransaction
				presaleData.max_tokens?presaleData.max_tokens:0,						//Max sold in presale across presale
				presaleData.max_tokens_per_wallet?presaleData.max_tokens_per_wallet:0,			//Limit presale per address
				mainSale.data[0].max_tokens_per_wallet?mainSale.data[0].max_tokens_per_wallet:mainSale.data[0].max_tokens,					//Limit sale per address ( includes presale )
							
			 	(presaleData.active && presaleData.start_date)?Math.floor(new Date(new Date(presaleData.start_date).toGMTString()).getTime()/1000)-offset:0,	//uint256 presaleStart;
				(presaleData.active && presaleData.end_date)?Math.floor(new Date(new Date(presaleData.end_date).toGMTString()).getTime()/1000)-offset:0,		//uint256 presaleEnd;
				
				(!!(mainSale.data[0].active) && mainSale.data[0].start_date)?Math.floor(new Date(new Date(mainSale.data[0].start_date).toGMTString()).getTime()/1000)-offset:0,	//uint256 mainSaleStart;
				(!!(mainSale.data[0].active) && mainSale.data[0].end_date)?Math.floor(new Date(new Date(mainSale.data[0].end_date).toGMTString()).getTime()/1000)-offset:0,		//uint256 mainSaleEnd;

				(!!(mainSale.data[0].active) && mainSale.data[0].eth_price)?ethers.utils.parseUnits(mainSale.data[0].eth_price,'ether'):0,	//uint256 fullPrice;
				//(!!(mainSale.data[0].active) && mainSale.data[0].max_tokens)?mainSale.data[0].max_tokens-reservedSupply:0,					//uint256 maxUserMintable 
				0,					//uint256 maxUserMintable (let the sale contract decide...)
				
				presaleData.signer_address?presaleData.signer_address:'0x0000000000000000000000000000000000000000',					//address signer;
	
				//this one below
				(!!(mainSale.data[0].active) && mainSale.data[0].token_price!==null)?ethers.utils.parseUnits(mainSale.data[0].token_price):0,    // uint256 price in token
				
				(!!(mainSale.data[0].active) && !!(mainSale.data[0].eth_sale_enabled)) || !!(presaleData.eth_sale_enabled),            								// uint256 sale for eth enabled ?
				(!!(mainSale.data[0].active) && !!(mainSale.data[0].token_sale_enabled)) || presaleData.token_sale_enabled || !!(presaleData.token_address),		// uint256 sale for token enabled ?
				mainSale.data[0].token_address?mainSale.data[0].token_address:presaleData.token_address?presaleData.token_address:'0x0000000000000000000000000000000000000000'					//accepted token contract address 
			 
			];

	/* 		const tokenConfig = [
				BigNumber.from(currentProjectId),               // _projectID
				nfts.data.total_nfts,                         	// _maxSupply
				info.data.name,       			       			// _name
				info.data.token_symbol,   		                // _symbol
				config.IPFS_GATEWAY+info.data.pre_reveal_json_hash,  					    // _tokenPreRevealURI
				info.data.metadata_url+'/api/metadata/',  		// _tokenRevealURI
				false,                        					// _transferLocked
				0,                          					// _reservedSupply
				0                            					// _giveawaySupply
			]; */


//			console.log('SALECONFIG',saleConfig);
			
			resolve(saleConfig);

		}).catch(e=>{console.log(e);reject(e)});
	});
}

const getWalletShareAddresses = (shares)=>{

	//console.log(shares.data)
	let res=[];

	for(let i=0; i<shares.data.length;i++){
		res.push(shares.data[i].address);
	}

	return res;
}

const getWalletShareShares = (shares)=>{
	let res=[];

	for(let i=0; i<shares.data.length;i++){
		res.push(shares.data[i].share);
	}

	return res;
}
