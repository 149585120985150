import { useContext, useEffect, useState } from "react";
import { DataContext } from "../DataStore/DataStore";

const useConfirm = () => {
  const {confirm, setConfirm} = useContext(DataContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = (title,prompt,positiveLabel,negativeLabel,confType) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({title, prompt, positiveLabel, negativeLabel, confType, isOpen: true, proceed: resolve, cancel: reject });
      setNeedsCleanup(true);
    });

    const reset = () => {
      setConfirm({ title: "", prompt: "", positiveLabel: "", negativeLabel: "", confType:"", proceed: null, cancel: null, isOpen: false });
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  // Call cancel in a cleanup func to avoid dangling confirm dialog
  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed
  };
};

export default useConfirm;