import {  useContext, useEffect, useState } from 'react';
import {
  DataContext,formState
} from '../../components/DataStore/DataStore';
import FormInput from '../../components/common/FormInput';

import SSPFooter from '../../components/SSPFooter/SSPFooter';
import { PlusCircle } from 'react-bootstrap-icons';
import titleIcon from '../../assets/images/page-title-icons/traits.svg';
import AlertModal from '../../components/common/AlertModal';
import { toast } from 'react-toast';
import InfoBox from '../../components/common/InfoBox';
import deleteIcon from '../../assets/images/icons/deleteBlackIcon.svg';
import './ShareSetup.css';
import { ethers } from 'ethers';
import useConfirm from '../../components/common/useConfirm';
import { setWalletShares } from '../../components/Api';
import saleContractAbi from '../../contracts/SaleContract.json';
import TxInProgressModal from "../../components/common/TxInProgressModal";
import BackdropModal from "../../components/common/BackdropModal";
import Web3Ctx from '../../components/Context/Web3Ctx';
import { getSaleConfig } from '../../components/Utils/GetSaleConfig';
import config from '../../config';

const ShareSetup = (props) => {
  const PAGE = 'SHARE_SETUP';

  const { currentProjectId,updatePageState,getWalletShareData,initDone, updateFormV2, deployStatus, projectChain, communityId } = useContext(DataContext);
  const {getProvider,setChain,chainId} = useContext(Web3Ctx);
  //Alert stuff
  const [alertType, setAlertType] = useState('ERROR');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertButtonLabel, setAlertButtonLabel] = useState('Close');
  const [alertTitle, setAlertTitle] = useState('Upload error');
  const [alertVisible, setAlertVisible] = useState(false);

  const initialData = {
    address:{value:'', touched: false, error:''},
    share: {value:'', touched: false, error:''},
  }
  const [shares,setShares] = useState([initialData]);

  const { isConfirmed } = useConfirm();
  const [saleConfig,setSaleConfig] = useState(null);
  const [approveInProgress,setApproveInProgress]=useState(false);
	const [txInProgress,setTxInProgress]=useState(false);
	const [saveInProgress,setSaveInProgress]=useState(false);
  const [txHash,setTxHash]=useState(null);



  useEffect(()=>{

		const updateSaleContract = async ()=>{

			if(chainId === projectChain){

        let selectedChainIdx = 0;
				const idx = config.CHAINS.findIndex(c => {
			  	return parseInt(c.id,16) === projectChain;
				});
				if(idx>-1){
				  selectedChainIdx=idx;
				}

				setApproveInProgress(true);
				let provider = await getProvider(projectChain);
				if(!provider)return;
				let sale = new ethers.Contract(deployStatus.contracts.sale, saleContractAbi.abi, provider);
				if(!sale)return;
				let sc = sale.connect(provider.getSigner());

				console.log(sc)
				
				let tx=await sc.UpdateSaleConfiguration(saleConfig).catch(handleError);
				setApproveInProgress(false);
				console.log('TX', tx);
				if(tx){
					setTxHash(`${config.CHAINS[selectedChainIdx].blockExplorerUrl}/tx/${tx.hash}`);
					setTxInProgress(true);
					let receipt = await tx.wait().catch(e=>{
						setTxInProgress(false);
						handleError(e);
						return;
					});
					console.log('receipt',receipt);
					setTxInProgress(false);
					setSaleConfig(null);
				}
				setSaveInProgress(false);
			}else{
				setSaveInProgress(false);
			}
		}

		if(saleConfig && chainId){
			updateSaleContract();
		}


	},[saleConfig,chainId]);

  useEffect(() => {
    if(currentProjectId != null && initDone){

      console.log('get wallet share data....');
  
      if(getWalletShareData){
          let wsData = getWalletShareData();
          let tmpshares = [];
          for(let i=0;i<wsData.length;i++){
            tmpshares.push({
              address:{value:wsData[i].address, touched: true, error:''},
              share: {value:Math.floor(wsData[i].share/100), touched: true, error:''},
            });
          }
          setShares(tmpshares);
      }
    }
  }, [currentProjectId, initDone]);

  const handleError = (e) => {
		console.error('handle error',e);
		if (e.error && e.error.message) {
		  toast.error(e.error.message);
		} else if (e.message) {
		  toast.error(e.message);
		} else if (e.reason) {
		  toast.error(e.reason);
		}
	  };

  const updateAddress=(value, idx)=>{
    let tmpShares = [...shares];
    tmpShares[idx].address.value=value;
    tmpShares[idx].address.error='';
    if(value!==''){
      tmpShares[idx].address.touched=true;
    }else{
      tmpShares[idx].address.touched=false;
    }
    setShares(tmpShares);
    
  }

  const updateShare=(value, idx)=>{
    let tmpShares = [...shares];
    tmpShares[idx].share.value=value;
    
    tmpShares[idx].share.error='';
    if(value!==''){
      tmpShares[idx].share.touched=true;
    }else{
      tmpShares[idx].share.touched=false;
    }
    setShares(tmpShares);
    
  }


  const validateAddress = (idx) => {
    if(shares[idx].address.touched===false){
      return false;
    }

    let tmpShares = [...shares];

    if(!ethers.utils.isAddress(tmpShares[idx].address.value)){
      tmpShares[idx].address.error='Invalid address.';
      setShares(tmpShares);
      
      return false;
    }
    updateFormV2(PAGE,null,tmpShares);
    return true;
  }

  const validateShare = (idx) => {
    if(shares[idx].share.touched===false){
      return false;
    }

    let tmpShares = [...shares];

    let shareValue = Number(tmpShares[idx].share.value);

    if(shareValue<=0){
      tmpShares[idx].share.error='Too low.';
      setShares(tmpShares);
      return false;
    }

    if(shareValue>100){
      tmpShares[idx].share.error='Too high.';
      setShares(tmpShares);
      return false;
    }
    updateFormV2(PAGE,null,tmpShares);
    return true;
  }



  const addNewAddress = ()=>{

    if(validateAddress(shares.length-1) && validateShare(shares.length-1)){
      setShares([...shares,initialData]);
      console.log('!!!!!!******')
      updateFormV2(PAGE,null,[...shares,initialData]);
    }else{
      toast.warn('Fill the last entry before adding a new one :)');
    }
  }

  const removeAddressAt = async (idx)=>{

    if(shares.length<2){
      return;
    }

    const confirmed = await isConfirmed('Remove address','Are you sure?','DELETE','BACK');

    if(!confirmed){
      return;
    }

    let tmpShares = [...shares];
    tmpShares.splice(idx,1);
    setShares(tmpShares);
    updateFormV2(PAGE,null,tmpShares);
  }

  const submitData = async ()=>{
    let isFormValid=true;
    let tempShares=[...shares];

    let sumOfShares=0;

    for(let i=0;i<tempShares.length;i++){

      if(!validateAddress(i)){
        console.log('INVALID ADDRESS', i);
        isFormValid=false;
        if(tempShares[i].address.value===''){
          tempShares[i].address.error='Required field';
        }
      }

      if(!validateShare(i)){
        console.log('INVALID SHARE AMOUNT', i);
        isFormValid=false;
        if(tempShares[i].share.value===''){
          tempShares[i].share.error='Required field';
        }
      }else{
        sumOfShares+=Number(shares[i].share.value);
      }
    }

    if(!isFormValid){
      setShares(tempShares);
    }else{
      
      if(tempShares.length===1){

        let conf=await isConfirmed('Warning!','There is only one wallet added. Are you sure?','YES','NO');
        
        
        if(!conf){
          return;
        }
      }

      if(sumOfShares!==100){
        let conf=await isConfirmed('Warning!','Total share value must be 100.','OK');
        return;
      }



      console.log('DS',deployStatus,projectChain);
      	setSaveInProgress(true);

      let txNeeded = (deployStatus.contracts && deployStatus.contracts.sale!==null);
     
      if(txNeeded){
				const confirmed = await isConfirmed('Sale contract update','To change your sale parameters on the blockchain please sign the transaction.','OK','Back');
				if(!confirmed){
					setSaveInProgress(false);
					return;
				}
			}

      let dataToSend = [];

      tempShares.forEach(item=>{
        dataToSend.push({address:item.address.value, share:Number(item.share.value)*100})
      });

      if(dataToSend.length>0){
        console.log(dataToSend);

        const res = await setWalletShares(currentProjectId,{shares:dataToSend}).catch(e=>console.log);
      
        console.log(res);

        if(res.success){

          toast.success('Wallet shares saved.');
          updatePageState(PAGE,formState.FORM_SAVED);

          if(txNeeded){
					  //TODO: update sale config on chain
					 // setApproveInProgress(true);

					  console.log('getting saleConfig');
					  let sconf = await getSaleConfig(currentProjectId,communityId,deployStatus.contracts.token).catch(e=>console.log);
					  
					  console.log(sconf)
					  if(sconf){
              if(chainId!==projectChain){
                await setChain(projectChain);
              }
              setSaleConfig(sconf);
					  }
					  
					
				  }else{
					  setSaveInProgress(false);
				  }
        }else{
          toast.error(res.message.message?res.message.message:res.message);
        }
      }
    }
  }

  
  return (
    //   plus marginBottom added to the row because of the scrolling
    <div className="row" style={{ marginBottom: '80px' }}>
      <div className="col-lg-10 mx-auto">
      <div style={{ display: 'flex'}}>
        <h4 className="page-title mt-1">
          <img src={titleIcon} style={{ marginRight: '5px' }} />
          6. Sales Revenue Distribution 
        </h4>
        <InfoBox position="bottom" title="Sales Revenue Distribution ">
          <p>Enter the wallet address(es) and the corresponding share(s), where the initial token sale revenue should be split.</p>
          <p>Click the + icon to add more wallets.</p>
          <p>The sum of the share values must be exactly 100.</p>
        </InfoBox>
        </div>
        {shares.map((item, idx) => {
          return (
            <div key={idx} className="rounded-box boxShadow p-4 mt-3">
              <div className='row  d-flex small-gutters'>

                  <div className='col-md-9 pl-3'>
                    <FormInput
                      className='mb-0'
                      type='text'
                      label='Address'
                      onChange={(e) => {
                        updateAddress(e.target.value, idx);
                      }}
                      value={shares[idx].address.value}
                      onBlur={()=>validateAddress(idx)}
                      error={shares[idx].address.error}
                    />
                   
                  </div>

                  <div className='col-md-2'>
                    <FormInput
                      className='mb-0'
                      type='number'
                      label='Share %'
                      onChange={(e) => {
                        e.target.value=e.target.value.replace(/[^0-9.]/g, '');
                        updateShare(e.target.value, idx);
                      }}
                      value={shares[idx].share.value}
                      onBlur={()=>validateShare(idx)}
                      error={shares[idx].share.error}
                    />
                   
                  </div>

                  <div className='col-md-1 text-center align-items-center d-flex justify-content-end'>
                    {(shares.length>1)&&
                    <img src={deleteIcon} className={`delete-button ${shares.length<2?'disabled':''}`} onClick={()=> removeAddressAt(idx)}/>
                    }
                  </div>
              </div>
            </div>
          );
        })}
        <button className="btn-with-icon mt-4" onClick={addNewAddress}>
          <PlusCircle
            className="primary"
            size={36}
            style={{ marginRight: '10px' }}
          />
          Add new address
        </button>
      </div>
      <AlertModal
        visible={alertVisible}
        type={alertType}
        hideModal={() => setAlertVisible(false)}
        modalData={{
          title: alertTitle,
          description: alertMessage,
          buttonText: alertButtonLabel,
        }}
      ></AlertModal>
      <BackdropModal visible={approveInProgress}/>
      <TxInProgressModal visible={txInProgress} txHash={txHash}/>

      <SSPFooter
        prev="/main-sale"
        next=""
        isSubmitEnabled={true}
        submit={{
          label: 'Submit Sales Revenue Distribution',
          handleSubmit: submitData,
        }}
        
      />
    </div>
  );
};

export default ShareSetup;
