import React, { useContext, useEffect, useState } from 'react';
import { DataContext, formState} from '../../components/DataStore/DataStore';
import { useFormik } from 'formik';

import FormInput from '../../components/common/FormInput';
import RoundedBox from '../../components/visual-utils/RoundedBox';
import SSPFooter from '../../components/SSPFooter/SSPFooter';
import titleIcon from '../../assets/images/page-title-icons/projection-screen.svg';
import AlertModal from '../../components/common/AlertModal';
import { createOrUpdateSale } from '../../components/Api';
import InfoBox from '../../components/common/InfoBox';
import { Link } from 'react-router-dom';
import { toast } from 'react-toast';
import { dateWithOffsetToIsoString} from '../../components/Utils';
import './MainSale.css';
import useConfirm from '../../components/common/useConfirm';
import { getSaleConfig } from '../../components/Utils/GetSaleConfig';
import Web3Ctx from '../../components/Context/Web3Ctx';
import { ethers } from 'ethers';
import saleContractAbi from '../../contracts/SaleContract.json';
import TxInProgressModal from "../../components/common/TxInProgressModal";
import BackdropModal from "../../components/common/BackdropModal";
import config from "../../config";
import { formSkeleton } from '../../components/DataStore/DataStore';

const styleConfig = {
  borderRadius: '20px',
  padding:"1.5rem",
  marginTop: '20px',
  backgroundColor: '#fff',
};

const MainSale = () => {
  const { updateFormV2, currentProjectId,updatePageState,allTimezones, communityId,projectChain,deployStatus, initDone, getMainSaleData, getPreSaleData,nftsLength } = useContext(DataContext);
  const {getProvider,setChain,chainId} = useContext(Web3Ctx);
  const PAGE = 'MAIN_SALE';
  const CURRENT_SALE_TYPE = 3;

  const [form] = useState({...formSkeleton[PAGE].form});
	const [formProperties] = useState({...formSkeleton[PAGE].formProperties});

  const [presaleStart,setPresaleStart] = useState(null);
  const [presaleEnd,setPresaleEnd] = useState(null);

  const { isConfirmed } = useConfirm();
  const [saleConfig,setSaleConfig] = useState(null);
  const [approveInProgress,setApproveInProgress]=useState(false);
	const [txInProgress,setTxInProgress]=useState(false);
  const [saveInProgress,setSaveInProgress]=useState(false);
	const [txHash,setTxHash]=useState(null);

  const [invalidDatesError,setInvalidDatesError] = useState('');
  const [invalidStartDateError,setInvalidStartDateError] = useState('');

  	//Alert stuff
	const [alertType, setAlertType] = useState('ERROR');
	const [alertMessage, setAlertMessage] = useState('');
	const [alertButtonLabel, setAlertButtonLabel]=useState('Close');
	const [alertTitle, setAlertTitle]=useState('Upload error');
	const [alertVisible, setAlertVisible] = useState(false);

  const [nativeTokenName,setNativeTokenName] = useState('ETH');
  const [formEnabled,setFormEnabled] = useState(true);
  const [walletTokensError,setWalletTokensError] = useState(null);


  useEffect(()=>{

		const updateSaleContract = async ()=>{
      
			if(chainId === projectChain){
       
        let selectedChainIdx = 0;
        const idx = config.CHAINS.findIndex(c => {
          return parseInt(c.id,16) === projectChain;
        });
        if(idx>-1){
          selectedChainIdx=idx;
        }


				let provider = await getProvider(projectChain);
				if(!provider)return;
				let sale = new ethers.Contract(deployStatus.contracts.sale, saleContractAbi.abi, provider);
				if(!sale)return;
				let sc = sale.connect(provider.getSigner());

				setApproveInProgress(true);
				let tx=await sc.UpdateSaleConfiguration(saleConfig).catch(handleError);
				setApproveInProgress(false);
				if(tx){

          
					setTxHash(`${config.CHAINS[selectedChainIdx].blockExplorerUrl}/tx/${tx.hash}`);
					setTxInProgress(true);
					let receipt = await tx.wait().catch(e=>{
						setTxInProgress(false);
						handleError(e);
						return;
					});
					setTxInProgress(false);
					setSaleConfig(null);
				}
        setSaveInProgress(false);
			}
		}

		if(saleConfig && chainId){
			updateSaleContract();
		}
	},[saleConfig,chainId]);

  const handleError = (e) => {
		console.error('handle error',e);
		if (e.error && e.error.message) {
		  toast.error(e.error.message);
		} else if (e.message) {
		  toast.error(e.message);
		} else if (e.reason) {
		  toast.error(e.reason);
		}
	};


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: form,
    validationSchema: formSkeleton[PAGE].formScheme,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {

      setSaveInProgress(true);
      let txNeeded = (deployStatus.contracts && deployStatus.contracts.sale!==null);


      if(txNeeded){
				const confirmed = await isConfirmed('Sale contract update','To change your sale parameters on the blockchain please sign the transaction.','OK','Back');
				if(!confirmed){
          setSaveInProgress(false);
					return;
				}
			}

      let dataForSale = {};

      if(!invalidDatesError){


        //sale
        dataForSale['active']=values.active === 'yes'?true:false ;


        if(values.active === 'yes'){
          dataForSale.free_mint=false;
          dataForSale.type_id=CURRENT_SALE_TYPE;
          dataForSale.timezone=allTimezones[values.timezone-1].label;
          
          dataForSale.eth_sale_enabled = values.eth_sale_enabled === 'yes'?true : false;
          if(dataForSale.eth_sale_enabled){
            dataForSale.eth_price=values.eth_price;
          }
  
          dataForSale.token_sale_enabled = values.token_sale_enabled === 'yes'?true : false;
          if(dataForSale.token_sale_enabled){
            dataForSale.token_price=values.token_price;
            dataForSale.token_address = values.token_address;
          }
  
          dataForSale.start_date=dateWithOffsetToIsoString(values.start_date,allTimezones[values.timezone-1].offset);
          dataForSale.end_date=dateWithOffsetToIsoString(values.end_date,allTimezones[values.timezone-1].offset);;
          dataForSale.lock_tokens_during_sale=values.lock_tokens_during_sale;
          dataForSale.signer_address="unused";
          dataForSale.whitelist_json=null;
          dataForSale.max_tokens_per_wallet=values.max_tokens_per_wallet?Number(values.max_tokens_per_wallet):nftsLength;
        }


       
        let data = dataForSale.active?{...dataForSale}:{active:false,
                                                        type_id:CURRENT_SALE_TYPE,
                                                        lock_tokens_during_sale:false/* ,
                                                        free_mint:false,
                                                        token_price:0,
                                                        token_address:ethers.constants.AddressZero,
                                                        start_date:0,
                                                        end_date:0,
                                                        lock_tokens_during_sale:false,
                                                        signer_address:"unused",
                                                        whitelist_json:null */
                                                      };

        let res = await createOrUpdateSale(currentProjectId,data).catch(handleError)
       
        if(res.success){
          updatePageState(PAGE,formState.FORM_SAVED);
          toast.success('New sale information saved');


          if(txNeeded){

          /*   let provider = await getProvider(projectChain);
            let token=null;
            if(deployStatus.contracts.token){
              token = new ethers.Contract(deployStatus.contracts.token, tokenContractAbi.abi, provider);
            } */
  
            let sconf = await getSaleConfig(currentProjectId,communityId,deployStatus.contracts.token).catch(e=>console.log);

            if(sconf){
              if(chainId!==projectChain){
                await setChain(projectChain);
              }
              setSaleConfig(sconf);
            }
          }else{
            setSaveInProgress(false);
          }
        }else{
          setSaveInProgress(false);
          toast.error(res.message.message?res.message.message:res.message);
        }
       
      }
    },
  });

  const handleFormikChange = (e, i) => {
    formik.handleChange(e);
    updateFormV2(PAGE, i, e.target.type==='checkbox'?e.target.checked:e.target.value);
  };


  useEffect(()=>{
		setFormEnabled(formik.values['active']==='yes');
	},[formik.values['active']]);


  useEffect(()=>{
    
    if(projectChain){
			if(projectChain===1 || projectChain===11155111){
				setNativeTokenName(projectChain===1?'ETH':'sETH');
			}else{
				setNativeTokenName(projectChain===137?'MATIC':'mMATIC');
			}
		}

      
    if (currentProjectId && initDone && getMainSaleData) {
      let saleObj = getMainSaleData();
      Object.keys(form).forEach(key=>{
        formik.values[key]=saleObj[key];
      });

      if(getPreSaleData){
        let preSaleObj=getPreSaleData();
        setPresaleStart(preSaleObj.start_date);
        setPresaleEnd(preSaleObj.end_date);
      }

      if(!formik.values.max_tokens_per_wallet){
        formik.values.max_tokens_per_wallet=nftsLength;
      }

      formik.validateForm();
    }
  },[currentProjectId,allTimezones, initDone]);

  useEffect(()=>{

    
    if(formik.values.start_date && formik.values.end_date){

			const sd = new Date(formik.values.start_date).getTime();
			const ed = new Date(formik.values.end_date).getTime();

    /*   if(presaleStart && presaleEnd){

        console.log(sd,ed,presaleStart.getTime(),presaleEnd.getTime());
      }
 */
  

			if(ed-sd<0){
				setInvalidDatesError('The event will end before the start date.')
			}else{
        setInvalidDatesError('');
			}

    }

    if(formik.values.start_date && presaleStart){
      const sd = new Date(formik.values.start_date).getTime();

     // console.log('diff',sd-presaleStart.getTime());

      if(sd<presaleStart.getTime()){
        setInvalidStartDateError('Main sale can\'t be started before presale');
      }else{
        setInvalidStartDateError('');
      }
    }else{
      setInvalidStartDateError('');
    }



	
	},[formik.values.start_date,formik.values.end_date]);

  useEffect(()=>{
		if(formik.values.max_tokens_per_wallet){

			if(Number(nftsLength)<Number(formik.values.max_tokens_per_wallet)){
				setWalletTokensError('Can\'t be higher than Nr. of tokens');
			}else{
				setWalletTokensError(null);
			}

		}else{setWalletTokensError(null)}


		
	},[formik.values.max_tokens_per_wallet]);

  const getTokenName = (label) => {
		return label.replace('*nativeToken*',nativeTokenName);
	}



  return (
    <div className="row main-sale-page">
      <div className="col-lg-8 mx-auto">
        <div className="social-media-page">
          <div style={{ display: 'flex' }}>
            <h4 className="page-title">
              <img src={titleIcon} style={{ marginRight: '3px' }} /> 6. Public
              sale
            </h4>
            <InfoBox position="bottom" title="Public sale">
            Enter the details of your sale, then click “Submit Main Sale”. To continue, click “Next Step”.
            </InfoBox>
          </div>
          <RoundedBox styleConfig={styleConfig} hasShadow={true}>
            <form onSubmit={formik.handleSubmit}>

              <FormInput 
                id={'active'}
                label={formProperties['active'].label}
                type={formProperties['active'].fieldType} 
                options={formProperties['active'].options}
                onChange={(e)=>{handleFormikChange(e,'active');setFormEnabled(e.target.value==='yes')}}
                onBlur={formik.handleBlur}
                value={formik.values['active']}
                error={(formik.touched['active'] && formik.errors['active'])?formik.errors['active']:null}
              />



              <FormInput
                  id={'timezone'}
                  label={formProperties['timezone'].label}
                  type={formProperties['timezone'].fieldType}
                  options={allTimezones}
                  onChange={(e)=>{handleFormikChange(e,'timezone')}}
                  onBlur={formik.handleBlur}
                  disabled={!formEnabled}
                  value={formik.values['timezone']}
                  error={
                    formik.touched['timezone'] &&
                    formik.errors['timezone']
                      ? formik.errors['timezone']
                      : null
                  }
                />


              <div className="two-col-row">
                <FormInput
                  id={'start_date'}
                  label={formProperties['start_date'].label}
                  type={formProperties['start_date'].fieldType}
                  options={formProperties['start_date'].options}
                  onChange={(e) => {
                    updateFormV2(PAGE, 'start_date', e);
                    formik.setFieldValue('start_date', e);
                  }}
                  onBlur={formik.handleBlur}
                  disabled={!formEnabled}
                  value={formik.values['start_date']}
                  error={
                    formik.touched['start_date'] &&
                    formik.errors['start_date']
                      ? formik.errors['start_date']
                      : invalidStartDateError
                  }
                />

                <FormInput
                  id={'end_date'}
                  label={formProperties['end_date'].label}
                  type={formProperties['end_date'].fieldType}
                  onChange={(e) => {
                    updateFormV2(PAGE, 'end_date', e);
                    formik.setFieldValue('end_date', e);
                  }}
                  onBlur={formik.handleBlur}
                  disabled={!formEnabled}
                  value={formik.values['end_date']}
                  error={
                    formik.touched['end_date'] &&
                    formik.errors['end_date']
                      ? formik.errors['end_date']
                      : invalidDatesError
                  }
                />
              </div>

              <FormInput
									id={'max_tokens_per_wallet'}
									label={formProperties['max_tokens_per_wallet'].label}
									type={formProperties['max_tokens_per_wallet'].fieldType}  
									onChange={(e)=>{e.target.value=e.target.value.replace(/[^0-9]/g, '');handleFormikChange(e,'max_tokens_per_wallet')}}
									onBlur={formik.handleBlur}
									disabled={!formEnabled}
									value={formik.values['max_tokens_per_wallet']}
									error={(formik.touched['max_tokens_per_wallet'] && formik.errors['max_tokens_per_wallet'])?formik.errors['max_tokens_per_wallet']:walletTokensError}
								/>
              
              <FormInput 
							id={'eth_sale_enabled'}
							label={getTokenName(formProperties['eth_sale_enabled'].label)}
							type={formProperties['eth_sale_enabled'].fieldType} 
							options={formProperties['eth_sale_enabled'].options}
							onChange={(e)=>{handleFormikChange(e,'eth_sale_enabled')}}
							onBlur={formik.handleBlur}
              disabled={!formEnabled}
							value={formik.values['eth_sale_enabled']}
							error={(formik.touched['eth_sale_enabled'] && formik.errors['eth_sale_enabled'])?formik.errors['eth_sale_enabled']:null}
						/>


						{/* <div className="row"> */}
							{/* <div className="col-md-12 pr-md-2"> */}
								<FormInput 
									id={'eth_price'}
									label={getTokenName(formProperties['eth_price'].label)}
									type={formProperties['eth_price'].fieldType}  
									onChange={(e)=>{e.target.value=e.target.value.replace(/[^0-9.]/g, '');handleFormikChange(e,'eth_price')}}
									onBlur={formik.handleBlur}
									disabled={formik.values['eth_sale_enabled']==='no' || !formEnabled}
									value={formik.values['eth_price']}
									error={(formik.touched['eth_price'] && formik.errors['eth_price'])?formik.errors['eth_price']:null}
								/>			
							{/* </div> */}

							{/*<div className="col-md-6 pl-md-2">*/}

								<FormInput 
									id={'token_sale_enabled'}
									label={formProperties['token_sale_enabled'].label}
									type={formProperties['token_sale_enabled'].fieldType} 
									options={formProperties['token_sale_enabled'].options}
									onChange={(e)=>{handleFormikChange(e,'token_sale_enabled')}}
									onBlur={formik.handleBlur}
                  disabled={!formEnabled}
									value={formik.values['token_sale_enabled']}
									error={(formik.touched['token_sale_enabled'] && formik.errors['token_sale_enabled'])?formik.errors['token_sale_enabled']:null}
								/>


								 <FormInput 
									id={'token_price'}
									label={formProperties['token_price'].label}
									isOptional={false}
									type={formProperties['token_price'].fieldType}  
									onChange={(e)=>{e.target.value=e.target.value.replace(/[^0-9.]/g, '');handleFormikChange(e,'token_price')}}
									onBlur={formik.handleBlur}
									disabled={formik.values['token_sale_enabled']==='no' || !formEnabled}
									value={formik.values['token_price']}
									error={(formik.touched['token_price'] && formik.errors['token_price'])?formik.errors['token_price']:null}
								/> 

								<FormInput 
									id={'token_address'}
									label={formProperties['token_address'].label}
									isOptional={false}
									type={formProperties['token_address'].fieldType}  
									onChange={(e)=>handleFormikChange(e,'token_address')}
									onBlur={formik.handleBlur}
									disabled={formik.values['token_sale_enabled']==='no' || !formEnabled}
									value={formik.values['token_address']}
									error={(formik.touched['token_address'] && formik.errors['token_address'])?formik.errors['token_address']:null}
								/> 
            </form>
          </RoundedBox>
          <AlertModal
            visible={alertVisible}
            type={alertType}
            hideModal={()=>setAlertVisible(false)}
            modalData = {{title:alertTitle,description:alertMessage, buttonText:alertButtonLabel}}
          >
          </AlertModal>
          <BackdropModal visible={approveInProgress}/>
          <TxInProgressModal visible={txInProgress} txHash={txHash}/>

          <SSPFooter
            prev="/presale"
            next="/share-setup"
            isSubmitEnabled={!txInProgress && !saveInProgress}
            submit={{
              label: 'Submit Public sale',
              handleSubmit: formik.handleSubmit,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default MainSale;
