import './ConfigLeaderboard.css';
import leftTrick from '../../assets/images/leftTrick.svg';
import rightTrick from '../../assets/images/rightTrick.svg';
import leftTopTrick from '../../assets/images/leftTopTrick.svg';
import rightTopTrick from '../../assets/images/rightTopTrick.svg';
import sbaIcon from '../../assets/images/stickerbook/sba-icon.png';

import RoundedBox from '../../components/visual-utils/RoundedBox';
import FormInput from '../../components/common/FormInput';
import { useContext, useEffect, useState } from 'react';
import BorderedNavigationLink from '../../components/visual-utils/navigation-links/BorderedNavigationLink';
import { DataContext } from '../../components/DataStore/DataStore';
import { communityHealthcheck, launchLeaderboard, launchSBA} from '../../components/Api';
import { toast } from 'react-toast';
import useConfirm from '../../components/common/useConfirm';
import config from '../../config';
import DeployInProgressModal from '../../components/common/DeployInProgressModal';
import useInterval from '../../components/common/useInterval';
import { set } from 'immutable';
import axios from 'axios';

const ConfigLeaderboard = (props) => {

	const collectionId = 1;

	const waitDuration = 25*60*1000; //5 minutes

	const chainNames = {
		1: 'mainnet',
		5: 'goerli',
		137: 'polygon',
		80001: 'mumbai'
	}

	const styleConfig = {
		borderRadius: '20px',
		padding:"1.5rem",
		marginTop: '20px',
		backgroundColor: '#fff',
	  };

	  const modalConfig = {
		position: 'relative',
		width: '100%',
		maxWidth: '508px',
	  };

	const {communityId,projectChain} = useContext(DataContext);
	//const {address,getProvider,setChain,chainId} = useContext(Web3Ctx);

	const { isConfirmed } = useConfirm();

	const [lbdRpcUrl,setLbdRpcUrl] = useState('');
	const [lbdWssUrl,setLbdWssUrl] = useState('');

	const [selectedLDBChain,setSelectedLDBChain] = useState(0);

	const [deployInProgressVisible,setDeployInProgressVisible] = useState(false);
	const [deployMessage,setDeployMessage] = useState('');
	const [lbdDashboardUrl,setLBDDashboardUrl] = useState('');
	const [pollInterval, setPollInterval] = useState(null);
	const [customRpc, setCustomRpc] = useState(false);

	const [customRpcUrl,setCustomRpcUrl] = useState('');
	const [customWssUrl,setCustomWssUrl] = useState('');

	const [errors,setErrors]=useState({
		"rpc-url":'',
		"wss-url":''
	});


	const onChainSelect = (chainIdx)=>{
		setSelectedLDBChain(chainIdx);
		console.log('CHAIN CHANGE',config.CHAINS[chainIdx]);

		if(config.CHAINS[chainIdx].id==="0x13881" || config.CHAINS[chainIdx].id==="0x89"){
			setLbdRpcUrl(config.POLYGON_SBA_RPC_URL);
			setLbdWssUrl(config.POLYGON_SBA_WSS);
		}else{
			setLbdRpcUrl(config.RPC_BASE + config.SBA_INFURA_ID);
			setLbdWssUrl(config.WSS_BASE + config.SBA_INFURA_ID);
		}
	}

	
	const handleError = (e) => {
		console.error('handle error',e);
		if (e.error && e.error.message) {
		  toast.error(e.error.message);
		} else if (e.message) {
		  toast.error(e.message);
		} else if (e.reason) {
		  toast.error(e.reason);
		}
	};

	useEffect(()=>{

		const checkDeployStatus = async()=>{
			let res = await LBDHealtcheck(true);
			console.log('deployed',res);

			switch (res){
				case '0':
					{
						setDeployMessage('The deployment of Leaderboard Admin started successfully. Please wait for the servers to come online...');
						setDeployInProgressVisible(true);
						console.log('poll START');
						setPollInterval(10000);
						break;
					}
			case '1':
				{
					let lbdUrl=`${config.DOMAIN}/${communityId}/leaderboard/admin/#/`;

					setDeployMessage('Leaderboard Admin has been deployed.');
					setLBDDashboardUrl(lbdUrl);
					setDeployInProgressVisible(true);

					break;
				}
			case '2':
				{

					break;
				}
			default:
				{
					//not deployed yet
					setDeployMessage('');
					setLBDDashboardUrl('');
					setDeployInProgressVisible(false);	
					break;
				}
			}
		}

		if(projectChain){

			console.log('projectChain',projectChain);


			let chIdx = config.CHAINS.findIndex(c => {
				return parseInt(c.id,16) === projectChain;
			});

			console.log('CH IDX', chIdx);

			if(chIdx>-1){
				console.log('chain found:', config.CHAINS[chIdx]);
				onChainSelect(chIdx);
			}else{
				toast.error('Chain id '+projectChain+' is not supported');
				return;
			}
			checkDeployStatus();
		}
	},[projectChain])



	const LBDHealtcheck = async(muted)=>{
		console.log('healtcheck called');

		let healthRes = await communityHealthcheck(communityId).catch(e=>console.log);
     	console.log('HEALTCHECK RESULT', healthRes);

		if(healthRes.success && healthRes.stickerbook){

			if(healthRes.leaderboard.status && Number(healthRes.leaderboard.status)>1 && !muted){
				setPollInterval(null);
				toast.error('Sticker Book Admin deployment failed.');
				setDeployInProgressVisible(false);
			}

			if(healthRes.leaderboard.status && Number(healthRes.leaderboard.status)===1){
				
				let lbdUrl=`${config.DOMAIN}/${communityId}/leaderboard/admin/#/`;
				setDeployMessage("Sticker Book Admin server is up and running.");
				setLBDDashboardUrl(lbdUrl);
				console.log('STOP polling (success)');
				setPollInterval(null);
			}

			let lbdDeployStarted = localStorage.getItem('LBD'+communityId);
			if(healthRes.leaderboard.status===null && lbdDeployStarted){
				//deploy started, but somehow the healthcheck gives back null
				return '0';
			} 
			return healthRes.leaderboard.status;
		}
		
		return null;
	}

	useInterval(
		LBDHealtcheck,
		pollInterval
	);

	const handleDeployLBD = async ()=>{

		let rpc = customRpc?customRpcUrl:lbdRpcUrl;
		let wss = customRpc?customWssUrl:lbdWssUrl;
		let etherscan = config.CHAINS[selectedLDBChain].blockExplorerUrl;
		
		if(!rpc){
			toast.error('RPC url is required');
			return;
		}
		if(!wss){
			toast.error('WSS url is required');
			return;
		}
		setDeployInProgressVisible(true);
		setDeployMessage('Leaderboard Admin Deploy is in progress.');

		let launchResponse = await launchLeaderboard(Number(communityId),rpc,wss,etherscan).catch(e=>console.log)
		console.log('launch response', launchResponse);
		if(launchResponse && launchResponse.success){
			setDeployMessage('The deployment of Leaderboard Admin started successfully. Please wait for the servers to come online...');
			localStorage.setItem('LBD'+communityId,new Date().getTime());
			console.log('poll START');
			setPollInterval(10000);
		}else{
			setDeployInProgressVisible(false);
			toast.error('Leaderboard Admin deployment failed. ');
		}
	}

	const checkUrl = async(e)=> {

		let url = e.target.value;
		let id = e.target.id;
		let err = {...errors};

		if(url===''){
			err[id]="Required field";
			setErrors(err);
			return;
		}


		let valid = false;

		if(id==='rpc-url'){
			valid = isRpcURL(url);
		}else{
			valid = isWssURL(url);
		}

		if(!valid){
			err[id]="Must be a valid url";
			setErrors(err);
			return;
		}

		if((config.CHAINS[selectedLDBChain].id==="0x13881" || config.CHAINS[selectedLDBChain].id==="0x89")){
			if(url.indexOf('quiknode')>-1){
				err[id]="Quiknode is not supported on polygon.";
				setErrors(err);
				return;
			}
		}
		err[id]="";
		setErrors(err);
	}

	const isRpcURL = (str) => {
		var urlRegex = '^(?:(?:https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		var url = new RegExp(urlRegex, 'i');
		return str.length < 2083 && url.test(str);
   }
	const isWssURL = (str) => {
		var urlRegex = '^(?:(?:wss)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		var url = new RegExp(urlRegex, 'i');
		return str.length < 2083 && url.test(str);
   }

	/* ***** visual helpers ***** */

	const SectionDividers = () => (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
		  <img src={leftTrick} style={{ maxHeight: '50px' }} />
		  <img src={rightTrick} style={{ maxHeight: '50px' }} />
		</div>
	  );

	const SectionTop = () => (
	<div
		style={{
		display: 'flex',
		justifyContent: 'space-between',
		}}
	>
		<img src={leftTopTrick} style={{ maxHeight: '50px' }} />
		<img src={rightTopTrick} style={{ maxHeight: '50px' }} />
	</div>
	);

	return (
		<div className={`community-space-outer`}>
			<div className="container">
				<div className="row">
					<div className="col-md-12">

						<SectionTop/>
						<div className="col-md-9 mx-auto mb-4">
					
							<img src={sbaIcon} className="title-icon mx-auto d-block"/>
							<h5 className="mb-1 text-center font-weight-light f14">
								LEADERBOARD ADMIN
							</h5>
							<h4 className="title mb-5">Leaderboard Admin Configurations</h4>
							<RoundedBox className="box-content" styleConfig={styleConfig} hasShadow={true}>
								<h4 className="title mb-3">About Leaderboard</h4>
								<p className="text-center mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ultrices, velit et ullamcorper rutrum, metus ligula pharetra lectus, sit amet tincidunt mi enim ut sapien. Fusce scelerisque semper ante, eu tempor est consequat mollis. Nam sodales risus et nulla placerat, a dapibus mauris suscipit. Sed velit elit, blandit a finibus eget, dignissim non felis. Maecenas sapien mauris, gravida nec faucibus gravida, pellentesque pharetra velit.</p>

							</RoundedBox>
						</div>

						<SectionDividers/>


						<div className="col-md-7 mx-auto mt-4">

							<FormInput
								id='sbaTargetChain'
								label={'Leaderboardk Admin\'s home chain'}
								type='select'
								options={config.CHAINS.map((c,i)=>{return {label:c.label, value:i}})}
								value={selectedLDBChain}
								onChange={e=>onChainSelect(e.target.value)}
								disabled={true}
							/>

							<FormInput 
								id='own-stuff'
								type='checkbox'
								label='I want to use my own RPC and WSS provider'
								onChange={(e)=>setCustomRpc(e.target.checked)}
							/>
						
						{customRpc&&<>

								{(config.CHAINS[selectedLDBChain].id==="0x13881" || config.CHAINS[selectedLDBChain].id==="0x89")&&
									<p className='mb-1 text-warning small'>Qucknode rpc and wss on polygon is not yet supported.</p>
								}


								<FormInput
									id='rpc-url'
									label='RPC Url'
									type='text'
									value={customRpcUrl}
									onChange={e=>setCustomRpcUrl(e.target.value)}
									onBlur={e=>checkUrl(e)}
									error={errors['rpc-url']}
								/>

								<FormInput
									id='wss-url'
									label='WSS Url'
									type='text'
									value={customWssUrl}
									onChange={e=>setCustomWssUrl(e.target.value)}
									onBlur={e=>checkUrl(e)}
									error={errors['wss-url']}
								/>
							</>
						}

							

						</div>


						<div className='text-center mt-5'>
							<BorderedNavigationLink
								type="submit"
								className="mx-auto"
								onClick={handleDeployLBD}
								enabled={communityId && !(customRpc && (errors['rpc-url'] || errors['wss-url'] || customRpcUrl==='' || customWssUrl===''))}>
								DEPLOY LEADERBOARD ADMIN
							</BorderedNavigationLink>
						</div>


					</div>


				</div>

				
				
				
		
			</div>
			
			<DeployInProgressModal 
				visible={deployInProgressVisible} 
				titleText={'Deploying LEADERBOARD ADMIN'} 
				message={deployMessage}
				appButtonLabel={'LEADERBOARD ADMIN'}
				destinationUrl={lbdDashboardUrl}	
			/>

		</div>
	)
}

export default ConfigLeaderboard;