import React, { useContext, useEffect, useState } from 'react'; 
import * as yup from "yup";
import { transformAll } from '@demvsystems/yup-ast';
import { getLayerInfo, getProjectInfoById, getProjectStatus, getSaleForProject, getSession, getSocialMedia, getSocialMediaTypes, getTimezones, getWalletShares } from '../Api';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Web3Ctx from '../Context/Web3Ctx';
import { getContractByAddress } from '../Utils/GetContract';
import config from '../../config';
import { BigNumber, ethers } from 'ethers';
import { SpinnerCircular } from 'spinners-react';
import useInterval from '../common/useInterval';
import { toast } from 'react-toast';
import { isoStringWithOffsetToDate } from '../Utils';
//import TZONES from '../common/TimeZones.json';
import AlertModal from '../common/AlertModal';

const ACCOUNT = 'ACCOUNT';
const CURRENT_PROJECT_ID = 'CURRENT_PROJECT_ID';
const CONNECTED_WALLETS = 'connectedWallets';

export const formState={
	FORM_EMPTY: 0,
	FORM_UNSAVED: 1,
	FORM_SAVED: 2,
	FORM_ERROR: 3
}

export const contractKeys={
	MASTER_REGISTRY:'MASTER_REGISTRY',
	LAUNCHKEY:'LAUNCHKEY',
	ZOOM:'ZOOM',
	ZOOM_VERSION_VIEW: 'ZOOM_VERSION_VIEW',
	PROJECT_FACTORY: 'PROJECT_FACTORY',
	MARKETPLACE_FACTORY: 'MARKETPLACE_FACTORY',
	COMMUNITY_LIST: 'COMMUNITY_LIST',
	VISUAL_TRAIT_REGISTRY_FACTORY: 'VISUAL_TRAIT_REGISTRY_FACTORY',
}

export const communityRegistryKeys={
	TOKEN_COUNT: 'TOKEN_COUNT',
	TOKEN_PREFIX: 'TOKEN_',
	CHILD_TOKEN_PREFIX: 'CHILD_TOKEN_',
	SALE_COUNT: 'SALE_COUNT',
	SALE_PREFIX: 'SALE_',
	TRAIT_REGISTRY_PREFIX: 'TRAIT_REGISTRY_',
	STICKERBOOK_COUNT: "STICKERBOOK_COUNT",
	FORGE_COUNT: "FORGE_COUNT",
	VISUAL_TRAIT_REGISTRY_PREFIX: "VISUAL_TRAIT_REGISTRY_",
	MARKETPLACE: 'MARKETPLACE'
}

export const DataContext = React.createContext({});



function richTextValidator(required,minLen,maxLen,message){
	return this.test("richValidator", message, function (value) {
	  const { path, createError } = this;

	  if (!value && required) {
		return createError({ path, message: `Required field` });
	  }

	  if(!value && !required){
		return true;
	  }

	  let doc = new DOMParser().parseFromString(value, 'text/html');

	  let plainText = doc.body.textContent;

	  let trimmed = plainText?.replace(/[\n\r]/g, '')
 
	 /*  if(trimmed&&value){
		  console.log('YUP VALUE',value.length,trimmed.length);
	  } */
 
	//  console.log('_________',path,value,value.length)

	  if (trimmed?.length < minLen) {
		return createError({ path, message: `Min length is ${minLen} characters` });
	  }
  
	  if (trimmed?.length > maxLen) {
		return createError({ path, message: `Max length is ${maxLen} characters` });
	  }
  
	  return true;
	});
}

yup.addMethod(yup.string, "richTextValidator", richTextValidator);
  
export const formSkeleton = {
		WELCOME_FORM:{
			form:{
				email:'',
				terms_accepted:false,
				newsletter_accepted:false
			},

			formProperties:{
				email:{
					label: 'Email (optional)',
					fieldType: 'email',
					className:'welcome-email'
				},
				terms_accepted:{
					label: 'I accept the terms and conditions.',
					fieldType: 'checkbox-custom',
					className:'terms-and-conditions'
				},
				newsletter_accepted:{
					label: 'I want to receive marketing related email.',
					fieldType: 'checkbox-custom',
					className:'terms-and-conditions',
				}
			},
			formScheme: yup.object().shape({
				email: yup.string().email('Invalid email format').max(320, 'Maximum 320 charactes.'),
				terms_accepted: yup.bool().oneOf([true],'Required field'),
				newsletter_accepted:null
			})
		},

		PROJECT_INFO:{
			form:{
				name:'',
				token_symbol:'',
				short_description:'',
				long_description:'',
				footer:'',
				small_banner:'',
				large_banner:''
			},
			formProperties:{
				name: {
					label: 'Project name',
					fieldType: 'text',
					help: 'Min 3 max 30 character.'
				},
				token_symbol:{
					label: 'Token symbol',
					fieldType: 'text',
					help: 'Min 1, max 10 character. Capital letters or/and numbers.'
				},
				short_description:{
					label: 'Intro description',
					fieldType: 'textarea',
					help: 'Min 10 max 200 character'
				},
				long_description:{
					label: 'Detailed description',
					fieldType: 'textarea',
					help: 'Min 10 max 5000 character'
				},
				footer:{
					label: 'Footer text',
					fieldType: 'textarea',
					help: 'Min 10 max 5000 character'
				},
				small_banner:{
					label: 'Small banner',
					fieldType: 'file',
					accept:{
								'image/png': ['.png'],
								'image/jpeg': ['.jpg', '.jpeg'],
							},
					help: '(png/jpg, recommended size: 750x750px)',
					dimensions:{width:750,height:750}
				},
				large_banner:{
					label: 'Large banner',
					fieldType: 'file',
					accept:{
								'image/png': ['.png'],
								'image/jpeg': ['.jpg', '.jpeg'],
							},
					value: '',
					help: '(png/jpg, recommended size: 3840x1000px)',
					dimensions:{width:3840,height:1000},
				}
			},
			formScheme: yup.object().shape({
				name: yup.string().required('Required field').min(3,'Min length is 3 characters.').max(30,'Max length is 30 characters.'),
				token_symbol:yup.string().required('Required field').min(1,'Min length is 1 character.').max(10,'Max length is 10 characters.'),
				/* short_description:yup.string().required('Required field').min(50,'Min length is 50 characters.').max(200,'Max length is 200 characters.'), */
				short_description:yup.string().richTextValidator(true,10,200),
				long_description:yup.string().richTextValidator(true,10,5000),
				footer:yup.string().richTextValidator(false,10,5000),
				small_banner:yup.mixed().required('Required field'),
				large_banner:yup.mixed().required('Required field')
			})
		},
		SOCIAL_MEDIA:{
			form: {
				Twitter:'',
				Discord:'',
				Facebook:'',
				Instagram:'',
				Web:'',
				OpenSea:''
			},
			formProperties:{	
				Twitter:{
					label: 'Twitter',
					fieldType: 'text'
				},
				Discord:{
					label: 'Discord',
					fieldType: 'text'
				},
				Facebook:{
					label: 'Facebook',
					fieldType: 'text',
				},
				Instagram:{
					label: 'Instagram',
					fieldType: 'text',
				},
				Web:{
					label: 'Web',
					fieldType: 'text',
				},
				OpenSea:{
					label: 'OpenSea',
					fieldType: 'text',
				},
			},
			formScheme: yup.object().shape({
				Twitter: yup.string().url('Must be a valid URL!').max(200,'Max length is 200 characters.'),
				Discord: yup.string().url('Must be a valid URL!').max(200,'Max length is 200 characters.'),
				Facebook: yup.string().url('Must be a valid URL!').max(200,'Max length is 200 characters.'),
				Instagram: yup.string().url('Must be a valid URL!').max(200,'Max length is 200 characters.'),
				Web: yup.string().url('Must be a valid URL!').max(200,'Max length is 200 characters.'),
				OpenSea: yup.string().url('Must be a valid URL!').max(200,'Max length is 200 characters.')
			  })
		},
		LAYERS_AND_CARDS:{
			form: {
				orientation:'',
				size:'',
				total_sides:'',
				total_nfts:'',
				vrf_shuffle: true
			},
			formProperties:{
				orientation:{
					label: 'Shape',
					fieldType: 'select',
					options: [
						{
							label:'Portrait',
							value:'portrait'
						},
						{
							label:'Square',
							value:'square'
						},
					],	
				},
				size:{
					label: 'Size',
					fieldType: 'select',
					options: {
						portrait:	[
										{
											label:'1080x1920',
											value:1
										},
										{
											label:'1080x1440',
											value:2
										},
										{
											label:'720x1280',
											value:3
										},
										{
											label:'Custom (WxH)',
											value:4
										}
									],
						square:	[
										{
											label:'2000x2000',
											value:1
										},
										{
											label:'1500x1500',
											value:2
										},
										{
											label:'1000x1000',
											value:3
										},
										{
											label:'Custom (WxW)',
											value:4
										}
									],
					}
				},
				total_sides:{
					label: 'Sides',
					fieldType: 'select',
					options: [
								{
									label:'1',
									value:1
								},
								{
									label:'2',
									value:2
								}
							]
				},
				total_nfts:{
					label: 'How many?',
					fieldType: 'number'
				},
				distribution:{
					options: [
						{
							label:'Random',
							value:'automatic'
						},
						{
							label:'Even',
							value:'even'
						},
						{
							label:'Manual',
							value:'manual'
						}
					]
				}
			},
			formScheme:yup.object().shape({
				orientation: yup.string().required('Required field'),
				size: yup.string().required('Required field'),
				total_sides: yup.number().required('Required field').min(1,'Minimum amount is 1.'),
				total_nfts: yup.number().required('Required field').max(10000,'Maximum amount is 10000.')
			})
		},
		PRESALE:{
			form:{
				active: 'yes',
				timezone: '',
				start_date: '',
				end_date:'',
				max_tokens: '',
				max_tokens_per_wallet:'',
				free_mint: false,
				eth_sale_enabled:'yes',
				eth_price:'',
				token_sale_enabled:'yes',
				token_price:'',
				token_address:'',
				whitelist:''
			},
			formProperties:{
				active: {
					label: 'I want to have private sale',
					fieldType: 'select',
					options: [
								{
									label:'Yes',
									value:'yes'
								},
								{
									label:'No',
									value:'no'
								},
								{
									label:'Custom',
									value:'custom'
								}
							]
				},
				timezone: {
					label: 'Timezone',
					fieldType: 'select'
				},
				start_date: {
					label: 'Start date',
					fieldType: 'date'
				},
				end_date:{
					label: 'End date',
					fieldType: 'date'
				},
				max_tokens: {
					label: 'Total # of Digital Collectibles',
					fieldType: 'number'
				},
				max_tokens_per_wallet:{
					label: 'Max # of Digital Collectibles / Wallet',
					fieldType: 'number'
				},
				free_mint: {
					label: 'Free mint',
					fieldType: 'checkbox'
				},
				eth_sale_enabled:{
					label: 'I want to have *nativeToken* sale',
					fieldType: 'select',
					options: [
								{
									label:'Yes',
									value:'yes'
								},
								{
									label:'No',
									value:'no'
								},
							]
				},
				eth_price:{
					label: 'Price (*nativeToken*)',
					fieldType: 'number'
				},
				token_sale_enabled:{
					label: 'I want to have Token sale',
					fieldType: 'select',
					options: [
								{
									label:'Yes',
									value:'yes'
								},
								{
									label:'No',
									value:'no'
								},
							]
				},
				token_price:{
					label: 'Token price',
					fieldType: 'number'
				},
				token_address:{
					label: 'Token address',
					fieldType: 'text'
				},
				whitelist:{
					label: 'Upload whitelist addresses',
					fieldType: 'file',
					help:'(CSV file)',
					accept:{
								'text/csv': ['.csv']
							},
						}
			},
			formScheme:yup.object().shape({
				active: null,
				timezone: yup.number().when('active',{is:'yes',then:yup.number().required('Required field')}),
				start_date: yup.date().when('active',{is:'yes',then:yup.date().required('Required field')}),
				end_date:yup.date().when('active',{is:'yes',then:yup.date().required('Required field')}),
				max_tokens: yup.number().min(0.000000001, "Amount can't be zero.").when('active',{
					is: (active) => {
						return (active!=='no');
					},
					then:yup.number().typeError('Must be a number.').required('Required field')}),
				max_tokens_per_wallet:yup.number().typeError('Must be a number.').min(0.000000001, "Amount can't be zero.").when('active',{is:'yes',then:yup.number().typeError('Must be a number.').required('Required field')}),
				free_mint: null,
				eth_sale_enabled:yup.string()
					.when('active',{is:'yes',
									then: 
									yup.string().when('free_mint',{is:false, 
													   then: 
													   yup.string().when('token_sale_enabled',{is:'no', 
																		then: 
																		yup.string().matches('yes','You need to enable at least one sale')})})}),	
	
				
				
				eth_price:yup.number().when('active',{is:'yes',then:yup.number().when('free_mint',{is:false, then: yup.number().when('eth_sale_enabled',{is:'yes',then:yup.number().typeError('Must be a number.').min(0.000000000000000001,'Amount can\'t be zero').required('Required field')})})}),
				token_sale_enabled:null,
				token_price:yup.number().when('active',{is:'yes',then:yup.number().when('free_mint',{is:false, then: yup.number().when('token_sale_enabled',{is:'yes',then:yup.number().typeError('Must be a number.').min(0.000000000000000001,'Amount can\'t be zero').required('Required field')})})}),
				token_address:yup.string().when('active',{ is: (active) => active === "yes" || active === "custom",then:yup.string().when('free_mint',{is:false, then: yup.string().when('token_sale_enabled',{is:'yes',then:yup.string().min(42,'Invalid address').max(42,'Invalid address').required('Required field')})})}),
				
				whitelist:yup.mixed().when('active',{
					is: (active) => {
						return (active!=='no');
					},
					then:yup.mixed().required('Required field')})
			})
		},
		MAIN_SALE:{

			form:{
				active: 'yes',
				timezone: '',
				start_date: '',
				end_date:'',
				eth_sale_enabled:'yes',
				eth_price:'',
				token_sale_enabled:'yes',
				token_price:'',
				token_address:'',
				lock_tokens_during_sale:false,
				max_tokens_per_wallet:'',
			},
			formProperties:{
				active: {
					label: 'I want to have public sale',
					fieldType: 'select',
					options: [
								{
									label:'Yes',
									value:'yes'
								},
								{
									label:'No',
									value:'no'
								},
							]
				},
				timezone:{
					label: 'Timezone',
					fieldType: 'select'
				},
				start_date:{
					label: 'Start date',
					fieldType: 'date'
				},
				end_date:{
					label: 'End date',
					fieldType: 'date',
				},
				eth_sale_enabled:{
					label: 'I want to have *nativeToken* sale',
					fieldType: 'select',
					options: [
								{
									label:'Yes',
									value:'yes'
								},
								{
									label:'No',
									value:'no'
								},
							]
				},
				eth_price:{
					label: 'Price (*nativeToken*)',
					fieldType: 'number'
				},
				token_sale_enabled:{
					label: 'I want to have Token sale',
					fieldType: 'select',
					options: [
								{
									label:'Yes',
									value:'yes'
								},
								{
									label:'No',
									value:'no'
								},
							]
				},
				token_price:{
					label: 'Token price',
					fieldType: 'number',
				},
				token_address:{
					label: 'Token address',
					fieldType: 'text',
				},
				max_tokens_per_wallet:{
					label: 'Max # of Digital Collectibles / Wallet',
					fieldType: 'number'
				}
			},
			formScheme:yup.object().shape({
				timezone: yup.number().when('active',{is:'yes',then:yup.number().required('Required field')}),
				start_date: yup.date().when('active',{is:'yes',then:yup.date().required('Required field')}),
				end_date:yup.date().when('active',{is:'yes',then:yup.date().required('Required field')}),
				eth_sale_enabled: yup.string().when('active',{is:'yes', then: 
					yup.string().when('token_sale_enabled',{is:'no', 
								then: 
								yup.string().matches('yes','You need to enable at least one sale')}),	
				}),
				
				eth_price:yup.number().when('active',{is:'yes',then:yup.number().when('eth_sale_enabled',{is:'yes',then:yup.number().typeError('Must be a number.').min(0.000000000000000001,'Amount can\'t be zero').required('Required field')})}),
				token_sale_enabled:null,
				token_price:yup.number().when('active',{is:'yes',then:yup.number().when('token_sale_enabled',{is:'yes',then:yup.number().typeError('Must be a number.').min(0.000000000000000001,'Amount can\'t be zero').required('Required field')})}),
				token_address:yup.string().when('active',{is:'yes',then:yup.string().when('token_sale_enabled',{is:'yes',then:yup.string().min(42,'Invalid address').max(42,'Invalid address').required('Required field')})}),
				max_tokens_per_wallet:yup.number().typeError('Must be a number.').min(1, "Amount can't be zero.").when('active',{is:'yes',then:yup.number().typeError('Must be a number.')}),
			})
		},
		DEPLOY_META:{
			form: {
				customRPC:'',
				customWSS:''
			},
			formProperties:{	
				customRPC:{
					label: 'Custom RPC Url',
					fieldType: 'text'
				},
				customWSS:{
					label: 'Custom WSS Url',
					fieldType: 'text'
				}
			},
			formScheme: yup.object().shape({
				customRPC: yup.string().url('Must be a valid URL!').max(200,'Max length is 200 characters.'),
				customWSS: yup.string().url('Must be a valid URL!').max(200,'Max length is 200 characters.')
			})
		},
	}



const DataStore =(props)=>{
	const {children} = props;
	const location = useLocation();


	const sx = {
		root: {
			display: "flex",
			minHeight: "100vh",
			height: "100%",
			weight: "100%",
			background: "#fff",
		},
		container: {
			textAlign: "center",
			margin: "auto",
		}
		};

	const initialPageData={
		PROJECT_INFO:{
			name:'Project Info',
			url: '/project-info',
			divider:false,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:true,
			completed:false
		},

		SOCIAL_MEDIA:{	
			name: 'Social Media',
			url: '/social-media',
			divider:false,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:true,
			completed:true
		},
		LAYERS_AND_CARDS:{
			name: 'Preferences, layers, images',
			url: '/layers-and-cards',
			divider:true,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:true,
			completed:false
		},
		PRESALE:{
			name: 'Private sale',
			url: '/presale',
			divider:false,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:false,
			completed:false
		},
		MAIN_SALE:{
			name: 'Public sale',
			url: '/main-sale',
			divider:false,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:false,
			completed:false
		},
		SHARE_SETUP:{
			name: 'Sales Revenue Distribution',
			url: '/share-setup',
			divider:true,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:false,
			completed:false
		},
		PUBLISH_WEBSITE:{
			name: 'Publish website',
			url: '/publish-website',
			divider:false,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:false,
			completed:false
		},
		DEPLOY_METADATA:{
			name: 'Deploy metadata',
			url: '/deploy-meta',
			divider:false,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:false,
			completed:false
		},
		DEPLOY_CONTRACTS:{
			name: 'Deploy contracts',
			url: '/deploy-contracts',
			divider:true,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:false,
			completed:false
		},
		REVEAL:{
			name: 'Reveal',
			url: '/reveal',
			divider:false,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:false,
			completed:true
		},
		LAYERS_ON_CHAIN:{
			name: 'Layers on chain',
			url: '/layers-on-chain',
			divider:false,
			state:formState.FORM_EMPTY,
			error:null,
			enabled:false,
			completed:true
		}
	}

	const [accountInfo,setAcountInfo] =useState({});
	const [currentProjectId,setCurrentProjectId] =useState(null);
	const [currentCommunityName,_setCurrentCommunityName] =useState('');

	const [isTheProjectPreviewable,setTheProjectPreviewable] = useState(false);
	const [isTheProjectPublishable,setIsTheProjectPublishable] = useState(false);
	
	//const [formData, setFormData] = useState (initialForm);
	const [pageData,setPageData] =useState(initialPageData) ;
	const [deployStatus,setDeployStatus] =useState({});

	const [layers, setLayers] =useState([]);

	const [allTimezones,setAllTimezones]=useState([]);

	const [initDone,setInitDone]=useState(false);
	const [nftsLength,setNFTsLength]=useState(0);

	const [communityId,setCommunityId]=useState(null);
	const [projectChain,setProjectChain]=useState(null);

	const [confirm, setConfirm] = useState({
		title:"",
		prompt: "",
		positiveLabel: "",
		negativeLabel: "",
		isOpen: false,
		proceed: null,
		cancel: null
	  });
	
	const [communityAddresses, setCommunityAddresses] = useState(null);
	const [communityRegistry, setCommunityRegistry] = useState(null);

	const {getProvider,getReadOnlyProvider} = useContext(Web3Ctx);
	const [previewSessionUrl,setPreviewSessionUrl]=useState(null);
	const [previewSessionPollInterval,setPreviewSessionPollInterval]=useState(null);
	const pollInterval = 15*60*1000;

	const [projectInfoDataFromBackend,setProjectInfoDataFromBackend] = useState(null);
	const [projectInfoData,setProjectInfoData] = useState(null);
	const [socialData,setSocialData] = useState(null);
	const [socialMediaTypes,setSocialMediaTypes] = useState(null);
	const [layerInfoData,setLayerInfoData] = useState(null);
	const [preSaleData,setPreSaleData] = useState(null);
	const [mainSaleData,setMainSaleData] = useState(null);
	const [walletShareData,setWalletShareData] = useState(null);

	const [helpModalVisible,setHelpModalVisible] = useState(false);


	const setCurrentCommunityName = (name) => {
		sessionStorage.setItem('ccn',name);
		_setCurrentCommunityName(name);
	};

	const getCurrentCommunityRegistryData = async (communityId, _provider) => {

		console.log('TAS: getCurrentCommunityRegistryData',communityId, _provider);

		let cr = {};		
		// init list contract

		let galaxisRegistryOnTargetChain = await getContractByAddress('GalaxisRegistry', config.GALAXIS_REGISTRY, _provider).catch(e=>console.log);

		console.log('COMMGR',galaxisRegistryOnTargetChain);

		let communityListAddress = await galaxisRegistryOnTargetChain.getRegistryAddress(contractKeys.COMMUNITY_LIST).catch(e=>console.log);

		let communityListContract = await getContractByAddress('CommunityList', communityListAddress, _provider);

		console.log('TAS: communityListContract (address, contract)',communityListAddress,communityListContract);
		
		
		// Does the current community have an entry in the community list?
		let communityRegistryEntry = await communityListContract.communities(Number(communityId)).catch(e=>console.log);
		
		console.log('TAS: communityRegistryEntry',communityRegistryEntry);
		
		if(communityRegistryEntry && communityRegistryEntry.registry === ethers.constants.AddressZero ){
			// communityRegistryEntry not found.. so it's not deployed
			cr["deployed"] = false;
			return cr;
		}
		
		cr["deployed"] = true;

		// We can now load our asset contracts from our Community Registry
		// Tokens / Sales / Trait Registries / Visual Layers / Forges / etc

		let thisCommunityRegistry = await getContractByAddress('CommunityRegistry', communityRegistryEntry.registry, _provider);
		
		console.log('TAS: thisCommunityRegistry (address)',communityRegistryEntry.registry)
		// !!! ZOOM: since this needs to be rewritten to support ZOOM, we load indexes first

		// load normal keys
		cr[communityRegistryKeys.MARKETPLACE] = await thisCommunityRegistry.getRegistryAddress(communityRegistryKeys.MARKETPLACE).catch(e=>console.log);

		// find out how many token, sale and trait registry contracts we have
		cr[communityRegistryKeys.TOKEN_COUNT] = await thisCommunityRegistry.getRegistryUINT(communityRegistryKeys.TOKEN_COUNT).catch(e=>console.log);
		cr[communityRegistryKeys.SALE_COUNT] = await thisCommunityRegistry.getRegistryUINT(communityRegistryKeys.SALE_COUNT).catch(e=>console.log);

		// load stickerbook and forge counts
		cr[communityRegistryKeys.STICKERBOOK_COUNT] = await thisCommunityRegistry.getRegistryUINT(communityRegistryKeys.STICKERBOOK_COUNT).catch(e=>console.log);
		cr[communityRegistryKeys.FORGE_COUNT] = await thisCommunityRegistry.getRegistryUINT(communityRegistryKeys.FORGE_COUNT).catch(e=>console.log);
		
		// TOKEN_COUNT represents the MAXIMUM number of tokens we have on current chain 
		// ( rerun this with a higher TOKEN_COUNT if you found a higher value on a different chain! )
		//
		// load each of them
		// might be master token => TOKEN_ + i
		// might be child token => CHILD_TOKEN_ + i
		// might HAVE traits => TRAIT_REGISTRY_ + i
		cr.data = {};
		cr.data.tokens = [];

		for(let i = 1; i <= cr[communityRegistryKeys.TOKEN_COUNT].toNumber(); i++) {
			
			const token_key = communityRegistryKeys.TOKEN_PREFIX+i
			const child_token_key = communityRegistryKeys.CHILD_TOKEN_PREFIX+i
			const trait_registry_key = communityRegistryKeys.TRAIT_REGISTRY_PREFIX+i
			const this_tokensale_count_key = communityRegistryKeys.SALE_PREFIX+i+"_COUNT";

			cr[token_key] = await thisCommunityRegistry.getRegistryAddress(token_key).catch(e=>console.log);
			cr[child_token_key] = await thisCommunityRegistry.getRegistryAddress(child_token_key).catch(e=>console.log);
			cr[trait_registry_key] = await thisCommunityRegistry.getRegistryAddress(trait_registry_key).catch(e=>console.log);

			// Load SALE_{TOKEN_ID}_COUNT
			cr[this_tokensale_count_key] = await thisCommunityRegistry.getRegistryUINT(
				this_tokensale_count_key
			).catch(e=>console.log);


			let tokenType = "master";
			if(cr[token_key] === ethers.constants.AddressZero && cr[child_token_key] != ethers.constants.AddressZero) {
				tokenType = "child";
			}

			let traitRegistryDeployed = false;
			if(cr[trait_registry_key] !== ethers.constants.AddressZero) {
				traitRegistryDeployed = true;
			}

			const _token = {
				tokenId: i,
				tokenType: tokenType,
				tokenKey: token_key,
				tokenAddress: cr[token_key],
				tokenChildKey: child_token_key,
				tokenChildAddress: cr[child_token_key],
				tokenSaleCountKey: this_tokensale_count_key,
				tokenSaleCount: cr[this_tokensale_count_key].toNumber(),
				sales: [],
				traitRegistryKey: trait_registry_key,
				traitRegistryAddress: cr[trait_registry_key],
				traitRegistryDeployed: traitRegistryDeployed,
				visualTraitRegistryAddress: ethers.constants.AddressZero,
				visualTraitRegistryTraitId: 0,
				visualTraitRegistryDeployed: false
			}

			if(cr[this_tokensale_count_key].toNumber() > 0) {
				// new sale indexing
				for(let j = 1; j <= cr[this_tokensale_count_key].toNumber(); j++) {
					const sale_key = communityRegistryKeys.SALE_PREFIX+i+"_"+j;
					cr[sale_key] = await thisCommunityRegistry.getRegistryAddress(sale_key).catch(e=>console.log);

					const _sale = {
						saleId: j,
						saleKey: sale_key,
						saleAddress: cr[sale_key]
					}

					_token.sales[j] = _sale;
				}
			}

			cr.data.tokens.push(_token);
		}

		// SALE_COUNT represents the MAXIMUM number of sales we have on this chain
		// based on how many tokens we have, we can then iterate through the count
		// and find each token's sales
		// uint: SALE_COUNT => Total number of sales on this chain
		// uint: SALE_{TOKEN_ID}_COUNT => Total number of sales on this for this token
		// address: SALE_{TOKEN_ID}_{SALE_INCREMENT} => Address of sale, 
		// 
		// i.e. 
		// Token 1 has 3 sales
		// Token 2 has no sales
		// Token 3 has 1 sale
		//
		// - TOKEN_COUNT = 3
		// - SALE_COUNT = 4
		// - SALE_1_COUNT = 3
		// - SALE_1_1 / SALE_1_2 / SALE_1_3 = address
		// - SALE_2_COUNT = 0
		// - SALE_2_1 = null
		// - SALE_3_COUNT = 1
		// - SALE_3_1 = address
		// 
		// !!! we now load these in token call
		//

		// TODO: disable this once new indexing is released
		// cr.data.sales = [];
		for(let i = 1; i <= cr[communityRegistryKeys.SALE_COUNT].toNumber(); i++) {
			const sale_key = communityRegistryKeys.SALE_PREFIX+i
			cr[sale_key] = await thisCommunityRegistry.getRegistryAddress(sale_key).catch(e=>console.log);

			const _sale = {
				saleId: i,
				saleKey: sale_key,
				saleAddress: cr[sale_key]
			}
			cr.data.tokens[i-1].sales[0] = _sale;
		}
		// TODO: disable this once new indexing is released


		// once we know we have trait registries, we need to load our visual trait registry
		for(let i = 1; i <= cr[communityRegistryKeys.TOKEN_COUNT].toNumber(); i++) {
			const thisTokensTraitRegistryAddress = cr[communityRegistryKeys.TRAIT_REGISTRY_PREFIX+i];

			if(thisTokensTraitRegistryAddress !== ethers.constants.AddressZero ){
				let thisTraitRegistry = await getContractByAddress('TraitRegistry', thisTokensTraitRegistryAddress, _provider);
				const existingTraits = await thisTraitRegistry.getTraits();
				if(existingTraits.length > 0) {
					for(let j = 0; j < existingTraits.length; j++) {
						if(existingTraits[j].traitType === 104) {
							// make sure not empty
							if(existingTraits[j].storageImplementer !== ethers.constants.AddressZero) {
								cr[communityRegistryKeys.VISUAL_TRAIT_REGISTRY_PREFIX+i] = existingTraits[j].storageImplementer;

								const _tokenKeyInArray = i - 1;
								cr.data.tokens[_tokenKeyInArray].VisualTraitRegistryAddress = cr[communityRegistryKeys.VISUAL_TRAIT_REGISTRY_PREFIX+i] ;
								cr.data.tokens[_tokenKeyInArray].visualTraitRegistryDeployed = true;
							}
						}
					}
				}

			} else {
				// trait registry for this does not exist.
				// visual trait registry cannot exist in this case.
			}

		}
		return cr;
	};


	

	useEffect(() => {

		const loadRegistryData = async()=>{
			console.log('loading registry data',communityId,projectInfoDataFromBackend)

			if(communityId !== null) {

				//console.log('ci,pd',communityId,projectInfoData);

				const _communityId = parseInt(communityId.toString());
				if(_communityId > 0) {
					//let provider = getProvider(config.DEPLOYED_CHAIN_ID);
					let provider = getProvider(projectChain);
					let cr = await getCurrentCommunityRegistryData(_communityId, provider).catch(e=>console.log);
					// 
					// for some reason we expect to only have 1 collection hash
					// or maybe it's somehow tied to a token id somewhere.. not sure

					if(projectInfoDataFromBackend){
						cr.collection_hash = projectInfoDataFromBackend.collection_hash;
						cr.specs = projectInfoDataFromBackend.specs;
						cr.metadata_url = projectInfoDataFromBackend.metadata_url;
					}


					console.warn('**********COMMUNITY REGISTRY',cr);
					setCommunityRegistry(cr);
				}
			}
		}

		loadRegistryData();
		
	},[communityId , projectInfoDataFromBackend]);


	useEffect(()=>{
		if(currentProjectId && communityRegistry){
			console.log('---=== checking deploy status ===---');
			checkDeployStatus(currentProjectId);
		}
	},[currentProjectId, communityRegistry])
	

	useEffect(()=>{
		
		const initAll = async()=>{
			//Getting addresses from Galaxis registry
			let ccn = sessionStorage.getItem('ccn');
			if(ccn){
				_setCurrentCommunityName(ccn);
			}

			//mainnet or goerli
			let provider = getProvider(config.DEPLOYED_CHAIN_ID);
			let galaxisRegistry = await getContractByAddress('GalaxisRegistry',config.GALAXIS_REGISTRY,provider);
			

			if(galaxisRegistry){
				//console.log('incoming addresses');

				let masterRegistryAddress = await galaxisRegistry.getRegistryAddress(contractKeys.MASTER_REGISTRY).catch(e=>console.log);
				let launchKeyAddress = await galaxisRegistry.getRegistryAddress(contractKeys.LAUNCHKEY).catch(e=>console.log);
				let zoomAddress = await galaxisRegistry.getRegistryAddress(contractKeys.ZOOM).catch(e=>console.log);

				let ca = {};
				ca[contractKeys.MASTER_REGISTRY]=masterRegistryAddress;
				ca[contractKeys.LAUNCHKEY]=launchKeyAddress;
				ca[contractKeys.ZOOM]=zoomAddress;
			

				console.log('CA',ca);
				setCommunityAddresses(ca);
				
			}else{
				console.log('init done 1, registry not found');
				setInitDone(true);
				return;
			}

			

			//check the local storage data
			if(localStorage){
				//console.log('get account');
				const account = localStorage.getItem(ACCOUNT);
				if(account){
					try{
						const acc = JSON.parse(account);
						setAcountInfo(acc);
					}catch (e){
						console.log('PARSE ERROR',e);
					}
				}else{
					console.log('init done 2');
					setInitDone(true); //not logged in
					return;
				}
				
				const currentId = localStorage.getItem(CURRENT_PROJECT_ID);
				//console.log('currentId',currentId, initDone);
				if(currentId!=null){
					//console.log('SET!!')
					setCurrentProjectId(currentId);
				}

				//get all available data from backend (to populate form values and set progress info in the sidebar)
				if(currentId !==null && location.pathname!=='/dashboard' && location.pathname!=='/publish'  /* && location.pathname!=='/mission-control' */){
					console.log('GETTING ALL DATA');
					getAllData(currentId,ccn);
					//checkDeployStatus(currentId);
				}else{
					
					console.log('init done 3');
					setInitDone(true);
				}
			}
		}



		//console.log('DS mounted');
		setInitDone(false);
		initAll();


		return () => {
          //  console.log('DS UNMOUNTED');
        }


	},[]);


	useEffect(()=>{

		let publishable = true;
		Object.keys(pageData).forEach(p=>{
			if(pageData[p].name!=='Social Media' && pageData[p].completed === false){
				publishable=false;
			}
		});
		setIsTheProjectPublishable(publishable);
	},[pageData]);

	useEffect(()=>{
		if(communityId){
			getPreviewSessionUrl(communityId);
			setPreviewSessionPollInterval(pollInterval);
		}
	},[communityId]);

	const getPreviewSessionUrl = async () => {
		const res = await getSession(communityId).catch((e) => console.log);
		 if (res.success) {
		   if (res.data) {
			setPreviewSessionUrl(res.data.url);
		//	console.log('SESSION URL',res.data.url);
			}
		 } else {
		   console.log('SESSION NOT FOUND.');
		 }
	   };
	
	useInterval(
		getPreviewSessionUrl,
		previewSessionPollInterval
	);


	const refreshCommunityData = async()=> {

		//TODO: multichain?
		//let provider = getProvider(config.DEPLOYED_CHAIN_ID);
		let provider = getProvider(projectChain);
		let cr = await getCurrentCommunityRegistryData(communityId,provider).catch(e=>console.log);
		
		if(cr){
			setCommunityRegistry(cr);
			checkDeployStatus(currentProjectId,cr)
		}
		return cr;
	}

	const getChainSpecificCommunityAddresses = async (communityChain) =>{
			let projectProvider = getProvider(communityChain);
			let galaxisRegistryOnProjectChain = await getContractByAddress('GalaxisRegistry',config.GALAXIS_REGISTRY,projectProvider);

			let zoomAddressOnProjectChain = await galaxisRegistryOnProjectChain.getRegistryAddress(contractKeys.ZOOM).catch(e=>console.log);
			let zoomVersionViewAddress = await galaxisRegistryOnProjectChain.getRegistryAddress(contractKeys.ZOOM_VERSION_VIEW).catch(e=>console.log);
			let clAddr = await galaxisRegistryOnProjectChain.getRegistryAddress(contractKeys.COMMUNITY_LIST).catch(e=>console.log);
			let VTRddr = await galaxisRegistryOnProjectChain.getRegistryAddress(contractKeys.VISUAL_TRAIT_REGISTRY_FACTORY).catch(e=>console.log);

			let ca = {...communityAddresses};
			ca['ZOOM_ON_PROJECT_CHAIN']=zoomAddressOnProjectChain;
			ca[contractKeys.ZOOM_VERSION_VIEW]=zoomVersionViewAddress;
			ca[contractKeys.COMMUNITY_LIST]=clAddr;
			ca[contractKeys.VISUAL_TRAIT_REGISTRY_FACTORY]=VTRddr;

			setCommunityAddresses(ca);
			return ca;
	}

	const checkDeployStatus = async(projectId, cr)=>{

		//console.log('++++layerInfoData',layerInfoData);
		
		let _communityRegistry = cr?cr:communityRegistry;

		console.log('DEPLOY STATUS CALLED', projectId, _communityRegistry);
		//let projectInfo = await getProjectInfoById(projectId).catch(e=>console.log('project info',e));
		console.log('getProjectInfoById',projectInfoData);

		let site = await getProjectStatus(projectId).catch(e=>console.log('site deploy status',e));


		console.log('getProjectStatus',site);


		let ds = {	siteStatus:	{
									reason:'',
									status:{
										name:'Unpublished'
									}
								},
						
					metaStatus:	{
									reason:'',
									status:{
										name:'Unpublished'
									}
								},
					metaDataUrl:null,
					isFactoryReady:false
		};

		if(site.success && projectInfoData){
	

			const siteFixed = localStorage.getItem('SIF'+site.data.community_id); 
			const metaFixed = localStorage.getItem('MIF'+site.data.community_id); 

			//console.log ('is site fixed',siteFixed);

			if(site.data.last_website_status && !siteFixed){
				ds.siteStatus = site.data.last_website_status;
			}
			if(site.data.last_metadata_status && !metaFixed){
				ds.metaStatus = site.data.last_metadata_status;
			}

			ds.metaDataUrl = _communityRegistry?.metadata_url;

			if(_communityRegistry?.SALE_COUNT>0){
				ds.contracts = {
					sale: _communityRegistry.SALE_1,
					token: _communityRegistry.TOKEN_1
				}
				ds.isFactoryReady = true;
			}else{
				ds.contracts = {
					sale: null,
					token:null
				}
				ds.isFactoryReady = false;
			}
		}

		//console.log('set ds',ds)
		//set sidebar status
		let tempPageData = {...pageData};
		//let tempPageData = {...initialPageData};
		
		let contractDeployEnabled = true;
		if(ds.siteStatus && ds.siteStatus.status.name==='Approved'){
			tempPageData['PUBLISH_WEBSITE'].state=formState.FORM_SAVED;
			tempPageData['PUBLISH_WEBSITE'].completed=true;
		}else{
			contractDeployEnabled=false;
		}

		if(ds.metaStatus && ds.metaStatus.status.name==='Approved' && ds.metaDataUrl!==null){
			tempPageData['DEPLOY_METADATA'].state=formState.FORM_SAVED;
			tempPageData['DEPLOY_METADATA'].completed=true;
		}else{
			contractDeployEnabled=false;
		}

		if(!tempPageData['PRESALE'].completed || !tempPageData['MAIN_SALE'].completed || !tempPageData['SHARE_SETUP'].completed ){
			contractDeployEnabled=false;
		}

		if(ds.contracts && ds.contracts.sale ){
			tempPageData['DEPLOY_CONTRACTS'].state=formState.FORM_SAVED;
			tempPageData['DEPLOY_CONTRACTS'].completed=true;
			tempPageData['REVEAL'].enabled=!!(layerInfoData?.vrf_shuffle);
		}

		//console.log('vl????',tempPageData['REVEAL'].enabled,(!tempPageData['REVEAL'].enabled && !!(layerInfoData.vrf_shuffle)===false));

		if(tempPageData['REVEAL'].enabled || (!tempPageData['REVEAL'].enabled && !!(layerInfoData?.vrf_shuffle)===false) ) {
			tempPageData['LAYERS_ON_CHAIN'].enabled=true;
		}

		if(contractDeployEnabled){
			tempPageData['DEPLOY_CONTRACTS'].enabled=true;
		}

		setPageData(tempPageData);
		setDeployStatus(ds);

		return ds;
	}


	const setPageErrors = ()=>{
		if(deployStatus){
			if(deployStatus.siteStatus){
				if(deployStatus.siteStatus.status.name==='Rejected'){
					setPageDeployError('PROJECT_INFO',deployStatus.siteStatus.reason);
				}else{
					setPageDeployError('PROJECT_INFO','');
				}
			}

			if(deployStatus.metaStatus){
				if(deployStatus.metaStatus.status.name==='Rejected'){
					setPageDeployError('LAYERS_AND_CARDS',deployStatus.metaStatus.reason);
				}else{
					setPageDeployError('LAYERS_AND_CARDS','');
				}
			}

		}
	}


	const clearPublishWebsiteError = ()=> {
		if(deployStatus){
			let tmpStatus = {...deployStatus};
			tmpStatus.siteStatus.status.name='Unpublished';
			setPageDeployError('PROJECT_INFO','');
			localStorage.setItem('SIF'+communityId,true);
			setDeployStatus(tmpStatus);
		}
	}

	const clearMetadataError = ()=> {
		if(deployStatus){
			let tmpStatus = {...deployStatus};
			tmpStatus.metaStatus.status.name='Unpublished';
			setPageDeployError('LAYERS_AND_CARDS','');
			localStorage.setItem('MIF'+communityId,true);
			setDeployStatus(tmpStatus);
		}
	}



	const getAllData = async (projectId,communityName) => {
		console.log('PID',projectId, !projectId, typeof projectId,communityName);
		if(projectId===null){
			return;
		}


		/* const getAllSales = async(projectId)=>{
			const saleTypes = await getSaleTypes().catch(e=>console.log);
			console.log(saleTypes);
			const promises = [];
			if(saleTypes.success){
				for(let i=0;i<saleTypes.data.length;i++){
					promises.push(getSaleForProject(projectId,saleTypes.data[i].id));
				}
			}
			return promises;
		}

		const salePromises = await getAllSales(projectId); */

		let promises = [
						getProjectInfoById(projectId),
						getSocialMedia(projectId),
						getLayerInfo(projectId),
						getWalletShares(projectId),
						getSaleForProject(projectId,1), //PreSale
						getSaleForProject(projectId,3), //MainSale
						getSaleForProject(projectId,4), //CustomPresale
						getTimezones(),
						getProjectStatus(projectId),
						getSocialMediaTypes()
						];

		Promise.all(promises)
		.then(res=>{
			parseData(res,communityName);
		});
	}

	const parseData = async (res,communityName) =>{

		console.log('PARSE DATA============')


		const [info, social, nfts, shares, presale, mainSale, customPresale, tZones, pStatus, socMediaTypes] = res;

		let tempPageData = {...pageData};
		//let tempPageData = {...initialPageData};


	//	console.log(tZones);
	//	console.log(TZONES);

	//	let tz=TZONES;
	//	setAllTimezones(tz);

		let tz=[];

	 	if(tZones.success){
			Object.keys(tZones.data).map(z=>{
				tz.push({label:tZones.data[z].name, value:Number(z)+1,offset:tZones.data[z].offset})
			});
			setAllTimezones(tz);
		}else{
			toast.error('Network error. Timezone info not found.');
			return;
		}

		if(pStatus.success){
			//console.log('community id',pStatus)
			setCommunityId(BigNumber.from(pStatus.data.community_id));
			setProjectChain(pStatus.data.chain_id ? pStatus.data.chain_id : config.DEPLOYED_CHAIN_ID);
		}

		if(nfts.data && nfts.data.valid_nfts_generated){
			setNFTsLengthFunc(nfts.data.total_nfts?nfts.data.total_nfts:0);
			tempPageData['LAYERS_AND_CARDS'].state= formState.FORM_SAVED;
			tempPageData['LAYERS_AND_CARDS'].completed=true;
			tempPageData['PRESALE'].enabled=true;
			tempPageData['MAIN_SALE'].enabled=true;
			tempPageData['SHARE_SETUP'].enabled=true;
			tempPageData['DEPLOY_METADATA'].enabled=true;
		}else{
			setNFTsLengthFunc(0);
		}

		if(info.data){

			console.log('info data',info.data);

			let pi = {...info.data,name:info.data.name!==''?info.data.name:communityName};
			setProjectInfoData(pi);
			setProjectInfoDataFromBackend(pi);
			setTheProjectPreviewable(true);
			tempPageData['PUBLISH_WEBSITE'].enabled=true;
			tempPageData['PROJECT_INFO'].state= formState.FORM_SAVED;
			tempPageData['PROJECT_INFO'].completed=true;

		}else{
		//	console.log('----CoMMunityNAmE',communityName);
		//	console.log('DS projectifo data (empty)',{...formSkeleton['PROJECT_INFO'].form,name:communityName,id:null},currentCommunityName.current);
			

			//hck-hck-hck :/

			let fdata = {...formSkeleton['PROJECT_INFO'].form,name:communityName,id:null}
			fdata.long_description = '<p></p>'
			fdata.footer = '<p></p>'
		
		
			setProjectInfoData(fdata);
			//setProjectInfoData({...formSkeleton['PROJECT_INFO'].form,name:communityName,id:null});
			setTheProjectPreviewable(false);
		}

		if(socMediaTypes.data){
			setSocialMediaTypes(socMediaTypes.data);

			if(social.data){
				//console.log('socdata',social.data)
					let tmpForm = {};
					let hasData = false;
					Object.keys(social.data).forEach(item => {
						//console.log('item',item);
						let typeObject = social.data[item].type_id;
						let type =  socMediaTypes.data.find((key) => key.id === typeObject);//getKeyByValue(socMediaTypes,typeObject);
						tmpForm[type.name]=social.data[item].url;
						//console.log('url',social.data[item].url)
						if(social.data[item].url!==''){
							hasData=true;
						}
					});
	
					
				if(hasData){
					tempPageData['SOCIAL_MEDIA'].state = formState.FORM_SAVED;
				}else{
					tempPageData['SOCIAL_MEDIA'].state = formState.FORM_EMPTY;
				}
	
				tempPageData['SOCIAL_MEDIA'].completed = true;
				setSocialData({data:tmpForm,items:social.data.length});
			}else{
				setSocialData({data:{...formSkeleton['SOCIAL_MEDIA'].form},items:0});
			}






		}else{
			setSocialMediaTypes(null)
		}





		

		if(nfts.data){
			console.log('LaYerInfo Data:',nfts.data);
			setLayerInfoData({...nfts.data});
		}else{
			setLayerInfoData(null);
		}

		//console.log('custom presale',customPresale);

		if(presale.data?.length>0){
			//console.log('PRESALE DATA',presale.data[0]);
			let saleObj = {...presale.data[0]};
			tempPageData['PRESALE'].state = formState.FORM_SAVED;
			tempPageData['PRESALE'].completed = true;
			if(saleObj.active===false){
				setPreSaleData({...formSkeleton['PRESALE'].form,active:'no'});
			}else{
					//update dateslocal with timezone's 
					if(tz.length>0){
						let timezoneItem = tz.find(i=>{
							if(i.label===saleObj['timezone']){
								return i;
							}
						});
		
						if(timezoneItem){
							saleObj['timezone']=timezoneItem.value;
							saleObj['start_date']=isoStringWithOffsetToDate(saleObj['start_date'],tz[timezoneItem.value-1].offset);
							saleObj['end_date']=isoStringWithOffsetToDate(saleObj['end_date'],tz[timezoneItem.value-1].offset);
						}
					}else{
						console.log('TIMEZONES NOT FOUND.');
					}
					Object.keys(formSkeleton['PRESALE'].formProperties).forEach((i) => {
						const field = i;
						const type = formSkeleton['PRESALE'].formProperties[field].fieldType;
						const value = (field==='active'||field==='eth_sale_enabled'||field==='token_sale_enabled')?(saleObj[field]?'yes':'no'):
						type==='date'?(saleObj[field]?
						new Date(saleObj[field]):''):
						saleObj[field]?saleObj[field]:formSkeleton['PRESALE'].form[field];
		
						saleObj[field]=value;
					});
		
		
					if(saleObj.whitelist){
						const response = await fetch(
							saleObj.whitelist,
							{
								method: 'GET'
							}
						).catch(e=>console.log);
		
		
						//console.log('fetch response',typeof response,response);
		
						if(response && response.json){
							const data = await response.json();
							//console.log('JSON', data, Object.keys(data));
		
							if(data && Object.keys(data).length>0){
							//	console.log('JSON', data, Object.keys(data));
							saleObj['whitelist']=Object.keys(data);
							}
						}else{
							saleObj['whitelist']=null;
						}
					}
					
					setPreSaleData({...saleObj});
			}
		}else{
			if(customPresale.data?.length>0){

				tempPageData['PRESALE'].state = formState.FORM_SAVED;
				tempPageData['PRESALE'].completed = true;

				let saleObj = {...customPresale.data[0]};

			//	console.log()

				if(saleObj.whitelist){
					const response = await fetch(
						saleObj.whitelist,
						{
							method: 'GET'
						}
					).catch(e=>console.log);
	
	
					//console.log('custom fetch response',typeof response,response);
	
					if(response && response.json){
						const data = await response.json();
						//console.log('CUSTOM JSON!!!!', data, Object.keys(data));
	
						if(data &&  Object.keys(data).length>0){
							//console.log('CUSTOM JSON', data, Object.keys(data));
							let wl=[];
							Object.keys(data).forEach(item => {
								let customParams = [];

								customParams.push(data[item].params[4]);
								customParams.push(data[item].params[3]);
								customParams.push(BigNumber.from(data[item].params[7]));
								customParams.push(BigNumber.from(data[item].params[8]));
								customParams.push(data[item].params[5]);
								customParams.push(data[item].params[6]);

								wl.push(customParams);
							})


							//console.log('Parsed wlist',wl);
							saleObj['whitelist']=wl;
						}
					}else{
						saleObj['whitelist']=null;
					}
				}

				saleObj['start_date']= new Date(saleObj['start_date']);
				saleObj['end_date']= new Date(saleObj['end_date']);
						
				//console.log('CUSTOM SALE!!',saleObj)
				setPreSaleData({...saleObj});
			}else{
				setPreSaleData({...formSkeleton['PRESALE'].form});
			}

		}

		if(mainSale.data?.length>0){
			//console.log('PRESALE DATA',presale.data[0]);
			let saleObj = {...mainSale.data[0]};
			tempPageData['MAIN_SALE'].state = formState.FORM_SAVED;
			tempPageData['MAIN_SALE'].completed = true;

			if(saleObj.active===false){
				setMainSaleData({...formSkeleton['MAIN_SALE'].form,active:'no'});
			}else{
					//update dateslocal with timezone's 
					if(tz.length>0){
						let timezoneItem = tz.find(i=>{
							if(i.label===saleObj['timezone']){
								return i;
							}
						});
		
						if(timezoneItem){
							saleObj['timezone']=timezoneItem.value;
							saleObj['start_date']=isoStringWithOffsetToDate(saleObj['start_date'],tz[timezoneItem.value-1].offset);
							saleObj['end_date']=isoStringWithOffsetToDate(saleObj['end_date'],tz[timezoneItem.value-1].offset);
						}else{
							console.log('unknown timezone');
						}
		
					}else{
						console.log('TIMEZONES NOT FOUND.');
					}
		
				
		
					Object.keys(formSkeleton['MAIN_SALE'].formProperties).forEach((i) => {
						const field = i;
						const type = formSkeleton['MAIN_SALE'].formProperties[field].fieldType;
						const value = (field==='active'||field==='eth_sale_enabled'||field==='token_sale_enabled')?(saleObj[field]?'yes':'no'):
						type==='date'?(saleObj[field]?
						new Date(saleObj[field]):''):
						saleObj[field]?saleObj[field]:formSkeleton['MAIN_SALE'].form[field];
		
						saleObj[field]=value;
					});
					//console.log('MainSaleData:', saleObj);
					setMainSaleData({...saleObj});
			}
		}else{

			setMainSaleData({...formSkeleton['MAIN_SALE'].form});
		}


		if(shares.data && shares.data.length>0 ){
			//console.log('WALLET SHARES Data:',shares.data);
			tempPageData['SHARE_SETUP'].state = formState.FORM_SAVED;
			tempPageData['SHARE_SETUP'].completed = true;
			setWalletShareData(shares.data);
		}else{
			setWalletShareData(null);
		}

		setPageData(tempPageData);

		console.log('ALL DATA LOADED');
		console.log('init done 4');
		setInitDone(true);

	};

	const updateFormV2 = (page,key,value)=>{

		switch (page){
		
				case 'PROJECT_INFO':
					{
						let tempProjectInfoData = {...projectInfoData};
						tempProjectInfoData[key]=value;
						setProjectInfoData(tempProjectInfoData);
					//	console.log('tempProjectInfoData',tempProjectInfoData);
						return;
					}
				case 'SOCIAL_MEDIA':
					{
						let tempSocData = {...socialData.data};
						//console.log('tempSocData',tempSocData)
						tempSocData[key]=value;
						setSocialData({...socialData,data:tempSocData});
						return;
					}
				case 'LAYERS_AND_CARDS':
					{
						let tempLayerInfoData = {};

						console	.log(key,value);
						
						if(key===null){
							tempLayerInfoData={...layerInfoData,...value}
						}else{
							tempLayerInfoData={...layerInfoData};
							tempLayerInfoData[key]=value;
						}

						//console.log('update layer info data',tempLayerInfoData);

						setLayerInfoData(tempLayerInfoData)

						return;
					}
				case 'PRESALE':
					{
						let tempPreSaleData = {};
						if(key===null){
							tempPreSaleData={...preSaleData,...value}
						}else{
							tempPreSaleData = {...preSaleData};
							//console.log('val',key,value, typeof value);
							tempPreSaleData[key]=value;
						}

						//console.log(tempPreSaleData);
						setPreSaleData(tempPreSaleData);


						return;
					}
				case 'MAIN_SALE':
					{
						//console.log(mainSaleData)
						let tempMainSaleData = {};
						if(key===null){
							tempMainSaleData={...mainSaleData,...value}
						}else{
							tempMainSaleData = {...mainSaleData};
							//console.log('val',key,value, typeof value);
							tempMainSaleData[key]=value;
						}

						//console.log(tempMainSaleData);
						setMainSaleData(tempMainSaleData);


						return;
					}
				case 'SHARE_SETUP':
					{
						//console.log('share update',value);

						let tempData = [];
						if(value.length>0){
							value.forEach(element => {
								tempData.push({address:element.address.value, share:element.share.value*100});
							});
							setWalletShareData(tempData);
						}
						return;
					}

		}
		
	}

	const updatePageState = (page,state)=>{
		//console.log('updatePageState',page,state);
		let tempPageData = {...pageData};
		let p = tempPageData[page];
		p.state = state;
		if(state===formState.FORM_SAVED){
			p.completed=true;
		}else{
			if(state===formState.FORM_EMPTY){
				p.completed=false;	
			}
		}

		if(tempPageData['PROJECT_INFO'].completed){
			tempPageData['PUBLISH_WEBSITE'].enabled=true;
		}

		if(tempPageData['LAYERS_AND_CARDS'].completed){
			tempPageData['PRESALE'].enabled = true;
			tempPageData['MAIN_SALE'].enabled = true;
			tempPageData['SHARE_SETUP'].enabled = true;
			tempPageData['DEPLOY_METADATA'].enabled = true;
		}else{
			tempPageData['PRESALE'].enabled = false;
			tempPageData['MAIN_SALE'].enabled = false;
			tempPageData['SHARE_SETUP'].enabled = false;
			tempPageData['DEPLOY_METADATA'].enabled = false;
		}

		//console.log('contracts enabled',tempPageData['PRESALE'].completed && tempPageData['MAIN_SALE'].completed && tempPageData['SHARE_SETUP'].completed && tempPageData['DEPLOY_METADATA'].completed)
		//console.log('contracts enabled details',tempPageData['PRESALE'].completed , tempPageData['MAIN_SALE'].completed , tempPageData['SHARE_SETUP'].completed , tempPageData['DEPLOY_METADATA'].completed)

		if(tempPageData['PRESALE'].completed && tempPageData['MAIN_SALE'].completed && tempPageData['SHARE_SETUP'].completed && tempPageData['DEPLOY_METADATA'].completed && tempPageData['PUBLISH_WEBSITE'].completed){
			tempPageData['DEPLOY_CONTRACTS'].enabled = true;
		}

		if(tempPageData['DEPLOY_CONTRACTS'].completed ){
			tempPageData['REVEAL'].enabled = !!(layerInfoData.vrf_shufffle);
		}

		setPageData(tempPageData);
	}

	const setPageDeployError = (page,data)=>{
		//console.log('updatePageState',page,state);
		let tempPageData = {...pageData};
		let p = tempPageData[page];
		p.error = data;
		setPageData(tempPageData);
	}


	const updateAccountInfo = (account)=>{
		setAcountInfo(account);

		if(localStorage){
			localStorage.setItem(ACCOUNT,JSON.stringify(account));
		}
	}

	const setProjectPreviewable = async (bool) =>{
		await getPreviewSessionUrl();
		setTheProjectPreviewable(bool)
	}

	const setNFTsLengthFunc = (length)=>{
		setNFTsLength(length)
	}

	const updateCurrentProjectId = (id)=>{
		setCurrentProjectId(id);
		let isNewProject = false;
		if(localStorage){
			const prevId = localStorage.getItem(CURRENT_PROJECT_ID);
			isNewProject=Number(prevId)!==Number(id);
			//console.log('NEW PROJECT',isNewProject);
			if(id){
				localStorage.setItem(CURRENT_PROJECT_ID,id);
			}else{
				localStorage.removeItem(CURRENT_PROJECT_ID);
			}
		}

		//resetForms(isNewProject);
		//console.log('resetForm');
	}


	const logOut = () =>{
		setAcountInfo(null);
		if(localStorage){
			localStorage.removeItem(ACCOUNT);
			localStorage.removeItem(CURRENT_PROJECT_ID);
			localStorage.removeItem(CONNECTED_WALLETS);
		}

		if(sessionStorage){
			sessionStorage.clear();
		}
	}

	const resetCommunityData = ()=>{
		setCommunityId(null);
		setDeployStatus(null);
		setPageData({...initialPageData});
		setProjectInfoData(null);
		setCommunityRegistry(null)
		
		sessionStorage.removeItem('ccn');
		localStorage.removeItem(CURRENT_PROJECT_ID);
		setCurrentProjectId(null);
	}
	
	const getProjectInfoData = ()=>{
		//console.log('getPROJECTINFODATA',projectInfoData);
		return {...projectInfoData}
	}

	const getSocialData = ()=>{
		return {...socialData}
	}
	const getSocMediaTypes = ()=>{
		return socialMediaTypes;
	}

	const getLayerInfoData = ()=>{
		return {...layerInfoData}
	}

	const getPreSaleData = ()=>{
		//console.log('GET PRESALE DATA')
		return {...preSaleData}
	}

	const getMainSaleData = ()=>{
		//console.log('GET MAINSALE DATA')
		return {...mainSaleData}
	}

	const getWalletShareData = ()=>{
		//console.log('GET WALLET SHARE DATA')
		return [...walletShareData];
	}




	return(
			<DataContext.Provider value={{
					initDone,
					communityAddresses,
					communityRegistry,
					refreshCommunityData,
					getChainSpecificCommunityAddresses,
					communityId,
					currentCommunityName,
					setCurrentCommunityName,
					projectChain,
					updateFormV2,
					layers, 
					setLayers,
					accountInfo,
					updateAccountInfo,
					currentProjectId,
					nftsLength,
					setNFTsLengthFunc,
					isTheProjectPreviewable,
					setProjectPreviewable,
					isTheProjectPublishable,
					updateCurrentProjectId,
					pageData,
					deployStatus,
					checkDeployStatus,
					clearPublishWebsiteError,
					clearMetadataError,
					updatePageState,
					setPageErrors,
					getAllData,
					allTimezones,
					confirm,
					setConfirm,
					logOut,
					resetCommunityData,
					setGlobalCommunityId:setCommunityId,
					previewSessionUrl,
					setHelpModalVisible,

					getProjectInfoData:projectInfoData?getProjectInfoData:null,
					getSocialData:socialData?getSocialData:null,
					getSocMediaTypes:socialMediaTypes?getSocMediaTypes:null,
					getLayerInfoData:layerInfoData?getLayerInfoData:null,
					getPreSaleData:preSaleData?getPreSaleData:null,
					getMainSaleData:mainSaleData?getMainSaleData:null,
					getWalletShareData:walletShareData?.length>0?getWalletShareData:null,

				}}>
					{!initDone?
						<div style={sx.root}>
							<div style={sx.container}>
							<SpinnerCircular size="48px" color="#ff692b" />
							</div>
						</div>
					:
					<>
						{children}
						<AlertModal
							visible={helpModalVisible}
							closeWithButtonOnly={true}
							hideModal={() => {
							setHelpModalVisible(false);
							}}
							type='INFO'
							modalData={{
								buttonText: 'Close',
							}}>

							<div className="modal-header justify-content-center mt-4">
							<h5 className="modal-title" id="exampleModalLongTitle">Token Architect</h5>
							</div>
							
							<p>The Token Architect is a fundamental module in the Galaxis ecosystem where your journey with your new community starts. Here you can:</p>
							<ul>
							<li>configure your website with your own descriptions, artworks and links</li>
							<li>design your own randomised multilayer collection of cards</li>
							<li>configure the parameters of Whitelist sale and Public sale with chain native currencies or ERC677 / 777 tokens, revenue share, etc.</li>
							<li>deploy your own master data server</li>
							<li>deploy the necessary sale and token contracts to the blockchain of your choice</li>
							<li>reveal your tokens on demand</li>
							</ul>

						</AlertModal>
					</>
					}
			</DataContext.Provider>
		)
	
}
export default DataStore;