import './BorderedNavigationLink.css';

const BorderedNavigationLink = ({type,filled,onClick,children,enabled = true}) => {
  return (
    <button
      type={type}
      disabled={!enabled}
      onClick={onClick}
      className={`borderedNavLink ${
        filled ? 'darkNavLink' : 'lightNavLink'
      } ${!enabled ? 'btn-inactive' : ''} `}
    >
      {children}
    </button>
  );
};

export default BorderedNavigationLink;
