import React, { useContext, useEffect, useState } from 'react';
import DashboardPanel from './common/DashboardPanel';
import myicon from '../../../assets/images/mc/marketplace.svg';
import { state } from '../MissionControl';
import { communityHealthcheck, restartMPL, restartUTC } from '../../../components/Api';
import { DataContext } from '../../../components/DataStore/DataStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../../config';



const MarketPlace = ({communityId,communityHealth})=>{
	const history = useHistory();
	const [components,setComponents] = useState(null);
	const [marketDisabled,setMarketDisabled] = useState(true);
	const [mplBaseUrl,setMplBaseUrl] = useState(null);

	const {
		deployStatus
	  } = useContext(DataContext);

	useEffect(()=>{
		if(communityId && deployStatus?.contracts && communityHealth){
			getHealtcheck();
			console.log('MarketplacePanel mounted. community: ',communityId,deployStatus);
		}else{
			//setComponents([])
			setComponents([
				{label:'Admin', status:state.INITIAL,restart:false}])
		}
	},[communityId,deployStatus,communityHealth]);

	const getHealtcheck = async ()=>{
		let admin = state.INITIAL;


		//let health = await communityHealthcheck(communityId).catch(e=>console.log);
		
		if(communityHealth){
			console.log('MarketPlace health',communityHealth);
			let restart = false;
			if(communityHealth.marketplace){
				if(communityHealth.marketplace.status==="0" || communityHealth.marketplace.status==="2"){
					admin = state.PROGRESS;
					restart = communityHealth.marketplace.status==="2"?true:false;
					setMplBaseUrl(null);
				}

				if(communityHealth.marketplace.status==="1"){
					admin = state.SUCCESS;
					restart= true;
					setMplBaseUrl(`${config.DOMAIN}/${communityId}/marketplace/1`);
				}

				if(communityHealth.marketplace.status==="3"){
					admin = state.ERROR;
					restart= true;
					setMplBaseUrl(null);
				}
			}

			setComponents([
				{label:'Admin', status:admin, restart}
			]);

			if(deployStatus?.contracts?.token){
				setMarketDisabled(false);
			}
		}else{
			console.log('healtcheck not found');
		}
	}

	const handleGoToConfigMPL = () =>{
		history.push('/config-marketplace');
	}
	const handleRestartMPL = async ()=>{
		let res = await restartMPL(communityId).catch(e=>console.log); 
		if(res){
			console.log('RESTART MPL CALLED',res);
			communityHealth.marketplace.status="0"
		} 
		getHealtcheck();
	}

	return(<>
		<DashboardPanel
			comingSoon={false}
			components={components}
			disabled={components===null || marketDisabled}
			title="Marketplace"
			icon={myicon}
			cta={{label:mplBaseUrl?"marketplace":"configure", action:mplBaseUrl?mplBaseUrl:handleGoToConfigMPL}}
			restartBtn={config.RESTART_BUTTONS_ENABLED?handleRestartMPL:null}
		/>
	</>
	);
}

export default MarketPlace;

