import './InfoBox.css';
import icon from '../../assets/images/icons/info-icon.svg';
import AlertModal from './AlertModal';
import { useState } from 'react';

const InfoBox = (props) => {

  const [infoboxVisible,setInfoboxVisible] = useState(false);



  return (
    <>
      <div className={`info-box-parent ${props.className?props.className:''}`}>
        <img src={icon} style={{ padding: '0 15px', maxHeight: '22px', cursor:'pointer' }} onClick={e=>{setInfoboxVisible(true)}} />
        {/* <div
          className="info-box"
          style={{
            left: props.isRewardInfo ? "-224%" : props.centered?"-180%":"0",
            minWidth: props.isRewardInfo ? "200px" : "250px",
            top: props.position === 'bottom' ? '100%' : 'unset',
            bottom: props.position === 'top' ? '100%' : 'unset'
          }}
        >
          {props.children}
        </div> */}
      </div>

      <AlertModal
        visible={infoboxVisible}
        closeWithButtonOnly={true}
        hideModal={() => {
          setInfoboxVisible(false);
        }}
        type='INFO'
        modalData={{
            buttonText: 'Close',
        }}>

        <div className="modal-header justify-content-center mt-4">
          <h5 className="modal-title" id="exampleModalLongTitle">{props.title}</h5>
        </div>
        
        {props.children}

      </AlertModal>


    </>
  );
};

export default InfoBox;
