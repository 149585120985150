import React, { useContext, useEffect, useState } from 'react';
import DashboardPanel from './common/DashboardPanel';
import myicon from '../../../assets/images/mc/utility-trait-logo.svg';
import { state } from '../MissionControl';
import { communityHealthcheck, restartUTC } from '../../../components/Api';
import { DataContext } from '../../../components/DataStore/DataStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../../config';



const UtiltyTraitCenter = ({communityId,  communityHealth})=>{
	const history = useHistory();
	const [utcDisabled,setUtcDisabled] = useState(true);
	const [components,setComponents] = useState(null);
	const [utcBaseUrl, setUtcBaseUrl] = useState(null);

	const {
		deployStatus
	  } = useContext(DataContext);

	useEffect(()=>{
		if(communityId && deployStatus?.contracts && communityHealth){
			getHealtcheck();
			console.log('UtiltyTraitCenter mounted. community: ',communityId,deployStatus);
		}else{
			//setComponents([]); //disable spinner

			setComponents([
				{label:'Admin', status:state.INITIAL,restart:false},
				{label:'Trait registry', status:state.INITIAL}
				/* ,
				{label:'Layers on chain', status:visual} 
				*/
			])
		}



	},[communityId,deployStatus,communityHealth]);
	

	const getHealtcheck = async ()=>{
		let admin = state.INITIAL;
		let contract = state.INITIAL;


		//let health = await communityHealthcheck(communityId).catch(e=>console.log);
		
		console.log('UTC communityHealth',communityHealth, deployStatus, communityId);
		if(communityHealth){
			let restart = false;
			if(communityHealth.utc){
				if(communityHealth.utc.status==="0" || communityHealth.utc.status==="2"){
					admin = state.PROGRESS;
					restart = communityHealth.utc.status==="2"?true:false;
					setUtcBaseUrl(null);
				}

				if(communityHealth.utc.status==="1"){
					admin = state.SUCCESS;
					restart=true;
					setUtcBaseUrl(`${config.DOMAIN}/${communityId}/utc/`);
				}

				if(communityHealth.utc.status==="3"){
					admin = state.ERROR;
					restart=true;
					setUtcBaseUrl(null);
				}
			}

			console.log('UTC GET STATUS', admin,restart);

			
			setComponents([
				{label:'Admin', status:admin,restart},
				{label:'Trait registry', status:contract}
				/* ,
				{label:'Layers on chain', status:visual} 
				*/
			]);

			if(deployStatus?.contracts?.token){
				setUtcDisabled(false);
			}
		}
	}

	const handleGoToConfigUTC = () =>{
		history.push('/config-utc')
	}
	
	const handleRestartUTC = async ()=>{
		let res = await restartUTC(communityId).catch(e=>console.log);  
		if(res){
			console.log('RESTART UTC CALLED',res);
			communityHealth.utc.status="0";
		}
		getHealtcheck();
	}
	
	return(<>
		<DashboardPanel
			comingSoon={false}
			
			disabled={components===null || utcDisabled}
			title="utility traits"
			icon={myicon}
			cta={{label:utcBaseUrl?"utc dashboard":"configure", action:utcBaseUrl?utcBaseUrl:handleGoToConfigUTC}}
			restartBtn={config.RESTART_BUTTONS_ENABLED?handleRestartUTC:null}
			components={components}
			
		/>
	</>
	);
}

export default UtiltyTraitCenter;

