import './WalletConnectScreen.css';
import React, { useContext, useEffect, useState } from 'react';
import RoundedBox from '../../components/visual-utils/RoundedBox';
import LogoBlack from '../../assets/images/galaxis_logo_light.svg';
import Web3Ctx from '../../components/Context/Web3Ctx';
import { getContractByAddress} from '../../components/Utils/GetContract';
import { toast } from 'react-toast';
import { checkAuth, createAccount, getAccountByAddress, logIn } from '../../components/Api';
import { contractKeys, DataContext} from '../../components/DataStore/DataStore';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FormInput from '../../components/common/FormInput';
import BorderedButton from '../../components/visual-utils/BorderedButton';
import { SpinnerCircular } from 'spinners-react';
import AlertModal from '../../components/common/AlertModal';

import config from '../../config';
import { formSkeleton } from '../../components/DataStore/DataStore';


const WalletConnectScreen = (props) => {
  const PAGE = 'WELCOME_FORM';
  const { updateAccountInfo, communityAddresses, initDone, updateFormV2 } = useContext(DataContext);
  const {address,handleConnect,getProvider,setChain,chainId} = useContext(Web3Ctx);

  
  const history = useHistory();

  	//Alert stuff
	const [alertType, setAlertType] = useState('ERROR');
	const [alertMessage, setAlertMessage] = useState('');
	const [alertButtonLabel, setAlertButtonLabel]=useState('Close');
	const [alertTitle, setAlertTitle]=useState('Upload error');
	const [alertVisible, setAlertVisible] = useState(false);
	
  const [openSeaActive, setOpenSeaActive] = useState(false);
  const [rightSideActive,setRightSideActive] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  //const { formData, updateForm, updateAccountInfo } = useContext(DataContext);


  useEffect(()=>{
    console.log('address changed',address);


    const checkUserBalance = async()=>{
      if(chainId!==config.DEPLOYED_CHAIN_ID){
        await setChain(config.DEPLOYED_CHAIN_ID);
        return;
      }

      let ethersProvider = getProvider(config.DEPLOYED_CHAIN_ID);
      if(!ethersProvider){
        return;
      }

      //console.log('PROVIDER',ethersProvider)

      
 
      const lk = await getContractByAddress('LaunchKey',communityAddresses[contractKeys.LAUNCHKEY], ethersProvider).catch(e=>console.log);
      const mr = await getContractByAddress('MasterRegistry',communityAddresses[contractKeys.MASTER_REGISTRY], ethersProvider).catch(e=>console.log);


      if(lk && mr){
        //console.log('Balance of adddress',address);
        const bal = await lk.balanceOf(address).catch(e=>console.log);
        /* let filter = await lk.filters.Transfer(address,communityAddresses[contractKeys.MASTER_REGISTRY]);
        filter.fromBlock = 7803917;
        let logs = await ethersProvider.getLogs(filter).catch(e=>console.log);
         */
        //console.log('balance and logs length',bal,logs.length,logs);


        const communities = await mr.userCommunities(address).catch(e=>console.log);
        if(Number(bal)===0 && communities?.length===0){
          //TODO: Display opensea link
          setRightSideActive(true);
          // DEBUG !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          setOpenSeaActive(true);
          //setOpenSeaActive(false);
        }else{
          //console.log('number of cards:',bal.toNumber());
          const accountRes = await getAccountByAddress(address.toLowerCase()).catch(e=>console.log);
          //console.log(accountRes);  
          if(accountRes.success && accountRes.data && accountRes.data.exists===true){
             // console.log('ACCOUNT FOUND', accountRes);
              const res=await checkAuth();

              if(res.success){
                //DASHBOARD
                //console.log('auth?', res)
                updateAccountInfo(res.data);
                //history.push('/dashboard');
                history.push('/dashboard');
              }else{
                //LOGIN SCREEN
                setRightSideActive(false);
              //  console.log('REDIRECT TO LOGIN SCREEN');
              }
    



              
            }else{
             // console.log('ACCOUNT NOT FOUND', accountRes);
              //GO TO REGISTER FORM
            //  console.log('REDIRECT TO REGISTRATION FORM')
              setRightSideActive(true);
            }
          }

         
          return;
          if(loggedIn){

            


        }
      }else{
        toast.error('Contract not found.')
      }
    }

    if(address && initDone && communityAddresses){
      //localStorage.removeItem('ACCOUNT');
      checkUserBalance();
    }

  },[address, chainId, initDone, communityAddresses])
 
 
/* 
  const handleConnect = async () => {
 

    if (onboard) {
        onboard.walletReset();
        const selected = await onboard.walletSelect().catch((e)=>{console.log(e)});
        console.log('selected', selected);
        if(selected){
           await  onboard.walletCheck();
        }
    }
  }
  */
 

  const handleLogin = async () => {
        let ethersProvider=getProvider(config.DEPLOYED_CHAIN_ID);
        const signer = ethersProvider.getSigner();
       // console.log('signer',signer);
       
        const sigFlat = await signer.signMessage(address.toLowerCase()).catch(e=>toast.error(e.message));
        //0x6FCCA8A59b545754E8f66bA29cCf2AE7f1138490
        console.log('signedMessage',sigFlat);
/* 
        console.log('trying to recover....')
        let recovered = ethers.utils.verifyMessage(address,sigFlat);
        console.log('recovered:',recovered);


 */

        if(sigFlat){
          //console.log(address);
          const res = await logIn({address:address.toLowerCase(),message_signed:sigFlat});
  
          //console.log('LOGIN',res);
  
          if(res.success){
          //  console.log
            updateAccountInfo(res.data);
            sessionStorage.setItem('access_token',res.data.token);

            const valid = await checkAuth().catch(e=>console.log);

            //console.log(valid);
  
            //TO dahboard
          //  console.log('to dashboard');
            //history.push('/dashboard');
            history.push('/dashboard');
          }
        }


  }
 
  const WhiteBox = () => {

    const styleConfig = {
      borderRadius: '20px',
      padding: '40px',
      backgroundColor: 'rgb(5 0 0 / 55%)',
    };

   
   
    return (
      <RoundedBox styleConfig={styleConfig}>
        <div className="email-address-form">
          <h3>
          Welcome to your 
            <br /> Mission Control!
          </h3>

          <p style={{marginBottom:0}}>
          Create. Build. Innovate.<br/><br/>
          Galaxis empowers creators with the tools necessary to build dynamic digital communities.<br/> This simple and effective platform guides you through the launch process - one step at a time. No coding required.
          <br/><br/>In order to log in you have to sign a message. Signing it is completely free!
          </p>
            {address?
             <button className='btn-with-icon border connect-btn' onClick={handleLogin}>Let's start</button>
             :
             <button className='btn-with-icon connect-btn' onClick={handleConnect}>Connect Wallet</button>}
        </div>
      </RoundedBox>
    );
  };

  const WhiteBoxForm = ({formSchema,formUpdate}) => {
    const styleConfig = {
      borderRadius: '20px',
      padding: '40px',
      backgroundColor: 'rgb(5 0 0 / 55%)'
    };

    const [form] = useState({...formSkeleton[PAGE].form});
    const [formProperties] = useState({...formSkeleton[PAGE].formProperties});
    const [isLoading,setIsLoading] = useState(false);

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: form,
      validationSchema: formSkeleton[PAGE].formScheme,
      validateOnBlur: true,
      validateOnChange: true,
      onSubmit: async(values) => {

        

       //values.terms_accepted=false;

        if(!values.terms_accepted){
          toast.error('Please accept the Terms and Conditions');
          return;
        }
        
        setIsLoading(true);

        let ethersProvider = getProvider(config.DEPLOYED_CHAIN_ID);
        const signer = ethersProvider.getSigner();
        // console.log('signer         ', signer);
        const sigAddress = await signer.getAddress();
        //console.log('signer.address ', sigAddress);

        const sigFlat = await signer.signMessage(sigAddress.toLowerCase()).catch(e=>{toast.error(e.message);setIsLoading(false);});
        console.log('signedMessage  ',sigFlat);

        if(!sigFlat){
          toast.error('Please sign the message with your wallet.');
          return;
        }
        
/* 
        const actualAddress = ethers.utils.verifyMessage(sigAddress.toLowerCase(), sigFlat)
        console.log('actualAddress  ', actualAddress);
 */
        const res = await createAccount({...values,address:sigAddress.toLowerCase(),signed_message:sigFlat});
        

        setIsLoading(false);
         if(res.success){
            //store user data
            updateAccountInfo(res.data);
            sessionStorage.setItem('access_token',res.data.token);

            const valid = await checkAuth().catch(e=>console.log);

           // console.log(valid);

           // history.push("/dashboard");
            history.push("/dashboard");
         }else{
//          console.log('resmes1',res, !!(res.message));
//          console.log('resmes1',res, !!(res.message));
          if(res.message){
            //toast.error(res.message.toJSON().message);
            setAlertTitle('Error');
            setAlertMessage(res.message);
          }else{
            console.log('Something went wrong, sorry.',res);
            return;
          }
          setAlertButtonLabel("Close");
          setAlertVisible(true);
         }
      },
    });

    const handleFormikChange=(e,i)=>{

      formik.handleChange(e);
      console.log('FIELD UPDATE',e,i);
      updateFormV2(PAGE, i, formProperties[i].fieldType==='checkbox-custom'?e.target.checked:e.target.value);
       if(i==='newsletter_accepted' && e.target.value===""){
        console.log('FFFF')
        formik.setFieldValue('newsletter_accepted',false);
        //updateForm(PAGE, 2, false);
      } 

    }

    const handleBrowseCard = () =>{
      // window.open("https://opensea.io/collection/ether-cards-founder")
      window.open("https://discord.com/invite/mBwauRSJNW")
    }

    const setMailError = (isDisabled)=>{
      if(isDisabled){
        toast.error('You need to give a valid email address if you want to receive emails.')
      }

    }

    return (
      <RoundedBox styleConfig={styleConfig}>
        <div className="email-address-form text-left">
          <h3>
            Launch Your
            <br /> Project Now!
          </h3>

          {openSeaActive?
            <>
              <p className='mb-4'>
                {/* Unfortunately, it appears that you don't have an Ether Card in order to start your project. To obtain one, please visit the following website: */}
                It appears that you are not currently enrolled in the Beta Test Program.  Please join our Discord to find out more information!
              </p>
              <BorderedButton
                type="link"
                extraStyle={{ marginTop: '0px',marginBottom:'40px' }}
                filled={true}
                onClick={handleBrowseCard}
              >
               Join Discord
              </BorderedButton>
            </>

            :
            <p>
              First, accept <a href='https://ether.cards/privacy-policy.html' style={{textDecoration:"underline"}}> terms and conditions</a> to get started. Next, choose whether or not to add an email address and receive marketing materials. Finally, click "Submit and start".
            </p>
          }

          {!openSeaActive&&<form onSubmit={formik.handleSubmit}>
            
            <FormInput
              id={'email'}
              label={formProperties['email'].label}
              type={formProperties['email'].fieldType}
              className={formProperties['email'].className}
              onChange={(e)=>handleFormikChange(e,'email')}
              onBlur={formik.handleBlur}
              value={formik.values['email']}
              error={
                formik.touched['email'] && formik.errors['email']
                  ? formik.errors['email']
                  : null
              }
            />

            <FormInput
              id={'terms_accepted'}
              label={formProperties['terms_accepted'].label}
              type={formProperties['terms_accepted'].fieldType}
              className={formProperties['terms_accepted'].className}
              onChange={(e)=>handleFormikChange(e,'terms_accepted')}
              onBlur={formik.handleBlur}
              value={formik.values['terms_accepted']}
              error={
                formik.touched['terms_accepted'] && formik.errors['terms_accepted']
                  ? formik.errors['terms_accepted']
                  : null
              }
            />    
            <FormInput
              id={'newsletter_accepted'}
              label={formProperties['newsletter_accepted'].label}
              type={formProperties['newsletter_accepted'].fieldType}
              disabled={!formik.values['email'] || formik.errors.email}
              className={formProperties['newsletter_accepted'].className}
              onChange={(e)=>handleFormikChange(e,'newsletter_accepted')}
              onBlur={formik.handleBlur}
              onClick={()=>{setMailError(!!(!formik.values['email'] || formik.errors.email))}}
              value={formik.values['newsletter_accepted']}
              error={
                formik.touched['newsletter_accepted'] && formik.errors['newsletter_accepted']
                  ? formik.errors['newsletter_accepted']
                  : null
              }
            />    
            
            
            
       

            <BorderedButton
              type="submit"
              extraStyle={{ marginTop: '30px' }}
              filled={true}
              disabled={!formik.isValid || isLoading || !formik.values['terms_accepted']}
            >
              {isLoading&&<SpinnerCircular size='16' className='mr-1'/>}
              Submit and start
            </BorderedButton>
          </form>}


        </div>
      </RoundedBox>
    );
  };


  return (
    <div className={`welcome-outer ${rightSideActive?'connected':''}`}>
      <div
        className="welcome-container container"
        style={{ maxWidth: '1150px', margin: '0 auto' }}
      >
        <div className="row align-items-end" style={{ maxHeight: '15%' }}>
          <div className="col-12 ">
            <div className='welcome-logo-div'>
            <img src={LogoBlack} style={{ width: '220px' }}/>
            </div>
            
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="connect-box col-lg-6 py-2 d-flex align-items-center px-lg-5 connect-container">
            <WhiteBox />
          </div>
          <div className="account-reg-box col-lg-6 py-2 px-lg-5 d-flex align-items-center">
            <WhiteBoxForm />
          </div>
        </div>
      </div>
      <AlertModal
        visible={alertVisible}
        type={alertType}
        hideModal={()=>setAlertVisible(false)}
        modalData = {{title:alertTitle,description:alertMessage, buttonText:alertButtonLabel}}
      >
      </AlertModal>
    </div>
  );
};

export default WalletConnectScreen;