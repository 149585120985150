import { BigNumber, ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toast';
import { communityHealthcheck, deployMetaData, getLayerInfo, getProjectInfoById, getProjectStatus, getSaleForProject, getSocialMedia, getUploadStatus, getWalletShares, publishProject, restartMetaData } from '../../components/Api';
import { DataContext, formState } from '../../components/DataStore/DataStore';
import BorderedNavigationLink from '../../components/visual-utils/navigation-links/BorderedNavigationLink';
import SSPFooter from '../../components/SSPFooter/SSPFooter';
import titleIcon from '../../assets/images/page-title-icons/publish_metadata.png';
import collapseIcon from '../../assets/images/icons/expand_circle_down_black_24dp.svg';
import clock from '../../assets/images/icons/wall-clock_dark.png';
import {
	CheckCircleFill,
	XCircleFill,
  } from 'react-bootstrap-icons';

import './DeployMeta.css';

import useConfirm from '../../components/common/useConfirm';
import EmailPopup from '../../components/common/EmailPopup';
import { SpinnerCircular} from 'spinners-react';
import useInterval from '../../components/common/useInterval';
import FormInput from '../../components/common/FormInput';
import config from '../../config';
import { yupToFormErrors } from 'formik';
import axios from 'axios';
import * as yup from 'yup';

const DeployMeta = (props) => {
	const { currentProjectId, isTheProjectPublishable,deployStatus,checkDeployStatus, updatePageState, projectChain, communityId } = useContext(DataContext);
	const [metaStatus, setMetaStatus] = useState(null);
	const [emailPopupVisible, setEmailPopupVisible] = useState(false);
	const [customRpc, setCustomRpc] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	
	const [metaServerUrl, setMetaServerUrl] = useState('unset');
	const [isUrlLive,setIsUrlLive] =  useState(false);


	const [pollInterval, setPollInterval] = useState(null);
	const [statusPercentage, setStatusPercentage] = useState(0);

	const [rpcUrl,setRpcUrl] = useState('');
	const [wssUrl,setWssUrl] = useState('');

	const [customRpcUrl,setCustomRpcUrl] = useState('');
	const [customWssUrl,setCustomWssUrl] = useState('');

	const { isConfirmed } = useConfirm();

	const [errors,setErrors]=useState({
		"rpc-url":'',
		"wss-url":''
	});

	const [proxyOK, setProxyOK] = useState(false);
	const [restartInProgress, setRestartInProgress] = useState(false);

	useEffect(()=>{

		if(projectChain){
			if(projectChain===11155111 || projectChain===1){
				//mainnnet
				setRpcUrl(config.RPC_BASE+config.METADATA_INFURA_ID);
				setWssUrl(config.WSS_BASE+config.METADATA_INFURA_ID);
			}else{
				//L2
				setRpcUrl(config.POLYGON_METADATA_RPC_URL);
				setWssUrl(config.POLYGON_METADATA_WSS);
			}
		}
	},[projectChain])


	useEffect(()=>{
		if(deployStatus.metaStatus){
			setMetaStatus(deployStatus.metaStatus);
			console.log('deployStatus.meta, sever url',deployStatus,deployStatus.metaStatus,metaServerUrl);
			
		//	if(!deployStatus.metaDataUrl){
			if(!metaServerUrl){
				if(deployStatus.metaStatus.status.name=='Approved'&& metaServerUrl==null){
					console.log('poll START');
					setPollInterval(20000);
				}
			}/* else{
				let mUrl = null;
				if(deployStatus.metaDataUrl?.indexOf('metadata')>-1){
					mUrl=deployStatus.metaDataUrl+"/";
				}else{
					mUrl=deployStatus.metaDataUrl+"/api/metadata/";
				}



				setMetaServerUrl(mUrl);
			} */
		}
	},[deployStatus,metaServerUrl]);


	useEffect(()=>{
		const checkStatus = async ()=>{
			console.log('checking status');
			checkDeployStatus(currentProjectId);
			checkMetaUri();
		}
		if(currentProjectId){
			checkStatus();
		}
	},[currentProjectId]);

	const checkMetaUri = async()=>{


		//console.log('metaUri called');
		let res = await getUploadStatus(currentProjectId).catch(e=>console.log);
		//console.log('RES',res);

		let healtcheckRes = await communityHealthcheck(communityId).catch(e=>console.log);

		console.log('HEALTHCHECKRES',healtcheckRes);

		if(healtcheckRes.proxy && healtcheckRes.proxy.status==="1"){
			setProxyOK(true);
		}else{
			setProxyOK(false);
		}

		if(res && res.success && healtcheckRes && healtcheckRes.success){
			console.log('image upload status',res.data);
			let mUrl = null;

			if(res.data.url && healtcheckRes.metadata && healtcheckRes.metadata.status==="1"){
				console.log('stop polling')
				setRestartInProgress(false);
				setIsUrlLive(true);
				setPollInterval(null);
				updatePageState('DEPLOY_METADATA',formState.FORM_SAVED);
				checkDeployStatus(currentProjectId);

				//console.log('+++++++====+++++',res.data.url.indexOf('app.galaxis'));
				if(res.data.url.indexOf('metadata')>-1){
					mUrl=res.data.url+"/";
				}else{
					mUrl=res.data.url+"/api/metadata/";
				}
				setMetaServerUrl(mUrl);
			}else{
				setStatusPercentage(Number(res.data.UploaderStatus));
				setMetaServerUrl(null);
				updatePageState('DEPLOY_METADATA',formState.FORM_EMPTY);
				if(healtcheckRes.metadata && healtcheckRes.metadata.status==="3"){
					console.log('stop polling (error)')
					setIsUrlLive(false);
					setPollInterval(null);
					toast.error('Metadata server deployment failed.');

				}
			}
			return mUrl;
		}else{

			setMetaServerUrl(null);
			updatePageState('DEPLOY_METADATA',formState.FORM_EMPTY);
			if(!res.success){
				if(res.data){
					toast.error(res.data.UploaderMsg);
				}else{
					toast.error(res.message.message?res.message.message:res.message);
				}
				console.log('UPLOAD ERROR');
				
				setStatusPercentage(-1);
				setPollInterval(null);
				return null;
			}
		}
		return null;
	}


	useInterval(
		checkMetaUri,
		pollInterval
	);


	const onPublishClicked =()=>{

		if(customRpc){
			if(!customRpcUrl){
				toast.error('RPC url is missingg.');
				return;
			}
			if(!customWssUrl){
				toast.error('WSS url is missingg.');
				return;
			}
		}

		const storedAcc = localStorage.getItem('ACCOUNT');
        let acc=JSON.parse(storedAcc);

		if(acc.email===null){
			setEmailPopupVisible(true);
		}else{
			handlePublish();
		}
	}

	const onEmailClose = ()=>{
		setEmailPopupVisible(false);
		handlePublish();
	}


	const handlePublish = async() => {
		const confirmed = await isConfirmed('Are you sure?','The metadata content cannot be changed once deployed. The process is irreversible.','Yes, I\'m sure','Cancel');
		if(!confirmed){
			return;
		}

		let rpc,wss;

		if(customRpc){
			rpc = customRpcUrl;
			wss= customWssUrl;
		}else{
			rpc = rpcUrl;
			wss= wssUrl;
		}

		setIsLoading(true);
		//TODO: Do some stuff

		let res = await deployMetaData(currentProjectId,rpc,wss).catch(e=>console.log);
		//let res = await deployMetaData(1).catch(e=>console.log);

		if(res && res.success){

			//hackish solution to disable the submit button during status check 
			localStorage.removeItem('MIF'+communityId);
			let ms = {...metaStatus};
			ms.status.name='Under Review';
			setMetaStatus(ms);

			checkDeployStatus(currentProjectId);
			toast.success('Metadata deploy request sent.');

		}else{
			toast.error('Metadata deploy request failed.');
		}

		setIsLoading(false);
	}

	const checkUrl = async(e)=> {

		let url = e.target.value;
		let id = e.target.id;
		let err = {...errors};

		if(url===''){
			err[id]="Required field";
			setErrors(err);
			return;
		}


		let valid = false;

		if(id==='rpc-url'){
			valid = isRpcURL(url);
		}else{
			valid = isWssURL(url);
		}

		if(!valid){
			err[id]="Must be a valid url";
			setErrors(err);
			return;
		}

		err[id]="";
		setErrors(err);
	}

	const isRpcURL = (str) => {
		var urlRegex = '^(?:(?:https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		var url = new RegExp(urlRegex, 'i');
		return str.length < 2083 && url.test(str);
   }
	const isWssURL = (str) => {
		var urlRegex = '^(?:(?:wss)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		var url = new RegExp(urlRegex, 'i');
		return str.length < 2083 && url.test(str);
   }

   const handleRestartMeta = async()=>{

		//console.log('RESTART CALLED');
		setRestartInProgress(true);
		let res = await restartMetaData(communityId).catch(e=>console.log);


		//console.log('restart RES',res);
		if(res){
			console.log('re-start polling');
			setPollInterval(20000);
		}
   }

	return (
		<>
			<div className="row" style={{ marginBottom: '80px' }}>
				<div className="col-lg-10 mx-auto">
						<h4 className="page-title mt-1">
						<img src={titleIcon} style={{ marginRight: '3px' }} /> 8. Deploy metadata & images
						</h4>
				</div>
				
				{metaStatus&&<div className="rounded-box boxShadow p-4 mt-3 col-lg-10  mx-auto">
					<div className={`publish-box-content`}>
 						<p>Your project's metadata will be reviewed by Galaxis. This process could take up to 48 hours. Once approved, your metadata server will be deployed automatically. The deployed metadata content cannot be changed.</p>
						
						
						
							{(metaStatus.status.name==='Rejected' || metaStatus.status.name==='Unpublished')&&
								<>
								<FormInput 
									id='own-stuff'
									type='checkbox'
									label='I want to use my own RPC and WSS provider'
									onChange={(e)=>setCustomRpc(e.target.checked)}
								/>


									{customRpc&&<>
											<FormInput
												id='rpc-url'
												label='RPC Url'
												type='text'
												value={customRpcUrl}
												onChange={e=>setCustomRpcUrl(e.target.value)}
												onBlur={e=>checkUrl(e)}
												error={errors['rpc-url']}
											/>

											<FormInput
												id='wss-url'
												label='WSS Url'
												type='text'
												value={customWssUrl}
												onChange={e=>setCustomWssUrl(e.target.value)}
												onBlur={e=>checkUrl(e)}
												error={errors['wss-url']}
											/>
										</>
									}
								</>
						}



						{(isLoading || metaServerUrl === 'unset')?
							<>
								<div className='text-center'>
									<SpinnerCircular size="32" color="#ff692b"/>
								</div>
							</>
							:
							<>
								{metaStatus.status.name!=='Unpublished' &&
								<div className='text-center'>
									<p className="mb-0 mt-3 text-center">
										<span>Metadata & images status:</span>{' '} 
										<span className={`d-block mt-1 ${metaStatus.status.name==='Rejected'?'text-warning':metaStatus.status.name==='Approved'?'text-success':'text-warning'}`}> 
										{metaServerUrl?	
											<strong>Deployed</strong> 
											:
											<strong>{metaStatus.status.name==='Rejected'?'Has been delayed':metaStatus.status.name}{(!metaServerUrl && metaStatus.status.name==='Approved')?', Deploying':''}</strong> 
										}
										</span>
									</p>

									{(!metaServerUrl && metaStatus.status.name==='Approved' && statusPercentage >= 0 )&&
										<>
											<div className='meta-progress-bar'>
												<div className='gauge' style={{right:`${100-statusPercentage}%`}}></div>
												<div className='progress-label'>{statusPercentage}%</div>
											</div>

											{statusPercentage===100&&
												<p>The images has been successfully uploaded to IPFS,<br/>launching the metadata server. <SpinnerCircular thickness={150} size={16} color="#ff692b"/></p>
											}
										</>
									}

									{metaServerUrl  &&
										<>
											{!isUrlLive?
												<SpinnerCircular thickness={150} size={16} color="#ff692b"/>
												:
												<>
													<p className='meta-url mt-1 mb-3 site-link'>Example metadata: <a href={metaServerUrl+'1/1'} target="_blank">{metaServerUrl}1/1</a></p>
													{config.RESTART_BUTTONS_ENABLED&&
														<BorderedNavigationLink
															type="submit"
															className="mx-auto"
															onClick={handleRestartMeta}
															enabled={(communityId && isUrlLive && proxyOK && !restartInProgress)}>
															RESTART METADATA SERVER
														</BorderedNavigationLink>
													}
												</>
											}
										</>
									}
									
									{metaStatus.status.name==='Rejected'&&
										<>
											<p style={{textAlign:'left'}} ><strong>Review:</strong></p>
											<div className="review-container" style={{textAlign:'left'}} dangerouslySetInnerHTML={{__html:metaStatus.reason}}/>
										</>
									}
								</div>}
							</>
						}

					</div>
				</div>}
			</div>

			{(metaStatus && metaStatus?.status.name!=='Approved')&&<SSPFooter
				prev=""
				next=""
				isSubmitEnabled = {proxyOK && metaStatus?.status.name!=='Under Review' && !isLoading && !(customRpc && (errors['rpc-url'] || errors['wss-url'] || customRpcUrl==='' || customWssUrl===''))}
				submit={{
				label: 'Deploy images & metadata',
				handleSubmit: onPublishClicked,
				}}
			/>}
			<EmailPopup visible={emailPopupVisible} hideModal={onEmailClose} />
		</>
	)
}

export default DeployMeta;