import { ethers } from 'ethers';
import config from '../../config';

import AddressesMain from '../../contracts/addresses-1.json';
import AddressesPolygon from '../../contracts/addresses-137.json';
import AddressesGoerli from '../../contracts/addresses-5.json';
import AddressesLocal from '../../contracts/addresses-1337.json';

import LaunchKey from '../../contracts/LaunchKey.json';
import Zoom2 from '../../contracts/Zoom2.json';
import ContractFactory from '../../contracts/ContractFactory.json';
import ContractFactory_2023071101 from '../../contracts/ContractFactory_2023071101.json';

import MasterRegistry from '../../contracts/MasterRegistry.json';
import GalaxisRegistry from '../../contracts/GalaxisRegistry.json';
import MarketplaceFactory from '../../contracts/MarketplaceFactory.json';
import CommunityList from '../../contracts/CommunityList.json';
import CommunityRegistry from '../../contracts/CommunityRegistry.json';

import TraitRegistry from '../../contracts/TraitRegistry.json';
import VisualTraitRegistry_20230508 from '../../contracts/VisualTraitRegistry_20230508.json';
import VisualTraitRegistryFactory_20230514 from '../../contracts/VisualTraitRegistryFactory_20230514.json';

import TokenContractAbi from '../../contracts/TokenContract.json';
import TokenContractAbi_2023090101 from '../../contracts/TokenContract_2023090101.json';




const contractAbis = {
						GalaxisRegistry, 
						LaunchKey, 
						Zoom2, 
						ContractFactory: {
							default: ContractFactory,
							2023071101: ContractFactory_2023071101
						},
						MasterRegistry, 
						MarketplaceFactory,
						CommunityList,
						CommunityRegistry,
						TraitRegistry,
						VisualTraitRegistry: {
							default: VisualTraitRegistry_20230508,
							20230508: VisualTraitRegistry_20230508
						},
						VisualTraitRegistryFactory: {
							default: VisualTraitRegistryFactory_20230514,
							20230514: VisualTraitRegistryFactory_20230514
						},
						TokenContract:{
							default: TokenContractAbi,
							2023090101: TokenContractAbi_2023090101
						}
					 };
/* 
const addresses = {
	1: { addr: AddressesMain, name: 'Main' },
	5: { addr: AddressesGoerli, name: 'Goerli' },
	137: { addr: AddressesPolygon, name: 'Polygon' },
	1337: { addr: AddressesLocal, name: 'localhost' },
};

export const getContractAddressByChain = (contractName,chainId)=>{
	return addresses[chainId]?addresses[chainId].addr[contractName]:null;
}


export const getContract = async (contractName, provider) => {
	const ntw = await provider.getNetwork();
	console.log(ntw);
	const contractAbi = contractAbis[contractName];
	const address = addresses[ntw.chainId].addr[contractName];

	let contract = null;

	try {
		let code = await provider.getCode(address);
		if(code==='0x'){
			return null;
		}
		contract = new ethers.Contract(address, contractAbi.abi, provider);

	} catch (e) {
		console.log('contract not found');
	}
	return contract;
}; */

const versionABI  = [
	"function getVersion(address) external view returns (uint256)"
]

let ZOOM_VERSION_VIEW = [];
const initOrGetCachedZoomVersionViewContract = async( _provider ) => {

	//console.log('zoom versionview provider ',_provider, _provider._network.chainId);

	//if(typeof ZOOM_VERSION_VIEW[config.DEPLOYED_CHAIN_ID] === "undefined") {
	if(typeof ZOOM_VERSION_VIEW[_provider._network.chainId] === "undefined") {
		const galaxisRegistry = new ethers.Contract(config.GALAXIS_REGISTRY, GalaxisRegistry.abi, _provider);
		const ZOOM_VERSION_VIEW_ADDRESS = await galaxisRegistry.getRegistryAddress("ZOOM_VERSION_VIEW").catch(e=>console.log);
		
		let contract = false;
		if(ZOOM_VERSION_VIEW_ADDRESS !== ethers.constants.AddressZero) {
			contract = new ethers.Contract(ZOOM_VERSION_VIEW_ADDRESS, versionABI, _provider);
		}
		//ZOOM_VERSION_VIEW[config.DEPLOYED_CHAIN_ID] = contract;
		ZOOM_VERSION_VIEW[_provider._network.chainId] = contract;
	}

	//return ZOOM_VERSION_VIEW[config.DEPLOYED_CHAIN_ID];
	return ZOOM_VERSION_VIEW[_provider._network.chainId];
}

export const getContractByAddress = async (contractName, address, provider)=>{



	let contractAbi = contractAbis[contractName];


	let contract = null;
	try {
		let code = await provider.getCode(address);

		//console.log('provider.getCode',code);

		

		if(code !== '0x'){

			const _ZoomVersionView = await initOrGetCachedZoomVersionViewContract(provider);

			let contractVersion = 0;

			if(_ZoomVersionView === false) {
				// we don't have _ZoomVersionView on current chain ... use default contract in this case
				contractVersion = 1;
				console.log("PLEASE DEPLOY --- ZOOM_VERSION_VIEW --- to current chain:", config.DEPLOYED_CHAIN_ID);
			} else {
				contractVersion = await _ZoomVersionView.getVersion(address).catch(e=>{console.log('no version found.',e)});
				
				//console.log('contract version',contractName,address,contractVersion, Number(contractVersion));

				if(contractVersion !== "undefined" && Number(contractVersion) > 0) {
					if(typeof contractAbis[contractName]["abi"] !== "undefined") {
						// just 1 ABI for contract.. use it
						// 1 = using default
						contractVersion = 1;

					} else if(
						typeof contractAbis[contractName] === "object" &&
						typeof contractAbis[contractName]["default"] !== "undefined"
					) {
						if(typeof contractAbis[contractName][contractVersion] !== "undefined") {
							contractAbi = contractAbis[contractName][Number(contractVersion)];
						} else {
							contractAbi = contractAbis[contractName]["default"];
							// 1 = using default
							contractVersion = 1;
						}
					}
				}
			}

			contract = new ethers.Contract(address, contractAbi.abi, provider);
			contract.ecVersion = parseInt(contractVersion.toString());


		}
	} catch (e) {
		console.log(contractName+' contract not found.');
		console.log(e);
	}

	return contract;
}
