import './AlertModal.css';
const BackdropModal = (props) => {
  const {visible} = props;

  return (
    <>
      {visible && (
        <div
          className="modal warning-modal"
          style={{ display: 'block',backgroundColor:"rgba(0,0,0,0.8)" }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >

        </div>
      )}
    </>
  );
};

export default BackdropModal;
