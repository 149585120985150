import './ProjectCard.css';
import addNewIcon from '../../assets/images/add-new.svg';
import logo from '../../assets/images/logoForProjectCard.svg';
import ongoing from '../../assets/images/icons/dashboard/ongoing_white.svg';
import declined from '../../assets/images/icons/dashboard/declined.svg';
import approved from '../../assets/images/icons/dashboard/approved_white.svg';
import launchKeyImage from '../../assets/images/dashboard/launchkey.jpeg';
import ethIcon from '../../assets/images/dashboard/ether.png';
import polygonIcon from '../../assets/images/dashboard/polygon.png';
import RoundedBox from '../../components/visual-utils/RoundedBox';
import { useEffect, useState } from 'react';
import { getContractByAddress } from '../../components/Utils/GetContract';
import { communityRegistryKeys, contractKeys } from '../../components/DataStore/DataStore';
import config from '../../config';
import { ethers } from 'ethers';

const styleConfig = {
  borderRadius: '10px',
  padding: '10px',
  backgroundColor: '#fff',
  boxSshadow: '0px 0px 8px 0px rgb(0 0 0 / 15%)',
};
const ProjectCardV2 = ({ card,onCreateProject,onOpenProject, getProvider }) => {
  const [visibleDotsPanel, setDotsPanelVisible] = useState(false);
  const [done, setDone] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(()=>{
    const checkContracts=async()=>{


     // console.log('CHECK CONTRACTS???');
      
      //console.time('card'+card.projectId);
     // console.log('card',card);
      let ethersProvider = null;
     
      if(card.chain_id){
        ethersProvider = await getProvider(card.chain_id);
      }

      if(!ethersProvider){
        console.log('provider not supported in this env',card.chain_id);
        return;
      }

			const galaxisRegistryOnTargetChain = await getContractByAddress('GalaxisRegistry',config.GALAXIS_REGISTRY,ethersProvider).catch(e => console.log('err:', e));
			
			if(!galaxisRegistryOnTargetChain){
				console.log('Target chain not supprted.');
				return;
			}
 
      
      let communityListAddress = await galaxisRegistryOnTargetChain.getRegistryAddress(contractKeys.COMMUNITY_LIST).catch(e => console.log('err:', e));
      let communityListContract = await getContractByAddress('CommunityList',communityListAddress , ethersProvider);
      let communityRegistryEntry = await communityListContract.communities(card.community_id).catch(e=>console.log);
      let thisCommunityRegistry = await getContractByAddress('CommunityRegistry', communityRegistryEntry.registry, ethersProvider);

      let sale_count = 0
      if(thisCommunityRegistry){
        sale_count  = await thisCommunityRegistry.getRegistryUINT(communityRegistryKeys.SALE_COUNT).catch(e=>console.log);
      }

      let contractsDeployed = (sale_count>0);

      let err = false
      if(card.siteStatus && card.siteStatus.status.name==="Rejected"){
        err = true;
      }
      
      if(card.metaStatus && card.metaStatus.status.name==="Rejected"){
        err = true;
      }


      if(!err){
        if((card.siteStatus && card.siteStatus.status.name==="Approved" )&& 
          (card.metaStatus && card.metaStatus.status.name==="Approved")){
          setDone(contractsDeployed);

          //console.log('LIVE?',contractsDeployed)
        }
      }else{
        setHasError(true);
        setDone(false);
      }
			

     // console.timeEnd('card'+card.projectId);
    }

    if(card && card.hasProject){
      checkContracts();
    }
  },[])


  const handleClick = () => {
    if (card.hasProject) {
      //alert('open existing project');
      if(onOpenProject){
        onOpenProject(card.community_id,card.projectId,card.community_name);
      }
    } else {
      //alert('add new project');
      if(!card.assignedButNoProjectYet && onCreateProject){
        //need to transfer the card to the registry
        onCreateProject(card.community_id,true,'');
      }else{
        //card already transferred, we only need to create the project
        onCreateProject(card.community_id,false,card.community_name);
      }
    }
  };

  const OpenProject = () => {
    handleClick();
  };


  return (
    <>
      <div className="project-card" style={{backgroundImage:`url(${launchKeyImage})`}}>
        <div className="project-card-inner" onClick={handleClick}>
          <div className="project-card-inner-child">
            {card.hasProject || card.assignedButNoProjectYet? (
              <div>
                
                {(!hasError && !done)&&
                  <>
                    <img src={ongoing} className="mb-4" />
                    <h5>Under Construction:</h5>
                  </>
                }

                {hasError&&
                  <>
                    <img src={declined} className="mb-4" />
                    <h5>Declined:</h5>
                  </>
                }

                {(!hasError && done)&&
                  <>
                    <img src={approved} className="mb-4" />
                    <h5>Live:</h5>
                  </>
                }

                
                <p style={{ lineHeight: '20px' }}>{card.community_name}</p>

                {card.chain_id&&
                  <>
                    <img src={(card.chain_id==1 || card.chain_id==11155111)?ethIcon:polygonIcon} className='chain-badge'/>
                  </>
                }
              </div>
            ) : (
              <div>
                <img src={addNewIcon} className="mb-4" />
                <h5 style={{ lineHeight: '20px' }}>Add project to this card</h5>
              </div>
            )}
          </div>
        </div>


      </div>
       {/* card id, dots panel */}
      <div className="project-card-id">
        <p>Card ID: {card.community_id}</p>
        {card.projectId!==null &&<p
          className="btn-with-icon open-dots-panel"
          onClick={() => setDotsPanelVisible(!visibleDotsPanel)}
        >
          ...
        </p>}
        {visibleDotsPanel && 
          <div className="dots-panel">
            <button className="btn-with-icon my-1" onClick={OpenProject}>
              Open Project
            </button>
          </div>
        }
      </div>
    </>
  );
};

export default ProjectCardV2;
