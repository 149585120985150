import React, { useEffect } from 'react';
import { useState } from 'react';

import './CreateProjectModal.css';
import config from '../../config';
import RoundedBox from '../../components/visual-utils/RoundedBox';
import FormInput from '../../components/common/FormInput';
import { toast } from 'react-toast';
import { ethers } from 'ethers';

const CreateProjectModal = ({address, provider, communityId, visible, hideModal, onCreate }) => {

  const supportedChains=config.CHAINS;
  const [chains,setChains] = useState([]);
  const [selectedChain, setSelectedChain] = useState();
  const [communityName, setCommunityName ] = useState('');
  const [communityNameError, setCommunityNameError ] = useState('');
  const [communityAdmin, setCommunityAdmin ] = useState(address);
  const [polygonWarningVisible,setPolygonWarningVisible] = useState(false);


  const styleConfig = {
    width: '100%',
    maxWidth: '800px',
    borderRadius: '20px',
  };

  useEffect(()=>{
    setCommunityAdmin(address);
  },[address])

  useEffect(()=>{
    if(supportedChains){
      let mapped = supportedChains.map((c,i)=>{
				return ({label:c.label,value:i})
			});
      setChains(mapped);
      setSelectedChain(mapped[0].value);
      setPolygonWarningVisible(false);
    }
  },[supportedChains,visible])

  const onSelectChain = (value)=>{
    setSelectedChain(value);
    console.log(supportedChains[value]);
    if(supportedChains[value].id==="0x13881" || supportedChains[value].id==="0x89"){
      setPolygonWarningVisible(true);
    }else{
      setPolygonWarningVisible(false);
    }
  }


  const createCommunity = async ()=>{
    if(communityName==""){
      setCommunityNameError('Community name is required.')
      return;
    }else if(communityName.length>30){
     // toast.error("Community name is too long. Max length is 30.");
      setCommunityNameError('Too long. Max length is 30.');
      return;
    }

    if(communityAdmin==""){
      toast.error("Community admin address is required.");
      return;
    }

    if(!ethers.utils.isAddress(communityAdmin)){
      toast.error("Wrong admin address.");
      return;
    }
    console.log('modal on create')
    onCreate({targetChain:supportedChains[selectedChain],communityId,communityName,communityAdmin});
    hideModal();
  }

  return (
    <>
      {visible && (
        <div
          className="modal warning-modal"
          style={{ display: 'block' }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: '600px' }}
          >
            <RoundedBox styleConfig={styleConfig}>
              <div className="modal-content">
                {/* <button
                  className="btn-with-icon"
                  style={{ position: 'absolute', right: '15px', top: '15px' }}
                  onClick={() => {
                    hideModal()
                  }}
                >
                  <img src={close} alt="close" />
                </button> */}
                
                <h4 className="create-modal-title mb-1">Create Project</h4>
                <h5 className="text-black text-center mb-4">Community id: {communityId}</h5>

                <div className="create-project-form">
                  <FormInput
                    id="chain-selector"
                    type="select"
                    label="Target chain"
                    options={chains}
                    value={selectedChain}
                    onChange={(e)=>{onSelectChain(e.target.value)}}
                  />

                  <FormInput
                    id="community-name"
                    type="text"
                    label="Community name"
                    value={communityName}
                    onChange={(e)=>{setCommunityName(e.target.value), setCommunityNameError('')}}
                    error={communityNameError}
                  />

                  <FormInput
                    id="admin-address"
                    disabled={true}
                    type="text"
                    label="Community admin address"
                    value={communityAdmin}
                    onChange={(e)=>{setCommunityAdmin(e.target.value)}}
                  />

                </div>

                <p className='text-center'>Please be aware that your LaunchKey token will be used when you create a new community. Once the community is created on the blockchain, your address will be saved as the owner and can be used for later administration tasks. Gas fees incurred when you create a new project.</p>
                {polygonWarningVisible&&<p className='text-center text-danger'>You selected an L2 chain for your community. Your transaction still takes place on mainnet, but it needs additional time for the L2 chain to synchronise. Depending on the selected L2 chain, this can take up to 30 minutes.</p>}

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn"
                    style={{ color: 'rgb(250, 128, 6)' }}
                    data-dismiss="modal"
                    onClick={() =>{ setSelectedChain('');setCommunityName('');hideModal()}}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn"
                    style={{ color: 'rgb(250, 128, 6)' }}
                    data-dismiss="modal"
                    onClick={createCommunity}
                  >
                    Create project
                  </button>
                </div>
              </div>
            </RoundedBox>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateProjectModal;
