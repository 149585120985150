import { BigNumber, ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toast';
import { getLayerInfo, getProjectInfoById, getProjectStatus, getSaleForProject, getSocialMedia, getWalletShares, publishProject, validateSlug } from '../../components/Api';
import { DataContext } from '../../components/DataStore/DataStore';
import BorderedNavigationLink from '../../components/visual-utils/navigation-links/BorderedNavigationLink';
import SSPFooter from '../../components/SSPFooter/SSPFooter';
import titleIcon from '../../assets/images/page-title-icons/publish_website.png';
import collapseIcon from '../../assets/images/icons/expand_circle_down_black_24dp.svg';
import clock from '../../assets/images/icons/wall-clock_dark.png';
import {
	CheckCircleFill,
	XCircleFill,
  } from 'react-bootstrap-icons';

import './PublishWebsite.css';

import useConfirm from '../../components/common/useConfirm';
import FormInput from '../../components/common/FormInput';
import slugify from 'slugify';
import EmailPopup from '../../components/common/EmailPopup';
import { SpinnerCircular } from 'spinners-react';
import config from '../../config';

const PublishWebsite = (props) => {
	const { currentProjectId,isTheProjectPreviewable, deployStatus, checkDeployStatus, communityId } = useContext(DataContext);
	
	
	const [siteStatus, setSiteStatus] = useState(null);
	const [emailPopupVisible, setEmailPopupVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [slug,setSlug]=useState('');

	const { isConfirmed } = useConfirm();


	const location = useLocation();
	const history = useHistory();


	useEffect(()=>{
		if(deployStatus.siteStatus){
			setSiteStatus(deployStatus.siteStatus);
		}

	},[deployStatus]);


	useEffect(()=>{

		if(currentProjectId!=null){

			setIsLoading(true);

			checkDeployStatus(currentProjectId);

			getProjectInfoById(currentProjectId)
			.then(info=>{
				console.log('ProjectInfo',info);
				if(info.data.slug){
					setSlug(info.data.slug)
				}else{
					suggestSlug(info.data.name.replace(/[^a-zA-Z0-9 ]/g, ''));
				}
				setIsLoading(false);

			}).catch(e=>{setIsLoading(false);});
		}
	},[currentProjectId]);

	const suggestSlug= async(name)=>{
		let mySlug = slugify(name,{lower:true});

	    let res = await validateSlug(communityId,mySlug);

		console.log('slug validation',res);

	 	if(res.data.message=="Slug is valid"){
			console.log('??');
			setSlug(mySlug);
		}else{
			setSlug(res.data.suggested_slug);
		} 
 
	}

	const checkSlug=async(slug)=>{
		console.log('COMMUNITY ID',communityId);

		let s = slug.toLowerCase().replace(/[^a-z0-9-]/g, "");
		setSlug(s);


		let res = await validateSlug(communityId,s).catch(e=>console.log);
		console.log('slug validation',res);

	 	if(res && res.data.message && res.data.message!=="Slug is valid"){
			setSlug(res.data.suggested_slug);
		}
	}


	const onEmailClose = ()=>{
		setEmailPopupVisible(false);
		handlePublish();
	}

	const onPublishClicked =()=>{
		const storedAcc = localStorage.getItem('ACCOUNT');
        let acc=JSON.parse(storedAcc);

		if(acc.email===null){
			setEmailPopupVisible(true);
		}else{
			handlePublish();
		}
	}

	const handlePublish = async() => {
		setIsLoading(true);

		let slugRes = await validateSlug(communityId,slug);
	 	
		if(slugRes.data.message!="Slug is valid"){
			toast.warn('The slug is already in use. :/');
			setSlug(slugRes.data.suggested_slug);
			return;
		}
		setSiteStatus({status:{name:'Under Review'}});

		const res = await publishProject(currentProjectId,slug).catch((e) => console.log);
		console.log('PUBLISH RES',res);
		
		if (res.success) {
			toast.success('Project has been published succesfully.');
			localStorage.removeItem('SIF'+communityId);
			checkDeployStatus(currentProjectId);
		}else{
			toast.error(res.message)
		}
		setIsLoading(false);
	}
	return (
		<>
			<div className="row" style={{ marginBottom: '80px' }}>
				<div className="col-lg-10 mx-auto">
						<h4 className="page-title mt-1">
						<img src={titleIcon} style={{ marginRight: '3px' }} /> 7. Publish your website 
						</h4>
				</div>

				{siteStatus&&
				<div className="rounded-box boxShadow p-4 mt-3 col-lg-10  mx-auto">
					{/* <h4 className="page-title mt-0 mb-1 d-flex justify-content-between">
						<span> 
							<span className="status-icon mr-2">

								{siteStatus.status.name==='Allowed'&&<CheckCircleFill size="24px" color="#ff692b" />}
								{siteStatus.status.name==='Rejected'&&<XCircleFill size="24px" color="#fa0000"/>}
								{siteStatus.status.name==='Under Review'&&<span className="wrapper in-progress">
									<img src={clock} />
								</span>}
								{siteStatus.status.name==='Unpublished'&&<span className="wrapper base-state">
									1.
								</span>}
							</span>

						
							Publish your website
						</span>
						
					</h4> */}

					<div className={`publish-box-content`}>
 						<p>Before the initial deployment your website needs to be reviewed and approved by Galaxis. This is a standard procedure to validate the content. The review process may take up to 48 hours. Once deployed, you can go back, modify the content and submit for reapproval again. Please use the Preview button to verify your changes!</p>
 						{/* <p>You can provide your email address if you haven't already, so that we can notify you when your website has been reviewed.</p> */}

						<FormInput 
							type="text"
							label="Slug"
							prefix="https://galaxis.xyz/projects/#/"
							value={slug}
							onChange={(e)=>setSlug(e.target.value)}
							onBlur={(e)=>checkSlug(e.target.value)}
						/>

					{isLoading?
						<>
							<div className='text-center'>
							<SpinnerCircular size="32" color="#ff692b"/>
							</div>
						</>
						:
						<>
						{siteStatus.status.name!=='Unpublished' &&
						<div className=''>
							<p className="mb-0"><strong>Website status:</strong> <span className={`${siteStatus.status.name==='Rejected'?'text-warning':siteStatus.status.name==='Approved'?'text-success':'text-warning'}`}>{siteStatus.status.name==='Rejected'?'Has been delayed':siteStatus.status.name}</span></p>
							
							{siteStatus.status.name==='Rejected'&&
								<>
								<p className='mt-1 mb-2'><strong>Review:</strong></p>
								<div style={{textAlign:'left'}} dangerouslySetInnerHTML={{__html:siteStatus.reason}}/>
								</>
							}
						</div>}

						{siteStatus.status.name==='Approved' &&
						<div>
							<p className="mb-0 site-link">
								<a href={`${config.GALAXIS_URL}/projects/${slug}`} target="_blank">
									{`${config.GALAXIS_URL}/projects/${slug}`}
								</a>
							</p>
						</div>}


						</>}


					</div>
				</div>}
			</div>

			<SSPFooter
				prev=""
				next=""
				isSubmitEnabled={isTheProjectPreviewable && !isLoading}
				submit={{
				label: 'Publish website',
				handleSubmit: onPublishClicked,
				}}
			/>

			<EmailPopup visible={emailPopupVisible} hideModal={onEmailClose} />
		</>
	)
}

export default PublishWebsite;