import { Link } from 'react-router-dom';
import BorderedNavigationLink from '../visual-utils/navigation-links/BorderedNavigationLink';
import './SSPFooter.css';
import { SpinnerCircular } from 'spinners-react';

const SSPFooter = ({ prev, next, submit, dataIsLoading = false, isSubmitEnabled = true }) => {
  const handleSubmit = () => {
    if (submit.handleSubmit) {
      submit.handleSubmit();
    }
  };

  return (
    <div className="ssp-container">
      <div className="container">
        <div className="row scrollbar-fix">
          <div className="offset-lg-3 col-lg-9 col-12">
            <div className="ssp-footer-container boxShadow">
              <div className="ssp-footer row align-items-center ">
                <div className="col-3 text-left">
                  {prev && <Link to={prev}>Previous Step</Link>}
                </div>
                {dataIsLoading ? (
                  <div className="col-6 text-center">
                    <SpinnerCircular
                      size={49}
                      thickness={100}
                      color="#ff692b"
                      secondaryColor="#ddd"
                    />
                  </div>
                ) : (
                  <div className="col-6 text-center">
                    <BorderedNavigationLink
                      type="submit"
                      onClick={handleSubmit}
                      enabled={isSubmitEnabled}
                    >
                      {submit.label}
                    </BorderedNavigationLink>
                  </div>
                )}

                <div className="col-3 text-right">
                  {next && <Link to={next}>Next Step</Link>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SSPFooter;
