const common = {
    IPFS_GATEWAY : 'https://ether-cards.mypinata.cloud/ipfs/',
    GALAXIS_REGISTRY: '0x1e8150050A7a4715aad42b905C08df76883f396F',
    
    INFURA_ID: 'cba2bebfea8d47d3bd60329072bb3a6d',
    UTC_INFURA_ID: 'ac54f38020e4466b86c514f90f6f1333',
    CS_INFURA_ID: '0a2126092b9441ea9847e4a23d338e2c',
    METADATA_INFURA_ID: '581c6bfad2cf449ab77a939209691719',
    SBA_INFURA_ID: '0b972ffab4924c66bab1f8c05603ecfe'
}

const dev = {
    GENZERO: true,
    RESTART_BUTTONS_ENABLED: true,
    DEPLOYED_NTW_NAME: 'sepolia',
    DEPLOYED_CHAIN_ID: 11155111,
    DEPLOYED_EXPLORER: 'https://sepolia.etherscan.io',
    FORTMATIC_KEY: 'pk_test_DD2BBA8AAA1D4FED',
    
    RPC_URL: `https://sepolia.infura.io/v3/${common.INFURA_ID}`,
    RPC_BASE: 'https://sepolia.infura.io/v3/',
    WSS_BASE: 'wss://sepolia.infura.io/ws/v3/',
   
    POLYGON_RPC_CS: 'https://polygon-mumbai.g.alchemy.com/v2/39CEcRGhwanrJDaXSj6CrJjNIjpIwsj2',
    POLYGON_UTC_RPC_URL: 'https://polygon-mumbai.g.alchemy.com/v2/wq5i4ISC7SMlpOeWuaAwYijDh8m3oA7l',
    POLYGON_UTC_WSS:'wss://polygon-mumbai.g.alchemy.com/v2/wq5i4ISC7SMlpOeWuaAwYijDh8m3oA7l',

    POLYGON_METADATA_RPC_URL: 'https://polygon-mumbai.g.alchemy.com/v2/2XhdJGwK75AC5ndxzkZSK2-VrFmHxOVV',
    POLYGON_METADATA_WSS:'wss://polygon-mumbai.g.alchemy.com/v2/2XhdJGwK75AC5ndxzkZSK2-VrFmHxOVV',
    
    POLYGON_SBA_RPC_URL: 'https://polygon-mumbai.g.alchemy.com/v2/39CEcRGhwanrJDaXSj6CrJjNIjpIwsj2',
    POLYGON_SBA_WSS:'wss://polygon-mumbai.g.alchemy.com/v2/39CEcRGhwanrJDaXSj6CrJjNIjpIwsj2',
    
    GALAXIS_API: 'https://cms.dev.galaxis.xyz/',
    GALAXIS_URL: 'https://galaxis-teaser-dev.staging.burneth.com/#',
    MISSION_CONTROL_API : 'https://mcb.dev.galaxis.xyz/',
    CONFIG_SERVER_URL: 'https://config-server.dev.galaxis.xyz/',
    DOMAIN: 'https://app.dev.galaxis.xyz',
    METADATA_URL_RIGHT_SIDE: '/', //new url format
    CHAINS: [
        {
            id: "0xAA36A7",
            token: "sETH",
            label: "Sepolia",
            rpcUrl: `https://sepolia.infura.io/v3/${common.INFURA_ID}`, // rpcURL required for wallet balances
            blockExplorerUrl: "https://sepolia.etherscan.io"
        },
        {
            id: "0x13881",
            token: "MATIC",
            label: "Polygon Mumbai Testnet",
            rpcUrl: `https://polygon-mumbai.g.alchemy.com/v2/IiEFaEDNdChMcqNZjssqeFa0yEJ6msvP`,
            blockExplorerUrl: "https://mumbai.polygonscan.com"
        },
        {
            id: "0x66EED",
            token: "ETH",
            label: "Arb Goerli Testnet",
            rpcUrl: `https://arbitrum-goerli.infura.io/v3/2476cc27c8d14595b792f87149441c27`,
            blockExplorerUrl: "https://goerli.arbiscan.io"
        },
    ]

};

const staging = {
    RESTART_BUTTONS_ENABLED: true,
    DEPLOYED_NTW_NAME: 'sepolia',
    DEPLOYED_CHAIN_ID: 11155111,
    DEPLOYED_EXPLORER: 'https://sepolia.etherscan.io',
    FORTMATIC_KEY: 'pk_test_DD2BBA8AAA1D4FED',
    RPC_URL: `https://sepolia.infura.io/v3/${common.INFURA_ID}`,
    RPC_BASE: 'https://sepolia.infura.io/v3/',
    WSS_BASE: 'wss://sepolia.infura.io/ws/v3/',
    POLYGON_RPC_CS: 'https://polygon-mumbai.g.alchemy.com/v2/39CEcRGhwanrJDaXSj6CrJjNIjpIwsj2',

    POLYGON_UTC_RPC_URL: 'https://polygon-mumbai.g.alchemy.com/v2/wq5i4ISC7SMlpOeWuaAwYijDh8m3oA7l',
    POLYGON_UTC_WSS:'wss://polygon-mumbai.g.alchemy.com/v2/wq5i4ISC7SMlpOeWuaAwYijDh8m3oA7l',

    POLYGON_METADATA_RPC_URL: 'https://polygon-mumbai.g.alchemy.com/v2/2XhdJGwK75AC5ndxzkZSK2-VrFmHxOVV',
    POLYGON_METADATA_WSS:'wss://polygon-mumbai.g.alchemy.com/v2/2XhdJGwK75AC5ndxzkZSK2-VrFmHxOVV',
    
    POLYGON_SBA_RPC_URL: 'https://polygon-mumbai.g.alchemy.com/v2/39CEcRGhwanrJDaXSj6CrJjNIjpIwsj2',
    POLYGON_SBA_WSS:'wss://polygon-mumbai.g.alchemy.com/v2/39CEcRGhwanrJDaXSj6CrJjNIjpIwsj2',

    GALAXIS_API: 'https://cms.staging.galaxis.xyz/',
    GALAXIS_URL: 'https://galaxis-teaser.staging.burneth.com/#',
    MISSION_CONTROL_API : 'https://mcb.staging.galaxis.xyz/',
    CONFIG_SERVER_URL: 'https://config-server.staging.galaxis.xyz/',
    DOMAIN: 'https://app.staging.galaxis.xyz',
    METADATA_URL_RIGHT_SIDE: '/', //new url format
    CHAINS: [
        {
            id: "0xAA36A7",
            token: "sETH",
            label: "Sepolia",
            rpcUrl: `https://sepolia.infura.io/v3/${common.INFURA_ID}`, // rpcURL required for wallet balances
            blockExplorerUrl: "https://sepolia.etherscan.io"
        },
        {
            id: "0x13881",
            token: "MATIC",
            label: "Polygon Mumbai Testnet",
            rpcUrl: `https://polygon-mumbai.g.alchemy.com/v2/IiEFaEDNdChMcqNZjssqeFa0yEJ6msvP`,
            blockExplorerUrl: "https://mumbai.polygonscan.com"
        },
    ]
};

const prod = {
    DEPLOYED_NTW_NAME: 'mainnet',
    DEPLOYED_CHAIN_ID: 1,
    DEPLOYED_EXPLORER: 'https://etherscan.io',
    FORTMATIC_KEY: 'pk_live_FBFF1F05F2879F29',
    RPC_URL: `https://mainnet.infura.io/v3/${common.INFURA_ID}`,
    RPC_BASE: 'https://mainnet.infura.io/v3/',
    POLYGON_RPC_CS: 'https://polygon-mainnet.g.alchemy.com/v2/MQa4GlJLOClPVVOgvOd3pbvOU-AihBRH',

    POLYGON_UTC_RPC_URL: 'https://polygon-mainnet.g.alchemy.com/v2/FNhOgELwNATK4OFklA20m7ie3EOWKF5-',
    POLYGON_UTC_WSS: 'wss://polygon-mainnet.g.alchemy.com/v2/FNhOgELwNATK4OFklA20m7ie3EOWKF5-',

    POLYGON_METADATA_RPC_URL: 'https://polygon-mainnet.g.alchemy.com/v2/dYe8RrHQxvTbGQDGkQteQevUrvUrE97X',
    POLYGON_METADATA_WSS:'wss://polygon-mainnet.g.alchemy.com/v2/dYe8RrHQxvTbGQDGkQteQevUrvUrE97X',
   
    POLYGON_SBA_RPC_URL: 'https://polygon-mainnet.g.alchemy.com/v2/MQa4GlJLOClPVVOgvOd3pbvOU-AihBRH',
    POLYGON_SBA_WSS:'wss://polygon-mainnet.g.alchemy.com/v2/MQa4GlJLOClPVVOgvOd3pbvOU-AihBRH',

    WSS_BASE: 'wss://mainnet.infura.io/ws/v3/',
    GALAXIS_API: 'https://cms.galaxis.xyz/',
    GALAXIS_URL: 'https://galaxis.xyz/#',
    MISSION_CONTROL_API: 'https://mcb.galaxis.xyz/',
    CONFIG_SERVER_URL: 'https://config-server.app.galaxis.xyz/',
    DOMAIN: 'https://app.galaxis.xyz',
    METADATA_URL_RIGHT_SIDE: '/',
    CHAINS: [
        {
            id: "0x1", // chain ID must be in hexadecimel
            token: "ETH", // main chain token
            label: "Ethereum Mainnet",
            rpcUrl: `https://mainnet.infura.io/v3/${common.INFURA_ID}`, // rpcURL required for wallet balances
            blockExplorerUrl: "https://etherscan.io"
        },
        {
            id: "0x89",
            token: "MATIC",
            label: "Polygon Mainnet",
            //rpcUrl: `https://polygon-mainnet.infura.io/v3/${common.INFURA_ID}`,
            rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/BuTIJ9MV7kd-f0PDjREViHjco_DJtpI_`,
            blockExplorerUrl: "https://polygonscan.com"
        },
    ]
};

// if use npm/yarn start,  NODE_ENV = "development" 
// if use npm/yarn build,  NODE_ENV = "production"
let envConfig = prod // process.env.NODE_ENV === "development" ? dev : prod
//let config = { ...envConfig, ...common }
let config = { ...envConfig,...common}

export default config;


// pointless comment for test commit