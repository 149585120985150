import { Button, makeStyles, Box, Grid } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/galaxis_logo_dark.svg';
import BorderedNavigationLink from '../visual-utils/navigation-links/BorderedNavigationLink';
import './SSPHeader.css';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DataContext } from '../DataStore/DataStore';
import { getSession, publishProject } from '../Api';
import { toast } from 'react-toast';
import Web3Ctx from '../Context/Web3Ctx';
import AlertModal from '../common/AlertModal';

const sx = {
  rightSide: {
    marginLeft: 'auto',
    marginRight: '0',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    fontSize: '1em',
  },
};

const SSPHeader = (props) => {
  const { isTheProjectPreviewable,previewSessionUrl } = useContext(DataContext);
  const {address,chainId,handleDisconnect} = useContext(Web3Ctx);
  const location = useLocation();
  const history = useHistory();

  const [toggleNav, setToggleNav] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [loadingPreviewUrl, setLoadingPreviewUrl] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const [publishActive, setPublishActive] = useState(false);


  useEffect(()=>{
    //console.log(location);
    if(location.pathname==='/publish'){
      setPublishActive(true);
    }else{
      setPublishActive(false);
    }
  },[location]);

  const menuButton = () => {
    props.toggleSideBar();
  };
  const handleToggleNav = () => {
   // console.log(toggleNav);
    setToggleNav(toggleNav ? false : true);
  };

  const handleDashboardClick = () => {
    history.push('/dashboard');
  };

  const handleMissionControlClick = () => {
    history.push('/mission-control');
  };

  

  const onDisconnectClicked = (e) => {

    // console.log('disconnect clicked');
    
      handleDisconnect();
      if (localStorage) {
        //localStorage.removeItem('selectedWallet');
        localStorage.removeItem('ACCOUNT');
        sessionStorage.removeItem('access_token');
      }
      history.push('/');
    
  };

  const handlePreview = async () => {

    if(previewSessionUrl){
      window.open(`${previewSessionUrl}`, '_blank', 'noopener,noreferrer');
    } else {
		  toast.error("Invalif preview session.")
		}


   /*  setLoadingPreviewUrl(true);
	//	const res = await getSession(currentProjectId).catch((e) => console.log);
		const res = await getSession(communityId).catch((e) => console.log);
		if (res.success) {
		  if (res.data) {
        setPreviewUrl(res.data.url);
        setPreviewVisible(true);
			  //window.open(`${res.data.url}`, '_blank', 'noopener,noreferrer');
		  }
		} else {
		  toast.error("An error occured!")
		}
    setLoadingPreviewUrl(false); */
	  };

  return (
    <nav
      className={`top-nav navbar navbar-expand-lg fixed-top navbar-light container`}
    >
      <button
        className="navbar-toggler d-block d-lg-none"
        type="button"
        onClick={menuButton}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <a className={`logo mx-auto mx-md-0`} href="/">
        <img src={logo} alt="toolbox" />
      </a>

      <button
        className="navbar-toggler"
        type="button"
        onClick={handleToggleNav}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse ${toggleNav && 'show'}`}
        id="navbarNavDropdown"
      >
        <ul className="navbar-nav ml-auto mr-0">
             {/*  <li className="nav-item" style={{fontSize:'9px'}}>
               {address} - {chainId}
              </li> */}
             {location.pathname!=='/dashboard'&&<li className="nav-item ml-3">
                <BorderedNavigationLink
                  filled={false}
                  onClick={handlePreview}
                  enabled={isTheProjectPreviewable && !loadingPreviewUrl}
                >
                  Preview
                </BorderedNavigationLink>
              </li>}
              {/* <li className="nav-item ml-3">
                <BorderedNavigationLink 
                  enabled={isTheProjectPreviewable}
                  filled={publishActive}
                  onClick={() => {history.push('/publish')}} >
                  Publish
                </BorderedNavigationLink>
              </li> */}
             {location.pathname!=='/dashboard'&&<li className="nav-item ml-3">
                {location.pathname!=='/mission-control'?
                  <BorderedNavigationLink onClick={() => handleMissionControlClick()}>
                    Mission control
                  </BorderedNavigationLink>
                  :
                  <BorderedNavigationLink onClick={() => handleDashboardClick()}>
                    My communities
                  </BorderedNavigationLink>
                
                }
              </li>}
              <li className="nav-item ml-3">
                <BorderedNavigationLink onClick={() => onDisconnectClicked()}>
                  Log Out
                </BorderedNavigationLink>
              </li>{' '}
        </ul>
      </div>

      <AlertModal
        visible={previewVisible}
        type={'INFO'}
        hideModal={()=>setPreviewVisible(false)}
        infoCallbackFunc={previewUrl}
        modalData = {{title:'Preview your project',description:'This is a private preview. Check the content you have created here.', buttonText:'Preview'}}
      >
      </AlertModal>




    </nav>
  );
};
export default SSPHeader;
