import React, { useContext, useEffect, useState } from 'react';
import './Base.css';
import Web3Ctx from '../Context/Web3Ctx';
import config from '../../config';
import { makeStyles } from '@material-ui/core/styles';
import SSPHeader from '../SSPHeader/SSPHeader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DataContext } from '../DataStore/DataStore';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import SSPSidebar from '../SSPSidebar/SSPSidebar';
import AlertModal from '../common/AlertModal';
import { useHistory } from 'react-router-dom';
import useConfirm from '../common/useConfirm';
import { checkAccessToken, checkAuth } from '../Api';
import { toast } from 'react-toast';

const withoutSidebar = ["/","/mint-pass","/welcome","/dashboard","/mission-control","/config-community-space","/config-utc","/config-stickerbook","/config-marketplace","/config-leaderboard","/config-vault"];
const withoutNav = ["/welcome","/sandbox","/wallet-connect","/","/emission-control"];
const multipleAddresses = ["/deploy-contracts"];
const projectIdNotNeeded = ["/","/dashboard"];

const useStyles = makeStyles({
    root: {
        width:'100%',
        height:'calc(100% - 80px)',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 0  
    },
    title: {
        fontSize: 32,
        color: '#FFF',
        textAlign: 'center',
    },
    

    content:{
        position:'fixed',
        left:'306px',
        top:80,
        width:'calc(100% - 306px)',
        height:'calc(100% - 80px)',
        overflow:'auto',
        '@media (max-width:768px)':{
            left:0,
            top:'80px',
            width:'100%',
            height:'calc(100% - 80px)',
        
            }
        },
        contentNoSb:{
            position:'fixed',
            left:0,
            top:'80px',
            width:'100%',
            height:'calc(100% - 80px)',
            overflow:'auto'
        },
        noNav:{
            position:'fixed',
            left:0,
            top:0,
            width:'100%',
            height:'100%',
            overflow:'auto'
        }



});

const Base = (props) => {
    const [mobileShowSidebar, setMobileShowSidebar] = useState(false);
    const classes = useStyles();
    const location = useLocation();
    const { onboard, address } = useContext(Web3Ctx);

    const disableSidebar = withoutSidebar.includes(location.pathname);
    const disableNav = withoutNav.includes(location.pathname);
    const canChangeAddress = multipleAddresses.includes(location.pathname);
    
    const history = useHistory();
    
    const {currentProjectId, initDone, accountInfo, logOut} = useContext(DataContext);

    const { isConfirmed } = useConfirm();

    const toggleSideBar = ()=>{
        setMobileShowSidebar(!mobileShowSidebar);
    }

    const hideSidebar = () => {
        setMobileShowSidebar(false);
    }

    useEffect(()=>{
        if(!address){
            if(logOut){
                console.log('LOGOUT',address);
                logOut();
            }
            history.push('/');
        }
    },[address])

    useEffect(()=>{
        const takeAction = async()=>{

            if(location.pathname==='/'){
                return;
            };


            //console.log('BASE',currentProjectId,location.pathname,initDone,address,accountInfo);


            if((address && accountInfo && accountInfo.address) && address.toLowerCase()!==accountInfo.address.toLowerCase() && !canChangeAddress){
                if(logOut){
                    console.log('LOGOUT');
                    logOut();
                }
                redirectTo('Your wallet address has been changed.','/','Login');
                return;
            }

            const res = await checkAccessToken(); // we are only check the existence of the token at the moment :/
            
          // console.log('result of check token',res);
            if(!res.success){
                if(logOut){
                    console.log('LOGOUT');
                    logOut();
                }

                redirectTo('Unauthorised access. Please log in.','/','Login');
                return;
            }else{
                let me = await checkAuth();
                if(me.success===false && me.status!=429){
                    console.log('LOGOUT');
                    logOut();
                    redirectTo('Unauthorised access. Please log in.','/','Login');
                    return;
                }else{
                    if(me.status===429){
                        toast.error('Too many requests.')
                    }
                }
            }

            
            const needId = !projectIdNotNeeded.includes(location.pathname);
            if(needId){
                if(!currentProjectId){
                    redirectTo('No project ID found. Please choose a project from the dashboard.','/dashboard','Dashboard');
                }
            }
            
        }


       // console.log('base:::',location,initDone,address);
        if(initDone){
         takeAction()
        }

    },[location,initDone,address])

    const redirectTo = async(message,pathTo,buttonLabel)=>{
        const confirmed = await isConfirmed('Warning',message,buttonLabel);
   
        if(confirmed){
            history.push(pathTo);
        }

    }

    return (
    <>
        <div className="container h-100 pt-80">
            {!disableNav &&
                <SSPHeader
                    onboard={onboard}
                    address={address}
                    toggleSideBar = {toggleSideBar}
                />
            }

            <div className="row position-relative">
                {!disableSidebar&&
                    <div className={`col-3  d-none d-lg-block fix-height side-container ${ mobileShowSidebar&&'mobil-side'}`}>
                        <div className='side-bar'>
                            <SSPSidebar onClose={hideSidebar}/>
                        </div>
                    </div>
                }

                    <div id="content-container" className={`${disableSidebar?'col-12':'col-lg-9 col-12 fix-height'}  pb-160`}>
                        {props.children}
                    </div>
            </div>


        </div>
        
     {/*    <AlertModal visible={true} type={'ADDRESS_ALERT'} modalData={{title:'Warning'}}>
            <p className='my-5'>Your wallet address has been changed.</p>
        </AlertModal>  */}

    </>
    );
};

export default Base;
