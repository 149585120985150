import { useEffect, useState } from 'react';
import RoundedBox from '../visual-utils/RoundedBox';
import './AlertModal.css';
import saveWarningIcon from '../../assets/images/icons/exclamation-mark.svg';
import errorIcon from '../../assets/images/icons/error-sign.svg';
import useConfirm from './useConfirm';

const NORMAL_COLOR = '#ff692b';

const styleConfig = {
  width: '100%',
  maxWidth: '508px',
};

const ConfirmModal = (props) => {
  const {title="", prompt = "", positiveLabel = "OK", negativeLabel, confType="", isOpen = false, proceed, cancel } = useConfirm();
  
  useEffect(() => {
    const handleKeydown = (e) => {
      if (proceed && isOpen && e.key === "Enter") {
        proceed();
      }
    };

    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [proceed, isOpen]);

  const Icon = ({confType}) => {
    return (
      <div className="text-center pt-3">
        <img src={confType==="ERROR"?errorIcon:saveWarningIcon} />
      </div>
    );
  };

  return (
    <>
      {isOpen && (
        <div
          className="modal warning-modal"
          style={{ display: 'block' }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <RoundedBox styleConfig={styleConfig}>
              <div className="modal-content">
              <Icon confType={confType}/>
                <div className="modal-header justify-content-center mt-4">
                  <h5 className="modal-title text-center" id="exampleModalLongTitle">
                    {title}
                  </h5>
                </div>
                <div className="modal-body row">

                  <div className="col-lg-10 mx-auto mt-3 mb-5 text-center">
                    <div style={{textAlign:'left'}} dangerouslySetInnerHTML={{__html:prompt}}/>
                  </div>
                  
                </div>
                <div className="modal-footer">
                    <button
                      type="button"
                      className={`btn ${confType=="ERROR"?"error":"normal"}`}
                      data-dismiss="modal"
                      onClick={proceed}
                    >
                      {positiveLabel}
                    </button>

                    {negativeLabel&&<button
                      type="button"
                      className={`btn`}
                      style={{ color: NORMAL_COLOR }}
                      data-dismiss="modal"
                      onClick={cancel}
                    >
                      {negativeLabel}
                    </button>}
                </div>
              </div>
            </RoundedBox>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmModal;
