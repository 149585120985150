import React, { useContext, useEffect, useState } from 'react';
import DashboardPanel from './common/DashboardPanel';
import myicon from '../../../assets/images/mc/CS.svg';
import { state } from '../MissionControl';
import { communityHealthcheck, restartUTC } from '../../../components/Api';
import { DataContext } from '../../../components/DataStore/DataStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../../config';



const CommunitySpace = ({communityId})=>{

	const handleGoToConfigCommunity = () =>{
		history.push('/config-community-space')
	}
	
	return(<>
		<DashboardPanel
			comingSoon={true}
			
			disabled={true}
			title="Community space"
			icon={myicon}
			cta={{label:"configure", action:()=>{}}}
		/>
	</>
	);
}

export default CommunitySpace;

