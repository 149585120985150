import React, { useContext, useEffect, useState } from 'react';
import DashboardPanel from './common/DashboardPanel';
import myicon from '../../../assets/images/mc/rewards.svg';
import { state } from '../MissionControl';
import { communityHealthcheck, restartUTC } from '../../../components/Api';
import { DataContext } from '../../../components/DataStore/DataStore';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import config from '../../../config';



const Rewards = ({communityId})=>{
	const [components,setComponents] = useState(null);
	const [rewardsDisabled,setRewardsDisabled] = useState(true);

	const {
		deployStatus
	  } = useContext(DataContext);

	useEffect(()=>{
		


		if(communityId && deployStatus?.contracts){
			getHealtcheck();
			console.log('RewardsPanel mounted. community: ',communityId,deployStatus);
		}else{
			//setComponents([])
			setComponents([
				{label:'Admin', status:state.INITIAL,restart:false}])
		}
	},[communityId,deployStatus]);

	const getHealtcheck = async ()=>{
		let admin = state.INITIAL;


		let health = await communityHealthcheck(communityId).catch(e=>console.log);
		
		if(health){
			console.log('Rewards health',health);
	
			if(health.marketplace){
				
			}

			setComponents([
				{label:'Admin', status:admin}
			]);

			if(deployStatus?.contracts?.token){
				setRewardsDisabled(false);
			}
		}else{
			console.log('healtcheck not found');
		}
	}

	return(<>
		<DashboardPanel
			comingSoon={true}
			components={components}
			disabled={true}
			title="Reward Room"
			icon={myicon}
			cta={{label:"configure", action:()=>{}}}
		/>
	</>
	);
}

export default Rewards;

