import { useContext, useEffect, useRef, useState } from "react";
import { updateLayerImageName } from "../../components/Api";
import editIcon from '../../assets/images/icons/edit.svg'
import deleteIcon from '../../assets/images/icons/delete_black.svg'
import { DataContext } from "../../components/DataStore/DataStore";
import useConfirm from "../../components/common/useConfirm";
import { padZeros } from "../../components/Utils";
import { LazyImage } from "../../components/common/LazyImage";
import { toast } from "react-toast";


const LayerImagePreview = ({listView,index,isManual,imageData, className, width, height, getAvailable, updateLocalSet, onDelete, onUpdateSuccess, disabled})=>{
	const { nftsLength } = useContext(DataContext);
	const [hasError,setHasError] = useState(false);
	const id = Math.random();
	const editRef = useRef(null);
	const distributionRef = useRef(null);
	const [distribution,_setDistribution] = useState(0);
	const [initialDistribution,setInitialDistribution] = useState(0);
	const [imageName,setImageName] = useState('');

	const [totalAvailable,setTotalAvailable] = useState(0);
	const [totalError,setTotalError] = useState(false);
	const distRef = useRef(distribution);
	const { isConfirmed } = useConfirm();

	const setDistribution = (val)=>{
		distRef.current = val;
		_setDistribution(val);
	}


	useEffect(()=>{

		//console.log('Image DATA', imageData,isManual);

		if(imageData.rarity!==undefined){
			setDistribution(imageData.rarity);
			setInitialDistribution(imageData.rarity);
		}
		if(imageData.name!==undefined){
			setImageName(imageData.name);
		}

		if(getAvailable){
			setTotalAvailable(getAvailable());
		}

	},[imageData,isManual])

	const handleOnFocus=(e)=>{
		console.log('EVNT',e)
		e.currentTarget.textContent='';
		if(getAvailable){
			let ta = getAvailable();
			console.log('REM',ta);
			setDistribution(Number(imageData.rarity));
			setInitialDistribution(Number(imageData.rarity));
			setTotalAvailable(ta);
		}
	}

	const handleOnKeyDown = (e)=>{
		if(e.keyCode===13){
			e.preventDefault();
			
			if(e.currentTarget.textContent===''){
				console.log('Error');
				setHasError(true);
				return;
			}
			e.target.blur();
		}
	}

	const handleOnBlur = async (e)=>{
		let newName = e.currentTarget.textContent;
		if(newName===''){
			e.currentTarget.textContent=imageData.name;
			console.log('Error');
			return;
		}else if(newName===imageData.name){
			return;
		}
		
		setHasError(false);
		console.log('SAVE now', imageData.id);
		updateLocalSet(newName,distribution);

		let res = await updateLayerImageName(imageData.id,{name:newName,rarity:distribution}).catch(e=>console.log);

		if(res.success){
			console.log('updated');
			toast.success('Image name updated.');
		}else{
			console.log('failed',res.message);
			toast.error('Image name update failed.');
		}
	} 

	const handleDistributionKeyDown = (e) =>{
		if(e.keyCode===13){
			e.preventDefault();
			
			if(e.currentTarget.textContent===''){
				console.log('Error');
				setHasError(true);
				return;
			}
			e.target.blur();
		}
	}

	const handleDistributionKeyUp = (e)=>{
		
		let dist = Number(e.currentTarget.textContent);
		dist=dist?dist:0;
		setDistribution(dist);
		
		if(totalAvailable-(distRef.current-initialDistribution)<0){
			setTotalError(true);
		}else{
			setTotalError(false);
		}
	}

	const handleDistributionBlur = async (e)=>{
	    console.log('hasError',totalError);
		if(totalError){
			if(Number(e.currentTarget.textContent)>0){
				console.log('HAS errror (and not 0)');
				e.currentTarget.textContent=initialDistribution;
				setTotalError(false)
				return;
			}else{
				setTotalError(false)
			}
		}

		


		let newValue = e.currentTarget.textContent;
		if(newValue===''){
			e.currentTarget.textContent=initialDistribution;
			console.log('Error');
			setTotalError(false)
			return;
		}else if(Number(newValue)===initialDistribution){

			return;
		}
		
		setHasError(false);

		if(nftsLength>0){
			const confirmed = await isConfirmed('Updating distribution','This action will affect the previously generated collection. The collection will be deleted, also the Utility Trait assignment.','Proceed','Back');
			if(!confirmed){
				return;
			}
		}

		console.log('NEW VALUE TO SEND',Number(newValue));
		updateLocalSet(imageData.name,Number(newValue));
		let res = await updateLayerImageName(imageData.id,{name:imageData.name,rarity:newValue}).catch(e=>console.log);

		if(res.success){
			console.log('updated');
			toast.success('Distribution updated.')
			if(onUpdateSuccess){
				onUpdateSuccess();
			}
		}else{
			console.log('failed',res.message);
			toast.error('Distribution update failed.')
		}

	} 

	const focusInput = () =>{
		//document.getElementById(id).focus();
		editRef.current.focus();
	}

	const deleteImage = () =>{
		//alert("delete image " + imageData.id)
		if(onDelete){
			onDelete(imageData.id);
		}
	}

	return (
		<>
		{listView?
		<div className="col-lg-12 mx-n5">
			<div className={`${className} ${index%2?'even':'odd'} small-gutters full-width px-3 py-3  align-items-stretch`}>
				
				<div className="col-2 pr-0 pr-md-4">
					<div className="layer-image-preview" style={{paddingTop: (height/width*100)+'%'}}>
					{imageData.thumb_url?
						<img src={imageData.thumb_url}/>
						:
						<LazyImage idx={index} src={imageData.original_url.replace("/original/","/thumb/")} />
					}
					
					</div>
				</div>

				<div className="col-2 d-flex align-items-center">
				<p style={{
							fontSize:"14px",
							lineHeight:"18px",
							fontWeight:'normal',
							paddingRight:"12px"
						}}>
					{padZeros(imageData.dns.toString(16),2).toUpperCase()}
					</p>
				</div>

				<div className={`${isManual?'col-5':'col-8'} d-flex align-items-center`}>
					<p  id={id} className={`editable ${hasError?'error':''}`}
						ref={editRef}
						tabIndex={1+index*2}
						style={{
							fontSize:"14px",
							lineHeight:"18px",
							fontWeight:'normal',
							paddingRight:"12px",
							maxWidth: '100%'
						}}
						contentEditable={disabled?false:true}
						spellCheck="false" 
						suppressContentEditableWarning={true}
						onKeyDown={handleOnKeyDown} 
						onBlur={handleOnBlur}>
						{imageData.name}
					</p>

					{!disabled&&<div className="item-buttons">
						<button title="Edit image name" className="btn-with-icon p-0" onClick={focusInput}><img src={editIcon}/></button>
						<button title="Delete image" className="btn-with-icon p-0" onClick={deleteImage}><img src={deleteIcon}/></button>
					</div>}
				</div>

				{isManual&&
				<div className="distribution-input col-3 d-flex align-items-center"  >
					&nbsp;
					<p ref={distributionRef}
					    className="editable"
						style={{
							fontSize:"14px",
							lineHeight:"18px",
							fontWeight:'normal',
							paddingRight:"12px"
						}}
						tabIndex={1+index*2+1}
						contentEditable={disabled?false:true}
						spellCheck="false" 
						suppressContentEditableWarning={true}
						onKeyDown={handleDistributionKeyDown} 
						onKeyUp={handleDistributionKeyUp} 
						onBlur={handleDistributionBlur}
						onFocus={handleOnFocus}>
						{initialDistribution}
					</p>
					<div className={`total-display ${(totalError)?'error':''}`}>
						Total:&nbsp; 
						<strong>
							{(totalAvailable-(distRef.current-initialDistribution))<0?
								'<0'
								:
								totalAvailable-(distRef.current-initialDistribution)
							}
						</strong>
					</div>
				</div>}
			</div>
		</div>
		:
		<div className={`${className}`}>
			<div className="layer-image-preview" style={{paddingTop: (height/width*100)+'%'}}>
				
				{imageData.thumb_url?
					<LazyImage idx={index} src={imageData.thumb_url+'?id='+imageData.id} />
					:
					<LazyImage idx={index} src={imageData.original_url.replace("/original/","/thumb/")+'?id='+imageData.id} />
				}

				{isManual&&<div className="distribution-input"  onClick={(()=>{distributionRef.current.focus()})}>
					Distribution:&nbsp;
					<span ref={distributionRef}
					   tabIndex={1+index*2}
					   contentEditable={disabled?false:true}
					   spellCheck="false" 
					   suppressContentEditableWarning={true}
					   onKeyDown={handleDistributionKeyDown} 
					   onKeyUp={handleDistributionKeyUp} 
					   onBlur={handleDistributionBlur}
					   onFocus={handleOnFocus}>
						{initialDistribution}
					</span>
				</div>}
				<div className={`total-display ${(totalError)?'error':''}`}>
					Total:&nbsp; 
					<strong>
						{(totalAvailable-(distRef.current-initialDistribution))<0?
							'<0'
							:
							totalAvailable-(distRef.current-initialDistribution)
						}
					</strong>
				</div>

			</div>
			<div className="d-flex mb-4 w-100">
				<p style={{
							width:'12%',
							fontSize:"14px",
							marginTop:"10px",
							lineHeight:"18px",
							fontWeight:'normal',
							paddingRight:"2px"
						}}>{padZeros(imageData.dns.toString(16),2).toUpperCase()}</p>
			
					<p id={id} className={`editable ${hasError?'error':''}`}
					ref={editRef}
					tabIndex={1+index*2+1}
					style={{
						width:'78%',
						fontSize:"14px",
						marginTop:"10px",
						lineHeight:"18px",
						fontWeight:'normal',
						paddingRight:"12px",
						whiteSpace:'wrap'
					}}
					contentEditable={disabled?false:true}
					spellCheck="false" 
					suppressContentEditableWarning={true}
					onKeyDown={handleOnKeyDown} 
					onBlur={handleOnBlur}>
					{imageData.name}
				</p>

				{!disabled&&<div className="d-flex flex-column " style={{marginTop:"10px"}} >
					<button title="Edit image name" className="btn-with-icon p-0" onClick={focusInput}><img src={editIcon}/></button>
					<button title="Delete image" className="btn-with-icon p-0" onClick={deleteImage}><img src={deleteIcon}/></button>
				</div>}
			</div>
		</div>
		
	
	}

	</>	
	);

}

export default LayerImagePreview;