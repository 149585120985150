import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SpinnerCircular, SpinnerCircularSplit, SpinnerInfinity } from 'spinners-react';
import RoundedBox from '../visual-utils/RoundedBox';
import './AlertModal.css';
const DeployInProgressModal = (props) => {
  const {visible,titleText,message, destinationUrl,appButtonLabel} = props;
  const history = useHistory();
  const styleConfig = {
    width: '100%',
    maxWidth: '580px',
  };

  return (
    <>
      {visible && (
        <div
          className="modal warning-modal"
          style={{ display: 'block',backgroundColor:"rgba(0,0,0,0.8)" }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <RoundedBox styleConfig={styleConfig}>
              <div className="modal-content text-center">
                <h5 className="modal-title mb-4" id="exampleModalLongTitle">
                  {titleText}
                </h5>
                {!destinationUrl&&<SpinnerCircular 
                  className='mx-auto mb-4' 
                  size={49}
                  thickness={100}
                  color="#ff692b"
                  secondaryColor="#ddd"/>}

                  <p>{message} {destinationUrl}</p>

                  {destinationUrl&&
                    <div className='row'>
                      <div className='col-md-6'>
                        <Link to={'mission-control'}  className={`btn borderedNavLink normal mission inline`}>
                          MISSION CONTROL
                        </Link>
                      </div>

                      <div className='col-md-6'>
                        <a href={destinationUrl} target="_blank" className={`btn borderedNavLink normal mission inline`}
                        onClick={()=>history.goBack()}>
                          {appButtonLabel}
                        </a>
                      </div>

                      

                    </div>
                  }


              </div>
            </RoundedBox>
          </div>

        </div>
      )}
    </>
  );
};

export default DeployInProgressModal;
