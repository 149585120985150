import React, { useContext, useEffect, useState } from 'react';
import { DataContext, formSkeleton } from '../../components/DataStore/DataStore';
import {useFormik } from 'formik';
import FormInput from '../../components/common/FormInput';
import RoundedBox from '../../components/visual-utils/RoundedBox';
import twitter from '../../assets/images/icons/twitter.svg';
import discord from '../../assets/images/icons/discord.svg';
import facebook from '../../assets/images/icons/facebook.svg';
import insta from '../../assets/images/icons/insta.svg';
import website from '../../assets/images/icons/website.svg';
import opensea from '../../assets/images/icons/opensea_orange_1.svg';
import youtube from '../../assets/images/icons/youtube_orange 1.svg';
import SSPFooter from '../../components/SSPFooter/SSPFooter';
import titleIcon from '../../assets/images/page-title-icons/social-media.svg';
import AlertModal from '../../components/common/AlertModal';
import { formState } from '../../components/DataStore/DataStore';
import { createSocialMedia } from '../../components/Api/index';
import { toast } from 'react-toast';
import { Prompt } from 'react-router-dom';
import InfoBox from '../../components/common/InfoBox';

const socialIcons = [twitter, discord, facebook, insta, website,opensea,youtube];

const styleConfig = {
  borderRadius: '20px',
  padding: '30px 30px 30px 70px',
  marginTop: '20px',
  backgroundColor: '#fff',
};

const SocialMedia = (props) => {
  const PAGE = 'SOCIAL_MEDIA';
  const {updateFormV2, currentProjectId, updatePageState, initDone, getSocialData, getSocMediaTypes } =
    useContext(DataContext);
  
  //Alert stuff
  const [alertType, setAlertType] = useState('ERROR');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertButtonLabel, setAlertButtonLabel] = useState('Close');
  const [alertTitle, setAlertTitle] = useState('Upload error');
  const [alertVisible, setAlertVisible] = useState(false);
  const [targetPath, setTargetPath] = useState('');

  //Social media types
  const [socialMediaTypes, setSocialMediaTypes] = useState(null);
  const [form,setForm] = useState({...formSkeleton[PAGE].form});
  const [formProperties] = useState({...formSkeleton[PAGE].formProperties});
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: form,
    validationSchema: formSkeleton[PAGE].formScheme,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      let socialMediaObjects = [];

      
     // console.log('mediatypeSSS',socialMediaTypes);
      for (const value in values) {
          let socialObj = {
            url: values[value],
            type_id: socialMediaTypes[value],
          };

         // console.log(value,values[value],socialMediaTypes[value]);
          socialMediaObjects.push(socialObj);
      }

      const data = { social_medias: socialMediaObjects };
      let res = null;

     // console.log('data',data)

      res = await createSocialMedia(parseInt(currentProjectId), data).catch((e) => console.log);
      if (res.success) {
        //console.log(res, " res data")
        let hasData = false;
          Object.keys(res.data).forEach((key,idx)=>{
       
            if(res.data[key].url!==''){
              hasData=true;
            }
          })
          if(hasData){
            updatePageState(PAGE, formState.FORM_SAVED);
          }else{
            updatePageState(PAGE, formState.FORM_EMPTY);
          }
       // updatePageState(PAGE,formState.FORM_SAVED);
        toast.success("Successfully updated")
        if(targetPath){
          props.history.push(targetPath);
        }
      } else {
        toast.error("error");
       // console.log(res.message);
      }
    },
  });

  const handleFormikChange=(e,i)=>{
    e.target.value=e.target.value.trim();
    formik.handleChange(e);
    updateFormV2(PAGE,i,e.target.value);
}

  /* here we get the social media types, we need these for the other api calls*/
  useEffect(() => {
    (async () => {
      let localSocialMediaTypes = {};
      const res = getSocMediaTypes();
     // console.log('RESSSS',res);
      if (res) {


        res.forEach((type) => {
          localSocialMediaTypes[type.name] = type.id;
        });
       // console.log('soc media types',localSocialMediaTypes);
        setSocialMediaTypes(localSocialMediaTypes);
      }
      
    })();
  }, []);

  useEffect(() => {
    if(initDone && currentProjectId && getSocMediaTypes && getSocialData){

  

    (async () => {
      const socialMedia = getSocialData();

      if (socialMedia) {
       // console.log('the form', form,socialMedia);

        




        




        if(socialMedia.items>0){
          let hasData = false;
          Object.keys(socialMedia.data).forEach((key,idx)=>{
            if(socialMedia.data[key]!==''){
              hasData=true;
            }
          })
          

          if(hasData){
            updatePageState(PAGE, formState.FORM_SAVED);
          }




          setForm({...socialMedia.data});
          await formik.validateForm();
        }
      }
    })();

  }
  }, [currentProjectId,initDone]);


  const waitForUser = (loc)=>{
    setAlertType('SAVE_WARNING');
    setAlertTitle('Unsaved data');
    setAlertMessage('Save and go?')
    setAlertVisible(true);
    setTargetPath(loc);
   // console.log(loc);
}
const sf=()=>{
  formik.handleSubmit();
}

useEffect(()=>{
  if(formik.dirty){
    updatePageState(PAGE, formState.FORM_UNSAVED);
  }
},[formik.dirty]);

  return (
    <><Prompt
    when={targetPath===''}
    message={(nextLocation)=>{
        
        if(formik.isValid && formik.dirty && formik.submitCount===0){
            waitForUser(nextLocation.pathname);
        }else{
            return true;
        }
            return false;
        }
    }
/>
    <div className="row">
      <div className="col-lg-8 mx-auto">
        <div className="social-media-page" style={{ margin: '0 auto' }}>
          <div style={{ display: 'flex'}}>
            <h4 className="page-title">
              <img src={titleIcon} style={{ marginRight: '5px' }} /> 2. Social
              Media
            </h4>
            <InfoBox position="bottom" title="Social Media">
            <p>Enter the link(s) of your social media if any. If you provide the links here, they will appear on your project page. You can also add them later, but you will have to re-publish your website. After providing the social media link(s), please click on “Submit Social Media” and then you can preview it again. 
            </p><p>Create your collection by clicking on “Next step” or jump directly to step 7. Publish website - to gain online visibility and begin building your community. After publishing your website, come back to step 3. Preferences, layers, images.</p></InfoBox>
          </div>
          <RoundedBox styleConfig={styleConfig} hasShadow={true}>
            {form&&<form onSubmit={formik.handleSubmit}>
              {Object.keys(formProperties).map((key, i) => {
                return (
                  <React.Fragment key={i}>
                    <FormInput
                      key={i}
                      id={formProperties[key].label}
                      label={formProperties[key].label==='Web'?'Website':formProperties[key].label}
                      mbNone={i === form.length-1}
                      isSocialInput={true}
                      socialIcon={socialIcons[i]}
                      type='text'
                      onChange={(e)=>handleFormikChange(e,key)}
                      onBlur={(e)=>{formik.handleBlur(e);}}
                      value={formik.values[key]}
                      error={
                        formik.touched[key] && formik.errors[key]
                          ? formik.errors[key]
                          : null
                      }
                    />
                  </React.Fragment>
                );
              })}

            </form>}

          </RoundedBox>
          <AlertModal
            visible={alertVisible}
            type={alertType}
            hideModal={() => setAlertVisible(false)}
            modalData={{
              title: alertTitle,
              description: alertMessage,
              buttonText: alertButtonLabel,
            }}
            saveFunc = {sf}
          ></AlertModal>
          <SSPFooter
            prev="/project-info"
            next="/layers-and-cards"
            submit={{
              label: 'Submit Social Media',
              handleSubmit: formik.handleSubmit,
            }}
          />
        </div>
      </div>
    </div></>
  );
};

export default SocialMedia;
