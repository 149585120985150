import { useEffect, useState } from 'react';
import RoundedBox from '../visual-utils/RoundedBox';
import './AlertModal.css';
import errorIcon from '../../assets/images/icons/error-sign.svg';
import warningIcon from '../../assets/images/icons/sign.svg';
import saveWarningIcon from '../../assets/images/icons/exclamation-mark.svg';
import infoIcon from '../../assets/images/icons/preview_orange.png';

const ERROR_COLOR = '#F50808';
const NORMAL_COLOR = '#ff692b';

const styleConfig = {
  width: '100%',
  maxWidth: '508px',
};
const sampleModalData = {
  title: 'Modal title',
  description: 'Modal description',
  buttonText: 'Button text',
};
const types = {
  ERROR: {
    icon: errorIcon,
    color: ERROR_COLOR,
  },
  WARNING: {
    icon: warningIcon,
    color: NORMAL_COLOR,
  },
  SAVE_WARNING: {
    icon: saveWarningIcon,
    color: NORMAL_COLOR,
  },
  DELETE_WARNING: {
    icon: saveWarningIcon,
    color: NORMAL_COLOR,
  },
  INFO: {
    icon: infoIcon,
    color: NORMAL_COLOR,
  },
  ADDRESS_ALERT: {
    icon: warningIcon,
    color: NORMAL_COLOR,
  }
};
//// Modal types :  WARNING, ERROR, SAVE_WARNING, INFO

// const AlertModal = ({ visible = false, hideModal, saveFunc, deleteFunc, type='WARNING', modalData=sampleModalData}) => {
const AlertModal = (props) => {
  const { visible, hideModal, modalData, type, saveFunc, deleteFunc, infoCallbackFunc, children ,closeWithButtonOnly} = props;
  const currentType = types[type];

  const Icon = () => {
    return (
      <div className="text-center pt-3">
        <img src={currentType.icon} />
      </div>
    );
  };
  return (
    <>
      {visible && (
        <div
          className="modal warning-modal"
          style={{ display: 'block' }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          onClick={() =>{ if(!closeWithButtonOnly){hideModal();}}}
        >
          <div className={`modal-dialog modal-dialog-centered ${children?'modal-lg':''}`} role="document">
            <RoundedBox styleConfig={!children?styleConfig:{}}>
              <div className="modal-content">
                <Icon />
                {children?
                <>
                  {children}
                </>
                :
                <>
                  <div className="modal-header justify-content-center mt-4">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      {modalData.title}
                    </h5>
                  </div>
                  <div className="modal-body text-center">
                    {modalData.description ? modalData.description : props.children }
                  </div>
                </>

                }
                <>
                <div className="modal-footer">
                  {type !== 'ADDRESS_ALERT'&&<>
                    {type === 'SAVE_WARNING' && (
                      <button
                        type="button"
                        className="btn"
                        style={{ color: currentType.color }}
                        data-dismiss="modal"
                        onClick={() => saveFunc()}
                      >
                        Save & Leave
                      </button>
                    )}
                    {type === 'DELETE_WARNING' && (
                      <button
                        type="button"
                        className="btn"
                        style={{ color: currentType.color }}
                        data-dismiss="modal"
                        onClick={() => deleteFunc()}
                      >
                        Delete
                      </button>
                    )}

                    {(type === 'INFO' && typeof infoCallbackFunc === 'function') && (
                      <button
                        type="button"
                        className="btn"
                        style={{ color: currentType.color }}
                        data-dismiss="modal"
                        onClick={() => infoCallbackFunc?infoCallbackFunc():hideModal()}
                      >
                        {modalData.buttonText}
                      </button>
                    )}

                    {(type === 'INFO' && typeof infoCallbackFunc !== 'function') && (
                      <a 
                        href={infoCallbackFunc}
                        target="_blank"
                        className="btn"
                        style={{ color: currentType.color }}
                        data-dismiss="modal"
                        onClick={() => hideModal()}
                      >
                        {modalData.buttonText}
                      </a>
                    )}

                    {type !== 'INFO' && <button
                      type="button"
                      className="btn"
                      style={{ color: currentType.color }}
                      data-dismiss="modal"
                      onClick={() => hideModal()}
                    >
                      {modalData.buttonText}
                    </button>}
                  </>}
                </div>
                </>
              </div>
            </RoundedBox>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertModal;
