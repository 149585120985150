import React, { useContext, useEffect, useRef, useState } from 'react';
import {DataContext} from '../../components/DataStore/DataStore';
import { useFormik } from 'formik';
import FormInput from '../../components/common/FormInput';
import SSPFooter from '../../components/SSPFooter/SSPFooter';
import titleIcon from '../../assets/images/page-title-icons/speedometer.svg';
import AlertModal from '../../components/common/AlertModal';
import {
  createProjectInfo,
  updateProjectInfo,
} from '../../components/Api';
import { toast } from 'react-toast';
import { formState } from '../../components/DataStore/DataStore';
import { Prompt } from 'react-router-dom';
import InfoBox from '../../components/common/InfoBox';
import './ProjectInfo.css';
import { formSkeleton } from '../../components/DataStore/DataStore';

//import { useBeforeunload } from 'react-beforeunload';

const ProjectInfo = (props) => {
  const PAGE = 'PROJECT_INFO';
  const {
    updateFormV2,
    currentProjectId,
    updatePageState,
    getProjectInfoData,
    setProjectPreviewable,
    currentCommunityName,
    setHelpModalVisible,
    initDone,
    clearPublishWebsiteError
  } = useContext(DataContext);

  const ref = useRef(null);

  const [alertType, setAlertType] = useState('ERROR');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertButtonLabel, setAlertButtonLabel] = useState('Close');
  const [alertTitle, setAlertTitle] = useState('Upload error');
  const [alertVisible, setAlertVisible] = useState(false);
  const [targetPath, setTargetPath] = useState('');

  const [projectInfoId, setProjectInfoId] = useState(null);

  const [form,setForm] = useState({...formSkeleton[PAGE].form});
  const [formProperties] = useState({...formSkeleton[PAGE].formProperties});


  

  useEffect(()=>{
    let firstLaunch = localStorage.getItem('ftmsg');
    if(!firstLaunch){
      setHelpModalVisible(true);
      localStorage.setItem('ftmsg',true);
    }
  },[])

  useEffect(() => {
    const populateForm = async () => {
      //  console.log('CURRENT PROJECT', currentProjectId,getProjectInfoData);
    
        const projectInfoData = getProjectInfoData();

       // console.log('PINFO DATA',projectInfoData);

        let tmpForm = {...form}; 
        Object.keys(formik.values).forEach((item) => {
          formik.values[item] = projectInfoData[item];
          tmpForm[item] = projectInfoData[item];
        });
        setProjectInfoId(projectInfoData.id);
        setProjectPreviewable(projectInfoData.token_symbol!=="");
        setForm({...tmpForm});
        await formik.validateForm();
  };

    if (currentProjectId && getProjectInfoData) {
      populateForm();
    }else{
      setForm({...form,name:currentCommunityName});
      setProjectPreviewable(false);
    }
  }, [currentProjectId, initDone]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: form,
    validationSchema: formSkeleton[PAGE].formScheme,
    validateOnBlur: true,
    validateOnChange: true,
    onChange: (v) => {
      console.log('change', v);
    },
    onSubmit: async (values) => {
      console.log(values);
      const data = {
        ...values,
        token_symbol: values.token_symbol.toUpperCase(),
        project_id: currentProjectId,
      };

     /*  if(data.footer===""){
        delete data.footer;
      } */

     // console.log('DATA',data)
      let res = null;
      if (projectInfoId) {
        res = await updateProjectInfo(projectInfoId, data).catch(
          (e) => console.log
        );
      } else {
        res = await createProjectInfo(data).catch((e) => console.log);
      }

      if (res.success) {
        toast.success(
          projectInfoId ? 'Project Info updated.' : 'Project Info created.'
        );

        setProjectInfoId(res.data.id);
        clearPublishWebsiteError();
        updateFormV2(PAGE,'id',res.data.id);
        updatePageState(PAGE, formState.FORM_SAVED);
        setProjectPreviewable();

        console.log(res, " res project info");
        if (targetPath) {
          props.history.push(targetPath);
        }
      } else {
        setTargetPath('');
      // console.log('++++++++++++++++++++++',res);

        toast.error(res.data);
      }
    },
  });

  const handleFormikChange = (e, key) => {

    //console.log('formik',e,key);
    //console.log('change?',e.target.value===formik.values[key]);
    if(e.target.value!==formik.values[key]){
      updateFormV2(PAGE,key,e.target.value);
      formik.handleChange(e);
      }
  };

  const showUploadErrors = (error) => {
    setAlertType('ERROR');
    setAlertTitle('Upload error');
    setAlertMessage(error);
    setAlertVisible(true);
  };

  const waitForUser = (loc) => {
    setAlertType('SAVE_WARNING');
    setAlertTitle('Unsaved data');
    setAlertMessage('Save and go?');
    setAlertVisible(true);
    setTargetPath(loc);
    console.log(loc);
  };

  const sf = () => {
    formik.handleSubmit();
  };

  useEffect(()=>{

    if(formik.isSubmitting && !formik.isValid){

      let key = Object.keys(formik.errors)[0];
      
      let errorMsg =formProperties[key].label  +': ' + formik.errors[Object.keys(formik.errors)[0]];
      toast.error(errorMsg);
    }
  },[formik.isSubmitting])


  useEffect(()=>{
    if(formik.dirty){
      updatePageState(PAGE, formState.FORM_UNSAVED);
    }
  },[formik.dirty]);

  
  return (
    <>
      <Prompt
        when={targetPath === ''}
        message={(nextLocation) => {
           //console.log(nextLocation,formik);
          if (
            formik.isValid &&
            formik.dirty &&
            formik.submitCount === 0
          ) {
            waitForUser(nextLocation.pathname);
          } else {
            return true;
          }
          return false;
        }}
      />

      {form&&<div className="row">
        <div className="col-md-12 mx-auto">
        <div style={{ display: 'flex'}}>
          <h4 className="page-title mt-1">
            <img src={titleIcon} style={{ marginRight: '3px' }} /> 1. Project
            Info{' '}
          </h4>
          <InfoBox position="bottom" title="Project Info">
            <p>Please complete all fields! Once completed, click “Submit”. Then click “Preview” at the top to check the content you have entered. Your project will open in a new tab. Close that tab after previewing and continue your project setup by clicking on “Next step”.</p>
          </InfoBox>
          </div>
          <div className="rounded-box boxShadow p-4 mt-3">
            <form ref={ref} onSubmit={formik.handleSubmit}>
              <div className="row small-gutters px-2">
                <div className="col-md-7">
                  <FormInput
                    id={'name'}
                    label='Project name'
                    type='text'
                    onChange={(e) => handleFormikChange(e, 'name')}
                    onBlur={formik.handleBlur}
                    value={formik.values['name']}
                    error={
                      formik.touched['name'] &&
                      formik.errors['name']
                        ? formik.errors['name']
                        : null
                    }
                  />
                </div>
                <div className="col-md-5">
                  <FormInput
                    id={'token_symbol'}
                    upperCase={true}
                    label='Token symbol'
                    help='Min 1 - max 10 characters. Capital letters and/or numbers.'
                    type='text'
                    onChange={(e) => handleFormikChange(e, 'token_symbol')}
                    onBlur={(e)=>{formik.handleBlur(e)}}
                    value={formik.values['token_symbol']}
                    error={
                      formik.touched['token_symbol'] &&
                      formik.errors['token_symbol']
                        ? formik.errors['token_symbol']
                        : null
                    }
                  />
                </div>
              </div>

              <FormInput
                id={'short_description'}
                label='Intro description'
                help='Min 10 - max 200 characters.'
                type='textarea'
                onChange={(e) => handleFormikChange(e, 'short_description')}
                onBlur={(e)=>{formik.handleBlur(e)}}
                value={formik.values['short_description']}
                error={
                  formik.touched['short_description'] &&
                  formik.errors['short_description']
                    ? formik.errors['short_description']
                    : null
                }
              />

              <FormInput
                id={'long_description'}
                isRich={true}
                label='Detailed description'
                help='Min 10 - 5000 characters.'
                type='textarea'
                onChange={(e) => handleFormikChange(e, 'long_description')}
                onBlur={(e)=>{formik.handleBlur(e)}}
                value={formik.values['long_description']}
                error={
                  formik.touched['long_description'] &&
                  formik.errors['long_description']
                    ? formik.errors['long_description']
                    : null
                }
              />
              <FormInput
                id={'footer'}
                isRich={true}
                label='Footer text'
                help='Min 10 - 5000 characters.'
                type='textarea'
                onChange={(e) => handleFormikChange(e, 'footer')}
                onBlur={(e)=>{formik.handleBlur(e)}}
                value={formik.values['footer']}
                error={
                  formik.touched['footer'] &&
                  formik.errors['footer']
                    ? formik.errors['footer']
                    : null
                }
              />

              <div className="row images">
                <div className="col-md-3">
                  <FormInput
                    id={'small_banner'}
                    label='Small banner'
                    help='(png/jpg)'
                    mbNone={true}
                    DISABLED_dimensions={{width:750,height:750}}
                    type='file'
                    accept={formProperties['small_banner'].accept}
                    value={formik.values['small_banner']}
                    onChange={(e) => {
                      //updateForm(PAGE, small_banner, e);
                      formik.setFieldValue('small_banner', e);
                      updateFormV2(PAGE,'small_banner', e);
                    }}
                    onBlur={formik.handleBlur}
                    onErrors={showUploadErrors}
                    multiUploadAllowed={false}
                    error={
                      formik.touched['small_banner'] &&
                      formik.errors['small_banner']
                        ? formik.errors['small_banner']
                        : null
                    }
                  />
                  <p className='small'>Recommended size:<br/>750x750px</p>
                </div>
                <div className="col-md-9 mt-3 mt-md-0">
                  <FormInput
                    id={'large_banner'}
                    label='Large banner'
                    help='(png/jpg)'
                    mbNone={true}
                    DISABLED_dimensions={{width:3840,height:1000}}
                    type='file'
                    accept={formProperties['large_banner'].accept}
                    value={formik.values['large_banner']}
                    onChange={(e) => {
                      //updateForm(PAGE, large_banner, e);
                      formik.setFieldValue('large_banner', e);
                      updateFormV2(PAGE,'large_banner', e);
                    }}
                    onBlur={formik.handleBlur}
                    onErrors={showUploadErrors}
                    error={
                      formik.touched['large_banner'] &&
                      formik.errors['large_banner']
                        ? formik.errors['large_banner']
                        : null
                    }
                  />
                  <p className='small text-right'>Recommended size: 3840x1000px</p>
                </div>
              </div>

              {/* <button className="btn" type="submit">Submit</button> */}
            </form>
          </div>
        </div>
      </div>}

      <AlertModal
        visible={alertVisible}
        hideModal={() => {
          setAlertVisible(false);
          console.log('hide');
        }}
        saveFunc={sf}
        type={alertType}
        modalData={{
          title: alertTitle,
          description: alertMessage,
          buttonText: alertButtonLabel,
        }}
      />

   
      <SSPFooter
        prev=""
        next="/social-media"
        submit={{
          label: projectInfoId ? 'Update Project Info' : 'Submit Project Info',
          handleSubmit: formik.handleSubmit,
        }}
      />
    </>
  );
};

export default ProjectInfo;
