import React, { useContext, useEffect, useState } from 'react';
import { CheckCircleFill, ClockHistory, XCircleFill } from 'react-bootstrap-icons';
import './DashboardPanel.css';
import { state } from '../../MissionControl';
import { SpinnerCircular } from 'spinners-react';

const DashboardPanel = ({icon, title, cta,restartBtn, components, disabled, comingSoon, disableDisable})=>{
	
	const [restartEnabled,setRestartEnabled] = useState(false);
	const [inProgress,setInProgress] = useState(false);

	useEffect(()=>{
		
		if(components && components.length > 0 ){
			//console.log('Dashboard panel components',components);
			let enable = false;
			let progress = false;
			components.forEach(item => {
				if(item.restart===true){
					enable=true;
				}

				if(item.status==state.PROGRESS && disableDisable!==true){
					progress=true;
				}
			});
			setRestartEnabled(enable);
			setInProgress(progress);
		}

	},[components])
	
	
	
	
	return (
		<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-4 dashboard-panel" style={{opacity:disabled?'0.4':'1'}}>
			<div className={`rounded-box mc-box`}>
				<img className="d-block mx-auto mc-icon" src={icon} />
				<h4 className="subtitle">{title}</h4>
				
				{comingSoon?
				<div className="satus-container row small-gutters mb-4 text-center">
					<h4 className="mx-auto mt-3 subtitle">Coming soon</h4>
				</div>
				:
				<div className="satus-container row small-gutters mb-4">
				
				{(components && components.length > 0 )?
				<>
					{components.map((item,idx)=>{return(
						<div key={idx} className="col-6">
							<div className={`status-box  ${item.status==state.ERROR?'error':''}`}>
								<span>{item.label}</span>
								<>
									{item.status==state.SUCCESS &&
										<CheckCircleFill size="18px" color="#000" />
									}

									{item.status==state.PROGRESS &&
										<ClockHistory size="18px" color="#000" />
									}

									{item.status==state.ERROR &&
										<XCircleFill size="18px" color="#fff" />
									}
								</>

							</div>
						</div>
					)})}
				</>
				:
				<div className='spinner-container mt-4'>
					<SpinnerCircular size="32" color="#ff692b"/>
				</div>
				}
					
				</div>
				}

			<div className="button-container mt-auto">
				{typeof cta.action === "string" || restartEnabled?
					<>
						{(typeof cta.action === "string") &&
							<a href={cta.action} target="_blank" className='btn borderedNavLink mission single'>{cta.label}</a>
						}
						
						{(restartBtn && restartEnabled)&&
                                  <button className={`borderedNavLink ${typeof cta.action !== "string"?'single':''} mission ml-1`}
                                  disabled={disabled}
                                  onClick={() => { restartBtn() }}
                                >
                                  RESTART
                                </button>
                        }
					</>
					:
					<button className={`borderedNavLink mission single ${disabled?'disabled':''}`}
						onClick={() => {cta.action()}} disabled={disabled||inProgress}>
						{inProgress?<><ClockHistory size="18px" color="#ff692b" /> DEPLOYING</>:cta.label}
					</button>
				}
			</div>
		</div>
	</div>
	);
}

export default DashboardPanel;