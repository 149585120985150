import { BigNumber, ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toast';
import { createOrUpdateSale, getLayerInfo, getProjectInfoById, getProjectStatus, getSaleForProject, getSocialMedia, getWalletShares, publishProject } from '../../components/Api';
import { contractKeys, DataContext } from '../../components/DataStore/DataStore';
import SSPFooter from '../../components/SSPFooter/SSPFooter';
import titleIcon from '../../assets/images/page-title-icons/contract.png';
import Web3Ctx from '../../components/Context/Web3Ctx';
import {
	CheckCircleFill,
	XCircleFill,
  } from 'react-bootstrap-icons';

import './Reveal.css';

import useConfirm from '../../components/common/useConfirm';
import config from '../../config';
import FormInput from '../../components/common/FormInput';
import { getContractAddress } from 'ethers/lib/utils';
import { getContract, getContractByAddress } from '../../components/Utils/GetContract';
import { SpinnerCircular } from 'spinners-react';
import tokenContractAbi from '../../contracts/TokenContract.json';
import BorderedNavigationLink from '../../components/visual-utils/navigation-links/BorderedNavigationLink';

const Reveal = (props) => {
	const {onboard,address,handleConnect,getProvider,setChain,chainId} = useContext(Web3Ctx);
	const { currentProjectId, deployStatus, projectChain } = useContext(DataContext);
	
	
	const location = useLocation();
	const history = useHistory();

	const supportedChains=config.CHAINS;

	
	const [txInProgress, setTxInProgress] = useState(false);
	const [txHash, setTxHash] = useState(null);
	const [startReveal, setStartReveal] = useState(null);
	const [tokenContract, setTokenContract] = useState(null);
	const [selectedChainIdx, setSelectedChainIdx] = useState(0);

	const [mintedSupply,setMintedSupply]=useState(0);
	const [revealsSoFar,setRevealsSoFar]=useState(0);
	const [revealed,setRevealed]=useState(0);
	const { isConfirmed } = useConfirm();

	useEffect(()=>{
		if(projectChain){
			console.log('PROJECT CHAIN',projectChain)
			const idx = supportedChains.findIndex(c => {
				return parseInt(c.id,16) === projectChain;
			});
			if(idx>-1){
				setSelectedChainIdx(idx);
			}
		}
	},[projectChain])

	useEffect(()=>{

		const initContract = async ()=>{
			let ethersProvider = await getProvider(projectChain);

			if(deployStatus.contracts.token){
				let token = new ethers.Contract(deployStatus.contracts.token, tokenContractAbi.abi, ethersProvider);
				if(token){
					setTokenContract(token);
				}
			}
		}

		//console.log(targetChain,chainId);
		if(projectChain && deployStatus?.contracts?.token){
			initContract();
		}

	},[projectChain,deployStatus]);

	useEffect(()=>{
		

		if(tokenContract){
			getRevealData();
		}
	},[tokenContract])

	useEffect(()=>{
		const revealThem = async()=>{
			console.log('REVEAL');

			let provider = getProvider(chainId);
			let tc = tokenContract.connect(provider.getSigner());

			let tx = await tc.revealAtCurrentSupply().catch(handleError)

			if(tx){
				setTxHash(tx.hash);
				setTxInProgress(true);
				let receipt = await tx.wait().catch(e=>{
				  setTxInProgress(false);
				  handleError(e);
				  return;
				});
			}
			await getRevealData();
			setTxInProgress(false);
			setStartReveal(false);
		}


		//console.log('fdsasdssadsad', startReveal,chainId,projectChain)

		if(startReveal && chainId===projectChain){
			revealThem();
		}

	},[startReveal,chainId,projectChain])

	const getRevealData = async ()=>{

		let ms = await tokenContract.mintedSupply().catch(e=>console.log);
		let revealCount = await tokenContract.currentRevealCount().catch(e=>console.log);
		let reveal = await tokenContract.reveals(revealCount).catch(e=>console.log);

		console.log('Minted Supply',ms);
		console.log('Reveal count',revealCount);
		console.log('Last reveal data',reveal);

		if(ms &&  reveal){
			setMintedSupply(Number(ms));
			setRevealsSoFar(revealCount);
			setRevealed(Number(reveal.RANGE_END));
		}
	}

	const revealAtCurrentSupply = async () =>{
		const confirmed = await isConfirmed('Reveal at current supply','This can take up to 10 minutes depending on VRF Chainlink response speed.','OK');

		if(chainId!==projectChain){
			await setChain(projectChain);
			setStartReveal(true);
			return;
		}
		setStartReveal(true);
	}
	
	const handleError = (e) => {
		console.error('handle error',e);
		if (e.error && e.error.message) {
		  toast.error(e.error.message);
		} else if (e.message) {
		  toast.error(e.message);
		} else if (e.reason) {
		  toast.error(e.reason);
		}
	  };

	

	return (
		<>
			<div className="row" style={{ marginBottom: '80px' }}>
				<div className="col-lg-8 mx-auto">
						<h4 className="page-title mt-1">
							<img src={titleIcon} style={{ marginRight: '3px' }} /> 10. Reveal
						</h4>
				</div>
					<div className="rounded-box boxShadow p-4 mt-3 col-lg-8 mx-auto">
						<div className={`publish-box-content`}>
							<p className="mb-2">Number of reveals: <span className="bolder">{revealsSoFar}</span> </p>
							<p className="mb-2">Minted supply: <span className="bolder">{mintedSupply}</span></p>
							<p className="mb-2">Revealed: <span className="bolder">{revealed}</span></p>
							<p className="mb-2">Unrevealed: <span className="bolder">{(mintedSupply-revealed)<0?0:mintedSupply-revealed}</span></p>

							{txInProgress&&
								<>
									<div className='text-center'>
										<SpinnerCircular size="32" color="#ff692b"/>
									</div>
									{txHash!=null&&<p className="text-center mt-2 mb-0">Transaction in progress. View on <a href={supportedChains[selectedChainIdx].blockExplorerUrl+'/tx/'+txHash} target="_blank">Block Explorer</a>.</p>}
								</>
								
							}
						</div>
					</div>

					<SSPFooter
						prev=""
						next=""
						isSubmitEnabled={mintedSupply-revealed>0 && !txInProgress}
						submit={{
						label: 'Reveal at current supply',
						handleSubmit: revealAtCurrentSupply,
						}}
					/>
				
			</div>

		</>
	)
}

export default Reveal;