import { SpinnerCircular, SpinnerCircularSplit, SpinnerInfinity } from 'spinners-react';
import RoundedBox from '../visual-utils/RoundedBox';
import './AlertModal.css';
const TxInProgressModal = (props) => {
  const {visible,txHash} = props;
  const styleConfig = {
    width: '100%',
    maxWidth: '508px',
  };

  return (
    <>
      {visible && (
        <div
          className="modal warning-modal"
          style={{ display: 'block',backgroundColor:"rgba(0,0,0,0.8)" }}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <RoundedBox styleConfig={styleConfig}>
              <div className="modal-content text-center">
                <h5 className="modal-title mb-4" id="exampleModalLongTitle">
                  Transaction in progress
                </h5>
                <SpinnerCircular 
                  className='mx-auto mb-4' 
                  size={49}
                  thickness={100}
                  color="#ff692b"
                  secondaryColor="#ddd"/>
                <p>You can check the transaction status on the{' '}
                  <a 
                    href={txHash}
                    target="_blank"
                    rel="noopener noreferrer">Block Explorer</a>.
                  </p>
              </div>

            </RoundedBox>
          </div>

        </div>
      )}
    </>
  );
};

export default TxInProgressModal;
