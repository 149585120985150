
import axios from 'axios';
import config from '../../config';


const MISSION_CONTROL_API = config.MISSION_CONTROL_API;


/* AUTH */

export const checkAccessToken=()=>{
	return new Promise (async(resolve,reject)=>{

		let acc = sessionStorage.getItem('access_token');
		//TODO: token validation, timed out, etc
		
		if(acc){
			resolve({success:true});
		}
		resolve({success:false});
	});
}


export const checkAuth = ()=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest('api/me').catch(e=>{console.log('PER ME',e, e.status);err=e});
		if(res){
			console.log('auth succ');
			resolve({success:true,data:res.data.data});
		}else{
			console.log('auth fail',JSON.stringify(err));
			console.log('STATUS',err.status);

			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}


export const logIn = (data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest('api/login',data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}




/* ACCOUNT */
export const createAccount = async(data)=>{
		return new Promise (async(resolve,reject)=>{
			let err = '';
			const res = await postRequest('api/accounts',data).catch(e=>{console.log('errorrrr',e);err=e});
			if(res){
				resolve({success:true,data:res.data.data});
			}else{
				resolve({success:false,message:err?.response?.statusText?err?.response?.statusText:err,status:err?.response?.status});
			}
		});
}

export const getAccountByAddress = async(address)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/accounts/address/${address}`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			console.log(res);
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText?err?.response?.statusText:err,status:err?.response?.status});
		}
	});
}

export const updateAccountByAddress = async(data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPutRequest('api/accounts',data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}



/* PROJECT */

export const createProject = async(data)=>{
		return new Promise (async(resolve,reject)=>{
			let err = '';
			const res = await authPostRequest('api/projects',data).catch(e=>{resolve({success:false, message:e})});
			if(res){
				resolve({success:true,data:res.data.data});
			}else{
				console.log('create failed',res)
				resolve({success:false,message:err.message});
			}
		});
}

export const deleteProject = async(projectId)=>{
		return new Promise (async(resolve,reject)=>{
			let err = '';
			const res = await authDeleteRequest(`api/projects/${projectId}`).catch(e=>{console.log('errorrrr',e);err=e});
			if(res){
				resolve({success:true,data:res.data.data});
			}else{
				resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
			}
		});
}

export const getProjectStatus = async(projectId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectId}`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}


/* GET PROJECTS BY ACCOUNT */
export const getProjects = async(accountId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/account/projects`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}


/* PROJECT INFO SMALL BANNER UPLOAD */

export const uploadSmallBanner = async(data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest('api/projectinfo/smallbanner',data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}
/* PROJECT INFO LARGE BANNER UPLOAD */

export const uploadLargeBanner = async(data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest('api/projectinfo/largebanner',data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}
/* PROJECT INFO PRE-REVEAL IMAGE UPLOAD */

export const uploadPreRevealImage = async(projectId,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/projectinfo/pre-reveal-image`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}


/* PROJECT INFO GET */
export const getProjectInfoById = async(projectId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectId}/projectinfo`).catch(e=>{console.log(e.toJSON().status);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* PROJECT INFO CREATE */

export const createProjectInfo = async(data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest('api/projectinfo',data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

export const getProjectNameById = async(projectId)=>{
    return new Promise(async(resolve)=>{

      const res= await getProjectInfoById(projectId).catch(e=>console.log);
	  console.log('BYID',res)
      if(res.success){
        resolve(res.data.name?res.data.name:'Unnamed');
      }else{
        resolve('?');
      }

    });
  }


/* PROJECT INFO PUT */
export const updateProjectInfo = async(id,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPutRequest(`api/projectinfo/${id}`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message,data:err});
		}
	});
}
/* UPDATE REVEAL DATA */

//projects/{projectId}/projectinfo/revealdata
export const updateProjectInfoRevealData = async(projectid,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPutRequest(`api/projects/${projectid}/projectinfo/revealdata`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}





/* GET SOCIAL MEDIA TYPES */ 
export const getSocialMediaTypes = async()=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/socialmedia-types`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* GET SOCIAL MEDIA FOR PROJECT */ 
export const getSocialMedia = async(projectID)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectID}/socialmedia`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* POST SOCIAL MEDIA FOR PROJECT */ 
export const createSocialMedia = async(projectID,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/socialmedia/${projectID}`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

/* IMAGES AND LAYERS GET */
export const getLayerInfo = async(projectId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectId}/layerinfo`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}
/* IMAGES AND LAYERS POST */
export const createLayerInfo = async(projectId,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/layerinfo`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

/* IMAGES AND LAYERS UPDATE */
export const updateLayerInfo = async(layerInfoId,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPutRequest(`api/layerinfo/${layerInfoId}`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

export const updateSide = async(sideId,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPutRequest(`api/sides/${sideId}`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

/* IMAGES AND LAYERS GET */

export const getSides = async(projectId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectId}/sides`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}




export const getLayers = async(sideId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/sides/${sideId}/layers`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* IMAGES AND LAYERS UPDATE */
export const updateLayers = async(layerId,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPutRequest(`api/layers/${layerId}`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err});
		}
	});
}
/* IMAGES AND LAYERS GET ALL LAYER IMAGES */
export const getImagesByLayerId = async(layerId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/layers/${layerId}/images`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}


/* IMAGES AND LAYERS UPLOAD LAYER IMAGES */
export const uploadLayerImages = async(layerID,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/layers/${layerID}/images/upload`,data).catch(e=>{console.log('errorrrr',e);err=e});
		
		console.log('API RES', res,err);

		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message?err.message:err});
		}
	});
}

export const deleteLayerImages = async(layerID)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authDeleteRequest(`api/layers/${layerID}/images`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

export const updateLayerImageName = async(imageId,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPutRequest(`api/images/${imageId}`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}
export const deleteLayerImage = async(imageId)=>{
	console.log('delete image',imageId);
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authDeleteRequest(`api/images/${imageId}`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}




/* GET UTILITY TYPES */ 
export const getUtilityTypes = async()=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/utility-traits/types`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* GET UTILITY TRAITs */ 
export const getUtilityTraitsOfProject = async(projectID)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectID}/utility-traits`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* POST UTILITY TRAIT */ 
export const createUtilityTrait = async(data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/utility-traits`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			console.log(err);
			resolve({success:false,message:err.message});
		}
	});
}

/* UPDATE UTILITY TRAIT */ 
export const updateUtilityTrait = async(traitID,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPutRequest(`api/utility-traits/${traitID}`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			//console.log('??????',err);
			resolve({success:false,message:err.message});
		}
	});
}

const handleErrors=(e)=>{
	console.log(e,e.request,e.response);
}

/* DELETE UTILITY TRAIT */ 
export const deleteUtilityTrait = async(traitID,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authDeleteRequest(`api/utility-traits/${traitID}`).catch(e=>{console.log(e.response);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* ASSIGN UTILITY TRAITS */ 
export const assignUtilityTraits = async(projectID)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectID}/utility-traits/distribute`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

/* NFT GENERATE, NFT GET */
export const generateNFTs = async(projectId, data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/nfts/generate`, data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data.nfts});
		}else{
			resolve({success:false,message:err});
		}
	});
}

export const getNFTs = async(projectId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectId}/nfts`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data.nfts});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* GET SALE TYPES */ 
export const getSaleTypes = async()=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/sale-types`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* GET A CERTAIN TYPE OF SALE */ 
export const getSaleForProject = async(projectId,saleType)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectId}/sales/type/${saleType}`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* UPLOAD WHITELIST FOR PRESALE */ 
export const uploadWhitelistForPresale = async(projectId, whitelist)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/sales/whitelist`,whitelist).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

/* CREATE OR UPDATE SALE */ 
export const createOrUpdateSale = async(projectId, data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/sales`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err});
		}
	});
}

/* PUBLISH PROJECT */ 
export const publishProject = async(projectId,slug)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/publish`,{project_id:projectId,slug:slug}).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

export const deployMetaData = async(projectId, rpc, wss)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/deploy`,{project_id:projectId, rpc_url:rpc, wss_rpc_url:wss}).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message?err.message:err.response.data});
		}
	});
}


export const restartMetaData = async(communityId)=>{

	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/metadata-server/restart`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message?err.message:err.response.data});
		}
	});
}

export const restartUTC = async(communityId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/utility-trait-center/restart`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message?err.message:err.response.data});
		}
	});
}
export const restartMPL = async(communityId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/marketplace/restart`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message?err.message:err.response.data});
		}
	});
}
export const restartSBA = async(communityId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/stickerbook/restart`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message?err.message:err.response.data});
		}
	});
}
export const restartLeaderboard = async(communityId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/leaderboard/restart`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message?err.message:err.response.data});
		}
	});
}

export const restartVault = async(communityId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/vault/restart`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message?err.message:err.response.data});
		}
	});
}

export const addTokenAddress = async(communityId,tokenAddress)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		
		
		//api/projects/cid/{communityId}/token-address/add
		const res = await authPostRequest(`api/projects/cid/${communityId}/token-address/add`,{address:tokenAddress}).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message?err.message:err.response.data});
		}
	});
}


/* COMMUNITY HEALTHCHECK */ 
export const communityHealthcheck = async(communityId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/healthcheck/${communityId}`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,...res.data});
		}else{
			resolve({success:false,message:err.message?err.message:err.response.data});
		}
	});
}


/* GET IMAGE UPLOAD STATUS*/ 
export const getUploadStatus = async(projectId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectId}/metadata-server/status`).catch((e)=>{console.log('errorrrr',e);err=e})
		
		console.log('RESULT',res);
		
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message, data:err.response.data});
		}
	});
}
/* GET PROJECT SESSION*/ 
export const getSession = async(projectId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/session`,projectId).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}


/* GET TIMEZONES */
export const getTimezones = async()=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/timezones`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}

/* OPENSEA */

/* 
	imagePath:
		logo-image
		banner-image
		featured-image
*/

export const uploadOpenseaImage = async(projectId,imagePath,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/openseainfo/${imagePath}`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}
export const uploadCommunitySpaceImage = async(projectId,data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/community-space-images/upload`,data).catch(e=>{console.log('errorrrr',e);err=e});
		
		console.log(JSON.stringify(err));
		
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

export const launchCommunitySpace = async(communityId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/community-space/deploy`).catch(e=>{console.log('errorrrr',e);err=e});
		
		console.log(JSON.stringify(err));
		
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}


export const launchUTC = async(communityId, rpc, wss, etherscan)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/utility-trait-center/deploy`,{rpc_url:rpc, wss_rpc_url:wss, etherscan_url:etherscan}).catch(e=>{console.log('errorrrr',e);err=e});
		
		console.log(JSON.stringify(err));
		
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

export const launchSBA = async(communityId, rpc, wss, etherscan)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/stickerbook/deploy`,{rpc_url:rpc, wss_rpc_url:wss, etherscan_url:etherscan}).catch(e=>{console.log('errorrrr',e);err=e});
		
		console.log(JSON.stringify(err));
		
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

export const launchMPL = async(communityId, rpc, etherscan)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/marketplace/deploy`,{rpc_url:rpc, etherscan_url:etherscan}).catch(e=>{console.log('errorrrr',e);err=e});
		
		console.log(JSON.stringify(err));
		
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}
export const launchLeaderboard = async(communityId, rpc, etherscan)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/leaderboard/deploy`,{rpc_url:rpc, etherscan_url:etherscan}).catch(e=>{console.log('errorrrr',e);err=e});
		
		console.log(JSON.stringify(err));
		
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

export const launchVault = async(communityId, rpc, wss,etherscan)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/cid/${communityId}/vault/deploy`,{rpc_url:rpc,wss_rpc_url:wss, etherscan_url:etherscan}).catch(e=>{console.log('errorrrr',e);err=e});
		
		console.log(JSON.stringify(err));
		
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}


///projects/{project}/openseainfo
export const createOpenSeaInfo = async(projectId, data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/openseainfo`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}


export const updateOpenSeaInfo = async(projectId, data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPutRequest(`api/projects/${projectId}/openseainfo`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message});
		}
	});
}

export const getOpenSeaInfo = async(projectId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectId}/openseainfo`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}



/* WALLET SHARES */

export const setWalletShares = async(projectId, data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/projects/${projectId}/shares`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			console.log('EEEE',err);
			resolve({success:false,message:err});
		}
	});
}

export const getWalletShares = async(projectId)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authGetRequest(`api/projects/${projectId}/shares`).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err?.response?.statusText,status:err?.response?.status});
		}
	});
}


//SLUG validation

export const validateSlug = (communityId,slug) =>{
	return axios.get(config.GALAXIS_API+`is-valid-slug/${communityId}/`+slug);
}



/* 
	**** CONFIG SERVER ENDPOINTS ****
*/

export const getGlobal = (endpoint) =>{
	return axios.get(config.CONFIG_SERVER_URL+'api/get_data_value/Global/'+endpoint);
}

export const getConfigData = (endpoint) =>{
	return axios.get(config.CONFIG_SERVER_URL+'api/get_data_value/'+endpoint);
}

export const setJson = (data) =>{
	return axios.post(config.CONFIG_SERVER_URL+'api/set_from_json/',data);
}


/*
	new config server endpoints (inside mcb)
*/

export const getConfig = async(data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/configserver/get`,{endpoint: `/api/get_data_value/${data}`}).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message, data:err.data});
		}
	});
}

export const setConfig = async(data)=>{
	return new Promise (async(resolve,reject)=>{
		let err = '';
		const res = await authPostRequest(`api/configserver/set`,data).catch(e=>{console.log('errorrrr',e);err=e});
		if(res){
			resolve({success:true,data:res.data.data});
		}else{
			resolve({success:false,message:err.message, data:err.data});
		}
	});
}




const headers = {
	'Content-Type': 'multipart/form-data',
  }
  


const authPostRequest = (endPoint, data) => {
	let token = sessionStorage.getItem('access_token');

	let config = {
		headers: {
		  'Authorization': 'Bearer ' + token
		}
	  }

	return new Promise((resolve, reject) => {
		axios
		  .post(MISSION_CONTROL_API + endPoint, data,config)
		  .then((res) => {
			resolve(res);
		  })
		  .catch((error) => {
			let eMsg = 'Error';
			if (error.response) {
			  if (error.response.data) {
				console.log('error response data',error.response.data)

				eMsg = error.response.data.message?error.response.data.message:'Error!';
				if (error.response.data.errors && error.response.data.errors) {
				  Object.keys(error.response.data.errors).forEach((i) => {
					console.log('foreach...',i);
					eMsg += ' ' + error.response.data.errors[i];
				  });
				}
			  }
			}else{
				eMsg = error;
			}

			//console.log('EMSG',JSON.stringify(eMsg));
			reject({message:eMsg});
		  });
	  });
};
const postRequest = (endPoint, data) => {
	return new Promise((resolve, reject) => {
		axios
		  .post(MISSION_CONTROL_API + endPoint, data)
		  .then((res) => {
			resolve(res);
		  })
		  .catch((error) => {
			let eMsg = 'Error';
			if (error.response) {
			  if (error.response.data) {
				eMsg = error.response.data.message?error.response.data.message:error.response.data;
				if (error.response.data.errors && error.response.data.errors) {
				  Object.keys(error.response.data.errors).forEach((i) => {
					eMsg += ' ' + error.response.data.errors[i];
				  });
				}
			  }
			}
			reject(eMsg);
		  });
	  });
};

// const postFormDataRequest = (endPoint, data) => {
// 	return axios.post(MISSION_CONTROL_API+endPoint,data,{
// 		headers:headers
// 	});
// }

const authPutRequest = (endPoint, data) => {
  return new Promise((resolve, reject) => {
	let token = sessionStorage.getItem('access_token');

	let config = {
		headers: {
		  'Authorization': 'Bearer ' + token
		}
	  }

    axios
      .put(MISSION_CONTROL_API + endPoint, data,config)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let eMsg = 'Error';
			if (error.response) {
			  if (error.response.data) {
				//console.log('error response data',error.response.data)

				eMsg = error.response.data.message?error.response.data.message:'Error!';
				if (error.response.data.errors && error.response.data.errors) {
				  Object.keys(error.response.data.errors).forEach((i) => {
					//console.log('foreach...',i);
					eMsg += ' ' + error.response.data.errors[i];
				  });
				}
			  }
			}else{
				eMsg = error;
			}

			//console.log('EMSG',JSON.stringify(eMsg));
			reject({message:eMsg});
      });
  });
};
const putRequest = (endPoint, data) => {
  return new Promise((resolve, reject) => {


    axios
      .put(MISSION_CONTROL_API + endPoint, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let eMsg = 'Error';
        if (error.response) {
          if (error.response.data) {
            eMsg = error.response.data.message;
            if (error.response.data.errors && error.response.data.errors) {
              Object.keys(error.response.data.errors).forEach((i) => {
                eMsg += ' ' + error.response.data.errors[i];
              });
            }
          }
        }
        reject(eMsg);
      });
  });
};

const authGetRequest = (endPoint) => {
	let token = sessionStorage.getItem('access_token');

	let config = {
		headers: {
		  'Authorization': 'Bearer ' + token
		}
	  }

	return axios.get(MISSION_CONTROL_API+endPoint,config);
}

const galaxisGetRequest = (endPoint) => {
	return axios.get(MISSION_CONTROL_API+endPoint);
}


const configServerGetRequest = (endPoint) => {
	return axios.get(config.CONFIG_SERVER_URL+endPoint);
}

const authDeleteRequest = (endPoint, data) => {
	let token = sessionStorage.getItem('access_token');

	let config = {
		headers: {
		  'Authorization': 'Bearer ' + token
		}
	  }
	return axios.delete(MISSION_CONTROL_API+endPoint,config);
}

const deleteRequest = (endPoint, data) => {
	return axios.delete(MISSION_CONTROL_API+endPoint);
}




const parseError = (error)=>{
	let eMsg="Error";
	if (error.response) {
		if(error.response.data){
			eMsg=error.response.data.message;
			if(error.response.data.errors && error.response.data.errors){
				Object.keys(error.response.data.errors).forEach(i=>{
					eMsg+=' '+(error.response.data.errors[i]);
				})
			}
		}
	}
	return eMsg;
}


//Fake section :)

/* export const getMetaDataStatus = (projectId)=>{
	const fakeStatus = {
		lastStatus: {
			id: Number(projectId),
			reason: "Check your images. Content not allowed.",
			status: {
				name: "Rejected",
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString()
			}
		}
	} 

	return new Promise (async(resolve,reject)=>{
			resolve({success:true,data:fakeStatus});
	});

} */



export  const apiUploadImages = (file) => {
	const imageUrls = [
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/8/boba.jpg',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/7/sirocco-hd-print-logo.jpg',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/5/guardian.jpg',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/28/card/ethercards-02.jpg',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/22/morbid.png',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/69/card/ocean-sunset.jpg',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/39/card/reach-for-the-stars.jpg',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/73/card/callerid.jpg',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/46/card/grownyourown.jpg',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/41/card/f11.jpg',
		'https://ether-cards.mypinata.cloud/ipfs/QmSSJqd9RBjtCSVgBb1x4kg37aD7jfH4n4A6qUD5JwDWin/12/card/believer.gif'];



	return new Promise ((resolve)=>{
		setTimeout(()=>{
							let success = true;
							let data=imageUrls[Math.floor(Math.random()*imageUrls.length)];
							let error = '';
							if(Math.random()<0.1){
								success = false;
								error = "Failed to upload: "+data.substring(data.lastIndexOf('/') + 1);
								console.log(error);
							}
							resolve({success,data,error});

						},200);
	})
}