import './ConfigMarketPlace.css';
import leftTrick from '../../assets/images/leftTrick.svg';
import rightTrick from '../../assets/images/rightTrick.svg';
import leftTopTrick from '../../assets/images/leftTopTrick.svg';
import rightTopTrick from '../../assets/images/rightTopTrick.svg';
import mpaIcon from '../../assets/images/marketplace/marketplace-icon.png';

import RoundedBox from '../../components/visual-utils/RoundedBox';
import FormInput from '../../components/common/FormInput';
import { useContext, useEffect, useState } from 'react';
import BorderedNavigationLink from '../../components/visual-utils/navigation-links/BorderedNavigationLink';
import { contractKeys, DataContext } from '../../components/DataStore/DataStore';
import { communityHealthcheck, getConfig, getGlobal, getProjectInfoById, launchCommunitySpace, launchMPL, launchSBA, launchUTC, setJson } from '../../components/Api';
import { toast } from 'react-toast';
import Web3Ctx from '../../components/Context/Web3Ctx';
import { BigNumber, ethers } from 'ethers';
import useConfirm from '../../components/common/useConfirm';
import config from '../../config';
import DeployInProgressModal from '../../components/common/DeployInProgressModal';
import useInterval from '../../components/common/useInterval';
import { set } from 'immutable';
import axios from 'axios';
import { getContractByAddress } from '../../components/Utils/GetContract';
import { PlusCircle } from 'react-bootstrap-icons';
import clearIcon from '../../assets/images/community-space/clear.svg';
import BackdropModal from '../../components/common/BackdropModal';
import TxInProgressModal from '../../components/common/TxInProgressModal';

const ConfigMarketPlace = (props) => {

	const collectionId = 1;

	const waitDuration = 25*60*1000; //5 minutes

	const chainNames = {
		1: 'mainnet',
		5: 'goerli',
		137: 'polygon',
		80001: 'mumbai'
	}

	const styleConfig = {
		borderRadius: '20px',
		padding:"1.5rem",
		marginTop: '20px',
		backgroundColor: '#fff',
	  };

	  const modalConfig = {
		position: 'relative',
		width: '100%',
		maxWidth: '508px',
	  };

	const {communityId,projectChain} = useContext(DataContext);
	const {address,getProvider,setChain,chainId} = useContext(Web3Ctx);

	const { isConfirmed } = useConfirm();

	const [marketplaceRpcUrl,setMarketplaceRpcUrl] = useState('');
	const [marketplaceWssUrl,setMarketplaceWssUrl] = useState('');

	const [selectedMarketplaceChain,setSelectedMarketplaceChain] = useState(0);

	const [deployInProgressVisible,setDeployInProgressVisible] = useState(false);
	const [deployMessage,setDeployMessage] = useState('');
	const [marketplaceDashboardUrl,setMarketplaceDashboardUrl] = useState('');
	const [pollInterval, setPollInterval] = useState(null);
	const [customRpc, setCustomRpc] = useState(false);

	const [customRpcUrl,setCustomRpcUrl] = useState('');
	const [customWssUrl,setCustomWssUrl] = useState('');

	const [doSubmit,setDoSubmit] = useState(false);

	const [marketPlaceAddress,setMarketPlaceAddress] = useState('');
	//const [collectionId,setCollectionId] = useState(1);
	
	const [approveInProgress,setApproveInProgress]=useState(false);
	const [txInProgress,setTxInProgress]=useState(false);
	const [txHash,setTxHash]=useState(null);

	const [royaltyFee,setRoyaltyFee] = useState('');
	const [wallets,setWallets] = useState ([
												{address:address,share:''}
											]);


	const [errors,setErrors]=useState({
		"rpc-url":'',
		"wss-url":''
	});

	useEffect(()=>{		
		if(communityId && projectChain){
			getMpa();
		}
	},[communityId,projectChain]);

	const getMpa = async () => {
		let mpa = null;
		console.log('------======= getting marketplace address =========------');
		let provider =  await getProvider(projectChain);

		const galaxisRegistryOnTargetChain = await getContractByAddress('GalaxisRegistry',config.GALAXIS_REGISTRY,provider).catch(e => console.log('err:', e));
			
		if(!galaxisRegistryOnTargetChain){
			toast.error('Target chain not supprted.');
			return;
		}


		const communityListAddress = await galaxisRegistryOnTargetChain.getRegistryAddress('COMMUNITY_LIST').catch(e=>console.log);
				
		console.log('communityListAddress',communityListAddress);

		
		let communityList = null;

		if(communityListAddress && communityListAddress!==ethers.constants.AddressZero ){
			communityList = await getContractByAddress('CommunityList',communityListAddress,provider);
			console.log('community list contract',communityList);
		}

		if(communityList){
			console.log(communityId)
			let community = await communityList.communities(Number(communityId)).catch(e=>console.log);

			console.log('communityt', community);

			if(community && community.registry){
				let communityRegistry = await getContractByAddress('CommunityRegistry',community.registry,provider);
			
				console.log('communityRegistry',communityRegistry);
				if(communityRegistry){
					let marketplace = await communityRegistry.getRegistryAddress(`MARKETPLACE_${collectionId}`);
					
					console.log(`MARKETPLACE_${collectionId}`,marketplace);
					if(marketplace){
						setMarketPlaceAddress(marketplace);
						mpa = marketplace;
					}
				}
			}
		}
		return mpa;
	}


	const onChainSelect = (chainIdx)=>{
		setSelectedMarketplaceChain(chainIdx);
		console.log('CHAIN CHANGE',config.CHAINS[chainIdx]);

		if(config.CHAINS[chainIdx].id==="0x13881" || config.CHAINS[chainIdx].id==="0x89"){
			setMarketplaceRpcUrl(config.POLYGON_UTC_RPC_URL);
			setMarketplaceWssUrl(config.POLYGON_UTC_WSS);
		}else{
			setMarketplaceRpcUrl(config.RPC_BASE + config.UTC_INFURA_ID);
			setMarketplaceWssUrl(config.WSS_BASE + config.UTC_INFURA_ID);
		}
	}

	
	const handleError = (e) => {
		console.error('handle error',e);
		if (e.error && e.error.message) {
		  toast.error(e.error.message);
		} else if (e.message) {
		  toast.error(e.message);
		} else if (e.reason) {
		  toast.error(e.reason);
		}
	};

	useEffect(()=>{

		const checkDeployStatus = async()=>{
/* 
			const data = {some:'value',oher:'other value'};
			const params = {
				object: `{"1": { "TEST": {"Config": ${JSON.stringify(data)}}}}`,
			};

			let setRes = await setJson(params).catch(e=>console.log());

			console.log('NEW CONFIG SERVER SET RESULT',setRes);




			let cfgRes = await getConfig('1/TEST').catch(e=>console.log);

			console.log('NEW CONFIG SERVER GET RESULT',cfgRes);

 */

			let res = await marketplaceHealtcheck(true);
			console.log('deployed',res);

			switch (res){
				case '0':
					{
						setDeployMessage('The deployment of the Marketplace started successfully. Please wait for the servers to come online...');
						setDeployInProgressVisible(true);
						console.log('poll START');
						setPollInterval(10000);
						break;
					}
			case '1':
				{
					let marketplaceUrl=`${config.DOMAIN}/${communityId}/marketplace/1`;
					setDeployMessage('Marketplace has been deployed.');
					setMarketplaceDashboardUrl(marketplaceUrl);
					setDeployInProgressVisible(true);

					break;
				}
			case '2':
				{

					break;
				}
			default:
				{
					//not deployed yet
					setDeployMessage('');
					setMarketplaceDashboardUrl('');
					setDeployInProgressVisible(false);	
					break;
				}
			}
		}

		if(projectChain){

			console.log('projectChain',projectChain);


			let chIdx = config.CHAINS.findIndex(c => {
				return parseInt(c.id,16) === projectChain;
			});

			console.log('CH IDX', chIdx);

			if(chIdx>-1){
				console.log('chain found:', config.CHAINS[chIdx]);
				onChainSelect(chIdx);

			}else{
				toast.error('Chain id '+projectChain+' is not supported');
				return;
			}
			checkDeployStatus();
		}
	},[projectChain])



	const marketplaceHealtcheck = async(muted)=>{
		console.log('MARKETPLACE healtcheck called');

		let healthRes = await communityHealthcheck(communityId).catch(e=>console.log);
     	console.log('HEALTCHECK RESULT', healthRes);

		if(healthRes.success && healthRes.marketplace){

			if(healthRes.marketplace.status && Number(healthRes.marketplace.status)>1 && !muted){
				setPollInterval(null);
				toast.error('Marketplace deployment failed.');
				setDeployInProgressVisible(false);
			}

			if(healthRes.marketplace.status && Number(healthRes.marketplace.status)===1){
				
				let mplUrl=`${config.DOMAIN}/${communityId}/marketplace/1`;
				setDeployMessage("Marketplace is up and running.");
				setMarketplaceDashboardUrl(mplUrl);
				console.log('STOP polling (success)');
				setPollInterval(null);
			}

			let mplDeployStarted = localStorage.getItem('MPLACE'+communityId);
			if(healthRes.marketplace.status===null && mplDeployStarted){
				//deploy started, but somehow the healthcheck gives back null
				return '0';
			} 

			return healthRes.marketplace.status;
		}
		
		return null;
	}

	useInterval(
		marketplaceHealtcheck,
		pollInterval
	);


	const submitData = async()=>{
		console.log('chains',chainId,projectChain);
		if(chainId!==projectChain){
			await setChain(projectChain);
			setDoSubmit(true);
			return;
		}
		_handleDeployMPL();
	}



	const _handleDeployMPL = async ()=>{

		let rpc = customRpc?customRpcUrl:marketplaceRpcUrl;
		//let wss = customRpc?customWssUrl:marketplaceWssUrl;
		let etherscan = config.CHAINS[selectedMarketplaceChain].blockExplorerUrl;
		
		if(!rpc){
			toast.error('RPC url is required');
			return;
		}
		/* if(!wss){
			toast.error('WSS url is required');
			return;
		} */


		//removed, the new factory's deeploy fn doesn't need royalty info
		/* if(!marketPlaceAddress || marketPlaceAddress===ethers.constants.AddressZero){
		
			let err = [];
			let sharesAreValid = true;
			let sumOfShares = 0;
			for(let i=0;i<wallets.length;i++){
				if(!validateAddress(i)){
				err.push({path:'wallet_address',message:'Invalid address'});
				}
		
				if(!validateShare(i)){
				err.push({path:'wallet_share',message:'Invalid amount'});
				sharesAreValid=false;
				}else{
				sumOfShares+=Number(wallets[i].share);
				}
			}

			if(sumOfShares!==100){
				sharesAreValid=false;
				err.push({path:'wallet_share',message:'The sum of shares must be 100'});
			}
			
			if(!royaltyFee){
				err.push({path:'royalty_fee',message:'Required'});
			}

			if(Number(royaltyFee)>10){
				err.push({path:'royalty_fee',message:'The maximum value is 10%'});
			}

			if(err.length){
				setErrorMessages(err,true);
				toast.error(err[0].path.replaceAll('_',' ')+': '+err[0].message);
				return;
			}else{
				setErrorMessages(err,true);
			}
		}else{
			console.log('marketplace already deployed, no validations needed (ATM :))');
		} */


		let mpa = marketPlaceAddress;
		if(!mpa || mpa===ethers.constants.AddressZero){
			console.log('NO CONTRACT FOUND, DEPLOYING...');

			let selectedChainIdx = 0;
			const idx = config.CHAINS.findIndex(c => {
				return parseInt(c.id,16) === projectChain;
			});
			if(idx>-1){
				selectedChainIdx=idx;
			}


			setApproveInProgress(true);
			let provider = await getProvider(projectChain);

			const galaxisRegistryOnTargetChain = await getContractByAddress('GalaxisRegistry',config.GALAXIS_REGISTRY,provider).catch(e => console.log('err:', e));
			
			if(!galaxisRegistryOnTargetChain){
				toast.error('Target chain not supprted.');
				return;
			}


			const marketplaceFactoryAddress = await galaxisRegistryOnTargetChain.getRegistryAddress('MARKETPLACE_FACTORY').catch(e=>console.log);
			let mf =null
			
			if(marketplaceFactoryAddress && marketplaceFactoryAddress!== ethers.constants.AddressZero){
				console.log('marketplaceFactoryAddress',marketplaceFactoryAddress);

				mf = await getContractByAddress('MarketplaceFactory',marketplaceFactoryAddress,provider);
			}
			
			let factory = null;
			

			if(mf){
				factory = mf.connect(provider.getSigner());
				console.log(factory);
			}

/* 			let _wallets = [];
			let _shares = []

			wallets.forEach(i=>{
				_wallets.push(i.address);
				_shares.push(Number(i.share)*100);
			});
 */
			if(factory){

				console.log('Community ID',Number(communityId));
				console.log('Token ID',collectionId);
				console.log('Royalty fee',Math.floor(Number(royaltyFee)*100));
				//console.log('Wallets',_wallets);
				//console.log('Shares',_shares);
	
				let tx = await factory.deploy(
					Number(communityId),
					collectionId).catch(handleError);
				setApproveInProgress(false);

				console.log('tx', tx);
				if(tx){
					localStorage.setItem('marketplaceHash'+Number(communityId),tx.hash);
					setTxHash(`${config.CHAINS[selectedChainIdx].blockExplorerUrl}/tx/${tx.hash}`);
					setTxInProgress(true);
					let receipt = await tx.wait().catch(e=>{
						setTxInProgress(false);
						handleError(e);
						return;
					});
					console.log('receipt',receipt);
					setTxInProgress(false);
				}else{
					setTxInProgress(false);
					
				}

			}else{
				setApproveInProgress(false);
				toast.error('Factory contract not found.');
				return;
			}
			
			mpa = await getMpa();
		}else{
			console.log('marketplace already deployed, no deploy needed (but update later))');
		}

		if(mpa===null || mpa === ethers.constants.AddressZero){
			toast.error('Marketplace address not found');
			return;
		}

		setDeployInProgressVisible(true);
		setDeployMessage('');
		
		setDeployMessage('Marketplace deploy is in progress.');
		//toast.success('UTC configuration saved.');
	
		let launchResponse = await launchMPL(Number(communityId),rpc,etherscan).catch(e=>console.log)
	
		console.log('launch response', launchResponse);
		if(launchResponse && launchResponse.success){
			setDeployMessage('The deployment of the Marketplace started successfully. Please wait for the servers to come online...');
			localStorage.setItem('MPLACE'+communityId,new Date().getTime());
			console.log('poll START');
			setPollInterval(10000);
		}else{
			setDeployInProgressVisible(false);
			toast.error('Marketplace deployment failed. ');
		}

		

	//	console.log('config server setJSON result',res);
	}

	const checkUrl = async(e)=> {

		let url = e.target.value;
		let id = e.target.id;
		let err = {...errors};

		if(url===''){
			err[id]="Required field";
			setErrors(err);
			return;
		}


		let valid = false;

		if(id==='rpc-url'){
			valid = isRpcURL(url);
		}else{
			valid = isWssURL(url);
		}

		if(!valid){
			err[id]="Must be a valid url";
			setErrors(err);
			return;
		}

		if((config.CHAINS[selectedMarketplaceChain].id==="0x13881" || config.CHAINS[selectedMarketplaceChain].id==="0x89")){
			if(url.indexOf('quiknode')>-1){
				err[id]="Quiknode is not supported on polygon.";
				setErrors(err);
				return;
			}
		}
		err[id]="";
		setErrors(err);
	}

	const isRpcURL = (str) => {
		var urlRegex = '^(?:(?:https)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		var url = new RegExp(urlRegex, 'i');
		return str.length < 2083 && url.test(str);
   }
	const isWssURL = (str) => {
		var urlRegex = '^(?:(?:wss)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
		var url = new RegExp(urlRegex, 'i');
		return str.length < 2083 && url.test(str);
   }

    /* const addNewAddress = ()=>{

		if(validateAddress(wallets.length-1) && validateShare(wallets.length-1)){
			let tmpWallets =  [...wallets];
			console.log('WALLETS',tmpWallets)

			tmpWallets.push({address:'',share:''});
			setWallets(tmpWallets);
		}else{
		toast.warn('Fill the last entry before adding a new one :)');
		}
	}

	const onAddressChange = (val,idx) => {
		let tmpWallets = [...wallets];
		tmpWallets[idx].address = val;
		setWallets(tmpWallets);
	}

	const onShareChange = (val,idx) => {
		let tmpWallets = [...wallets];
		tmpWallets[idx].share = val;
		setWallets(tmpWallets);
	}

	const removeAddressAt = async (idx)=>{
		let tmpWallets =  [...wallets];
		if(tmpWallets.length<2){
			return;
		}

		const confirmed = await isConfirmed('Remove address','Are you sure?','DELETE','BACK');

		if(!confirmed){
			return;
		}

		tmpWallets.splice(idx,1);
		setWallets(tmpWallets);
	}

	const validateAddress = (idx) => {
		console.log(wallets[idx].address)

		if(!ethers.utils.isAddress(wallets[idx].address)){
			return false;
		}
		return true;
	}

	const validateShare = (idx) => {
		if(wallets[idx].share===''){
			return false;
		}

		let shareValue = Number(wallets[idx].value);

		if(shareValue<=0){
			return false;
		}

		if(shareValue>100){
			return false;
		}
		return true;
	}
 */
	const setErrorMessages = (inner,displayAll) => {

		//	console.log('ERRDATA',inner)
		let tmpErrors = {};

		inner.forEach(err => {
		//	console.log(err.message, err.path);
			if(displayAll){
				tmpErrors[err.path]=err.message;
			}else{
				if(configData[err.path]!==''){
					tmpErrors[err.path]=err.message;
				}
			}
		});

		setErrors(tmpErrors)
	}

	return (
		<div className={`community-space-outer`}>
			<div className="container">
				<div className="row">
					<div className="col-md-12">

					

						<div
							style={{
							display: 'flex',
							justifyContent: 'space-between',
							}}
						>
							<img src={leftTopTrick} style={{ maxHeight: '50px' }} />
							<img src={rightTopTrick} style={{ maxHeight: '50px' }} />
						</div>

						<div className="col-md-9 mx-auto mb-4">
					
							<img src={mpaIcon} className="title-icon mx-auto d-block"/>
							<h5 className="mb-1 text-center font-weight-light f14">
								MARKETPLACE
							</h5>
							<h4 className="title mb-5">Marketplace Configurations</h4>
							<RoundedBox className="box-content" styleConfig={styleConfig} hasShadow={true}>
								<h4 className="title mb-3">About Marketplace</h4>
								<p className="text-center">You are ready to deploy the Marketplace for your community. The Marketplace enables you to offer your Membership cards for sale on the platform in a secure way. You can offer to purchase your cards one-by-one after you set a price for them.</p>
								<p className="text-center mb-3">By trading cards, the Marketplace provides a safe environment to cater the needs of the community members.</p>
							</RoundedBox>
						</div>

						

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<img src={leftTrick} style={{ maxHeight: '50px' }} />
							<img src={rightTrick} style={{ maxHeight: '50px' }} />
						</div>


						<div className="col-md-7 mx-auto mt-4">
					{/* 
							{(!marketPlaceAddress || marketPlaceAddress == ethers.constants.AddressZero || 1==1)&&
							<>
								<p className="mb-0 mt-4">Set the royalty fee  (max 10%)</p>
				
								<FormInput
									id={'royalty_fee'}
									label='Royalty Fee'
									type='number'
									value={royaltyFee}
									onChange={(e)=>{setRoyaltyFee(e.target.value)}}
									error={errors.royalty_fee}
								/>
						
									

								<p className="mb-0 mt-4">Set up the shares, add the address and its shares (%)</p>

								{wallets.map((item,idx)=> {return(
									<div className='row' key={'market'+idx}>
										<div className='col-7 pr-0'>
											<FormInput
												id={'share-address-1'}
												label='Address'
												type='text'
												value={wallets[idx].address}
												disabled={idx===0}
												onChange={(e)=>{onAddressChange(e.target.value,idx)}}
											/>
										</div>
										<div className='col-4  pr-1'>
											<FormInput
												id={'share-address-percentage'}
												label='Share'
												type='text'
												value={wallets[idx].share}
												onChange={(e)=>{onShareChange(e.target.value,idx)}}
											/>
										</div>
										<div className='col-1 px-1'>
										{idx>0&&
											<img className='remove-wallet' 
													src={clearIcon} 
													style={{marginTop:'calc(50% - 6px)'}} 
													onClick={()=>removeAddressAt(idx)}
													/>
										}
										</div>
									</div>
								)})
								}

								{errors.wallet_address&&
									<p className="help-text error">{errors.wallet_address}</p>
								}
								{errors.wallet_share&&
									<p className="help-text error">{errors.wallet_share}</p>
								}

								<button className="btn-with-icon my-4" onClick={addNewAddress}>
								<PlusCircle
									className="primary"
									size={36}
									style={{ marginRight: '10px' }}
								/>
								Add new address
								</button>

							</>}	
 */}
								
							



							<FormInput
								id='mplTargetChain'
								label={'Marketplace\'s home chain'}
								type='select'
								options={config.CHAINS.map((c,i)=>{return {label:c.label, value:i}})}
								value={selectedMarketplaceChain}
								onChange={e=>onChainSelect(e.target.value)}
								disabled={true}
							/>

							<FormInput 
								id='own-stuff'
								type='checkbox'
								label='I want to use my own RPC provider'
								onChange={(e)=>setCustomRpc(e.target.checked)}
							/>
						
						{customRpc&&<>

								{/* {(config.CHAINS[selectedMarketplaceChain].id==="0x13881" || config.CHAINS[selectedMarketplaceChain].id==="0x89")&&
									<p className='mb-1 text-warning small'>Qucknode rpc and wss on polygon is not yet supported.</p>
								} */}


								<FormInput
									id='rpc-url'
									label='RPC Url'
									type='text'
									value={customRpcUrl}
									onChange={e=>setCustomRpcUrl(e.target.value)}
									onBlur={e=>checkUrl(e)}
									error={errors['rpc-url']}
								/>

							{/* 	<FormInput
									id='wss-url'
									label='WSS Url'
									type='text'
									value={customWssUrl}
									onChange={e=>setCustomWssUrl(e.target.value)}
									onBlur={e=>checkUrl(e)}
									error={errors['wss-url']}
								/> */}
							</>
						}

							

						</div>


						<div className='text-center mt-5'>
							<BorderedNavigationLink
								type="submit"
								className="mx-auto"
								onClick={submitData}
								enabled={communityId && !(customRpc && (errors['rpc-url']  || customRpcUrl==='' ))}>
								DEPLOY MARKETPLACE
							</BorderedNavigationLink>
						</div>


					</div>


				</div>

				
				
				
		
			</div>
			<BackdropModal visible={approveInProgress}/>
            <TxInProgressModal visible={txInProgress} txHash={txHash}/>
			
			<DeployInProgressModal 
				visible={deployInProgressVisible} 
				titleText={'Deploying Marketplace'} 
				message={deployMessage}
				appButtonLabel={'MARKETPLACE'}
				destinationUrl={marketplaceDashboardUrl}	
			/>

		</div>
	)
}

export default ConfigMarketPlace;